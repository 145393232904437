import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080 1080" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  >
 
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M292.000000,1.000000 
	C373.022003,1.000000 454.044037,1.000000 535.531616,1.404616 
	C536.088928,4.696054 535.549561,7.411767 532.166748,7.830479 
	C528.603882,8.271486 527.951172,10.354486 527.968811,13.373244 
	C527.984253,16.018053 528.708191,20.005466 527.330505,21.057524 
	C523.305542,24.131161 520.661133,29.168911 516.800720,31.191559 
	C512.486450,33.452087 511.865051,35.662140 511.985291,39.468266 
	C512.064148,41.964294 512.002197,44.464848 511.999573,46.963367 
	C511.990540,55.587433 511.639404,55.938801 502.983856,56.000767 
	C496.251923,56.048965 496.030792,56.268909 496.002716,63.119793 
	C495.988403,66.617668 496.046997,70.116745 495.969849,73.613014 
	C495.940948,74.924004 496.029968,77.081100 495.367615,77.399002 
	C490.744110,79.618103 488.795624,85.406982 485.065887,87.077217 
	C480.260162,89.229309 479.901184,91.753494 479.984131,95.711914 
	C480.057434,99.208221 479.971924,102.707558 479.996002,106.205284 
	C480.016541,109.195724 479.003540,111.139313 475.806213,112.098900 
	C474.297028,112.551826 473.229950,114.481697 471.965424,115.745262 
	C467.888580,119.818939 464.176056,124.392159 459.574371,127.745201 
	C457.470581,129.278137 455.981445,130.186081 456.000427,132.819031 
	C456.032837,137.316040 455.975128,141.813614 455.993805,146.310806 
	C456.006378,149.338898 454.978912,151.590210 451.657532,151.957367 
	C448.575104,152.298096 447.895935,154.292145 448.013184,156.886353 
	C448.141785,159.732071 447.263184,161.570801 444.132996,162.263367 
	C440.061920,163.164108 438.477814,165.888016 439.174255,170.107391 
	C439.390686,171.418625 439.042084,173.048569 438.400482,174.234024 
	C436.414978,177.902573 434.163757,181.427292 431.684326,185.156021 
	C429.236145,185.877258 427.119354,186.450317 424.774414,186.787537 
	C423.383789,186.077972 422.221313,185.604263 420.437653,184.877411 
	C420.703491,186.878860 420.843201,187.930740 420.736664,189.108887 
	C420.001038,189.493439 419.511688,189.751724 418.628052,190.032043 
	C415.860199,190.767700 413.528198,191.845291 411.104675,192.122314 
	C405.327545,192.782684 400.873779,198.777252 394.262573,196.228394 
	C393.773376,196.039780 392.530334,197.547165 391.769928,198.385910 
	C390.785309,199.471985 390.142334,201.362625 388.988434,201.714783 
	C383.982880,203.242416 378.828247,204.273712 373.765137,205.625320 
	C372.211090,206.040176 370.468445,206.569412 369.341827,207.616287 
	C365.403778,211.275467 361.697174,215.183792 357.539856,219.011139 
	C355.779541,219.346222 354.382050,219.667633 352.951965,219.646667 
	C352.198059,218.702225 351.476837,218.100143 350.755554,217.498047 
	C350.171326,219.013031 349.587097,220.528030 348.815979,222.334869 
	C343.220734,225.649872 337.708923,228.506027 332.445862,231.763931 
	C329.653809,233.492233 327.347839,235.989075 324.715942,237.999557 
	C323.331940,239.056763 321.750946,240.497360 320.202515,240.570480 
	C316.469696,240.746704 314.862976,242.892471 313.155914,245.764130 
	C311.689667,248.230606 309.285278,250.127853 307.386658,252.354355 
	C306.595306,253.282318 306.039673,254.411240 305.313782,255.770172 
	C305.239441,256.532074 305.228149,256.972565 305.041138,257.637085 
	C304.371094,258.124481 303.876740,258.387878 303.145721,258.366028 
	C302.265594,257.741669 301.622162,257.402557 300.708496,257.104614 
	C299.979706,257.423126 299.521118,257.700470 298.857544,258.206177 
	C298.112335,259.299011 297.572083,260.163452 296.687439,261.087738 
	C292.577118,264.102783 288.811157,267.057983 284.978943,270.297119 
	C284.609436,271.070587 284.306213,271.560028 283.679749,272.109406 
	C282.588867,273.116577 281.821198,274.063843 281.011902,275.362610 
	C276.410828,280.269196 271.854340,284.827271 267.290985,289.378418 
	C263.770508,292.889465 259.891968,296.111877 256.802399,299.967316 
	C252.708466,305.076050 249.406448,310.812195 245.435928,316.028931 
	C241.166672,321.638153 236.355331,326.835510 232.102554,332.456207 
	C229.973495,335.270111 228.838531,338.826263 226.768890,341.696991 
	C221.798080,348.591919 217.501694,355.790924 214.666229,364.066193 
	C211.406631,365.578552 211.670670,367.738556 212.915894,370.316772 
	C212.903885,370.502808 212.858551,370.872894 212.533691,370.985443 
	C210.088058,372.532898 207.706833,373.713531 205.954819,375.508362 
	C205.058701,376.426392 205.289474,378.444427 205.004272,380.376831 
	C203.104614,385.615631 201.292145,390.476440 199.296722,395.260956 
	C197.171829,400.355957 194.892136,405.386932 192.642899,410.429291 
	C191.642029,412.673126 191.125412,415.754364 189.371964,416.903168 
	C186.342682,418.887817 184.887146,421.017609 184.975906,424.547241 
	C185.029678,426.685760 184.949707,428.896149 184.471588,430.968506 
	C181.352264,444.488647 178.422409,458.066650 174.737885,471.434814 
	C173.397232,476.298889 172.044312,480.893127 172.586792,485.944366 
	C171.264603,487.156555 169.944336,488.203552 169.459900,489.551056 
	C168.498764,492.224426 167.986557,495.060577 167.312317,497.835175 
	C166.889908,499.573456 166.298218,501.304260 166.149658,503.069336 
	C165.615570,509.414490 165.946899,515.923523 164.636124,522.091125 
	C162.617828,531.588074 161.248398,541.056335 160.908600,550.763184 
	C160.643250,558.343445 159.564835,565.896240 158.799927,573.456482 
	C158.718155,574.264648 158.385971,575.071289 158.052002,575.826477 
	C155.344543,581.949158 155.015594,587.993774 158.219223,594.105286 
	C158.872498,595.351624 158.992645,597.007202 158.955154,598.463684 
	C158.775589,605.437500 158.396652,612.406433 158.238678,619.380432 
	C158.206573,620.798706 158.814453,622.233215 159.138657,623.657654 
	C159.753922,626.360840 160.779831,629.040771 160.925064,631.768127 
	C161.443268,641.500427 161.513229,651.257202 162.074844,660.986145 
	C162.282242,664.578979 163.432602,668.111267 164.063766,671.686951 
	C165.325958,678.837585 166.404373,686.022766 167.787720,693.149231 
	C169.125137,700.038940 167.706299,701.969482 160.760315,701.999084 
	C159.094193,702.006165 157.427353,702.039917 155.762039,702.004761 
	C151.799652,701.921082 149.927658,703.853088 149.998474,707.797546 
	C150.037338,709.961975 150.229782,712.177429 149.842163,714.275513 
	C149.610855,715.527527 148.495041,717.497925 147.598114,717.601746 
	C141.903839,718.261169 141.904541,722.142090 141.959030,726.384094 
	C142.001938,729.723694 142.813721,733.048889 137.664352,734.486145 
	C134.972961,735.237488 133.215347,739.326904 131.021530,741.896240 
	C130.938385,741.993530 130.704239,741.968750 130.538986,741.988098 
	C126.937454,742.409973 125.971130,744.776489 125.994392,747.989136 
	C126.036621,753.820312 126.003044,759.652100 125.999336,765.483643 
	C125.993980,773.907410 125.993317,773.907410 117.410309,774.000671 
	C110.893417,774.071411 110.056679,774.933960 110.000885,781.647217 
	C109.992577,782.646851 109.950371,783.648987 110.002617,784.645874 
	C110.161583,787.679199 109.335419,789.648926 105.831367,790.263977 
	C104.436775,790.508667 103.342072,792.504272 102.127121,793.719116 
	C100.671028,795.175049 99.174522,796.596680 97.802811,798.129150 
	C96.480057,799.606812 94.720940,801.035522 94.194565,802.796875 
	C93.552681,804.944824 94.265244,807.463623 93.873962,809.732727 
	C93.629280,811.151672 92.560822,813.447083 91.636795,813.563599 
	C85.995377,814.275146 85.795372,818.059875 85.980171,822.339783 
	C86.087891,824.834351 86.127762,827.345642 85.968216,829.834595 
	C85.752243,833.203796 87.265511,836.953552 81.705650,838.471436 
	C79.020378,839.204468 77.260040,843.317993 75.066452,845.888550 
	C74.983376,845.985901 74.749237,845.961792 74.583939,845.980408 
	C70.993980,846.384399 69.963211,848.713074 69.995392,851.943542 
	C70.038544,856.275024 69.945221,860.608215 70.009743,864.939148 
	C70.052238,867.791382 69.225052,869.514648 66.044434,870.243347 
	C64.523941,870.591675 62.151859,872.891968 62.308762,874.006470 
	C63.711472,883.971252 59.443470,887.654419 49.874859,886.142883 
	C45.946934,890.165405 41.662262,893.916199 38.280521,898.354858 
	C37.061787,899.954468 38.395557,903.296814 37.838490,905.673706 
	C37.467705,907.255737 36.031475,909.454712 34.688313,909.776672 
	C30.929689,910.677979 29.928818,912.897034 29.986971,916.295410 
	C30.058228,920.459534 29.957802,924.626282 30.006393,928.791077 
	C30.038651,931.555908 29.149096,932.956238 26.239798,934.246277 
	C21.320854,936.427490 19.678892,942.761902 14.427769,945.044373 
	C13.962125,945.246765 14.060550,946.910095 14.023843,947.899597 
	C13.962113,949.563538 14.013473,951.231384 13.997825,952.897400 
	C13.858468,967.735229 15.750551,965.861206 1.000000,966.000000 
	C1.000000,810.977966 1.000000,655.955994 1.421317,500.466705 
	C6.366730,500.021881 10.621078,499.673798 11.942325,494.090179 
	C12.135108,493.275482 13.099690,492.222748 13.886338,492.036316 
	C19.678188,490.663788 19.919312,486.270905 20.077482,481.563812 
	C20.138807,479.738861 20.681959,476.635895 21.664339,476.345795 
	C28.126497,474.437469 27.924637,469.499176 28.112074,464.376801 
	C28.168398,462.837585 29.239429,461.147308 30.274363,459.874969 
	C31.934544,457.833862 33.937504,456.068634 35.824661,454.216431 
	C36.734760,453.323181 37.583858,452.013214 38.679554,451.725372 
	C43.262474,450.521271 43.904381,447.021118 43.964928,443.207214 
	C44.062778,437.043915 44.106819,430.875580 43.963184,424.714264 
	C43.884083,421.321167 45.155216,420.040344 48.620640,419.936615 
	C59.062737,419.624023 59.274540,419.138580 60.140888,408.262268 
	C60.275311,406.574677 62.146412,405.006439 63.268330,403.421143 
	C64.783058,401.280823 67.369751,399.315613 67.679619,397.029083 
	C68.300880,392.444824 67.316307,387.516571 73.694733,386.265808 
	C74.166367,386.173340 74.695587,385.304382 74.825066,384.714569 
	C75.549232,381.415894 77.656647,379.758057 80.773453,378.697479 
	C81.893768,378.316254 82.519867,376.497742 83.384071,375.344604 
	C84.246513,374.193878 84.931267,372.324524 86.028542,372.033997 
	C91.524246,370.578766 92.023598,366.476624 92.002579,361.922699 
	C91.987198,358.590485 91.742516,355.226868 92.130440,351.939819 
	C92.307968,350.435486 93.483223,348.196472 94.686607,347.817749 
	C99.170708,346.406464 99.869392,343.059601 99.970787,339.231079 
	C100.063385,335.734497 100.122734,332.228363 99.969795,328.736359 
	C99.822029,325.362427 101.113167,324.042511 104.598938,323.935822 
	C115.073021,323.615204 115.312408,323.127655 116.119469,312.281586 
	C116.239738,310.665253 117.533470,308.211761 118.832169,307.775116 
	C123.322182,306.265625 123.990623,302.864166 123.937042,299.084412 
	C123.882141,295.210968 123.840439,291.756866 128.787689,290.685577 
	C129.844757,290.456696 130.445892,288.427216 131.403488,287.358459 
	C132.657837,285.958527 134.061096,284.692413 135.393784,283.362213 
	C136.724945,282.033478 138.103012,280.745972 139.356842,279.347443 
	C140.313828,278.279938 140.914810,276.330627 142.008514,276.042389 
	C147.814163,274.512451 148.020828,270.108124 148.002563,265.444458 
	C147.990173,262.278839 147.735504,259.078796 148.128647,255.962189 
	C148.319366,254.450226 149.468536,252.203171 150.666779,251.826874 
	C155.145508,250.420319 155.870483,247.088013 155.970520,243.252762 
	C156.061722,239.756088 156.120758,236.250168 155.970047,232.757996 
	C155.825302,229.404633 157.070984,228.044769 160.577209,227.935089 
	C171.080414,227.606491 171.302612,227.109268 172.139130,216.305130 
	C172.270294,214.611069 173.809662,212.183731 175.288513,211.605148 
	C178.762726,210.245926 179.852509,207.656265 179.847687,204.603485 
	C179.840317,199.929916 179.701248,195.576004 185.643784,194.233643 
	C186.398895,194.063065 186.777863,192.337830 187.383392,191.365417 
	C188.067017,190.267593 188.626541,188.479874 189.556000,188.245544 
	C196.442764,186.509338 196.023331,181.168411 196.003235,175.941925 
	C195.987228,171.776581 196.115738,167.607010 195.967102,163.446930 
	C195.823914,159.439346 195.734756,155.742142 200.921921,154.646347 
	C201.868622,154.446350 202.852371,152.810898 203.190247,151.654373 
	C204.057053,148.687378 205.949402,148.001541 208.807892,147.946823 
	C218.793579,147.755585 219.495346,146.863983 220.086823,136.570068 
	C220.177872,134.985458 221.051239,132.399124 222.098190,132.090118 
	C227.844086,130.394302 227.862732,126.016953 228.083115,121.380318 
	C228.168640,119.581490 228.802704,116.563889 229.828842,116.258499 
	C236.061996,114.403450 235.912338,109.647957 236.095062,104.691322 
	C236.153870,103.096321 236.970474,100.446877 237.989059,100.147697 
	C243.989288,98.385292 243.752304,93.769554 244.142517,89.010666 
	C244.298477,87.108589 245.608307,84.309570 247.110718,83.706154 
	C250.770386,82.236313 251.699692,79.576813 251.913513,76.305412 
	C252.108765,73.318321 251.991699,70.310326 251.998718,67.311523 
	C252.015213,60.256687 252.011948,60.256683 259.022827,59.992386 
	C265.382019,59.752663 267.683411,58.010712 267.868866,52.345901 
	C268.014587,47.895939 267.548462,43.595638 273.433807,42.345200 
	C274.299774,42.161209 274.755798,40.211952 275.475372,39.128532 
	C276.186279,38.058178 276.820770,36.364769 277.782013,36.126274 
	C283.841522,34.622772 283.810150,30.026457 284.136444,25.195816 
	C284.266632,23.268362 285.479034,20.346443 286.936188,19.782484 
	C290.829132,18.275795 291.779022,15.464094 291.935242,11.994795 
	C292.100037,8.336312 291.989990,4.665452 292.000000,1.000000 
z"/>
<path fill="#5B595C" opacity="1.000000" stroke="none" 
	d="
M291.531342,1.000000 
	C291.989990,4.665452 292.100037,8.336312 291.935242,11.994795 
	C291.779022,15.464094 290.829132,18.275795 286.936188,19.782484 
	C285.479034,20.346443 284.266632,23.268362 284.136444,25.195816 
	C283.810150,30.026457 283.841522,34.622772 277.782013,36.126274 
	C276.820770,36.364769 276.186279,38.058178 275.475372,39.128532 
	C274.755798,40.211952 274.299774,42.161209 273.433807,42.345200 
	C267.548462,43.595638 268.014587,47.895939 267.868866,52.345901 
	C267.683411,58.010712 265.382019,59.752663 259.022827,59.992386 
	C252.011948,60.256683 252.015213,60.256687 251.998718,67.311523 
	C251.991699,70.310326 252.108765,73.318321 251.913513,76.305412 
	C251.699692,79.576813 250.770386,82.236313 247.110718,83.706154 
	C245.608307,84.309570 244.298477,87.108589 244.142517,89.010666 
	C243.752304,93.769554 243.989288,98.385292 237.989059,100.147697 
	C236.970474,100.446877 236.153870,103.096321 236.095062,104.691322 
	C235.912338,109.647957 236.061996,114.403450 229.828842,116.258499 
	C228.802704,116.563889 228.168640,119.581490 228.083115,121.380318 
	C227.862732,126.016953 227.844086,130.394302 222.098190,132.090118 
	C221.051239,132.399124 220.177872,134.985458 220.086823,136.570068 
	C219.495346,146.863983 218.793579,147.755585 208.807892,147.946823 
	C205.949402,148.001541 204.057053,148.687378 203.190247,151.654373 
	C202.852371,152.810898 201.868622,154.446350 200.921921,154.646347 
	C195.734756,155.742142 195.823914,159.439346 195.967102,163.446930 
	C196.115738,167.607010 195.987228,171.776581 196.003235,175.941925 
	C196.023331,181.168411 196.442764,186.509338 189.556000,188.245544 
	C188.626541,188.479874 188.067017,190.267593 187.383392,191.365417 
	C186.777863,192.337830 186.398895,194.063065 185.643784,194.233643 
	C179.701248,195.576004 179.840317,199.929916 179.847687,204.603485 
	C179.852509,207.656265 178.762726,210.245926 175.288513,211.605148 
	C173.809662,212.183731 172.270294,214.611069 172.139130,216.305130 
	C171.302612,227.109268 171.080414,227.606491 160.577209,227.935089 
	C157.070984,228.044769 155.825302,229.404633 155.970047,232.757996 
	C156.120758,236.250168 156.061722,239.756088 155.970520,243.252762 
	C155.870483,247.088013 155.145508,250.420319 150.666779,251.826874 
	C149.468536,252.203171 148.319366,254.450226 148.128647,255.962189 
	C147.735504,259.078796 147.990173,262.278839 148.002563,265.444458 
	C148.020828,270.108124 147.814163,274.512451 142.008514,276.042389 
	C140.914810,276.330627 140.313828,278.279938 139.356842,279.347443 
	C138.103012,280.745972 136.724945,282.033478 135.393784,283.362213 
	C134.061096,284.692413 132.657837,285.958527 131.403488,287.358459 
	C130.445892,288.427216 129.844757,290.456696 128.787689,290.685577 
	C123.840439,291.756866 123.882141,295.210968 123.937042,299.084412 
	C123.990623,302.864166 123.322182,306.265625 118.832169,307.775116 
	C117.533470,308.211761 116.239738,310.665253 116.119469,312.281586 
	C115.312408,323.127655 115.073021,323.615204 104.598938,323.935822 
	C101.113167,324.042511 99.822029,325.362427 99.969795,328.736359 
	C100.122734,332.228363 100.063385,335.734497 99.970787,339.231079 
	C99.869392,343.059601 99.170708,346.406464 94.686607,347.817749 
	C93.483223,348.196472 92.307968,350.435486 92.130440,351.939819 
	C91.742516,355.226868 91.987198,358.590485 92.002579,361.922699 
	C92.023598,366.476624 91.524246,370.578766 86.028542,372.033997 
	C84.931267,372.324524 84.246513,374.193878 83.384071,375.344604 
	C82.519867,376.497742 81.893768,378.316254 80.773453,378.697479 
	C77.656647,379.758057 75.549232,381.415894 74.825066,384.714569 
	C74.695587,385.304382 74.166367,386.173340 73.694733,386.265808 
	C67.316307,387.516571 68.300880,392.444824 67.679619,397.029083 
	C67.369751,399.315613 64.783058,401.280823 63.268330,403.421143 
	C62.146412,405.006439 60.275311,406.574677 60.140888,408.262268 
	C59.274540,419.138580 59.062737,419.624023 48.620640,419.936615 
	C45.155216,420.040344 43.884083,421.321167 43.963184,424.714264 
	C44.106819,430.875580 44.062778,437.043915 43.964928,443.207214 
	C43.904381,447.021118 43.262474,450.521271 38.679554,451.725372 
	C37.583858,452.013214 36.734760,453.323181 35.824661,454.216431 
	C33.937504,456.068634 31.934544,457.833862 30.274363,459.874969 
	C29.239429,461.147308 28.168398,462.837585 28.112074,464.376801 
	C27.924637,469.499176 28.126497,474.437469 21.664339,476.345795 
	C20.681959,476.635895 20.138807,479.738861 20.077482,481.563812 
	C19.919312,486.270905 19.678188,490.663788 13.886338,492.036316 
	C13.099690,492.222748 12.135108,493.275482 11.942325,494.090179 
	C10.621078,499.673798 6.366730,500.021881 1.421317,499.999725 
	C1.000000,333.758759 1.000000,167.517517 1.000000,1.000000 
	C97.687386,1.000000 194.375031,1.000000 291.531342,1.000000 
z"/>
<path fill="#49474A" opacity="1.000000" stroke="none" 
	d="
M1.000000,966.468628 
	C15.750551,965.861206 13.858468,967.735229 13.997825,952.897400 
	C14.013473,951.231384 13.962113,949.563538 14.023843,947.899597 
	C14.060550,946.910095 13.962125,945.246765 14.427769,945.044373 
	C19.678892,942.761902 21.320854,936.427490 26.239798,934.246277 
	C29.149096,932.956238 30.038651,931.555908 30.006393,928.791077 
	C29.957802,924.626282 30.058228,920.459534 29.986971,916.295410 
	C29.928818,912.897034 30.929689,910.677979 34.688313,909.776672 
	C36.031475,909.454712 37.467705,907.255737 37.838490,905.673706 
	C38.395557,903.296814 37.061787,899.954468 38.280521,898.354858 
	C41.662262,893.916199 45.946934,890.165405 49.874859,886.142883 
	C59.443470,887.654419 63.711472,883.971252 62.308762,874.006470 
	C62.151859,872.891968 64.523941,870.591675 66.044434,870.243347 
	C69.225052,869.514648 70.052238,867.791382 70.009743,864.939148 
	C69.945221,860.608215 70.038544,856.275024 69.995392,851.943542 
	C69.963211,848.713074 70.993980,846.384399 74.583939,845.980408 
	C74.749237,845.961792 74.983376,845.985901 75.066452,845.888550 
	C77.260040,843.317993 79.020378,839.204468 81.705650,838.471436 
	C87.265511,836.953552 85.752243,833.203796 85.968216,829.834595 
	C86.127762,827.345642 86.087891,824.834351 85.980171,822.339783 
	C85.795372,818.059875 85.995377,814.275146 91.636795,813.563599 
	C92.560822,813.447083 93.629280,811.151672 93.873962,809.732727 
	C94.265244,807.463623 93.552681,804.944824 94.194565,802.796875 
	C94.720940,801.035522 96.480057,799.606812 97.802811,798.129150 
	C99.174522,796.596680 100.671028,795.175049 102.127121,793.719116 
	C103.342072,792.504272 104.436775,790.508667 105.831367,790.263977 
	C109.335419,789.648926 110.161583,787.679199 110.002617,784.645874 
	C109.950371,783.648987 109.992577,782.646851 110.000885,781.647217 
	C110.056679,774.933960 110.893417,774.071411 117.410309,774.000671 
	C125.993317,773.907410 125.993980,773.907410 125.999336,765.483643 
	C126.003044,759.652100 126.036621,753.820312 125.994392,747.989136 
	C125.971130,744.776489 126.937454,742.409973 130.538986,741.988098 
	C130.704239,741.968750 130.938385,741.993530 131.021530,741.896240 
	C133.215347,739.326904 134.972961,735.237488 137.664352,734.486145 
	C142.813721,733.048889 142.001938,729.723694 141.959030,726.384094 
	C141.904541,722.142090 141.903839,718.261169 147.598114,717.601746 
	C148.495041,717.497925 149.610855,715.527527 149.842163,714.275513 
	C150.229782,712.177429 150.037338,709.961975 149.998474,707.797546 
	C149.927658,703.853088 151.799652,701.921082 155.762039,702.004761 
	C157.427353,702.039917 159.094193,702.006165 160.760315,701.999084 
	C167.706299,701.969482 169.125137,700.038940 167.787720,693.149231 
	C166.404373,686.022766 165.325958,678.837585 164.063766,671.686951 
	C163.432602,668.111267 162.282242,664.578979 162.074844,660.986145 
	C161.513229,651.257202 161.443268,641.500427 160.925064,631.768127 
	C160.779831,629.040771 159.753922,626.360840 159.138657,623.657654 
	C158.814453,622.233215 158.206573,620.798706 158.238678,619.380432 
	C158.396652,612.406433 158.775589,605.437500 158.955154,598.463684 
	C158.992645,597.007202 158.872498,595.351624 158.219223,594.105286 
	C155.015594,587.993774 155.344543,581.949158 158.052002,575.826477 
	C158.385971,575.071289 158.718155,574.264648 158.799927,573.456482 
	C159.564835,565.896240 160.643250,558.343445 160.908600,550.763184 
	C161.248398,541.056335 162.617828,531.588074 164.636124,522.091125 
	C165.946899,515.923523 165.615570,509.414490 166.149658,503.069336 
	C166.298218,501.304260 166.889908,499.573456 167.312317,497.835175 
	C167.986557,495.060577 168.498764,492.224426 169.459900,489.551056 
	C169.944336,488.203552 171.264603,487.156555 172.604675,486.353821 
	C172.341476,495.454681 171.680496,504.175323 170.735748,513.014587 
	C170.308228,514.124146 170.164490,515.115112 170.024246,516.479370 
	C170.021194,517.848022 170.014648,518.843445 170.005157,520.282593 
	C169.945587,522.459839 169.888947,524.193359 169.454758,526.001099 
	C168.395844,527.079407 167.195602,528.042847 167.113510,529.093628 
	C166.302414,539.477905 165.215469,549.872070 165.112991,560.272339 
	C164.979279,573.843994 165.666977,587.423767 165.845764,601.364502 
	C165.916504,606.588501 166.008530,611.461121 166.481018,616.296509 
	C166.576721,617.275818 168.109711,618.114685 168.980469,619.346069 
	C168.982010,620.110596 168.983200,620.547424 168.664825,621.119568 
	C166.205460,623.975769 165.198471,626.569885 168.232254,629.525452 
	C169.095978,630.366943 169.112610,632.192322 169.299118,633.595520 
	C170.316559,641.250244 171.192688,648.924377 172.279144,656.568848 
	C172.646103,659.150818 173.388382,661.694763 174.136551,664.203125 
	C174.986389,667.052368 177.064651,669.942932 176.801239,672.638916 
	C176.056366,680.262512 177.953339,687.743225 181.425018,693.619446 
	C185.138321,699.904541 184.314682,706.772949 187.105225,712.774902 
	C190.204514,719.440796 193.683258,725.888794 194.099564,733.430176 
	C194.107224,733.569031 194.310593,733.686829 194.382065,733.834106 
	C195.889069,736.940918 197.705643,739.940918 198.830780,743.180969 
	C201.698318,751.438782 205.024734,759.342102 211.181885,765.834595 
	C212.827179,767.569458 213.669647,770.087097 214.794083,772.289551 
	C216.497528,775.626282 218.140259,778.994263 219.779663,782.363159 
	C220.860962,784.585144 221.941086,786.810181 222.930038,789.073914 
	C223.921021,791.342468 224.539337,793.811462 225.773438,795.928955 
	C229.563583,802.432007 234.170731,808.356628 237.053879,815.513855 
	C240.259872,823.472595 248.420898,828.298157 251.870728,836.339722 
	C252.054092,836.767151 252.639099,837.007080 252.889893,837.423096 
	C254.331192,839.813538 255.745270,842.220886 257.132812,844.642944 
	C257.868866,845.927795 258.285431,848.144958 259.268982,848.417908 
	C265.929779,850.266602 264.900787,856.004883 266.074066,860.727112 
	C266.372528,861.928345 267.525024,863.652893 268.510925,863.822144 
	C273.097717,864.609924 273.875549,867.824890 273.957367,871.531921 
	C274.063904,876.361389 273.997101,881.194763 273.997955,886.026489 
	C274.000031,897.440491 274.002289,897.558960 262.539429,897.935791 
	C259.001556,898.052124 257.777191,899.475708 257.976532,902.795166 
	C258.096008,904.784851 258.349609,906.911499 257.798950,908.754272 
	C257.282684,910.482056 255.963043,913.083984 254.691589,913.275696 
	C249.646774,914.036255 249.828583,917.331116 249.978043,920.972412 
	C250.087265,923.633728 250.007462,926.303223 249.997452,928.968933 
	C249.967834,936.854858 248.892319,937.943176 241.097504,937.999390 
	C234.054214,938.050293 234.297043,938.064575 233.874710,945.228638 
	C233.714828,947.940735 232.552277,952.607910 231.152451,952.885681 
	C224.942368,954.118164 226.425247,958.438904 225.852951,962.391357 
	C225.493729,964.872375 224.122818,968.842346 222.537354,969.202576 
	C217.854294,970.266785 217.863586,973.109985 217.982880,976.591919 
	C218.068390,979.088196 218.069717,981.592163 217.982971,984.088379 
	C217.848755,987.950623 218.732086,991.829346 213.588150,994.088989 
	C210.491989,995.449219 208.422989,999.136353 205.861572,1001.747620 
	C205.667709,1001.945190 205.139404,1001.795471 204.913467,1001.983643 
	C201.159546,1005.109802 199.476059,1010.401428 194.269104,1012.113770 
	C193.857498,1012.249207 194.020462,1014.298584 194.010727,1015.459290 
	C193.975769,1019.624329 194.277176,1023.821228 193.838684,1027.940796 
	C193.638885,1029.817993 192.189606,1032.943970 190.994827,1033.099121 
	C185.197342,1033.851929 186.335388,1037.861816 185.861740,1041.615845 
	C185.523026,1044.300293 184.609436,1047.985107 182.697708,1049.131836 
	C180.205933,1050.626465 178.977615,1052.415161 177.213867,1054.431641 
	C173.909317,1058.209839 170.441635,1062.545044 166.430222,1065.078735 
	C162.942841,1067.281372 161.793274,1068.917358 161.971375,1072.504150 
	C162.111664,1075.329590 161.999832,1078.167603 162.000000,1081.000000 
	C108.418518,1081.000000 54.837040,1081.000000 1.000000,1081.000000 
	C1.000000,1042.979492 1.000000,1004.958374 1.000000,966.468628 
z"/>
<path fill="#2E282A" opacity="1.000000" stroke="none" 
	d="
M1081.000000,704.000000 
	C1081.000000,829.574036 1081.000000,955.148132 1081.000000,1081.000000 
	C1008.979370,1081.000000 936.958313,1081.000000 864.468628,1080.572144 
	C863.836792,1076.117432 863.870605,1072.409180 869.333252,1071.652222 
	C870.383240,1071.506836 871.738098,1069.040649 871.857727,1067.556885 
	C872.204651,1063.252686 872.071899,1058.902222 871.979553,1054.572876 
	C871.902954,1050.982910 872.849304,1048.543823 876.916199,1047.746826 
	C878.131592,1047.508667 879.504028,1045.418213 879.838257,1043.955566 
	C880.345825,1041.734497 879.565186,1039.196777 880.169739,1037.026978 
	C880.669617,1035.233032 882.173157,1032.649658 883.597778,1032.385132 
	C887.696228,1031.623779 888.190125,1029.186890 888.007202,1025.901367 
	C887.951721,1024.904907 888.154175,1023.874634 887.977478,1022.907593 
	C886.559448,1015.144470 897.724976,1016.246582 897.701904,1009.271362 
	C897.700562,1008.853882 900.920227,1008.308777 902.674316,1008.041077 
	C903.977112,1007.842224 905.339478,1007.916138 906.665588,1008.009033 
	C910.487183,1008.276733 912.119141,1006.598145 912.034180,1002.726440 
	C911.888000,996.065979 911.961609,989.399536 912.031494,982.736450 
	C912.046875,981.267761 911.969543,978.861633 912.723267,978.491028 
	C917.190247,976.295227 919.251526,970.735046 922.656616,969.117249 
	C927.743835,966.700256 928.247253,963.934937 927.998474,959.635620 
	C927.766968,955.633057 928.306824,952.349670 933.354065,951.666992 
	C934.368347,951.529907 935.589539,949.402405 935.852539,948.027527 
	C936.283691,945.773743 935.637451,943.299133 936.149658,941.080139 
	C936.545776,939.363708 937.860901,936.682739 939.041138,936.528870 
	C944.027283,935.878723 944.060364,932.707642 944.051086,928.966064 
	C944.041992,925.316956 943.108643,921.569275 948.459961,919.701843 
	C951.361328,918.689331 953.244995,914.765259 955.600891,912.166565 
	C955.690247,912.067932 955.936584,912.130859 956.078918,912.058105 
	C960.819153,909.637207 961.998413,903.229248 967.633972,901.680054 
	C968.030212,901.571167 967.970276,899.506042 967.982178,898.349121 
	C968.023315,894.351257 967.955872,890.351868 968.031616,886.354980 
	C968.056458,885.045776 967.949768,882.966492 968.657104,882.563904 
	C972.880798,880.159851 975.273560,874.832092 978.576904,873.202759 
	C983.827454,870.612976 984.276917,867.703430 984.000427,863.253845 
	C983.758911,859.366943 984.525330,856.384949 989.237732,855.696472 
	C990.292603,855.542419 991.568481,853.497925 991.838684,852.143433 
	C992.286926,849.896057 991.708313,847.448181 992.125732,845.188904 
	C992.426331,843.562073 993.497070,840.885620 994.474670,840.768494 
	C1000.997437,839.987183 1000.086731,835.344421 999.983704,831.085571 
	C999.895569,827.443420 1000.420227,824.516235 1004.912476,823.781311 
	C1006.083435,823.589783 1006.958374,821.656067 1007.991577,820.543213 
	C1011.820374,816.419128 1015.652527,812.298096 1019.511414,808.202209 
	C1019.696350,808.005981 1020.289429,808.176819 1020.451477,807.979126 
	C1021.661926,806.502991 1023.656555,805.062622 1023.819946,803.450439 
	C1024.287964,798.832092 1023.618164,794.088257 1024.202515,789.496521 
	C1024.449707,787.554565 1026.387329,784.855286 1028.134766,784.291138 
	C1031.209961,783.298401 1031.997925,781.704041 1032.027710,778.885803 
	C1032.057495,776.076843 1031.335449,771.702515 1032.760254,770.759033 
	C1036.964355,767.975281 1039.232666,762.384949 1042.917114,760.944458 
	C1048.356812,758.817810 1047.970703,755.727783 1048.000244,751.787354 
	C1048.052124,744.833252 1048.829712,744.053345 1055.768921,744.001099 
	C1056.768433,743.993591 1057.772827,743.930908 1058.766724,744.004333 
	C1062.672485,744.292908 1064.160889,742.413025 1064.030029,738.625366 
	C1063.880859,734.299316 1063.724609,729.935852 1064.147583,725.645447 
	C1064.329224,723.804199 1065.758301,720.765686 1066.974854,720.578918 
	C1071.831055,719.833496 1072.083984,716.866211 1072.045898,713.045105 
	C1071.959229,704.356934 1072.375488,704.037903 1081.000000,704.000000 
z"/>
<path fill="#312C2D" opacity="1.000000" stroke="none" 
	d="
M1081.000000,703.531372 
	C1072.375488,704.037903 1071.959229,704.356934 1072.045898,713.045105 
	C1072.083984,716.866211 1071.831055,719.833496 1066.974854,720.578918 
	C1065.758301,720.765686 1064.329224,723.804199 1064.147583,725.645447 
	C1063.724609,729.935852 1063.880859,734.299316 1064.030029,738.625366 
	C1064.160889,742.413025 1062.672485,744.292908 1058.766724,744.004333 
	C1057.772827,743.930908 1056.768433,743.993591 1055.768921,744.001099 
	C1048.829712,744.053345 1048.052124,744.833252 1048.000244,751.787354 
	C1047.970703,755.727783 1048.356812,758.817810 1042.917114,760.944458 
	C1039.232666,762.384949 1036.964355,767.975281 1032.760254,770.759033 
	C1031.335449,771.702515 1032.057495,776.076843 1032.027710,778.885803 
	C1031.997925,781.704041 1031.209961,783.298401 1028.134766,784.291138 
	C1026.387329,784.855286 1024.449707,787.554565 1024.202515,789.496521 
	C1023.618164,794.088257 1024.287964,798.832092 1023.819946,803.450439 
	C1023.656555,805.062622 1021.661926,806.502991 1020.451477,807.979126 
	C1020.289429,808.176819 1019.696350,808.005981 1019.511414,808.202209 
	C1015.652527,812.298096 1011.820374,816.419128 1007.991577,820.543213 
	C1006.958374,821.656067 1006.083435,823.589783 1004.912476,823.781311 
	C1000.420227,824.516235 999.895569,827.443420 999.983704,831.085571 
	C1000.086731,835.344421 1000.997437,839.987183 994.474670,840.768494 
	C993.497070,840.885620 992.426331,843.562073 992.125732,845.188904 
	C991.708313,847.448181 992.286926,849.896057 991.838684,852.143433 
	C991.568481,853.497925 990.292603,855.542419 989.237732,855.696472 
	C984.525330,856.384949 983.758911,859.366943 984.000427,863.253845 
	C984.276917,867.703430 983.827454,870.612976 978.576904,873.202759 
	C975.273560,874.832092 972.880798,880.159851 968.657104,882.563904 
	C967.949768,882.966492 968.056458,885.045776 968.031616,886.354980 
	C967.955872,890.351868 968.023315,894.351257 967.982178,898.349121 
	C967.970276,899.506042 968.030212,901.571167 967.633972,901.680054 
	C961.998413,903.229248 960.819153,909.637207 956.078918,912.058105 
	C955.936584,912.130859 955.690247,912.067932 955.600891,912.166565 
	C953.244995,914.765259 951.361328,918.689331 948.459961,919.701843 
	C943.108643,921.569275 944.041992,925.316956 944.051086,928.966064 
	C944.060364,932.707642 944.027283,935.878723 939.041138,936.528870 
	C937.860901,936.682739 936.545776,939.363708 936.149658,941.080139 
	C935.637451,943.299133 936.283691,945.773743 935.852539,948.027527 
	C935.589539,949.402405 934.368347,951.529907 933.354065,951.666992 
	C928.306824,952.349670 927.766968,955.633057 927.998474,959.635620 
	C928.247253,963.934937 927.743835,966.700256 922.656616,969.117249 
	C919.251526,970.735046 917.190247,976.295227 912.723267,978.491028 
	C911.969543,978.861633 912.046875,981.267761 912.031494,982.736450 
	C911.961609,989.399536 911.888000,996.065979 912.034180,1002.726440 
	C912.119141,1006.598145 910.487183,1008.276733 906.665588,1008.009033 
	C905.339478,1007.916138 903.977112,1007.842224 902.674316,1008.041077 
	C900.920227,1008.308777 897.700562,1008.853882 897.701904,1009.271362 
	C897.724976,1016.246582 886.559448,1015.144470 887.977478,1022.907593 
	C888.154175,1023.874634 887.951721,1024.904907 888.007202,1025.901367 
	C888.190125,1029.186890 887.696228,1031.623779 883.597778,1032.385132 
	C882.173157,1032.649658 880.669617,1035.233032 880.169739,1037.026978 
	C879.565186,1039.196777 880.345825,1041.734497 879.838257,1043.955566 
	C879.504028,1045.418213 878.131592,1047.508667 876.916199,1047.746826 
	C872.849304,1048.543823 871.902954,1050.982910 871.979553,1054.572876 
	C872.071899,1058.902222 872.204651,1063.252686 871.857727,1067.556885 
	C871.738098,1069.040649 870.383240,1071.506836 869.333252,1071.652222 
	C863.870605,1072.409180 863.836792,1076.117432 864.000000,1080.572144 
	C835.645752,1081.000000 807.291565,1081.000000 778.468994,1080.581299 
	C778.061707,1079.000732 777.734863,1077.049438 778.243713,1076.799438 
	C783.155029,1074.387085 785.100769,1068.726074 789.516541,1066.254639 
	C792.408447,1064.635986 794.033569,1063.331055 794.004272,1060.089233 
	C793.963623,1055.593140 793.806580,1051.081665 794.130493,1046.607910 
	C794.239990,1045.095825 795.500610,1042.550659 796.509521,1042.407227 
	C801.829163,1041.651123 802.078430,1038.161743 802.037415,1034.017456 
	C801.958679,1026.055176 802.050293,1026.054443 810.079712,1026.000000 
	C817.262695,1025.951294 817.971069,1025.226562 817.998352,1017.867798 
	C818.007629,1015.369690 817.818115,1012.854919 818.044922,1010.377563 
	C818.345886,1007.088806 816.495239,1003.227173 822.080811,1001.587891 
	C824.784790,1000.794312 826.073914,995.365601 828.809998,994.452942 
	C833.185181,992.993591 834.182922,990.682983 834.020142,986.781006 
	C833.909119,984.121033 834.502441,981.334656 833.909912,978.809082 
	C831.752747,969.614990 842.976562,969.081848 844.309082,962.260376 
	C850.413086,961.881958 850.133728,957.502197 850.003723,953.198608 
	C849.895447,949.612488 849.561462,945.984558 854.817078,945.617737 
	C855.812927,945.548157 857.418335,944.052002 857.516907,943.088684 
	C857.893066,939.411194 860.989258,939.092041 863.051147,937.268738 
	C866.560852,934.165100 869.099304,930.056213 873.244629,927.229736 
	C874.679749,926.251221 873.444153,921.809631 874.136353,919.132996 
	C874.619385,917.265076 875.978394,914.736267 877.524353,914.213806 
	C880.841431,913.093018 882.301819,911.351318 881.861023,908.035583 
	C881.407227,904.621277 878.894165,903.989441 875.985046,904.002441 
	C871.155518,904.024048 866.292847,903.691589 861.512756,904.179749 
	C859.638000,904.371277 856.717041,906.116882 856.410095,907.597717 
	C855.568848,911.656433 853.204834,912.072510 849.912292,912.026855 
	C843.251953,911.934326 836.589417,912.021973 829.927856,911.999878 
	C826.978943,911.990112 824.492737,912.743225 824.100342,916.108765 
	C823.711914,919.440979 821.638184,920.029663 818.843872,920.009094 
	C812.349182,919.961365 805.852905,920.070801 799.359314,919.976501 
	C795.782654,919.924622 793.483093,921.267456 791.938354,924.646179 
	C791.274841,926.097473 789.325562,927.657288 787.776428,927.878357 
	C782.863464,928.579224 777.851868,928.550415 772.901001,929.034424 
	C767.839111,929.529480 762.054077,928.177917 758.848145,934.055542 
	C758.599731,934.510925 757.714233,934.761536 757.089172,934.847351 
	C749.219910,935.927490 741.326904,936.850037 733.482361,938.083130 
	C731.582642,938.381775 729.770691,939.546631 728.039734,940.527283 
	C726.148804,941.598389 724.549561,943.368347 722.556030,944.036133 
	C718.323486,945.453979 713.016113,944.510803 710.831177,950.039795 
	C710.630615,950.547302 709.377869,950.871887 708.606812,950.885437 
	C699.665466,951.041809 690.722107,951.241211 681.780762,951.184570 
	C674.736145,951.139954 668.348389,952.396118 663.119751,957.689941 
	C662.276978,958.543335 659.014465,958.623596 658.435791,957.865906 
	C653.728088,951.701111 647.784851,954.050842 641.754150,954.851013 
	C641.005493,954.134705 640.502747,953.567322 640.145752,952.666260 
	C641.861023,949.888367 643.430542,947.444214 645.240356,945.092590 
	C646.987122,945.123413 648.493530,945.061707 650.111694,945.094971 
	C650.223450,945.190063 650.639526,945.234558 650.639526,945.234558 
	C650.639526,945.234558 651.000000,945.000000 651.250000,945.000000 
	C651.500000,945.000000 652.000000,945.000000 652.348511,945.222412 
	C653.663757,945.625671 654.637268,945.993774 655.596313,945.959473 
	C673.422119,945.322083 691.247986,944.675537 709.067017,943.877991 
	C710.302307,943.822693 712.325195,942.772034 712.549500,941.839783 
	C713.708008,937.023804 717.145447,936.774414 721.409302,936.914368 
	C726.775818,936.554077 731.730042,936.142883 736.690918,936.032349 
	C743.553040,935.879456 743.798645,935.771301 745.448486,929.000000 
	C752.167175,928.657715 758.457458,928.503418 764.693054,927.837585 
	C766.205078,927.676086 768.430481,926.055664 768.773743,924.709717 
	C769.597717,921.478821 771.553467,921.085022 774.262329,920.962158 
	C777.516113,920.814453 780.754761,920.335510 784.386353,919.973877 
	C786.900146,918.925171 790.120422,918.445557 790.949158,916.777649 
	C793.377197,911.890625 797.110474,910.644653 801.953247,911.008606 
	C804.690735,911.214294 806.172729,910.076355 807.940063,907.792603 
	C809.699524,905.518982 813.199707,903.181030 815.838135,903.288513 
	C821.589478,903.523010 824.028625,901.050293 825.437500,896.000000 
	C827.916687,896.000000 829.958313,896.000000 832.386353,895.973816 
	C836.013611,895.527344 838.310974,894.324402 840.291931,891.205994 
	C841.670227,889.036255 845.299805,887.469727 848.080811,887.188660 
	C854.017151,886.588867 860.059692,886.864868 866.050659,887.046204 
	C869.691833,887.156372 871.013611,885.804932 871.068604,882.113770 
	C871.219849,871.961548 872.052917,871.097229 881.786743,871.071960 
	C885.630249,871.062012 887.069641,869.838257 887.078613,865.885254 
	C887.098511,857.070435 889.101379,855.358704 898.050415,854.923584 
	C899.663513,854.845154 902.367981,854.113586 902.631897,853.151917 
	C904.281738,847.140747 908.688538,846.790100 913.627441,846.981567 
	C916.288574,847.084778 918.972839,847.173950 921.616455,846.935059 
	C923.294983,846.783325 924.917969,846.017151 926.565857,845.526611 
	C925.341675,844.471985 923.952637,843.554443 922.933411,842.329285 
	C921.533813,840.646973 919.561340,838.788818 919.434204,836.898438 
	C919.324585,835.268799 921.160583,832.374512 922.647583,831.983582 
	C927.132690,830.804321 927.041443,827.809998 927.034424,824.338318 
	C927.019165,816.809021 928.858765,814.798096 936.558228,815.106628 
	C941.606323,815.308899 943.409851,813.699829 943.108643,808.597656 
	C942.837036,803.997070 942.126160,798.626892 944.084229,794.894714 
	C946.455627,790.374695 951.002747,786.556274 955.430603,783.716003 
	C958.194946,781.942749 959.047913,780.477295 959.029175,777.587891 
	C958.955688,766.261475 959.037720,754.934021 958.983582,743.607422 
	C958.964417,739.601318 959.920288,736.504822 964.254333,735.069763 
	C967.323120,734.053650 970.058228,725.933167 969.234924,722.781433 
	C968.203430,718.832642 967.073486,714.709717 967.251282,710.718384 
	C967.361084,708.254333 969.293579,704.652832 971.355591,703.766052 
	C974.886902,702.247437 974.983459,700.065735 975.037781,697.132751 
	C975.096252,693.969971 974.990479,691.453247 971.087708,690.207703 
	C969.406494,689.671082 967.470154,686.851135 967.452820,685.051086 
	C967.435486,683.254028 969.328125,680.384094 970.991577,679.838562 
	C974.789001,678.593201 975.300903,676.230530 975.307861,672.761841 
	C976.077026,670.319885 976.933289,668.117249 976.940002,665.912109 
	C977.029114,636.274902 976.999878,606.637451 977.377808,577.074829 
	C982.727173,577.563049 984.179138,580.225281 984.110229,585.155212 
	C983.840576,604.452820 984.003662,623.756409 983.998657,643.057800 
	C983.997925,645.963074 983.914795,649.229309 987.605835,649.741272 
	C991.778320,650.320068 992.047119,652.867920 992.024414,656.124695 
	C991.958435,665.608459 991.800415,675.102051 992.205627,684.569275 
	C992.283813,686.396606 994.414185,689.010315 996.196228,689.688049 
	C998.950684,690.735596 1001.162781,689.117493 1002.032104,685.896362 
	C1002.404663,684.515808 1003.621643,682.458069 1004.669373,682.318604 
	C1009.733887,681.644653 1010.057129,678.334717 1010.034668,674.353455 
	C1009.987854,666.062927 1010.068970,666.062439 1018.230103,665.999573 
	C1025.163818,665.946167 1025.957397,665.156921 1025.997559,658.229126 
	C1026.012085,655.733337 1026.468750,653.128113 1025.917114,650.760864 
	C1023.779785,641.590515 1035.040649,641.097046 1036.334595,634.252686 
	C1042.022461,633.906982 1042.203369,629.957214 1042.019531,625.680725 
	C1041.912476,623.189514 1041.994629,620.689819 1042.001221,618.193970 
	C1042.011597,614.245300 1041.128662,609.931213 1047.266602,609.357849 
	C1048.287720,609.262390 1049.469849,606.699707 1049.857300,605.102295 
	C1050.315552,603.213196 1050.044922,601.133179 1049.994629,599.138306 
	C1049.924072,596.339478 1050.998169,595.066772 1053.806763,593.733521 
	C1058.628784,591.444275 1060.449707,585.338318 1065.573730,582.907166 
	C1066.119141,582.648376 1065.935791,580.725708 1065.981079,579.570679 
	C1066.046265,577.909058 1066.051514,576.242493 1066.010010,574.579895 
	C1065.932617,571.473145 1067.374512,569.966431 1070.517700,570.009155 
	C1074.011108,570.056641 1077.505859,570.007202 1081.000000,570.000000 
	C1081.000000,614.354248 1081.000000,658.708435 1081.000000,703.531372 
M1021.400513,760.059387 
	C1017.563782,759.954041 1015.984192,762.024597 1016.014282,765.646851 
	C1016.036438,768.312195 1015.419556,771.213135 1016.287292,773.566772 
	C1016.956116,775.380493 1019.402527,777.466553 1021.222290,777.632080 
	C1022.655396,777.762451 1025.283447,775.384094 1025.702271,773.702026 
	C1026.444092,770.722351 1026.307495,767.352234 1025.744751,764.291504 
	C1025.459839,762.742310 1023.440308,761.512207 1021.400513,760.059387 
M1001.651550,793.084595 
	C999.145630,797.526855 999.811401,801.121582 1003.528809,801.669250 
	C1005.226501,801.919312 1007.920288,801.358887 1008.727234,800.181946 
	C1009.713684,798.743164 1010.045715,795.192444 1009.141968,794.479004 
	C1007.439087,793.135010 1004.648682,793.168823 1001.651550,793.084595 
M975.410339,723.296082 
	C975.509460,724.691162 975.608582,726.086182 975.707703,727.481262 
	C976.237122,727.419250 976.766541,727.357178 977.295959,727.295166 
	C977.485962,725.698914 979.702454,723.813110 975.410339,723.296082 
z"/>
<path fill="#3D383B" opacity="1.000000" stroke="none" 
	d="
M1034.000000,1.000000 
	C1049.635620,1.000000 1065.271118,1.000000 1081.000000,1.000000 
	C1081.000000,58.687531 1081.000000,116.375107 1080.573853,174.531708 
	C1075.909180,175.754150 1071.623779,176.325043 1067.465698,177.391754 
	C1066.450195,177.652252 1065.422729,179.448730 1065.161621,180.698074 
	C1064.760376,182.617340 1064.954224,184.675964 1065.009155,186.672348 
	C1065.102295,190.055374 1064.093384,192.252060 1060.354614,193.236206 
	C1058.941772,193.608139 1057.331665,196.108231 1057.163330,197.774765 
	C1056.713501,202.227905 1056.974976,206.755142 1056.995605,211.252762 
	C1057.009399,214.262939 1056.046143,216.565750 1052.712769,216.945023 
	C1049.659790,217.292374 1048.978516,219.217514 1048.999878,221.840958 
	C1049.035278,226.171829 1048.994263,230.503265 1049.001587,234.834442 
	C1049.027466,250.291458 1050.167358,249.007660 1034.614746,248.999832 
	C1025.105469,248.995056 1024.934814,248.278931 1024.851807,258.485596 
	C1024.834961,260.562012 1023.393921,263.912079 1021.860962,264.421082 
	C1018.753235,265.452911 1016.925903,266.621552 1016.608826,269.986725 
	C1016.532227,270.799225 1015.507507,272.126923 1014.830078,272.185150 
	C1007.656799,272.801605 1009.016785,278.101685 1009.021545,282.584259 
	C1009.025085,285.925415 1008.212891,288.212708 1004.440613,289.214996 
	C1003.005554,289.596283 1001.339722,292.040039 1001.169861,293.687256 
	C1000.710815,298.138550 1001.209717,302.682068 1000.885925,307.157013 
	C1000.757385,308.934265 999.656738,311.996643 998.666870,312.137207 
	C992.009827,313.082581 993.148315,317.809296 992.883606,322.242188 
	C992.758911,324.330353 991.666931,327.958649 990.581665,328.128296 
	C984.260010,329.116516 984.951782,333.469147 984.969971,337.832489 
	C984.995972,344.044067 984.001282,344.927826 977.611877,344.999084 
	C969.079834,345.094238 968.974121,345.095154 969.044739,353.488617 
	C969.079163,357.577271 968.506348,360.818939 963.513306,361.278778 
	C962.737488,361.350250 961.479431,362.399475 961.421204,363.080139 
	C960.881775,369.387207 956.445496,369.157776 951.941467,369.015228 
	C949.278748,368.931000 946.608459,368.920990 943.946594,369.017212 
	C940.227722,369.151611 937.449646,368.378082 936.747009,363.950989 
	C936.569092,362.830078 934.689270,361.677704 933.349915,361.119476 
	C931.698669,360.431213 929.794739,360.348999 927.816345,359.730133 
	C925.753479,357.637329 923.874268,355.819275 921.918457,353.612701 
	C921.619446,348.222137 919.905151,344.432861 914.254517,343.758972 
	C913.811768,343.706177 913.415466,343.262970 912.997803,342.591553 
	C913.251709,334.898346 907.477356,331.280640 903.196289,327.085938 
	C899.991699,323.946167 896.690735,319.703461 890.810608,320.689636 
	C889.282532,315.435211 886.735596,311.922516 880.968384,312.194214 
	C880.649353,312.209229 880.322937,312.068817 879.977539,311.641296 
	C878.302612,309.521240 876.650085,307.760620 874.908081,305.717529 
	C873.879822,305.291016 872.940979,305.146912 871.567444,305.002045 
	C868.495911,304.780853 864.888550,305.952087 865.053894,301.341217 
	C865.248962,295.904266 861.690918,293.053864 858.045410,289.992523 
	C854.642517,287.135040 850.926941,284.293488 848.540527,280.661926 
	C844.508728,274.526550 838.384705,271.522827 832.571045,267.868927 
	C826.597900,264.114746 820.586792,260.390289 814.867798,256.270355 
	C808.486267,251.673172 802.426270,246.630814 796.194275,241.823730 
	C795.417297,241.224380 794.423767,240.914154 793.565979,240.409760 
	C788.437439,237.393921 782.746399,236.113007 776.896362,234.681839 
	C772.862854,231.241257 768.931458,228.120285 765.269226,224.783066 
	C767.269043,224.252228 770.031799,223.100189 770.564941,223.757202 
	C774.395630,228.478119 780.383606,227.337006 784.999023,229.625381 
	C791.614441,232.905457 798.070801,236.512192 804.527466,240.101151 
	C805.629517,240.713791 807.042908,241.832962 807.208740,242.891068 
	C808.326721,250.026566 813.827576,249.466827 818.893005,250.097046 
	C820.630798,250.313263 823.382690,251.282089 823.704651,252.449493 
	C825.179688,257.797882 828.910095,258.398010 833.377380,257.986389 
	C837.278442,257.626923 838.509583,259.492462 840.830017,262.781219 
	C843.603882,266.712463 848.400085,271.256897 853.173706,272.582642 
	C859.883118,274.445953 867.327148,273.815247 874.462646,273.946045 
	C880.395813,274.054810 881.839783,272.447845 881.995361,266.611328 
	C882.247009,257.171814 882.247070,257.171692 891.717163,256.999054 
	C892.050171,256.992981 892.384766,256.972260 892.716064,256.995270 
	C896.328552,257.245972 897.938538,255.399506 897.963989,251.946732 
	C897.995850,247.617340 898.074524,243.285736 897.973389,238.958817 
	C897.907715,236.147537 898.489075,234.397491 901.736145,233.597031 
	C903.377136,233.192535 904.598145,230.852112 905.869629,229.274612 
	C906.551208,228.428940 906.835754,226.570038 907.472107,226.486664 
	C915.104675,225.486771 913.862122,219.796021 914.076416,214.704437 
	C914.138794,213.222137 914.904114,210.601456 915.633789,210.496613 
	C922.604980,209.494843 922.193237,204.458282 921.974060,199.536041 
	C921.833313,196.376236 922.382935,194.315613 926.131958,193.437408 
	C927.713135,193.067017 929.173218,190.288330 929.812317,188.319977 
	C930.450195,186.355347 929.710510,183.980438 930.114807,181.889832 
	C930.379639,180.521088 931.431030,178.417908 932.430054,178.228867 
	C937.155518,177.334656 938.027893,174.299271 938.036011,170.200958 
	C938.051941,162.130493 938.142334,162.049194 946.125305,162.032242 
	C950.179260,162.023636 953.372742,161.294006 954.153625,156.491119 
	C954.307373,155.545227 955.736572,154.283432 956.751770,154.092316 
	C960.930603,153.305618 962.044861,150.619263 962.009399,146.859009 
	C961.952820,140.864670 962.119324,134.866013 961.955627,128.875656 
	C961.853333,125.135269 962.652039,123.213196 966.585327,121.044815 
	C970.684998,118.784767 972.741394,113.210068 977.329895,110.333031 
	C978.640381,109.511307 977.631531,105.283722 978.080078,102.685913 
	C978.356079,101.087318 979.149536,98.465752 980.102173,98.276688 
	C986.319702,97.042824 986.063477,92.594742 986.076721,87.925858 
	C986.081970,86.091942 986.693359,82.784576 987.451965,82.656525 
	C994.911438,81.397430 994.100464,75.923439 993.987854,70.761017 
	C993.932007,68.195656 994.390503,66.479431 997.440491,65.679863 
	C999.143738,65.233353 1000.445923,63.073032 1001.817871,61.582832 
	C1005.309570,57.790310 1008.737183,53.938854 1012.202087,50.121529 
	C1012.293091,50.021194 1012.525879,50.062618 1012.679932,50.004482 
	C1017.913757,48.029179 1018.874512,45.400288 1017.981995,37.414337 
	C1017.468689,32.820869 1014.315796,33.062290 1011.097412,32.867767 
	C1009.283020,32.758106 1007.361206,32.603622 1005.726196,31.908537 
	C1004.346802,31.322083 1003.311279,29.926834 1002.123535,28.889582 
	C1003.391968,27.931583 1004.586914,26.293528 1005.942993,26.146982 
	C1009.564758,25.755598 1013.255859,26.011147 1016.919373,25.998299 
	C1024.838623,25.970530 1025.944580,24.889547 1026.000610,17.156174 
	C1026.010254,15.824201 1025.767700,14.416062 1026.124756,13.182988 
	C1026.446167,12.072446 1027.318848,10.393500 1028.140503,10.272809 
	C1033.865601,9.431908 1034.225830,5.453598 1034.000000,1.000000 
z"/>
<path fill="#3F3D40" opacity="1.000000" stroke="none" 
	d="
M1033.531372,1.000000 
	C1034.225830,5.453598 1033.865601,9.431908 1028.140503,10.272809 
	C1027.318848,10.393500 1026.446167,12.072446 1026.124756,13.182988 
	C1025.767700,14.416062 1026.010254,15.824201 1026.000610,17.156174 
	C1025.944580,24.889547 1024.838623,25.970530 1016.919373,25.998299 
	C1013.255859,26.011147 1009.564758,25.755598 1005.942993,26.146982 
	C1004.586914,26.293528 1003.391968,27.931583 1002.123535,28.889582 
	C1003.311279,29.926834 1004.346802,31.322083 1005.726196,31.908537 
	C1007.361206,32.603622 1009.283020,32.758106 1011.097412,32.867767 
	C1014.315796,33.062290 1017.468689,32.820869 1017.981995,37.414337 
	C1018.874512,45.400288 1017.913757,48.029179 1012.679932,50.004482 
	C1012.525879,50.062618 1012.293091,50.021194 1012.202087,50.121529 
	C1008.737183,53.938854 1005.309570,57.790310 1001.817871,61.582832 
	C1000.445923,63.073032 999.143738,65.233353 997.440491,65.679863 
	C994.390503,66.479431 993.932007,68.195656 993.987854,70.761017 
	C994.100464,75.923439 994.911438,81.397430 987.451965,82.656525 
	C986.693359,82.784576 986.081970,86.091942 986.076721,87.925858 
	C986.063477,92.594742 986.319702,97.042824 980.102173,98.276688 
	C979.149536,98.465752 978.356079,101.087318 978.080078,102.685913 
	C977.631531,105.283722 978.640381,109.511307 977.329895,110.333031 
	C972.741394,113.210068 970.684998,118.784767 966.585327,121.044815 
	C962.652039,123.213196 961.853333,125.135269 961.955627,128.875656 
	C962.119324,134.866013 961.952820,140.864670 962.009399,146.859009 
	C962.044861,150.619263 960.930603,153.305618 956.751770,154.092316 
	C955.736572,154.283432 954.307373,155.545227 954.153625,156.491119 
	C953.372742,161.294006 950.179260,162.023636 946.125305,162.032242 
	C938.142334,162.049194 938.051941,162.130493 938.036011,170.200958 
	C938.027893,174.299271 937.155518,177.334656 932.430054,178.228867 
	C931.431030,178.417908 930.379639,180.521088 930.114807,181.889832 
	C929.710510,183.980438 930.450195,186.355347 929.812317,188.319977 
	C929.173218,190.288330 927.713135,193.067017 926.131958,193.437408 
	C922.382935,194.315613 921.833313,196.376236 921.974060,199.536041 
	C922.193237,204.458282 922.604980,209.494843 915.633789,210.496613 
	C914.904114,210.601456 914.138794,213.222137 914.076416,214.704437 
	C913.862122,219.796021 915.104675,225.486771 907.472107,226.486664 
	C906.835754,226.570038 906.551208,228.428940 905.869629,229.274612 
	C904.598145,230.852112 903.377136,233.192535 901.736145,233.597031 
	C898.489075,234.397491 897.907715,236.147537 897.973389,238.958817 
	C898.074524,243.285736 897.995850,247.617340 897.963989,251.946732 
	C897.938538,255.399506 896.328552,257.245972 892.716064,256.995270 
	C892.384766,256.972260 892.050171,256.992981 891.717163,256.999054 
	C882.247070,257.171692 882.247009,257.171814 881.995361,266.611328 
	C881.839783,272.447845 880.395813,274.054810 874.462646,273.946045 
	C867.327148,273.815247 859.883118,274.445953 853.173706,272.582642 
	C848.400085,271.256897 843.603882,266.712463 840.830017,262.781219 
	C838.509583,259.492462 837.278442,257.626923 833.377380,257.986389 
	C828.910095,258.398010 825.179688,257.797882 823.704651,252.449493 
	C823.382690,251.282089 820.630798,250.313263 818.893005,250.097046 
	C813.827576,249.466827 808.326721,250.026566 807.208740,242.891068 
	C807.042908,241.832962 805.629517,240.713791 804.527466,240.101151 
	C798.070801,236.512192 791.614441,232.905457 784.999023,229.625381 
	C780.383606,227.337006 774.395630,228.478119 770.564941,223.757202 
	C770.031799,223.100189 767.269043,224.252228 764.935913,224.783264 
	C763.888916,225.000916 763.444458,225.002090 762.750977,224.999756 
	C762.501953,224.996246 762.003845,224.991531 761.933960,224.646423 
	C756.853699,218.470093 754.778992,208.741302 743.804810,210.785980 
	C742.983337,210.939041 741.989868,210.093216 741.060547,209.755219 
	C733.039185,206.838104 725.014099,203.930984 716.615845,201.018402 
	C715.492676,201.009613 714.743896,201.003281 713.896912,200.684387 
	C712.865356,198.915771 711.932007,197.459717 711.384399,196.009323 
	C712.589172,196.137238 713.568909,196.012817 714.199829,196.423843 
	C717.169678,198.358551 719.801880,199.461075 723.109436,196.558838 
	C723.971802,195.802109 726.954834,197.103958 728.760254,197.914093 
	C729.848328,198.402298 730.470947,200.521851 731.427368,200.613907 
	C738.031982,201.249680 743.050598,210.823883 751.002258,204.126038 
	C751.692383,203.544739 753.269958,204.005753 754.435425,204.002258 
	C758.099121,203.991272 761.851257,204.467804 765.394531,203.811966 
	C767.657837,203.393036 770.950623,201.666550 771.430969,199.890518 
	C772.485046,195.992950 774.794067,195.997162 777.751770,195.979614 
	C787.182251,195.923615 787.795044,195.186325 788.071167,185.659149 
	C788.123413,183.854874 788.806824,180.686142 789.679932,180.508316 
	C796.354797,179.149033 796.236328,174.338058 795.990479,169.336075 
	C795.847473,166.426651 796.808044,165.100647 799.726685,163.450912 
	C804.227234,160.907043 806.626343,155.448273 811.302368,152.577637 
	C811.959106,152.174454 811.924683,150.414688 811.959778,149.275177 
	C812.046814,146.446808 812.005005,143.613876 811.985779,140.782990 
	C811.955322,136.291519 809.667969,134.036011 805.185974,134.011398 
	C802.512207,133.996719 799.838318,134.008713 797.164551,134.008713 
	C797.173401,133.337326 797.182251,132.665939 797.191101,131.994553 
	C799.721375,131.994553 802.390503,132.500885 804.743652,131.855240 
	C807.203369,131.180328 810.824829,129.822845 811.350403,128.012421 
	C812.540039,123.914413 814.986450,123.839943 818.105530,124.000999 
	C822.870056,124.246994 827.316040,124.014137 828.233398,117.854630 
	C828.314148,117.312485 828.956665,116.496147 829.431519,116.424332 
	C836.406982,115.369484 836.262939,110.252464 835.994507,105.070183 
	C835.852783,102.334190 836.488770,100.732948 839.555054,99.524231 
	C841.548401,98.738449 843.416687,95.566055 843.797913,93.212975 
	C844.481506,88.992752 843.884766,84.578896 844.080261,80.257584 
	C844.140259,78.929672 844.850098,76.626839 845.582458,76.490532 
	C852.201172,75.258759 852.284790,70.498611 851.998840,65.429268 
	C851.837952,62.575577 852.808777,61.230385 855.615479,59.469090 
	C860.255676,56.557121 863.777283,51.849129 867.702209,47.831470 
	C868.098206,47.426159 867.885925,46.480606 868.209778,45.937000 
	C868.605042,45.273487 869.205200,44.356041 869.825867,44.261059 
	C875.967651,43.321003 876.076050,38.919014 876.090393,34.138737 
	C876.096252,32.184971 876.780396,28.720442 877.703918,28.524414 
	C884.372314,27.108881 884.060791,22.310890 884.079163,17.317528 
	C884.085205,15.665202 884.740112,12.727170 885.536621,12.567844 
	C892.450745,11.184723 892.232971,6.162444 892.000000,1.000000 
	C939.020935,1.000000 986.041809,1.000000 1033.531372,1.000000 
z"/>
<path fill="#424043" opacity="1.000000" stroke="none" 
	d="
M162.468658,1081.000000 
	C161.999832,1078.167603 162.111664,1075.329590 161.971375,1072.504150 
	C161.793274,1068.917358 162.942841,1067.281372 166.430222,1065.078735 
	C170.441635,1062.545044 173.909317,1058.209839 177.213867,1054.431641 
	C178.977615,1052.415161 180.205933,1050.626465 182.697708,1049.131836 
	C184.609436,1047.985107 185.523026,1044.300293 185.861740,1041.615845 
	C186.335388,1037.861816 185.197342,1033.851929 190.994827,1033.099121 
	C192.189606,1032.943970 193.638885,1029.817993 193.838684,1027.940796 
	C194.277176,1023.821228 193.975769,1019.624329 194.010727,1015.459290 
	C194.020462,1014.298584 193.857498,1012.249207 194.269104,1012.113770 
	C199.476059,1010.401428 201.159546,1005.109802 204.913467,1001.983643 
	C205.139404,1001.795471 205.667709,1001.945190 205.861572,1001.747620 
	C208.422989,999.136353 210.491989,995.449219 213.588150,994.088989 
	C218.732086,991.829346 217.848755,987.950623 217.982971,984.088379 
	C218.069717,981.592163 218.068390,979.088196 217.982880,976.591919 
	C217.863586,973.109985 217.854294,970.266785 222.537354,969.202576 
	C224.122818,968.842346 225.493729,964.872375 225.852951,962.391357 
	C226.425247,958.438904 224.942368,954.118164 231.152451,952.885681 
	C232.552277,952.607910 233.714828,947.940735 233.874710,945.228638 
	C234.297043,938.064575 234.054214,938.050293 241.097504,937.999390 
	C248.892319,937.943176 249.967834,936.854858 249.997452,928.968933 
	C250.007462,926.303223 250.087265,923.633728 249.978043,920.972412 
	C249.828583,917.331116 249.646774,914.036255 254.691589,913.275696 
	C255.963043,913.083984 257.282684,910.482056 257.798950,908.754272 
	C258.349609,906.911499 258.096008,904.784851 257.976532,902.795166 
	C257.777191,899.475708 259.001556,898.052124 262.539429,897.935791 
	C274.002289,897.558960 274.000031,897.440491 273.997955,886.026489 
	C273.997101,881.194763 274.063904,876.361389 273.957367,871.531921 
	C273.875549,867.824890 273.097717,864.609924 268.510925,863.822144 
	C267.525024,863.652893 266.372528,861.928345 266.074066,860.727112 
	C264.900787,856.004883 265.929779,850.266602 259.268982,848.417908 
	C258.285431,848.144958 257.868866,845.927795 257.132812,844.642944 
	C255.745270,842.220886 254.331192,839.813538 252.889893,837.423096 
	C252.639099,837.007080 252.054092,836.767151 251.870728,836.339722 
	C248.420898,828.298157 240.259872,823.472595 237.053879,815.513855 
	C234.170731,808.356628 229.563583,802.432007 225.773438,795.928955 
	C224.539337,793.811462 223.921021,791.342468 222.930038,789.073914 
	C221.941086,786.810181 220.860962,784.585144 219.779663,782.363159 
	C218.140259,778.994263 216.497528,775.626282 214.794083,772.289551 
	C213.669647,770.087097 212.827179,767.569458 211.181885,765.834595 
	C205.024734,759.342102 201.698318,751.438782 198.830780,743.180969 
	C197.705643,739.940918 195.889069,736.940918 194.382065,733.834106 
	C194.310593,733.686829 194.107224,733.569031 194.099564,733.430176 
	C193.683258,725.888794 190.204514,719.440796 187.105225,712.774902 
	C184.314682,706.772949 185.138321,699.904541 181.425018,693.619446 
	C177.953339,687.743225 176.056366,680.262512 176.801239,672.638916 
	C177.064651,669.942932 174.986389,667.052368 174.136551,664.203125 
	C173.388382,661.694763 172.646103,659.150818 172.279144,656.568848 
	C171.192688,648.924377 170.316559,641.250244 169.299118,633.595520 
	C169.112610,632.192322 169.095978,630.366943 168.232254,629.525452 
	C165.198471,626.569885 166.205460,623.975769 168.749329,621.470642 
	C170.781265,625.456482 172.409164,629.226685 174.033798,633.441467 
	C174.028793,636.257629 174.027023,638.629089 173.808472,641.303223 
	C174.331848,646.447876 174.751068,651.366699 175.886932,656.114014 
	C177.620453,663.359253 179.831268,670.491150 181.872330,677.661804 
	C184.450729,686.720276 186.446564,696.006836 189.823257,704.759216 
	C193.604843,714.561096 198.668823,723.866638 203.120789,733.413330 
	C203.676163,734.604248 203.771866,736.006042 204.301895,737.212891 
	C207.367981,744.194092 210.341934,751.223328 213.677841,758.075256 
	C215.327362,761.463318 218.121109,764.314880 219.629593,767.746582 
	C222.521133,774.324646 225.209076,780.892517 231.295181,785.341858 
	C232.114075,785.940491 232.385757,787.281311 232.923767,788.271423 
	C236.557419,794.958435 239.797058,801.903320 243.946381,808.253662 
	C247.822128,814.185303 252.762756,819.411255 257.046783,825.089600 
	C259.812225,828.755127 261.921814,832.946411 264.885345,836.424988 
	C270.597351,843.129822 276.638763,849.559814 282.668671,855.986389 
	C289.288116,863.041443 294.811340,871.184937 303.351654,876.348999 
	C306.690063,878.367676 309.870667,880.783936 312.709778,883.458008 
	C316.427704,886.959839 319.636963,890.995422 323.251801,894.614868 
	C326.257050,897.623840 329.083710,901.495422 332.790131,902.963684 
	C338.729065,905.316406 340.100555,911.652588 345.153229,914.898621 
	C347.686584,916.526245 350.001556,920.075500 353.226593,920.315918 
	C360.107330,920.828796 361.670654,926.014709 362.635376,930.781860 
	C363.619537,935.644958 366.250732,936.272583 370.026215,935.912537 
	C371.807220,935.742737 373.528534,934.970032 375.282928,934.492737 
	C377.802185,933.807373 380.297424,934.452148 379.642639,937.287415 
	C378.667816,941.508545 380.090271,943.428467 383.815796,944.784119 
	C384.824860,945.151367 385.273926,946.889832 386.141327,947.853760 
	C387.419769,949.274658 389.024506,950.429565 390.158569,951.945923 
	C391.609528,953.885803 393.675415,956.008728 393.820282,958.164062 
	C394.288330,965.129883 393.932831,972.147522 394.026947,979.143433 
	C394.074493,982.677429 394.021667,985.729919 389.427917,986.624390 
	C388.112122,986.880615 387.162720,989.155396 386.103821,990.549133 
	C385.337891,991.557373 384.806183,993.409058 383.934326,993.564758 
	C377.685272,994.680725 378.040192,999.236328 377.898987,1003.883911 
	C377.840240,1005.817627 377.011871,1009.156555 375.968109,1009.396362 
	C369.886169,1010.794067 369.737823,1015.089661 370.007568,1019.807495 
	C370.194519,1023.077576 369.702972,1025.597412 365.746918,1026.494385 
	C364.348969,1026.811279 363.277344,1028.860474 362.208008,1030.236328 
	C361.429443,1031.237793 361.048126,1033.263550 360.234467,1033.409546 
	C353.465485,1034.623535 353.991852,1039.607300 353.911835,1044.547852 
	C353.885101,1046.200317 353.144775,1049.099487 352.279419,1049.281494 
	C345.306458,1050.746582 345.847565,1055.901855 345.993073,1060.969727 
	C346.074768,1063.814575 345.283478,1065.663452 341.931091,1066.015381 
	C329.991455,1067.269165 330.000031,1067.350708 330.000000,1079.500610 
	C330.000000,1080.000366 330.000000,1080.500244 330.000000,1081.000000 
	C274.312439,1081.000000 218.624878,1081.000000 162.468658,1081.000000 
z"/>
<path fill="#4B484B" opacity="1.000000" stroke="none" 
	d="
M432.015717,185.007843 
	C434.163757,181.427292 436.414978,177.902573 438.400482,174.234024 
	C439.042084,173.048569 439.390686,171.418625 439.174255,170.107391 
	C438.477814,165.888016 440.061920,163.164108 444.132996,162.263367 
	C447.263184,161.570801 448.141785,159.732071 448.013184,156.886353 
	C447.895935,154.292145 448.575104,152.298096 451.657532,151.957367 
	C454.978912,151.590210 456.006378,149.338898 455.993805,146.310806 
	C455.975128,141.813614 456.032837,137.316040 456.000427,132.819031 
	C455.981445,130.186081 457.470581,129.278137 459.574371,127.745201 
	C464.176056,124.392159 467.888580,119.818939 471.965424,115.745262 
	C473.229950,114.481697 474.297028,112.551826 475.806213,112.098900 
	C479.003540,111.139313 480.016541,109.195724 479.996002,106.205284 
	C479.971924,102.707558 480.057434,99.208221 479.984131,95.711914 
	C479.901184,91.753494 480.260162,89.229309 485.065887,87.077217 
	C488.795624,85.406982 490.744110,79.618103 495.367615,77.399002 
	C496.029968,77.081100 495.940948,74.924004 495.969849,73.613014 
	C496.046997,70.116745 495.988403,66.617668 496.002716,63.119793 
	C496.030792,56.268909 496.251923,56.048965 502.983856,56.000767 
	C511.639404,55.938801 511.990540,55.587433 511.999573,46.963367 
	C512.002197,44.464848 512.064148,41.964294 511.985291,39.468266 
	C511.865051,35.662140 512.486450,33.452087 516.800720,31.191559 
	C520.661133,29.168911 523.305542,24.131161 527.330505,21.057524 
	C528.708191,20.005466 527.984253,16.018053 527.968811,13.373244 
	C527.951172,10.354486 528.603882,8.271486 532.166748,7.830479 
	C535.549561,7.411767 536.088928,4.696054 535.998596,1.404616 
	C592.687561,1.000000 649.375122,1.000000 706.531372,1.461255 
	C706.990051,4.588488 707.100220,7.261412 706.946594,9.919089 
	C706.549988,16.780655 704.735107,18.995930 697.812317,18.822325 
	C692.184631,18.681196 690.699524,20.732059 690.766296,26.018705 
	C690.798889,28.601248 689.304810,32.799084 687.463806,33.486610 
	C683.256958,35.057678 682.825195,37.609367 682.974854,41.179249 
	C683.093323,44.006611 683.093506,46.848843 682.955139,49.675106 
	C682.606873,56.785595 680.744019,59.010925 673.556274,58.837337 
	C668.122131,58.706100 666.708191,60.669632 666.782715,65.775040 
	C666.821167,68.410789 665.458984,72.783379 663.698486,73.392876 
	C659.214600,74.945213 659.227539,77.747688 658.826965,81.412178 
	C658.513306,84.281990 657.450928,88.645393 655.525513,89.437019 
	C651.466248,91.105927 650.779419,93.461494 650.972046,97.092407 
	C651.113098,99.750839 651.063843,102.425346 650.977417,105.088737 
	C650.727844,112.779800 648.856018,114.957230 641.141602,114.872124 
	C636.309631,114.818825 634.754944,116.411133 634.837219,121.187881 
	C634.892334,124.386406 635.434265,128.691910 630.923035,129.922913 
	C627.609680,130.827057 627.005188,132.864456 626.950439,135.846115 
	C626.862122,140.652008 626.625488,145.474915 626.035339,150.239075 
	C625.872253,151.555862 624.320984,152.771103 623.257019,153.890869 
	C621.197327,156.058563 618.021240,157.772583 617.134338,160.312408 
	C615.612366,164.671082 613.528503,166.691193 608.889465,166.110596 
	C607.932800,165.990860 606.421021,166.641083 605.964050,167.410767 
	C605.627930,167.976929 606.543518,169.340454 606.993469,170.305664 
	C607.595947,171.598267 608.287476,172.849365 608.523315,174.099487 
	C605.010742,174.409698 601.915588,174.738724 598.508362,175.222855 
	C595.489441,175.236954 592.782654,175.095932 589.876831,174.656158 
	C588.442627,173.593521 587.278992,172.338409 585.960571,172.146591 
	C578.228149,171.021652 570.474182,169.509781 562.699768,169.330795 
	C550.463135,169.049042 538.201660,169.640427 525.958618,170.082108 
	C524.281555,170.142609 522.650024,171.467026 520.705078,172.348633 
	C519.989075,172.437927 519.565552,172.384888 518.937134,172.018250 
	C515.833069,168.880081 513.656433,169.718582 511.657532,173.142303 
	C510.912933,173.213684 510.480255,173.181580 509.782654,172.871155 
	C506.355927,172.235321 503.185059,171.530106 500.034729,171.612244 
	C496.457458,171.705536 492.892395,172.462646 489.333038,173.003479 
	C482.890564,173.982407 476.456238,175.014923 469.857208,175.681030 
	C465.729187,174.065948 461.816772,172.081009 457.776703,171.773666 
	C455.061859,171.567123 452.166351,173.735092 449.351685,174.840698 
	C449.516296,175.453445 449.680939,176.066193 449.845581,176.678940 
	C451.583893,176.802689 453.322235,176.926453 454.931580,177.370392 
	C447.206970,180.129669 439.611328,182.568756 432.015717,185.007843 
M442.817078,179.323532 
	C442.986023,179.185364 443.154968,179.047195 443.323944,178.909042 
	C443.240906,178.818314 443.157837,178.727585 443.074829,178.636871 
	C442.924072,178.795319 442.773346,178.953766 442.817078,179.323532 
z"/>
<path fill="#434144" opacity="1.000000" stroke="none" 
	d="
M891.531372,1.000000 
	C892.232971,6.162444 892.450745,11.184723 885.536621,12.567844 
	C884.740112,12.727170 884.085205,15.665202 884.079163,17.317528 
	C884.060791,22.310890 884.372314,27.108881 877.703918,28.524414 
	C876.780396,28.720442 876.096252,32.184971 876.090393,34.138737 
	C876.076050,38.919014 875.967651,43.321003 869.825867,44.261059 
	C869.205200,44.356041 868.605042,45.273487 868.209778,45.937000 
	C867.885925,46.480606 868.098206,47.426159 867.702209,47.831470 
	C863.777283,51.849129 860.255676,56.557121 855.615479,59.469090 
	C852.808777,61.230385 851.837952,62.575577 851.998840,65.429268 
	C852.284790,70.498611 852.201172,75.258759 845.582458,76.490532 
	C844.850098,76.626839 844.140259,78.929672 844.080261,80.257584 
	C843.884766,84.578896 844.481506,88.992752 843.797913,93.212975 
	C843.416687,95.566055 841.548401,98.738449 839.555054,99.524231 
	C836.488770,100.732948 835.852783,102.334190 835.994507,105.070183 
	C836.262939,110.252464 836.406982,115.369484 829.431519,116.424332 
	C828.956665,116.496147 828.314148,117.312485 828.233398,117.854630 
	C827.316040,124.014137 822.870056,124.246994 818.105530,124.000999 
	C814.986450,123.839943 812.540039,123.914413 811.350403,128.012421 
	C810.824829,129.822845 807.203369,131.180328 804.743652,131.855240 
	C802.390503,132.500885 799.721375,131.994553 797.191101,131.994553 
	C797.182251,132.665939 797.173401,133.337326 797.164551,134.008713 
	C799.838318,134.008713 802.512207,133.996719 805.185974,134.011398 
	C809.667969,134.036011 811.955322,136.291519 811.985779,140.782990 
	C812.005005,143.613876 812.046814,146.446808 811.959778,149.275177 
	C811.924683,150.414688 811.959106,152.174454 811.302368,152.577637 
	C806.626343,155.448273 804.227234,160.907043 799.726685,163.450912 
	C796.808044,165.100647 795.847473,166.426651 795.990479,169.336075 
	C796.236328,174.338058 796.354797,179.149033 789.679932,180.508316 
	C788.806824,180.686142 788.123413,183.854874 788.071167,185.659149 
	C787.795044,195.186325 787.182251,195.923615 777.751770,195.979614 
	C774.794067,195.997162 772.485046,195.992950 771.430969,199.890518 
	C770.950623,201.666550 767.657837,203.393036 765.394531,203.811966 
	C761.851257,204.467804 758.099121,203.991272 754.435425,204.002258 
	C753.269958,204.005753 751.692383,203.544739 751.002258,204.126038 
	C743.050598,210.823883 738.031982,201.249680 731.427368,200.613907 
	C730.470947,200.521851 729.848328,198.402298 728.760254,197.914093 
	C726.954834,197.103958 723.971802,195.802109 723.109436,196.558838 
	C719.801880,199.461075 717.169678,198.358551 714.199829,196.423843 
	C713.568909,196.012817 712.589172,196.137238 711.067749,195.867432 
	C709.244385,195.479126 708.123352,195.238388 706.871338,194.667664 
	C706.110596,193.838516 705.552612,193.072052 704.840088,192.880463 
	C694.905212,190.208954 684.949341,187.616043 674.671875,184.900024 
	C671.226440,182.201187 668.107666,179.606308 665.366516,177.035095 
	C673.501831,180.897644 681.364380,180.864670 689.402710,178.074677 
	C687.737488,176.504715 685.859253,175.993164 684.202942,175.079391 
	C683.351074,174.609451 682.510559,173.472321 682.390320,172.542877 
	C682.305664,171.888321 683.393433,170.479843 684.041870,170.426193 
	C690.321289,169.906647 690.087402,165.522964 690.033569,160.991501 
	C689.953552,154.249252 690.253723,154.045273 697.100830,154.001297 
	C697.933105,153.995941 698.768311,153.950592 699.597107,154.003586 
	C704.361450,154.308167 706.164917,151.941162 706.036804,147.353256 
	C705.874268,141.532104 705.951172,135.702377 706.018005,129.877426 
	C706.058289,126.366898 705.120605,122.847733 710.564026,121.452011 
	C713.227173,120.769173 714.994019,116.622993 717.204102,114.076942 
	C717.371948,113.883614 717.956055,114.069847 718.151367,113.879387 
	C721.980591,110.146835 725.122192,105.732811 729.643860,102.652267 
	C730.322510,102.189941 729.973877,100.123550 729.986694,98.798111 
	C730.025208,94.803688 730.037781,90.808449 730.001099,86.814064 
	C729.976929,84.181808 730.805237,82.626190 733.688721,81.800682 
	C735.361389,81.321815 736.648132,79.244781 737.927734,77.733795 
	C738.730347,76.785973 739.100891,74.667107 739.830200,74.587662 
	C746.643311,73.845520 746.336487,68.984947 745.956848,64.243408 
	C745.568115,59.389122 747.642822,57.454468 752.356445,57.988251 
	C753.178040,58.081299 754.019531,58.009205 754.851624,57.997646 
	C761.045959,57.911606 761.957764,56.993301 761.992249,50.602264 
	C762.027283,44.110939 761.878418,37.615009 762.097900,31.130842 
	C762.152100,29.530624 763.160217,26.696857 764.005493,26.588894 
	C770.959351,25.700710 769.825500,20.672979 770.096252,16.064081 
	C770.209656,14.133412 771.140808,10.748877 772.078247,10.611856 
	C778.236389,9.711744 778.268799,5.556256 778.000000,1.000000 
	C815.687561,1.000000 853.375122,1.000000 891.531372,1.000000 
z"/>
<path fill="#332D2F" opacity="1.000000" stroke="none" 
	d="
M642.000000,955.000000 
	C647.784851,954.050842 653.728088,951.701111 658.435791,957.865906 
	C659.014465,958.623596 662.276978,958.543335 663.119751,957.689941 
	C668.348389,952.396118 674.736145,951.139954 681.780762,951.184570 
	C690.722107,951.241211 699.665466,951.041809 708.606812,950.885437 
	C709.377869,950.871887 710.630615,950.547302 710.831177,950.039795 
	C713.016113,944.510803 718.323486,945.453979 722.556030,944.036133 
	C724.549561,943.368347 726.148804,941.598389 728.039734,940.527283 
	C729.770691,939.546631 731.582642,938.381775 733.482361,938.083130 
	C741.326904,936.850037 749.219910,935.927490 757.089172,934.847351 
	C757.714233,934.761536 758.599731,934.510925 758.848145,934.055542 
	C762.054077,928.177917 767.839111,929.529480 772.901001,929.034424 
	C777.851868,928.550415 782.863464,928.579224 787.776428,927.878357 
	C789.325562,927.657288 791.274841,926.097473 791.938354,924.646179 
	C793.483093,921.267456 795.782654,919.924622 799.359314,919.976501 
	C805.852905,920.070801 812.349182,919.961365 818.843872,920.009094 
	C821.638184,920.029663 823.711914,919.440979 824.100342,916.108765 
	C824.492737,912.743225 826.978943,911.990112 829.927856,911.999878 
	C836.589417,912.021973 843.251953,911.934326 849.912292,912.026855 
	C853.204834,912.072510 855.568848,911.656433 856.410095,907.597717 
	C856.717041,906.116882 859.638000,904.371277 861.512756,904.179749 
	C866.292847,903.691589 871.155518,904.024048 875.985046,904.002441 
	C878.894165,903.989441 881.407227,904.621277 881.861023,908.035583 
	C882.301819,911.351318 880.841431,913.093018 877.524353,914.213806 
	C875.978394,914.736267 874.619385,917.265076 874.136353,919.132996 
	C873.444153,921.809631 874.679749,926.251221 873.244629,927.229736 
	C869.099304,930.056213 866.560852,934.165100 863.051147,937.268738 
	C860.989258,939.092041 857.893066,939.411194 857.516907,943.088684 
	C857.418335,944.052002 855.812927,945.548157 854.817078,945.617737 
	C849.561462,945.984558 849.895447,949.612488 850.003723,953.198608 
	C850.133728,957.502197 850.413086,961.881958 844.309082,962.260376 
	C842.976562,969.081848 831.752747,969.614990 833.909912,978.809082 
	C834.502441,981.334656 833.909119,984.121033 834.020142,986.781006 
	C834.182922,990.682983 833.185181,992.993591 828.809998,994.452942 
	C826.073914,995.365601 824.784790,1000.794312 822.080811,1001.587891 
	C816.495239,1003.227173 818.345886,1007.088806 818.044922,1010.377563 
	C817.818115,1012.854919 818.007629,1015.369690 817.998352,1017.867798 
	C817.971069,1025.226562 817.262695,1025.951294 810.079712,1026.000000 
	C802.050293,1026.054443 801.958679,1026.055176 802.037415,1034.017456 
	C802.078430,1038.161743 801.829163,1041.651123 796.509521,1042.407227 
	C795.500610,1042.550659 794.239990,1045.095825 794.130493,1046.607910 
	C793.806580,1051.081665 793.963623,1055.593140 794.004272,1060.089233 
	C794.033569,1063.331055 792.408447,1064.635986 789.516541,1066.254639 
	C785.100769,1068.726074 783.155029,1074.387085 778.243713,1076.799438 
	C777.734863,1077.049438 778.061707,1079.000732 778.000366,1080.581299 
	C743.312439,1081.000000 708.624878,1081.000000 673.468628,1080.533691 
	C673.000000,1079.567993 672.995178,1079.068604 673.000732,1078.569214 
	C673.173279,1063.079834 671.060913,1065.123169 686.507751,1065.015869 
	C697.495911,1064.939453 697.151367,1065.770264 696.976257,1054.907104 
	C696.921753,1051.528809 697.474426,1049.155273 701.641785,1048.733521 
	C702.858643,1048.610229 704.559448,1046.224487 704.695312,1044.751831 
	C704.798767,1043.628906 702.862793,1041.351440 701.692505,1041.251953 
	C697.547668,1040.899170 696.990967,1038.549561 696.959167,1035.139771 
	C696.866211,1025.174438 696.788025,1025.175903 706.945862,1024.997070 
	C707.777466,1024.982422 708.789185,1025.243164 709.402832,1024.865234 
	C710.673889,1024.082764 712.669861,1023.001160 712.719543,1021.963684 
	C712.950806,1017.126953 715.952759,1016.819702 719.593384,1016.979736 
	C721.584656,1017.067322 723.794739,1017.455872 725.516663,1016.753723 
	C726.948181,1016.170166 728.531860,1014.269470 728.690552,1012.804993 
	C728.811340,1011.690308 727.004150,1010.007812 725.674988,1009.212769 
	C724.680237,1008.617676 723.080505,1009.011108 721.750000,1009.003906 
	C712.961609,1008.956665 712.961609,1008.960144 713.000183,1000.161438 
	C713.030701,993.200134 713.213257,993.017029 720.431946,993.002319 
	C726.923157,992.989136 733.429443,993.235291 739.895569,992.830872 
	C741.632446,992.722229 744.414062,990.909729 744.730225,989.449463 
	C746.947754,979.206909 745.209106,977.208008 734.910461,976.998413 
	C734.577637,976.991638 734.244141,976.978577 733.911987,976.992432 
	C730.997620,977.113525 729.445679,976.045898 728.795227,972.876526 
	C728.488342,971.381226 726.199463,969.242493 724.754395,969.199036 
	C714.278259,968.883545 703.786011,968.926086 693.305237,969.150269 
	C691.903931,969.180237 689.394897,970.812073 689.347717,971.795654 
	C689.104126,976.877563 685.981079,977.204773 682.151794,977.010681 
	C668.435730,976.315308 654.717468,975.664551 641.000000,974.535278 
	C641.333374,967.715637 641.666687,961.357849 642.000000,955.000000 
z"/>
<path fill="#3E3A3D" opacity="1.000000" stroke="none" 
	d="
M330.468658,1081.000000 
	C330.000000,1080.500244 330.000000,1080.000366 330.000000,1079.500610 
	C330.000031,1067.350708 329.991455,1067.269165 341.931091,1066.015381 
	C345.283478,1065.663452 346.074768,1063.814575 345.993073,1060.969727 
	C345.847565,1055.901855 345.306458,1050.746582 352.279419,1049.281494 
	C353.144775,1049.099487 353.885101,1046.200317 353.911835,1044.547852 
	C353.991852,1039.607300 353.465485,1034.623535 360.234467,1033.409546 
	C361.048126,1033.263550 361.429443,1031.237793 362.208008,1030.236328 
	C363.277344,1028.860474 364.348969,1026.811279 365.746918,1026.494385 
	C369.702972,1025.597412 370.194519,1023.077576 370.007568,1019.807495 
	C369.737823,1015.089661 369.886169,1010.794067 375.968109,1009.396362 
	C377.011871,1009.156555 377.840240,1005.817627 377.898987,1003.883911 
	C378.040192,999.236328 377.685272,994.680725 383.934326,993.564758 
	C384.806183,993.409058 385.337891,991.557373 386.103821,990.549133 
	C387.162720,989.155396 388.112122,986.880615 389.427917,986.624390 
	C394.021667,985.729919 394.074493,982.677429 394.026947,979.143433 
	C393.932831,972.147522 394.288330,965.129883 393.820282,958.164062 
	C393.675415,956.008728 391.609528,953.885803 390.158569,951.945923 
	C389.024506,950.429565 387.419769,949.274658 386.141327,947.853760 
	C385.273926,946.889832 384.824860,945.151367 383.815796,944.784119 
	C380.090271,943.428467 378.667816,941.508545 379.642639,937.287415 
	C380.297424,934.452148 377.802185,933.807373 375.282928,934.492737 
	C373.528534,934.970032 371.807220,935.742737 370.026215,935.912537 
	C366.250732,936.272583 363.619537,935.644958 362.635376,930.781860 
	C361.670654,926.014709 360.107330,920.828796 353.226593,920.315918 
	C350.001556,920.075500 347.686584,916.526245 345.153229,914.898621 
	C340.100555,911.652588 338.729065,905.316406 332.790131,902.963684 
	C329.083710,901.495422 326.257050,897.623840 323.251801,894.614868 
	C319.636963,890.995422 316.427704,886.959839 312.709778,883.458008 
	C309.870667,880.783936 306.690063,878.367676 303.351654,876.348999 
	C294.811340,871.184937 289.288116,863.041443 282.668671,855.986389 
	C276.638763,849.559814 270.597351,843.129822 264.885345,836.424988 
	C261.921814,832.946411 259.812225,828.755127 257.046783,825.089600 
	C252.762756,819.411255 247.822128,814.185303 243.946381,808.253662 
	C239.797058,801.903320 236.557419,794.958435 232.923767,788.271423 
	C232.385757,787.281311 232.114075,785.940491 231.295181,785.341858 
	C225.209076,780.892517 222.521133,774.324646 219.629593,767.746582 
	C218.121109,764.314880 215.327362,761.463318 213.677841,758.075256 
	C210.341934,751.223328 207.367981,744.194092 204.301895,737.212891 
	C203.771866,736.006042 203.676163,734.604248 203.120789,733.413330 
	C198.668823,723.866638 193.604843,714.561096 189.823257,704.759216 
	C186.446564,696.006836 184.450729,686.720276 181.872330,677.661804 
	C179.831268,670.491150 177.620453,663.359253 175.886932,656.114014 
	C174.751068,651.366699 174.331848,646.447876 174.045807,641.538330 
	C179.123596,645.253906 182.132629,649.650391 179.884613,656.311157 
	C180.486496,660.290344 181.749939,663.942993 181.811569,667.615906 
	C181.878357,671.596863 183.561249,673.734680 186.992218,675.256226 
	C186.996368,675.502625 187.005493,675.995361 187.003357,676.434448 
	C187.109314,682.663879 187.115494,688.494690 192.994339,692.379761 
	C193.333038,694.172424 193.669159,695.586243 194.002960,697.375000 
	C194.001236,698.500000 194.001846,699.250000 194.025543,700.373596 
	C195.364304,703.165588 196.679977,705.584045 197.996826,708.376831 
	C198.000320,709.500000 198.002609,710.248779 198.069611,711.343201 
	C199.088333,713.126709 200.042343,714.564575 200.997238,716.376831 
	C201.000595,717.499939 201.003052,718.248657 201.010315,719.381592 
	C201.531235,721.288391 201.895584,722.884583 202.591980,724.319763 
	C204.659912,728.581360 206.858551,732.779480 209.005554,737.376892 
	C209.005554,738.500305 209.006104,739.249207 209.010681,740.375366 
	C210.674667,742.835205 212.334610,744.917969 214.054413,747.280518 
	C214.410324,748.040222 214.706375,748.520142 215.078003,749.342529 
	C218.302414,755.852539 221.254654,762.131775 224.674545,768.145020 
	C226.692184,771.692749 229.658707,774.688721 231.834625,778.160889 
	C237.127289,786.606323 242.048935,795.286560 247.429001,803.674072 
	C251.030380,809.288574 254.792969,814.884399 259.161682,819.897400 
	C263.315735,824.664001 268.350983,828.662659 272.999390,832.999512 
	C273.000000,833.000000 273.001221,833.000000 273.074280,833.335571 
	C283.181061,846.706970 293.034760,859.901489 307.180756,869.005127 
	C308.439941,869.815552 309.055664,871.616699 309.989502,872.944092 
	C310.916321,874.261536 311.582672,876.054443 312.854279,876.789734 
	C321.187378,881.608276 326.227478,891.385498 336.852142,892.753845 
	C337.416412,892.826477 337.935547,893.494934 338.414032,893.950684 
	C343.200226,898.510315 347.886414,903.180786 352.807465,907.589233 
	C354.147766,908.789917 356.206879,909.916687 357.896912,909.862244 
	C363.044128,909.696228 365.692017,912.608276 368.078583,916.519165 
	C369.112885,918.214111 370.686981,919.988953 372.441528,920.763916 
	C379.937866,924.074768 388.806335,924.928406 393.481201,933.082092 
	C393.688416,933.443542 394.311157,933.588074 394.761353,933.785706 
	C402.255280,937.075500 409.812073,940.230530 417.221344,943.700745 
	C420.418091,945.197876 423.099854,947.073853 425.133423,950.634277 
	C426.281616,952.644592 431.573944,952.287964 435.333130,952.998413 
	C436.111450,952.999451 436.556183,952.999695 437.265350,953.216675 
	C441.926849,959.073792 448.121918,961.175842 454.836090,962.348022 
	C455.519531,962.467346 456.442688,963.257629 456.618896,963.908691 
	C457.831726,968.389343 461.326202,968.896606 465.145844,969.360352 
	C467.677612,969.667786 471.846863,970.101074 472.306030,971.473877 
	C474.142609,976.964294 478.259430,975.530396 482.050354,976.132446 
	C484.949341,976.592834 488.673370,977.485229 490.204620,979.543640 
	C492.235809,982.274109 494.205109,982.829468 497.178528,983.117493 
	C499.814789,983.372803 503.825134,983.902222 504.667450,985.589417 
	C507.545990,991.355286 512.184998,991.234741 517.247009,991.027283 
	C520.246216,990.904358 522.677002,991.934265 522.863892,995.314392 
	C523.075134,999.134155 523.098938,1002.989685 522.799744,1006.797913 
	C522.700867,1008.056274 521.448364,1010.171387 520.548523,1010.268005 
	C514.044617,1010.966919 514.895935,1015.622131 515.013367,1019.902344 
	C515.113220,1023.540649 514.593994,1026.479248 510.095428,1027.217285 
	C508.922211,1027.409668 508.029327,1029.325439 507.012360,1030.453857 
	C503.337891,1034.531372 499.689667,1038.633301 495.953552,1042.653564 
	C495.587555,1043.047363 494.626556,1042.819946 494.044830,1043.097290 
	C493.116180,1043.539917 492.274139,1044.164185 490.682343,1045.156128 
	C492.415802,1046.023193 493.493378,1046.344482 494.288666,1047.005493 
	C495.912689,1048.355103 498.694641,1049.899536 498.681549,1051.342651 
	C498.636749,1056.287598 501.002563,1061.729980 496.877625,1066.105103 
	C496.217316,1066.805542 494.722626,1066.839355 493.591492,1066.946045 
	C492.104126,1067.086426 490.591400,1067.061279 489.095490,1066.987549 
	C485.805817,1066.825439 483.217834,1067.265625 482.683716,1071.430420 
	C482.524292,1072.673584 480.720184,1074.512695 479.493988,1074.665039 
	C475.374786,1075.177002 474.738495,1077.650024 475.000000,1081.000000 
	C426.979095,1081.000000 378.958221,1081.000000 330.468658,1081.000000 
z"/>
<path fill="#393336" opacity="1.000000" stroke="none" 
	d="
M928.000000,360.004883 
	C929.794739,360.348999 931.698669,360.431213 933.349915,361.119476 
	C934.689270,361.677704 936.569092,362.830078 936.747009,363.950989 
	C937.449646,368.378082 940.227722,369.151611 943.946594,369.017212 
	C946.608459,368.920990 949.278748,368.931000 951.941467,369.015228 
	C956.445496,369.157776 960.881775,369.387207 961.421204,363.080139 
	C961.479431,362.399475 962.737488,361.350250 963.513306,361.278778 
	C968.506348,360.818939 969.079163,357.577271 969.044739,353.488617 
	C968.974121,345.095154 969.079834,345.094238 977.611877,344.999084 
	C984.001282,344.927826 984.995972,344.044067 984.969971,337.832489 
	C984.951782,333.469147 984.260010,329.116516 990.581665,328.128296 
	C991.666931,327.958649 992.758911,324.330353 992.883606,322.242188 
	C993.148315,317.809296 992.009827,313.082581 998.666870,312.137207 
	C999.656738,311.996643 1000.757385,308.934265 1000.885925,307.157013 
	C1001.209717,302.682068 1000.710815,298.138550 1001.169861,293.687256 
	C1001.339722,292.040039 1003.005554,289.596283 1004.440613,289.214996 
	C1008.212891,288.212708 1009.025085,285.925415 1009.021545,282.584259 
	C1009.016785,278.101685 1007.656799,272.801605 1014.830078,272.185150 
	C1015.507507,272.126923 1016.532227,270.799225 1016.608826,269.986725 
	C1016.925903,266.621552 1018.753235,265.452911 1021.860962,264.421082 
	C1023.393921,263.912079 1024.834961,260.562012 1024.851807,258.485596 
	C1024.934814,248.278931 1025.105469,248.995056 1034.614746,248.999832 
	C1050.167358,249.007660 1049.027466,250.291458 1049.001587,234.834442 
	C1048.994263,230.503265 1049.035278,226.171829 1048.999878,221.840958 
	C1048.978516,219.217514 1049.659790,217.292374 1052.712769,216.945023 
	C1056.046143,216.565750 1057.009399,214.262939 1056.995605,211.252762 
	C1056.974976,206.755142 1056.713501,202.227905 1057.163330,197.774765 
	C1057.331665,196.108231 1058.941772,193.608139 1060.354614,193.236206 
	C1064.093384,192.252060 1065.102295,190.055374 1065.009155,186.672348 
	C1064.954224,184.675964 1064.760376,182.617340 1065.161621,180.698074 
	C1065.422729,179.448730 1066.450195,177.652252 1067.465698,177.391754 
	C1071.623779,176.325043 1075.909180,175.754150 1080.573853,175.000366 
	C1081.000000,223.020889 1081.000000,271.041779 1080.570557,319.531372 
	C1072.549683,320.106842 1072.040039,320.632416 1072.000244,328.397095 
	C1071.995972,329.229584 1071.964233,330.063354 1071.997925,330.894379 
	C1072.112549,333.715057 1070.900879,334.905884 1068.166016,336.281250 
	C1063.418213,338.668976 1061.437500,344.603912 1056.401245,347.118286 
	C1055.914795,347.361176 1056.057129,348.991425 1056.022095,349.981659 
	C1055.963379,351.644775 1055.943237,353.313080 1056.003662,354.975708 
	C1056.108032,357.847321 1055.067505,359.319427 1052.051636,360.309326 
	C1050.319458,360.877930 1048.468506,363.610779 1048.195435,365.580597 
	C1047.606934,369.829010 1047.944702,374.214722 1048.014771,378.542480 
	C1048.062744,381.507812 1047.426880,383.430328 1043.965210,384.309204 
	C1042.349487,384.719482 1040.766846,387.273041 1040.175415,389.169037 
	C1039.510742,391.299652 1040.285645,393.843994 1039.845337,396.093018 
	C1039.578491,397.456421 1038.323853,399.544342 1037.292114,399.684845 
	C1032.208374,400.377197 1031.945923,403.732697 1031.967041,407.693512 
	C1032.010742,415.933319 1031.934326,415.933838 1023.712830,416.000122 
	C1023.213318,416.004150 1022.712524,416.029205 1022.214539,416.002197 
	C1017.738342,415.759552 1015.788025,417.941132 1015.986023,422.338196 
	C1016.075806,424.330688 1016.306213,426.421204 1015.822571,428.299896 
	C1015.477783,429.639008 1014.207153,431.507690 1013.072510,431.730774 
	C1009.735657,432.386810 1008.169983,434.086151 1007.763855,437.357880 
	C1007.662903,438.171478 1006.702698,439.472076 1006.045471,439.531555 
	C999.035889,440.165985 1000.171753,445.295166 999.905090,449.909943 
	C999.793274,451.845978 998.876892,455.242859 997.944519,455.380035 
	C991.802734,456.283508 991.920044,460.420807 991.923767,464.989380 
	C991.925842,467.519684 993.234985,471.660522 988.918945,471.564209 
	C984.691284,471.469879 986.122070,467.358612 986.059448,464.817474 
	C985.853455,456.457611 985.549500,456.048706 977.272278,455.999908 
	C970.185791,455.958130 970.174438,455.807281 969.928284,448.678986 
	C969.840759,446.148285 969.569031,441.920197 968.276428,441.439697 
	C963.526733,439.674194 962.674438,435.878662 961.355652,431.990936 
	C960.955139,430.810028 960.020447,429.616028 959.005188,428.871307 
	C954.401184,425.493835 949.680908,422.274841 945.001770,418.548950 
	C945.000732,415.399414 945.000366,412.699707 945.000000,409.554718 
	C945.332458,404.269287 945.386719,399.728760 938.971558,398.525543 
	C938.042114,398.351196 937.331848,395.934418 936.890198,394.445557 
	C935.810669,390.805878 936.486206,386.332245 931.538696,384.696472 
	C930.801208,384.452637 930.234924,382.782745 930.118835,381.715088 
	C929.333008,374.487000 928.689514,367.243439 928.000000,360.004883 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M437.000946,953.000000 
	C436.556183,952.999695 436.111450,952.999451 435.259949,952.626160 
	C434.089142,950.264771 433.497162,946.648499 432.533691,946.546631 
	C425.073120,945.758057 420.770752,940.292114 415.326416,936.324829 
	C410.708466,932.959839 405.137390,930.907288 400.003052,928.245056 
	C395.136871,925.721924 390.540009,922.139832 385.371613,920.875610 
	C378.900146,919.292542 374.416382,915.485962 369.518372,911.613281 
	C365.860046,908.720703 363.924316,903.414734 357.589172,903.712341 
	C354.884705,903.839478 351.876617,901.148254 349.306610,899.316650 
	C347.323822,897.903442 345.803253,895.839050 344.087402,894.054382 
	C335.102814,884.709778 321.080261,881.412842 314.383484,869.317627 
	C314.310852,869.186462 314.113037,869.129272 313.985443,869.023438 
	C307.778137,863.873474 301.289032,859.020569 295.448303,853.483826 
	C289.976868,848.297119 285.350830,842.227844 280.017273,836.881592 
	C278.203827,835.063782 275.369232,834.264587 273.001221,833.000000 
	C273.001221,833.000000 273.000000,833.000000 272.977295,832.630737 
	C272.119171,830.487732 271.570801,828.488403 270.389923,826.986267 
	C267.743011,823.619202 264.573364,820.656982 261.992096,817.245728 
	C256.825500,810.417725 251.739380,803.514465 246.964539,796.410645 
	C244.584702,792.869934 242.965256,788.818176 241.384491,785.004639 
	C243.168671,785.715149 245.022339,786.066162 245.893234,787.183289 
	C249.625946,791.971497 253.039856,797.006714 256.625275,801.911499 
	C261.306458,808.315247 265.954071,814.747314 270.808899,821.017883 
	C272.079559,822.659180 274.085205,823.722595 275.714264,825.097656 
	C277.345520,826.474731 279.019836,827.819153 280.513824,829.336853 
	C284.432434,833.317627 288.190033,837.458435 292.150757,841.395752 
	C295.656647,844.881042 299.734192,847.858276 302.872681,851.630981 
	C308.015015,857.812378 312.497559,864.511047 321.017761,866.632202 
	C322.448608,866.988464 323.523651,868.685547 324.821838,869.687012 
	C326.230804,870.773743 327.693237,871.793579 329.159698,872.802795 
	C331.297150,874.273987 334.032043,875.288574 335.478790,877.257324 
	C337.766418,880.370300 340.425018,881.426147 344.252136,880.996948 
	C344.500610,880.998779 344.997559,881.002441 345.020630,881.371460 
	C346.479797,889.749695 353.194000,893.034302 359.353943,895.465271 
	C365.240021,897.788269 369.501862,901.292053 373.579498,905.698547 
	C375.189301,907.438171 377.388672,909.238342 379.597595,909.710571 
	C388.399597,911.592468 396.056274,915.368103 402.444244,921.720459 
	C402.898071,922.171692 403.521362,922.535095 404.132660,922.732422 
	C408.751282,924.223572 413.402710,925.614990 418.004608,927.154846 
	C419.045593,927.503235 419.889496,928.400696 420.899475,928.889709 
	C423.150146,929.979553 425.384491,931.156250 427.738953,931.968872 
	C435.549286,934.664429 445.173981,933.503296 449.907562,942.726013 
	C450.367065,943.621277 452.179504,944.161743 453.412354,944.260132 
	C458.204681,944.642700 463.014587,944.866211 467.821899,944.976440 
	C470.599731,945.040161 472.508179,945.589905 473.224457,948.835144 
	C473.515594,950.154297 475.489899,951.863831 476.873474,952.042908 
	C481.791992,952.679504 486.783173,952.849304 491.752258,952.965637 
	C494.811859,953.037292 496.640137,953.768250 497.209717,957.294006 
	C497.471649,958.915283 499.670135,961.403442 501.074738,961.461548 
	C508.104797,961.752136 515.167114,961.471863 522.208984,961.176147 
	C525.642761,961.032043 528.328796,961.101562 529.267761,965.434448 
	C530.132568,969.425293 532.973877,969.140503 536.326782,967.899536 
	C538.756714,967.000122 541.724365,966.476074 544.200684,966.992188 
	C550.358154,968.275452 556.320007,970.712769 562.414978,966.654663 
	C563.479126,965.946167 565.379028,966.001648 566.747070,966.323914 
	C573.632080,967.945557 580.440857,969.898010 587.343201,971.434143 
	C588.952820,971.792358 590.873657,971.160461 592.563477,970.676941 
	C598.541138,968.966370 604.365845,966.389465 610.372986,971.009277 
	C613.011353,973.038391 616.250793,975.316528 619.343994,975.515991 
	C626.521667,975.978760 633.776489,975.245483 641.000000,974.997009 
	C654.717468,975.664551 668.435730,976.315308 682.151794,977.010681 
	C685.981079,977.204773 689.104126,976.877563 689.347717,971.795654 
	C689.394897,970.812073 691.903931,969.180237 693.305237,969.150269 
	C703.786011,968.926086 714.278259,968.883545 724.754395,969.199036 
	C726.199463,969.242493 728.488342,971.381226 728.795227,972.876526 
	C729.445679,976.045898 730.997620,977.113525 733.911987,976.992432 
	C734.244141,976.978577 734.577637,976.991638 734.910461,976.998413 
	C745.209106,977.208008 746.947754,979.206909 744.730225,989.449463 
	C744.414062,990.909729 741.632446,992.722229 739.895569,992.830872 
	C733.429443,993.235291 726.923157,992.989136 720.431946,993.002319 
	C713.213257,993.017029 713.030701,993.200134 713.000183,1000.161438 
	C712.961609,1008.960144 712.961609,1008.956665 721.750000,1009.003906 
	C723.080505,1009.011108 724.680237,1008.617676 725.674988,1009.212769 
	C727.004150,1010.007812 728.811340,1011.690308 728.690552,1012.804993 
	C728.531860,1014.269470 726.948181,1016.170166 725.516663,1016.753723 
	C723.794739,1017.455872 721.584656,1017.067322 719.593384,1016.979736 
	C715.952759,1016.819702 712.950806,1017.126953 712.719543,1021.963684 
	C712.669861,1023.001160 710.673889,1024.082764 709.402832,1024.865234 
	C708.789185,1025.243164 707.777466,1024.982422 706.945862,1024.997070 
	C696.788025,1025.175903 696.866211,1025.174438 696.959167,1035.139771 
	C696.990967,1038.549561 697.547668,1040.899170 701.692505,1041.251953 
	C702.862793,1041.351440 704.798767,1043.628906 704.695312,1044.751831 
	C704.559448,1046.224487 702.858643,1048.610229 701.641785,1048.733521 
	C697.474426,1049.155273 696.921753,1051.528809 696.976257,1054.907104 
	C697.151367,1065.770264 697.495911,1064.939453 686.507751,1065.015869 
	C671.060913,1065.123169 673.173279,1063.079834 673.000732,1078.569214 
	C672.995178,1079.068604 673.000000,1079.567993 673.000000,1080.533691 
	C643.312439,1081.000000 613.624878,1081.000000 583.468994,1080.581665 
	C583.013000,1079.331299 582.761719,1078.376831 583.081482,1077.687378 
	C584.883057,1073.802979 585.890137,1068.104736 588.938293,1066.591919 
	C593.213623,1064.470093 594.046936,1062.216187 594.029846,1058.281250 
	C593.994202,1050.067993 594.063843,1050.067749 602.307495,1050.000122 
	C602.806824,1049.996094 603.307495,1049.970825 603.805298,1049.997681 
	C608.293274,1050.238525 610.150330,1048.026611 610.023682,1043.644409 
	C609.898743,1039.320557 609.703552,1034.956787 610.170105,1030.679321 
	C610.350037,1029.030029 611.995789,1026.634644 613.448425,1026.212402 
	C616.848999,1025.223999 618.023010,1023.326233 618.005005,1020.086304 
	C617.964294,1012.762878 617.909851,1005.437744 618.023560,998.115845 
	C618.094116,993.575684 615.894104,991.843628 611.564270,991.976074 
	C607.407104,992.103210 603.242065,991.946838 599.081848,992.009277 
	C596.248718,992.051758 594.619263,991.208557 593.724304,988.094299 
	C593.247498,986.435181 590.659546,984.354858 589.016602,984.348022 
	C587.375854,984.341248 585.620300,986.576538 584.114868,988.035034 
	C583.054199,989.062500 582.327576,990.434753 581.451782,991.653076 
	C580.336304,990.572266 578.580322,989.667114 578.226562,988.377380 
	C577.205750,984.656494 574.735168,983.941223 571.427490,983.980713 
	C565.103394,984.056213 558.775269,984.094543 552.454285,983.933289 
	C550.725891,983.889160 548.148254,983.633789 547.444397,982.529541 
	C543.714111,976.675842 537.790710,977.665466 532.332153,976.982056 
	C526.264832,976.222412 520.230042,975.182556 514.152100,974.537292 
	C510.844727,974.186096 507.427338,974.029297 504.152039,974.470825 
	C501.244568,974.862732 498.959137,975.359985 497.849609,971.741211 
	C497.460114,970.470825 496.292145,968.825439 495.149902,968.496521 
	C485.821808,965.810364 476.413788,963.402344 467.034119,960.894531 
	C463.141418,959.853821 457.998535,960.950439 457.065247,954.795593 
	C456.954193,954.063171 454.970764,953.154297 453.829163,953.116577 
	C448.224609,952.931274 442.611206,953.012695 437.000946,953.000000 
z"/>
<path fill="#464446" opacity="1.000000" stroke="none" 
	d="
M777.531372,1.000000 
	C778.268799,5.556256 778.236389,9.711744 772.078247,10.611856 
	C771.140808,10.748877 770.209656,14.133412 770.096252,16.064081 
	C769.825500,20.672979 770.959351,25.700710 764.005493,26.588894 
	C763.160217,26.696857 762.152100,29.530624 762.097900,31.130842 
	C761.878418,37.615009 762.027283,44.110939 761.992249,50.602264 
	C761.957764,56.993301 761.045959,57.911606 754.851624,57.997646 
	C754.019531,58.009205 753.178040,58.081299 752.356445,57.988251 
	C747.642822,57.454468 745.568115,59.389122 745.956848,64.243408 
	C746.336487,68.984947 746.643311,73.845520 739.830200,74.587662 
	C739.100891,74.667107 738.730347,76.785973 737.927734,77.733795 
	C736.648132,79.244781 735.361389,81.321815 733.688721,81.800682 
	C730.805237,82.626190 729.976929,84.181808 730.001099,86.814064 
	C730.037781,90.808449 730.025208,94.803688 729.986694,98.798111 
	C729.973877,100.123550 730.322510,102.189941 729.643860,102.652267 
	C725.122192,105.732811 721.980591,110.146835 718.151367,113.879387 
	C717.956055,114.069847 717.371948,113.883614 717.204102,114.076942 
	C714.994019,116.622993 713.227173,120.769173 710.564026,121.452011 
	C705.120605,122.847733 706.058289,126.366898 706.018005,129.877426 
	C705.951172,135.702377 705.874268,141.532104 706.036804,147.353256 
	C706.164917,151.941162 704.361450,154.308167 699.597107,154.003586 
	C698.768311,153.950592 697.933105,153.995941 697.100830,154.001297 
	C690.253723,154.045273 689.953552,154.249252 690.033569,160.991501 
	C690.087402,165.522964 690.321289,169.906647 684.041870,170.426193 
	C683.393433,170.479843 682.305664,171.888321 682.390320,172.542877 
	C682.510559,173.472321 683.351074,174.609451 684.202942,175.079391 
	C685.859253,175.993164 687.737488,176.504715 689.402710,178.074677 
	C681.364380,180.864670 673.501831,180.897644 664.964966,177.035156 
	C660.790649,178.007904 657.395447,179.004272 653.732910,179.783569 
	C647.107422,175.105698 640.124939,172.345154 632.291199,172.365967 
	C626.526062,172.381287 620.761963,172.775940 614.778076,172.816498 
	C612.436951,170.709976 612.141235,172.201981 611.751282,174.199371 
	C610.655823,174.605408 609.816345,174.808411 608.965393,174.788239 
	C608.953857,174.565048 608.940735,174.118301 608.940735,174.118301 
	C608.287476,172.849365 607.595947,171.598267 606.993469,170.305664 
	C606.543518,169.340454 605.627930,167.976929 605.964050,167.410767 
	C606.421021,166.641083 607.932800,165.990860 608.889465,166.110596 
	C613.528503,166.691193 615.612366,164.671082 617.134338,160.312408 
	C618.021240,157.772583 621.197327,156.058563 623.257019,153.890869 
	C624.320984,152.771103 625.872253,151.555862 626.035339,150.239075 
	C626.625488,145.474915 626.862122,140.652008 626.950439,135.846115 
	C627.005188,132.864456 627.609680,130.827057 630.923035,129.922913 
	C635.434265,128.691910 634.892334,124.386406 634.837219,121.187881 
	C634.754944,116.411133 636.309631,114.818825 641.141602,114.872124 
	C648.856018,114.957230 650.727844,112.779800 650.977417,105.088737 
	C651.063843,102.425346 651.113098,99.750839 650.972046,97.092407 
	C650.779419,93.461494 651.466248,91.105927 655.525513,89.437019 
	C657.450928,88.645393 658.513306,84.281990 658.826965,81.412178 
	C659.227539,77.747688 659.214600,74.945213 663.698486,73.392876 
	C665.458984,72.783379 666.821167,68.410789 666.782715,65.775040 
	C666.708191,60.669632 668.122131,58.706100 673.556274,58.837337 
	C680.744019,59.010925 682.606873,56.785595 682.955139,49.675106 
	C683.093506,46.848843 683.093323,44.006611 682.974854,41.179249 
	C682.825195,37.609367 683.256958,35.057678 687.463806,33.486610 
	C689.304810,32.799084 690.798889,28.601248 690.766296,26.018705 
	C690.699524,20.732059 692.184631,18.681196 697.812317,18.822325 
	C704.735107,18.995930 706.549988,16.780655 706.946594,9.919089 
	C707.100220,7.261412 706.990051,4.588488 707.000000,1.461255 
	C730.354248,1.000000 753.708435,1.000000 777.531372,1.000000 
M627.946960,162.903732 
	C631.616943,164.509354 635.150879,164.917099 638.854553,162.127487 
	C634.364746,159.041748 629.793213,159.262054 627.946960,162.903732 
M660.479065,170.739685 
	C661.463257,170.244339 662.447449,169.748978 663.431641,169.253616 
	C663.309692,168.692322 663.187744,168.131012 663.065857,167.569702 
	C661.377014,167.714905 659.688232,167.860107 657.999390,168.005295 
	C658.606262,168.891052 659.213074,169.776810 660.479065,170.739685 
z"/>
<path fill="#363032" opacity="1.000000" stroke="none" 
	d="
M945.002441,418.998779 
	C949.680908,422.274841 954.401184,425.493835 959.005188,428.871307 
	C960.020447,429.616028 960.955139,430.810028 961.355652,431.990936 
	C962.674438,435.878662 963.526733,439.674194 968.276428,441.439697 
	C969.569031,441.920197 969.840759,446.148285 969.928284,448.678986 
	C970.174438,455.807281 970.185791,455.958130 977.272278,455.999908 
	C985.549500,456.048706 985.853455,456.457611 986.059448,464.817474 
	C986.122070,467.358612 984.691284,471.469879 988.918945,471.564209 
	C993.234985,471.660522 991.925842,467.519684 991.923767,464.989380 
	C991.920044,460.420807 991.802734,456.283508 997.944519,455.380035 
	C998.876892,455.242859 999.793274,451.845978 999.905090,449.909943 
	C1000.171753,445.295166 999.035889,440.165985 1006.045471,439.531555 
	C1006.702698,439.472076 1007.662903,438.171478 1007.763855,437.357880 
	C1008.169983,434.086151 1009.735657,432.386810 1013.072510,431.730774 
	C1014.207153,431.507690 1015.477783,429.639008 1015.822571,428.299896 
	C1016.306213,426.421204 1016.075806,424.330688 1015.986023,422.338196 
	C1015.788025,417.941132 1017.738342,415.759552 1022.214539,416.002197 
	C1022.712524,416.029205 1023.213318,416.004150 1023.712830,416.000122 
	C1031.934326,415.933838 1032.010742,415.933319 1031.967041,407.693512 
	C1031.945923,403.732697 1032.208374,400.377197 1037.292114,399.684845 
	C1038.323853,399.544342 1039.578491,397.456421 1039.845337,396.093018 
	C1040.285645,393.843994 1039.510742,391.299652 1040.175415,389.169037 
	C1040.766846,387.273041 1042.349487,384.719482 1043.965210,384.309204 
	C1047.426880,383.430328 1048.062744,381.507812 1048.014771,378.542480 
	C1047.944702,374.214722 1047.606934,369.829010 1048.195435,365.580597 
	C1048.468506,363.610779 1050.319458,360.877930 1052.051636,360.309326 
	C1055.067505,359.319427 1056.108032,357.847321 1056.003662,354.975708 
	C1055.943237,353.313080 1055.963379,351.644775 1056.022095,349.981659 
	C1056.057129,348.991425 1055.914795,347.361176 1056.401245,347.118286 
	C1061.437500,344.603912 1063.418213,338.668976 1068.166016,336.281250 
	C1070.900879,334.905884 1072.112549,333.715057 1071.997925,330.894379 
	C1071.964233,330.063354 1071.995972,329.229584 1072.000244,328.397095 
	C1072.040039,320.632416 1072.549683,320.106842 1080.570557,320.000000 
	C1081.000000,362.354218 1081.000000,404.708466 1080.580566,447.531647 
	C1073.162476,448.258728 1072.064575,449.360657 1072.005493,456.266724 
	C1071.978394,459.433105 1071.912964,462.602905 1072.022705,465.765686 
	C1072.135620,469.018585 1071.763062,471.580505 1067.780273,472.480530 
	C1066.376465,472.797791 1065.290039,474.830750 1064.218506,476.204956 
	C1063.438354,477.205444 1063.072021,479.245911 1062.262085,479.392609 
	C1055.525146,480.613007 1056.008057,485.557983 1055.890869,490.520050 
	C1055.849731,492.255829 1054.887085,495.232269 1053.845215,495.472351 
	C1048.064819,496.804443 1047.978027,500.855988 1047.890991,505.449310 
	C1047.851562,507.530121 1047.011597,511.132721 1045.898438,511.400574 
	C1039.793701,512.869751 1040.120728,517.218506 1039.854614,521.871887 
	C1039.736084,523.944397 1038.511108,527.140503 1036.991333,527.713989 
	C1033.105835,529.180054 1031.889038,531.578857 1031.990601,535.309875 
	C1032.063232,537.974487 1032.001587,540.642700 1032.000122,543.309326 
	C1031.995483,551.815125 1031.983398,551.617310 1023.589478,552.130005 
	C1021.234741,552.273804 1017.315002,553.555542 1016.966370,555.002747 
	C1015.674622,560.364197 1012.074585,559.892029 1008.155762,560.066040 
	C1003.695435,560.264099 1001.651794,558.703064 1001.947937,554.039978 
	C1002.200806,550.056824 1002.285217,546.009644 1001.840698,542.058716 
	C1001.628662,540.174316 1000.390076,537.363708 998.960449,536.869263 
	C995.730835,535.752319 993.931580,534.083557 993.197021,530.825317 
	C993.014587,530.016235 991.955261,528.916077 991.179260,528.807800 
	C985.457825,528.009399 986.174194,523.849487 985.864319,519.798828 
	C985.680908,517.401062 984.640137,514.716614 983.120300,512.874390 
	C981.659912,511.104218 979.085144,510.253510 977.000000,508.544800 
	C977.000000,505.060547 977.000000,502.030273 977.215332,498.674744 
	C978.203247,494.526428 979.216614,490.241608 974.431641,488.589966 
	C970.665039,487.289825 969.320251,490.486572 968.539062,493.605225 
	C968.503723,493.746399 967.990356,493.767822 966.346191,494.204315 
	C966.346191,488.134705 966.317383,482.412964 966.356384,476.691681 
	C966.382263,472.888306 967.569153,468.668640 966.287903,465.398895 
	C964.984924,462.074066 961.721375,458.949188 958.528503,457.089447 
	C955.176392,455.136932 954.393127,452.569427 954.099976,449.410065 
	C953.869934,446.931671 954.013794,444.418091 954.000671,441.920105 
	C953.959717,434.103424 952.965515,433.115967 945.001953,432.534210 
	C945.001831,427.712677 945.002136,423.355743 945.002441,418.998779 
z"/>
<path fill="#332D2F" opacity="1.000000" stroke="none" 
	d="
M977.000000,508.998779 
	C979.085144,510.253510 981.659912,511.104218 983.120300,512.874390 
	C984.640137,514.716614 985.680908,517.401062 985.864319,519.798828 
	C986.174194,523.849487 985.457825,528.009399 991.179260,528.807800 
	C991.955261,528.916077 993.014587,530.016235 993.197021,530.825317 
	C993.931580,534.083557 995.730835,535.752319 998.960449,536.869263 
	C1000.390076,537.363708 1001.628662,540.174316 1001.840698,542.058716 
	C1002.285217,546.009644 1002.200806,550.056824 1001.947937,554.039978 
	C1001.651794,558.703064 1003.695435,560.264099 1008.155762,560.066040 
	C1012.074585,559.892029 1015.674622,560.364197 1016.966370,555.002747 
	C1017.315002,553.555542 1021.234741,552.273804 1023.589478,552.130005 
	C1031.983398,551.617310 1031.995483,551.815125 1032.000122,543.309326 
	C1032.001587,540.642700 1032.063232,537.974487 1031.990601,535.309875 
	C1031.889038,531.578857 1033.105835,529.180054 1036.991333,527.713989 
	C1038.511108,527.140503 1039.736084,523.944397 1039.854614,521.871887 
	C1040.120728,517.218506 1039.793701,512.869751 1045.898438,511.400574 
	C1047.011597,511.132721 1047.851562,507.530121 1047.890991,505.449310 
	C1047.978027,500.855988 1048.064819,496.804443 1053.845215,495.472351 
	C1054.887085,495.232269 1055.849731,492.255829 1055.890869,490.520050 
	C1056.008057,485.557983 1055.525146,480.613007 1062.262085,479.392609 
	C1063.072021,479.245911 1063.438354,477.205444 1064.218506,476.204956 
	C1065.290039,474.830750 1066.376465,472.797791 1067.780273,472.480530 
	C1071.763062,471.580505 1072.135620,469.018585 1072.022705,465.765686 
	C1071.912964,462.602905 1071.978394,459.433105 1072.005493,456.266724 
	C1072.064575,449.360657 1073.162476,448.258728 1080.580566,448.000305 
	C1081.000000,488.354218 1081.000000,528.708435 1081.000000,569.531372 
	C1077.505859,570.007202 1074.011108,570.056641 1070.517700,570.009155 
	C1067.374512,569.966431 1065.932617,571.473145 1066.010010,574.579895 
	C1066.051514,576.242493 1066.046265,577.909058 1065.981079,579.570679 
	C1065.935791,580.725708 1066.119141,582.648376 1065.573730,582.907166 
	C1060.449707,585.338318 1058.628784,591.444275 1053.806763,593.733521 
	C1050.998169,595.066772 1049.924072,596.339478 1049.994629,599.138306 
	C1050.044922,601.133179 1050.315552,603.213196 1049.857300,605.102295 
	C1049.469849,606.699707 1048.287720,609.262390 1047.266602,609.357849 
	C1041.128662,609.931213 1042.011597,614.245300 1042.001221,618.193970 
	C1041.994629,620.689819 1041.912476,623.189514 1042.019531,625.680725 
	C1042.203369,629.957214 1042.022461,633.906982 1036.334595,634.252686 
	C1035.040649,641.097046 1023.779785,641.590515 1025.917114,650.760864 
	C1026.468750,653.128113 1026.012085,655.733337 1025.997559,658.229126 
	C1025.957397,665.156921 1025.163818,665.946167 1018.230103,665.999573 
	C1010.068970,666.062439 1009.987854,666.062927 1010.034668,674.353455 
	C1010.057129,678.334717 1009.733887,681.644653 1004.669373,682.318604 
	C1003.621643,682.458069 1002.404663,684.515808 1002.032104,685.896362 
	C1001.162781,689.117493 998.950684,690.735596 996.196228,689.688049 
	C994.414185,689.010315 992.283813,686.396606 992.205627,684.569275 
	C991.800415,675.102051 991.958435,665.608459 992.024414,656.124695 
	C992.047119,652.867920 991.778320,650.320068 987.605835,649.741272 
	C983.914795,649.229309 983.997925,645.963074 983.998657,643.057800 
	C984.003662,623.756409 983.840576,604.452820 984.110229,585.155212 
	C984.179138,580.225281 982.727173,577.563049 977.377808,576.608032 
	C976.999146,571.044983 976.998352,566.023743 976.998291,560.533508 
	C976.999390,543.042664 976.999695,526.020691 977.000000,508.998779 
z"/>
<path fill="#3B3539" opacity="1.000000" stroke="none" 
	d="
M437.265350,953.216675 
	C442.611206,953.012695 448.224609,952.931274 453.829163,953.116577 
	C454.970764,953.154297 456.954193,954.063171 457.065247,954.795593 
	C457.998535,960.950439 463.141418,959.853821 467.034119,960.894531 
	C476.413788,963.402344 485.821808,965.810364 495.149902,968.496521 
	C496.292145,968.825439 497.460114,970.470825 497.849609,971.741211 
	C498.959137,975.359985 501.244568,974.862732 504.152039,974.470825 
	C507.427338,974.029297 510.844727,974.186096 514.152100,974.537292 
	C520.230042,975.182556 526.264832,976.222412 532.332153,976.982056 
	C537.790710,977.665466 543.714111,976.675842 547.444397,982.529541 
	C548.148254,983.633789 550.725891,983.889160 552.454285,983.933289 
	C558.775269,984.094543 565.103394,984.056213 571.427490,983.980713 
	C574.735168,983.941223 577.205750,984.656494 578.226562,988.377380 
	C578.580322,989.667114 580.336304,990.572266 581.451782,991.653076 
	C582.327576,990.434753 583.054199,989.062500 584.114868,988.035034 
	C585.620300,986.576538 587.375854,984.341248 589.016602,984.348022 
	C590.659546,984.354858 593.247498,986.435181 593.724304,988.094299 
	C594.619263,991.208557 596.248718,992.051758 599.081848,992.009277 
	C603.242065,991.946838 607.407104,992.103210 611.564270,991.976074 
	C615.894104,991.843628 618.094116,993.575684 618.023560,998.115845 
	C617.909851,1005.437744 617.964294,1012.762878 618.005005,1020.086304 
	C618.023010,1023.326233 616.848999,1025.223999 613.448425,1026.212402 
	C611.995789,1026.634644 610.350037,1029.030029 610.170105,1030.679321 
	C609.703552,1034.956787 609.898743,1039.320557 610.023682,1043.644409 
	C610.150330,1048.026611 608.293274,1050.238525 603.805298,1049.997681 
	C603.307495,1049.970825 602.806824,1049.996094 602.307495,1050.000122 
	C594.063843,1050.067749 593.994202,1050.067993 594.029846,1058.281250 
	C594.046936,1062.216187 593.213623,1064.470093 588.938293,1066.591919 
	C585.890137,1068.104736 584.883057,1073.802979 583.081482,1077.687378 
	C582.761719,1078.376831 583.013000,1079.331299 583.000305,1080.581665 
	C547.312439,1081.000000 511.624878,1081.000000 475.468658,1081.000000 
	C474.738495,1077.650024 475.374786,1075.177002 479.493988,1074.665039 
	C480.720184,1074.512695 482.524292,1072.673584 482.683716,1071.430420 
	C483.217834,1067.265625 485.805817,1066.825439 489.095490,1066.987549 
	C490.591400,1067.061279 492.104126,1067.086426 493.591492,1066.946045 
	C494.722626,1066.839355 496.217316,1066.805542 496.877625,1066.105103 
	C501.002563,1061.729980 498.636749,1056.287598 498.681549,1051.342651 
	C498.694641,1049.899536 495.912689,1048.355103 494.288666,1047.005493 
	C493.493378,1046.344482 492.415802,1046.023193 490.682343,1045.156128 
	C492.274139,1044.164185 493.116180,1043.539917 494.044830,1043.097290 
	C494.626556,1042.819946 495.587555,1043.047363 495.953552,1042.653564 
	C499.689667,1038.633301 503.337891,1034.531372 507.012360,1030.453857 
	C508.029327,1029.325439 508.922211,1027.409668 510.095428,1027.217285 
	C514.593994,1026.479248 515.113220,1023.540649 515.013367,1019.902344 
	C514.895935,1015.622131 514.044617,1010.966919 520.548523,1010.268005 
	C521.448364,1010.171387 522.700867,1008.056274 522.799744,1006.797913 
	C523.098938,1002.989685 523.075134,999.134155 522.863892,995.314392 
	C522.677002,991.934265 520.246216,990.904358 517.247009,991.027283 
	C512.184998,991.234741 507.545990,991.355286 504.667450,985.589417 
	C503.825134,983.902222 499.814789,983.372803 497.178528,983.117493 
	C494.205109,982.829468 492.235809,982.274109 490.204620,979.543640 
	C488.673370,977.485229 484.949341,976.592834 482.050354,976.132446 
	C478.259430,975.530396 474.142609,976.964294 472.306030,971.473877 
	C471.846863,970.101074 467.677612,969.667786 465.145844,969.360352 
	C461.326202,968.896606 457.831726,968.389343 456.618896,963.908691 
	C456.442688,963.257629 455.519531,962.467346 454.836090,962.348022 
	C448.121918,961.175842 441.926849,959.073792 437.265350,953.216675 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M170.008087,519.838867 
	C170.014648,518.843445 170.021194,517.848022 170.228729,516.201660 
	C170.626312,514.665833 170.822922,513.780884 171.019516,512.895996 
	C171.680496,504.175323 172.341476,495.454681 172.984558,486.324524 
	C172.044312,480.893127 173.397232,476.298889 174.737885,471.434814 
	C178.422409,458.066650 181.352264,444.488647 184.471588,430.968506 
	C184.949707,428.896149 185.029678,426.685760 184.975906,424.547241 
	C184.887146,421.017609 186.342682,418.887817 189.371964,416.903168 
	C191.125412,415.754364 191.642029,412.673126 192.642899,410.429291 
	C194.892136,405.386932 197.171829,400.355957 199.296722,395.260956 
	C201.292145,390.476440 203.104614,385.615631 205.288300,380.185303 
	C207.136871,378.740082 208.694717,377.897949 210.179291,377.423035 
	C209.463409,379.314484 208.794220,380.828217 208.182480,382.364807 
	C203.654800,393.737701 199.138550,405.115143 194.357391,416.661499 
	C193.852066,417.587982 193.608063,418.344147 193.192993,419.451965 
	C191.734543,424.188354 190.447189,428.573029 188.988922,433.136047 
	C188.721191,433.797791 188.624344,434.281128 188.350403,435.079834 
	C187.258377,439.073608 186.343430,442.752075 185.195068,446.625183 
	C184.825272,447.587372 184.688904,448.354889 184.383133,449.496338 
	C183.586563,452.875275 182.959396,455.880249 182.115601,459.178528 
	C179.576691,473.637177 177.254410,487.802582 174.762634,502.241272 
	C174.586670,503.359863 174.580200,504.205139 174.464981,505.495483 
	C173.805573,510.657440 173.254898,515.374390 172.365295,520.092651 
	C171.353622,520.008972 170.680847,519.923950 170.008087,519.838867 
z"/>
<path fill="#635F43" opacity="1.000000" stroke="none" 
	d="
M215.009521,363.970581 
	C217.501694,355.790924 221.798080,348.591919 226.768890,341.696991 
	C228.838531,338.826263 229.973495,335.270111 232.102554,332.456207 
	C236.355331,326.835510 241.166672,321.638153 245.435928,316.028931 
	C249.406448,310.812195 252.708466,305.076050 256.802399,299.967316 
	C259.891968,296.111877 263.770508,292.889465 267.290985,289.378418 
	C271.854340,284.827271 276.410828,280.269196 281.326416,275.287292 
	C282.456024,273.923492 283.229492,272.986481 284.002991,272.049500 
	C284.306213,271.560028 284.609436,271.070587 285.312317,270.229065 
	C287.805359,269.529205 289.898804,269.181335 291.812622,269.127625 
	C286.976715,274.224792 282.320374,279.027832 277.377960,283.984558 
	C264.620270,299.028839 251.836029,313.672821 239.811920,328.916382 
	C233.992432,336.293976 229.599701,344.797028 224.278641,352.929016 
	C223.303391,354.365997 222.610504,355.664459 221.724701,357.159790 
	C221.255524,358.030792 220.979263,358.704865 220.409576,359.583435 
	C219.680923,360.854279 219.245712,361.920685 218.738297,363.078064 
	C218.666077,363.169098 218.740692,363.389130 218.457672,363.561035 
	C217.805206,364.492310 217.435760,365.251678 216.965546,366.160217 
	C216.864777,366.309387 216.804413,366.664276 216.530396,366.761658 
	C215.908432,367.029266 215.560455,367.199493 215.164032,366.946991 
	C215.080215,365.673035 215.044876,364.821808 215.009521,363.970581 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M431.684326,185.156006 
	C439.611328,182.568756 447.206970,180.129669 455.262177,177.371063 
	C456.164062,177.054153 456.606384,177.056747 457.345581,177.112595 
	C458.764069,177.126007 459.885620,177.086166 461.435486,177.042603 
	C464.582001,176.700928 467.300262,176.362991 470.018494,176.025055 
	C476.456238,175.014923 482.890564,173.982407 489.333038,173.003479 
	C492.892395,172.462646 496.457458,171.705536 500.034729,171.612244 
	C503.185059,171.530106 506.355927,172.235321 509.399353,173.017090 
	C505.170197,174.078751 501.059387,174.716156 496.620209,175.365051 
	C496.083588,175.444534 495.875336,175.512512 495.360718,175.444260 
	C493.022675,175.611511 490.991028,175.914978 488.637207,176.171768 
	C487.895874,176.219894 487.476746,176.314697 486.781555,176.314667 
	C485.988037,176.441467 485.470551,176.663086 484.513977,176.865860 
	C477.742798,178.085983 471.410614,179.324966 464.801147,180.517212 
	C464.009552,180.644363 463.495270,180.818237 462.602875,180.970840 
	C461.508179,181.171539 460.791595,181.393509 459.763123,181.501709 
	C458.658569,181.646774 457.865875,181.905609 456.680359,182.168533 
	C451.140930,183.631805 445.994324,185.090988 440.480530,186.531982 
	C438.741364,186.980942 437.369446,187.448090 435.620117,187.867615 
	C433.226898,188.583221 431.211121,189.346466 428.805176,190.086349 
	C425.606354,190.810852 422.797760,191.558731 419.893799,192.009430 
	C419.539734,191.144852 419.281036,190.577423 419.022308,190.010010 
	C419.511688,189.751724 420.001038,189.493439 421.098022,189.109009 
	C422.804596,188.329712 423.903595,187.676559 425.002594,187.023407 
	C427.119354,186.450317 429.236145,185.877258 431.684326,185.156006 
z"/>
<path fill="#D5D2A8" opacity="1.000000" stroke="none" 
	d="
M277.664062,283.830872 
	C282.320374,279.027832 286.976715,274.224792 292.116272,269.010559 
	C294.857330,266.453278 297.115143,264.307220 299.664795,262.078613 
	C301.098572,260.881165 302.240479,259.766205 303.382416,258.651276 
	C303.876740,258.387878 304.371094,258.124481 305.350952,257.553406 
	C306.428711,256.672150 307.020966,256.098633 307.815735,255.313416 
	C308.697510,254.804459 309.376801,254.507187 310.082001,254.545700 
	C311.142029,255.954544 312.176178,257.027618 313.065216,258.400940 
	C310.223236,261.011963 307.526337,263.322662 304.511719,265.764862 
	C301.639099,268.377441 299.084229,270.858521 296.443451,273.329468 
	C296.357544,273.319366 296.298157,273.481873 295.977386,273.552429 
	C290.906097,278.001495 286.155548,282.379974 281.166260,286.438507 
	C279.839691,285.356018 278.751862,284.593445 277.664062,283.830872 
z"/>
<path fill="#5F5A4A" opacity="1.000000" stroke="none" 
	d="
M310.056091,254.209915 
	C309.376801,254.507187 308.697510,254.804459 307.442993,255.243118 
	C306.370789,255.405930 305.873840,255.427353 305.376862,255.448761 
	C306.039673,254.411240 306.595306,253.282318 307.386658,252.354355 
	C309.285278,250.127853 311.689667,248.230606 313.155914,245.764130 
	C314.862976,242.892471 316.469696,240.746704 320.202515,240.570480 
	C321.750946,240.497360 323.331940,239.056763 324.715942,237.999557 
	C327.347839,235.989075 329.653809,233.492233 332.445862,231.763931 
	C337.708923,228.506027 343.220734,225.649872 349.094330,222.165985 
	C350.701233,221.133194 351.842896,220.561127 352.984589,219.989044 
	C354.382050,219.667633 355.779541,219.346222 357.893402,219.059631 
	C358.844879,219.125549 359.079926,219.156631 359.136292,219.492844 
	C353.569336,223.331146 348.101593,226.749878 342.812042,230.425064 
	C335.359955,235.602798 328.039917,240.970566 320.315582,246.339767 
	C318.135345,247.875626 316.302979,249.328812 314.151031,250.836487 
	C312.572998,251.997284 311.314545,253.103592 310.056091,254.209915 
z"/>
<path fill="#4B484B" opacity="1.000000" stroke="none" 
	d="
M359.315002,219.187714 
	C359.079926,219.156631 358.844879,219.125549 358.256226,219.045975 
	C361.697174,215.183792 365.403778,211.275467 369.341827,207.616287 
	C370.468445,206.569412 372.211090,206.040176 373.765137,205.625320 
	C378.828247,204.273712 383.982880,203.242416 388.988434,201.714783 
	C390.142334,201.362625 390.785309,199.471985 391.769928,198.385910 
	C392.530334,197.547165 393.773376,196.039780 394.262573,196.228394 
	C400.873779,198.777252 405.327545,192.782684 411.104675,192.122314 
	C413.528198,191.845291 415.860199,190.767700 418.628052,190.032043 
	C419.281036,190.577423 419.539734,191.144852 419.613342,192.252350 
	C414.451447,194.664429 409.359497,196.280121 404.519348,198.455978 
	C389.400055,205.252792 374.375977,212.261276 359.315002,219.187714 
z"/>
<path fill="#5F5A4A" opacity="1.000000" stroke="none" 
	d="
M299.372955,262.161133 
	C297.115143,264.307220 294.857330,266.453278 292.295868,268.716400 
	C289.898804,269.181335 287.805359,269.529205 285.378601,269.945099 
	C288.811157,267.057983 292.577118,264.102783 296.942078,261.294800 
	C298.151703,261.681732 298.762329,261.921448 299.372955,262.161133 
z"/>
<path fill="#635F43" opacity="1.000000" stroke="none" 
	d="
M210.252563,377.055847 
	C208.694717,377.897949 207.136871,378.740082 205.294983,379.773743 
	C205.289474,378.444427 205.058701,376.426392 205.954819,375.508362 
	C207.706833,373.713531 210.088058,372.532898 212.693756,371.118805 
	C213.178680,371.139618 213.496368,371.403442 213.391830,371.717438 
	C212.275711,373.706238 211.264130,375.381042 210.252563,377.055847 
z"/>
<path fill="#635F43" opacity="1.000000" stroke="none" 
	d="
M299.664795,262.078613 
	C298.762329,261.921448 298.151703,261.681732 297.286438,261.234924 
	C297.572083,260.163452 298.112335,259.299011 299.124084,258.158081 
	C300.056641,257.608887 300.517670,257.336182 300.978729,257.063477 
	C301.622162,257.402557 302.265594,257.741669 303.145721,258.366028 
	C302.240479,259.766205 301.098572,260.881165 299.664795,262.078613 
z"/>
<path fill="#4B484B" opacity="1.000000" stroke="none" 
	d="
M424.774414,186.787552 
	C423.903595,187.676559 422.804596,188.329712 421.344238,188.982727 
	C420.843201,187.930740 420.703491,186.878860 420.437653,184.877411 
	C422.221313,185.604263 423.383789,186.077972 424.774414,186.787552 
z"/>
<path fill="#4B484B" opacity="1.000000" stroke="none" 
	d="
M352.951965,219.646683 
	C351.842896,220.561127 350.701233,221.133194 349.281189,221.874146 
	C349.587097,220.528030 350.171326,219.013031 350.755554,217.498047 
	C351.476837,218.100143 352.198059,218.702225 352.951965,219.646683 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M214.666214,364.066193 
	C215.044876,364.821808 215.080215,365.673035 215.150757,367.115234 
	C215.185944,367.706177 215.005035,367.992218 214.705521,368.087708 
	C213.913315,368.832367 213.420624,369.481537 212.927917,370.130707 
	C211.670670,367.738556 211.406631,365.578552 214.666214,364.066193 
z"/>
<path fill="#5F5A4A" opacity="1.000000" stroke="none" 
	d="
M283.679749,272.109406 
	C283.229492,272.986481 282.456024,273.923492 281.368011,274.935791 
	C281.821198,274.063843 282.588867,273.116577 283.679749,272.109406 
z"/>
<path fill="#635F43" opacity="1.000000" stroke="none" 
	d="
M212.915909,370.316772 
	C213.420624,369.481537 213.913315,368.832367 214.692810,368.406036 
	C214.485184,369.553741 213.990768,370.478577 213.496368,371.403442 
	C213.496368,371.403442 213.178680,371.139618 213.018616,371.006256 
	C212.858551,370.872894 212.903885,370.502808 212.915909,370.316772 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M305.313782,255.770172 
	C305.873840,255.427353 306.370789,255.405930 307.240479,255.454803 
	C307.020966,256.098633 306.428711,256.672150 305.526672,257.329376 
	C305.228149,256.972565 305.239441,256.532074 305.313782,255.770172 
z"/>
<path fill="#5F5A4A" opacity="1.000000" stroke="none" 
	d="
M300.708496,257.104614 
	C300.517670,257.336182 300.056641,257.608887 299.329071,257.929718 
	C299.521118,257.700470 299.979706,257.423126 300.708496,257.104614 
z"/>
<path fill="#3E3A3D" opacity="1.000000" stroke="none" 
	d="
M166.001251,601.000000 
	C165.666977,587.423767 164.979279,573.843994 165.112991,560.272339 
	C165.215469,549.872070 166.302414,539.477905 167.113510,529.093628 
	C167.195602,528.042847 168.395844,527.079407 169.498901,526.443481 
	C169.872711,532.418396 169.763412,538.025452 169.788727,543.631897 
	C169.858505,559.091003 169.978882,574.549805 170.108627,590.008484 
	C170.113968,590.645081 170.347565,591.307007 170.591949,591.909668 
	C171.681152,594.595398 172.812988,597.263916 173.558167,600.009155 
	C172.465439,601.340881 171.530991,602.530701 171.077179,603.881897 
	C170.528732,605.514893 170.387421,607.284668 169.845795,608.690125 
	C168.143784,606.056213 170.405228,601.894287 166.001251,601.000000 
z"/>
<path fill="#322818" opacity="1.000000" stroke="none" 
	d="
M173.929413,599.938660 
	C172.812988,597.263916 171.681152,594.595398 170.591949,591.909668 
	C170.347565,591.307007 170.113968,590.645081 170.108627,590.008484 
	C169.978882,574.549805 169.858505,559.091003 169.788727,543.631897 
	C169.763412,538.025452 169.872711,532.418396 169.876465,526.369202 
	C169.888947,524.193359 169.945587,522.459839 170.005157,520.282593 
	C170.680847,519.923950 171.353622,520.008972 172.395691,520.508179 
	C172.865509,535.942322 172.966003,550.962341 172.863892,566.176025 
	C172.906906,567.316162 173.152527,568.262634 173.524445,569.204224 
	C173.650711,569.199341 173.878952,569.090759 173.778900,569.534912 
	C173.979202,573.307251 174.279556,576.635498 174.470001,580.333984 
	C174.789520,582.357422 175.218948,584.010620 175.525711,585.934082 
	C175.363464,586.794739 175.323883,587.385132 175.168915,588.338745 
	C176.023438,593.472046 176.993362,598.242188 177.868225,603.282043 
	C177.683929,604.113708 177.594711,604.675659 177.402969,605.710327 
	C177.189819,607.146118 177.079208,608.109131 176.634918,609.024414 
	C175.795715,608.005188 175.064362,607.084412 174.832703,606.051514 
	C174.383163,604.047241 174.214478,601.979858 173.929413,599.938660 
z"/>
<path fill="#3E3A3D" opacity="1.000000" stroke="none" 
	d="
M174.037048,632.996826 
	C172.409164,629.226685 170.781265,625.456482 169.068878,621.335266 
	C168.983200,620.547424 168.982010,620.110596 168.987671,618.941711 
	C169.343338,616.124451 169.692200,614.039368 170.374664,612.040283 
	C171.920441,613.202759 174.128632,614.235657 174.177795,615.362732 
	C174.433609,621.227539 174.139618,627.116394 174.037048,632.996826 
z"/>
<path fill="#424043" opacity="1.000000" stroke="none" 
	d="
M170.041046,611.954224 
	C169.692200,614.039368 169.343338,616.124451 168.987289,618.613953 
	C168.109711,618.114685 166.576721,617.275818 166.481018,616.296509 
	C166.008530,611.461121 165.916504,606.588501 165.845764,601.364502 
	C170.405228,601.894287 168.143784,606.056213 169.836151,609.060791 
	C170.047455,610.475769 170.044250,611.214966 170.041046,611.954224 
z"/>
<path fill="#3E3A3D" opacity="1.000000" stroke="none" 
	d="
M170.735748,513.014587 
	C170.822922,513.780884 170.626312,514.665833 170.225235,515.828369 
	C170.164490,515.115112 170.308228,514.124146 170.735748,513.014587 
z"/>
<path fill="#2F292B" opacity="1.000000" stroke="none" 
	d="
M975.000000,673.000000 
	C975.300903,676.230530 974.789001,678.593201 970.991577,679.838562 
	C969.328125,680.384094 967.435486,683.254028 967.452820,685.051086 
	C967.470154,686.851135 969.406494,689.671082 971.087708,690.207703 
	C974.990479,691.453247 975.096252,693.969971 975.037781,697.132751 
	C974.983459,700.065735 974.886902,702.247437 971.355591,703.766052 
	C969.293579,704.652832 967.361084,708.254333 967.251282,710.718384 
	C967.073486,714.709717 968.203430,718.832642 969.234924,722.781433 
	C970.058228,725.933167 967.323120,734.053650 964.254333,735.069763 
	C959.920288,736.504822 958.964417,739.601318 958.983582,743.607422 
	C959.037720,754.934021 958.955688,766.261475 959.029175,777.587891 
	C959.047913,780.477295 958.194946,781.942749 955.430603,783.716003 
	C951.002747,786.556274 946.455627,790.374695 944.084229,794.894714 
	C942.126160,798.626892 942.837036,803.997070 943.108643,808.597656 
	C943.409851,813.699829 941.606323,815.308899 936.558228,815.106628 
	C928.858765,814.798096 927.019165,816.809021 927.034424,824.338318 
	C927.041443,827.809998 927.132690,830.804321 922.647583,831.983582 
	C921.160583,832.374512 919.324585,835.268799 919.434204,836.898438 
	C919.561340,838.788818 921.533813,840.646973 922.933411,842.329285 
	C923.952637,843.554443 925.341675,844.471985 926.565857,845.526611 
	C924.917969,846.017151 923.294983,846.783325 921.616455,846.935059 
	C918.972839,847.173950 916.288574,847.084778 913.627441,846.981567 
	C908.688538,846.790100 904.281738,847.140747 902.631897,853.151917 
	C902.367981,854.113586 899.663513,854.845154 898.050415,854.923584 
	C889.101379,855.358704 887.098511,857.070435 887.078613,865.885254 
	C887.069641,869.838257 885.630249,871.062012 881.786743,871.071960 
	C872.052917,871.097229 871.219849,871.961548 871.068604,882.113770 
	C871.013611,885.804932 869.691833,887.156372 866.050659,887.046204 
	C860.059692,886.864868 854.017151,886.588867 848.080811,887.188660 
	C845.299805,887.469727 841.670227,889.036255 840.291931,891.205994 
	C838.310974,894.324402 836.013611,895.527344 832.386292,895.532715 
	C832.333313,890.745178 832.666626,886.372559 833.077637,881.613281 
	C833.951172,877.268127 834.006836,872.907959 839.153259,871.544128 
	C839.862793,871.356079 840.123535,869.837524 840.831482,869.201538 
	C842.102905,868.059326 843.436768,866.516418 844.957336,866.215149 
	C848.655945,865.482361 852.471497,865.129761 856.249084,865.048279 
	C864.490112,864.870422 865.783081,863.503662 865.000000,854.600037 
	C865.000000,853.133362 865.000000,852.066711 865.328369,850.895264 
	C870.337463,846.890625 876.179871,843.751587 879.373108,838.877136 
	C882.581665,833.979309 886.740051,833.993286 891.036316,833.143555 
	C896.961609,831.971802 898.862000,828.969177 897.000000,822.539368 
	C897.000000,818.385803 897.000000,814.692932 897.327576,810.895630 
	C902.436768,807.194153 907.218384,803.597046 912.245361,799.937134 
	C912.660583,799.249573 912.830261,798.624817 913.000000,797.545898 
	C913.000000,794.061218 913.000000,791.030579 913.373779,787.909912 
	C915.765564,787.215942 917.757324,786.211426 919.806091,786.077454 
	C928.880859,785.483765 928.886780,785.574280 931.129639,776.710571 
	C931.507812,775.614136 931.753906,774.807068 932.220825,773.655823 
	C932.627075,772.010315 933.012024,770.701660 932.964844,769.408813 
	C932.691406,761.924622 934.458191,755.830872 942.598694,753.243286 
	C943.695923,752.894470 944.820007,750.635742 944.848755,749.239136 
	C945.055725,739.162659 944.983643,729.080383 945.000000,718.536804 
	C945.000000,714.049072 945.000000,710.024536 945.000061,705.533142 
	C945.000916,700.044983 945.001709,695.023743 945.367126,689.930542 
	C951.353638,689.004333 953.477417,685.747864 953.146545,680.118896 
	C953.034424,678.212036 954.139771,675.020935 955.509216,674.475098 
	C959.098083,673.044556 963.139160,672.748291 967.329651,672.217285 
	C970.106201,672.622986 972.553101,672.811462 975.000000,673.000000 
z"/>
<path fill="#302E31" opacity="1.000000" stroke="none" 
	d="
M641.000000,974.535278 
	C633.776489,975.245483 626.521667,975.978760 619.343994,975.515991 
	C616.250793,975.316528 613.011353,973.038391 610.372986,971.009277 
	C604.365845,966.389465 598.541138,968.966370 592.563477,970.676941 
	C590.873657,971.160461 588.952820,971.792358 587.343201,971.434143 
	C580.440857,969.898010 573.632080,967.945557 566.747070,966.323914 
	C565.379028,966.001648 563.479126,965.946167 562.414978,966.654663 
	C556.320007,970.712769 550.358154,968.275452 544.200684,966.992188 
	C541.724365,966.476074 538.756714,967.000122 536.326782,967.899536 
	C532.973877,969.140503 530.132568,969.425293 529.267761,965.434448 
	C528.328796,961.101562 525.642761,961.032043 522.208984,961.176147 
	C515.167114,961.471863 508.104797,961.752136 501.074738,961.461548 
	C499.670135,961.403442 497.471649,958.915283 497.209717,957.294006 
	C496.640137,953.768250 494.811859,953.037292 491.752258,952.965637 
	C486.783173,952.849304 481.791992,952.679504 476.873474,952.042908 
	C475.489899,951.863831 473.515594,950.154297 473.224457,948.835144 
	C472.508179,945.589905 470.599731,945.040161 467.821899,944.976440 
	C463.014587,944.866211 458.204681,944.642700 453.412354,944.260132 
	C452.179504,944.161743 450.367065,943.621277 449.907562,942.726013 
	C445.173981,933.503296 435.549286,934.664429 427.738953,931.968872 
	C425.384491,931.156250 423.150146,929.979553 420.899475,928.889709 
	C419.889496,928.400696 419.045593,927.503235 418.004608,927.154846 
	C413.402710,925.614990 408.751282,924.223572 404.132660,922.732422 
	C403.521362,922.535095 402.898071,922.171692 402.444244,921.720459 
	C396.056274,915.368103 388.399597,911.592468 379.597595,909.710571 
	C377.388672,909.238342 375.189301,907.438171 373.579498,905.698547 
	C369.501862,901.292053 365.240021,897.788269 359.353943,895.465271 
	C353.194000,893.034302 346.479797,889.749695 345.437683,881.370483 
	C347.221527,880.999512 348.611359,880.998535 350.375580,880.998779 
	C351.498535,881.001648 352.247131,881.003235 353.074158,881.335999 
	C353.379700,882.107178 353.491364,882.713562 353.850800,882.963013 
	C363.790497,889.864075 373.754242,896.730469 383.722839,903.589783 
	C383.985535,903.770447 384.372894,903.760742 384.658630,903.918518 
	C388.975342,906.301086 393.288818,908.689575 397.594086,911.092773 
	C399.752747,912.297668 401.759521,914.060547 404.069824,914.648865 
	C408.979553,915.898987 412.997559,920.599365 418.782745,918.560547 
	C419.121674,918.441040 419.669037,918.946472 420.130188,919.134766 
	C428.909210,922.718994 437.412018,927.653931 446.542664,929.526550 
	C457.183563,931.708801 466.696564,938.597473 478.156250,937.089966 
	C478.761200,937.010315 479.573395,937.210022 480.042511,937.586731 
	C488.045624,944.013367 498.204926,944.477478 507.491669,946.599915 
	C517.038513,948.781860 527.193237,948.279480 537.075195,949.038940 
	C539.214783,949.203369 541.318909,949.786499 543.450989,950.097168 
	C554.760803,951.745483 566.032837,953.896912 577.403076,954.791504 
	C583.704468,955.287231 590.163452,953.218750 596.567139,953.090942 
	C611.039551,952.801880 625.521790,953.000000 640.000000,953.000000 
	C640.502747,953.567322 641.005493,954.134705 641.754150,954.851013 
	C641.666687,961.357849 641.333374,967.715637 641.000000,974.535278 
z"/>
<path fill="#2C2A2C" opacity="1.000000" stroke="none" 
	d="
M975.307861,672.761841 
	C972.553101,672.811462 970.106201,672.622986 967.490234,671.832642 
	C967.533508,670.259888 967.896240,669.295044 967.935791,668.317139 
	C968.647034,650.731995 969.852478,633.145142 969.852112,615.558716 
	C969.851746,597.472290 968.653259,579.386353 968.013977,561.298950 
	C967.914001,558.470703 967.911194,555.632385 968.022644,552.804993 
	C968.154236,549.466309 967.958801,546.562073 964.017883,545.211487 
	C963.052673,544.880798 962.210449,542.873596 962.099854,541.570190 
	C960.966309,528.213867 959.238770,514.844116 959.231567,501.477295 
	C959.226379,491.733063 953.453430,484.353210 951.381226,475.586700 
	C951.285156,475.180328 950.765320,474.668671 950.356506,474.572388 
	C943.768372,473.020538 945.458313,468.293121 945.380066,463.538025 
	C945.308167,459.163727 944.548645,452.677277 941.730286,450.929321 
	C936.440552,447.648712 936.073486,442.729462 933.986511,438.291199 
	C930.730652,431.366852 927.608582,424.378998 924.304016,417.478333 
	C922.186401,413.056183 919.693054,408.811035 917.647644,404.358154 
	C916.007202,400.786957 915.657654,396.179993 913.138916,393.577850 
	C910.553284,390.906494 909.443909,388.317535 909.428345,385.003052 
	C914.266052,384.409149 917.337585,385.672974 917.975769,390.618561 
	C918.093933,391.534454 918.576294,392.889862 919.251465,393.168884 
	C923.820007,395.056549 923.425415,398.740265 922.597229,402.367706 
	C921.903015,405.408264 923.323303,406.156128 925.759338,407.373688 
	C927.928406,408.457825 930.106140,410.798920 930.920166,413.072205 
	C933.031982,418.970490 934.277649,425.172424 936.095398,431.184692 
	C937.441956,435.638336 938.898926,440.233154 945.151001,440.361664 
	C946.242554,445.225006 946.862122,449.827728 948.216125,454.203125 
	C949.371948,457.938049 951.371338,461.411896 952.998047,465.427917 
	C954.730347,471.623169 956.379578,477.419250 958.284912,483.129913 
	C958.562744,483.962677 960.063049,484.387604 960.998108,485.439209 
	C961.332336,489.584747 961.666199,493.292389 962.083618,497.409302 
	C962.444763,501.447723 962.797607,505.073059 962.981140,508.706970 
	C963.213013,513.298035 961.487366,518.588989 967.792114,520.819885 
	C968.337463,521.012878 968.736389,522.189636 968.845276,522.960083 
	C970.271240,533.052734 971.625671,543.155457 973.014648,553.253357 
	C973.432922,556.294434 972.994507,559.795593 976.997559,561.002441 
	C976.998352,566.023743 976.999146,571.044983 977.000000,576.533142 
	C976.999878,606.637451 977.029114,636.274902 976.940002,665.912109 
	C976.933289,668.117249 976.077026,670.319885 975.307861,672.761841 
z"/>
<path fill="#2C2A2C" opacity="1.000000" stroke="none" 
	d="
M640.145752,952.666260 
	C625.521790,953.000000 611.039551,952.801880 596.567139,953.090942 
	C590.163452,953.218750 583.704468,955.287231 577.403076,954.791504 
	C566.032837,953.896912 554.760803,951.745483 543.450989,950.097168 
	C541.318909,949.786499 539.214783,949.203369 537.075195,949.038940 
	C527.193237,948.279480 517.038513,948.781860 507.491669,946.599915 
	C498.204926,944.477478 488.045624,944.013367 480.042511,937.586731 
	C479.573395,937.210022 478.761200,937.010315 478.156250,937.089966 
	C466.696564,938.597473 457.183563,931.708801 446.542664,929.526550 
	C437.412018,927.653931 428.909210,922.718994 420.130188,919.134766 
	C419.669037,918.946472 419.121674,918.441040 418.782745,918.560547 
	C412.997559,920.599365 408.979553,915.898987 404.069824,914.648865 
	C401.759521,914.060547 399.752747,912.297668 397.594086,911.092773 
	C393.288818,908.689575 388.975342,906.301086 384.658630,903.918518 
	C384.372894,903.760742 383.985535,903.770447 383.722839,903.589783 
	C373.754242,896.730469 363.790497,889.864075 353.850800,882.963013 
	C353.491364,882.713562 353.379700,882.107178 353.483826,881.334656 
	C356.556610,882.181519 359.447479,883.105713 362.003876,884.601501 
	C365.637665,886.727661 368.898285,889.498413 372.556458,891.574097 
	C375.826599,893.429626 379.454193,894.643982 382.859650,896.275757 
	C385.951599,897.757141 388.956757,899.419739 392.123505,901.314758 
	C397.186249,905.549622 401.575531,910.721558 409.081909,909.353760 
	C410.503723,910.767761 412.145874,912.963562 413.129456,912.700073 
	C417.807709,911.447266 420.536835,913.825989 423.503845,916.736511 
	C424.791199,917.999207 426.998169,918.473022 428.877136,918.956665 
	C435.043335,920.543945 441.906250,920.242859 446.548645,925.823547 
	C447.018799,926.388733 447.985504,926.707581 448.767792,926.819641 
	C454.171692,927.594177 459.587799,928.283386 465.249817,929.000488 
	C465.499634,929.001038 465.999298,929.002441 466.133606,929.327026 
	C467.250031,930.191467 468.172363,930.968567 469.223755,931.233704 
	C477.710175,933.373352 486.360962,934.970947 494.692871,937.588989 
	C508.862915,942.041565 523.288757,944.054443 538.084351,944.054932 
	C540.518311,944.054993 542.948669,945.076416 545.386963,945.106018 
	C553.256348,945.201782 561.128784,945.054871 569.333252,945.001526 
	C570.110840,945.001831 570.555176,945.002136 571.095947,945.287109 
	C572.128540,945.382019 573.064636,945.192261 574.469360,945.001953 
	C581.718262,945.000977 588.501770,945.130005 595.278015,944.964661 
	C602.522156,944.787842 609.759644,944.333374 617.284973,944.169495 
	C622.713318,944.559265 627.856628,944.779602 633.429810,944.999573 
	C637.573059,944.999390 641.286499,944.999695 645.000000,945.000000 
	C643.430542,947.444214 641.861023,949.888367 640.145752,952.666260 
z"/>
<path fill="#2B292A" opacity="1.000000" stroke="none" 
	d="
M645.240356,945.092529 
	C641.286499,944.999695 637.573059,944.999390 633.502930,944.629272 
	C633.726379,939.867554 636.439026,938.901428 640.527100,938.904968 
	C647.629395,938.910950 654.742981,938.464355 661.829712,937.920532 
	C664.911865,937.684021 667.932678,936.260376 670.993225,936.217163 
	C676.433533,936.140259 681.897461,936.547302 687.323914,937.049744 
	C691.646118,937.449890 695.107666,936.106934 698.416626,933.000000 
	C700.222229,933.000000 701.611084,933.000000 703.352478,932.930298 
	C707.103516,930.514099 711.122192,928.694641 713.705688,925.655396 
	C715.793518,923.199097 717.181702,921.956360 720.515320,922.467224 
	C722.554138,922.779724 724.830383,921.542786 727.375000,921.001160 
	C728.500000,921.000366 729.250000,921.000183 730.462646,921.000000 
	C736.905579,920.591125 743.830322,923.111267 747.546387,915.618774 
	C747.701721,915.305603 748.467285,915.099976 748.911011,915.153870 
	C757.432434,916.187073 761.919861,910.070984 767.375000,905.001282 
	C768.500000,905.000488 769.250000,905.000244 770.404785,904.998474 
	C774.155945,904.998474 776.966431,904.415771 778.335205,900.622864 
	C778.765198,899.431396 780.751831,898.616455 782.186523,898.007141 
	C786.626099,896.121704 792.201050,896.739807 795.297119,891.828247 
	C795.681274,891.218811 797.104919,891.195129 798.069275,891.032593 
	C803.006042,890.200439 808.586731,890.846863 810.833252,884.441101 
	C811.148315,883.542664 813.695801,883.307190 815.255615,882.987793 
	C819.870178,882.043030 825.183960,882.403992 826.716614,876.185364 
	C826.881409,875.516663 827.778687,874.724243 828.480103,874.541626 
	C832.934143,873.382019 834.252502,869.932434 835.401978,866.001587 
	C838.202637,865.333862 840.601318,864.666931 843.398438,863.998657 
	C845.682007,863.259583 848.342651,863.057251 849.296021,861.676270 
	C851.507812,858.472412 854.309387,858.159790 857.726868,857.848389 
	C860.217102,857.621460 862.580200,855.999817 865.000000,855.000000 
	C865.783081,863.503662 864.490112,864.870422 856.249084,865.048279 
	C852.471497,865.129761 848.655945,865.482361 844.957336,866.215149 
	C843.436768,866.516418 842.102905,868.059326 840.831482,869.201538 
	C840.123535,869.837524 839.862793,871.356079 839.153259,871.544128 
	C834.006836,872.907959 833.951172,877.268127 832.662231,881.613647 
	C830.187073,882.775330 826.914062,883.096741 826.454529,884.405762 
	C824.794739,889.134521 821.644775,890.496033 817.244934,889.966492 
	C811.825745,889.314148 809.087646,891.535889 808.532776,897.000000 
	C803.969910,897.014832 799.861145,896.824707 795.781433,897.087219 
	C787.340454,897.630127 786.978210,898.035400 784.554016,906.000000 
	C778.242310,906.551758 771.608704,904.964844 768.171936,912.120178 
	C767.711060,913.079590 765.900574,913.324524 764.849060,914.085449 
	C763.841370,914.814697 763.045776,915.829285 762.089661,916.639099 
	C760.442200,918.034607 758.895264,920.248169 757.037537,920.570557 
	C750.354309,921.730225 743.570007,922.677063 736.801880,922.851807 
	C732.081726,922.973633 729.176880,924.419373 727.562500,929.000000 
	C725.083313,929.000000 723.041687,929.000000 720.626709,929.073669 
	C718.428223,929.845520 715.387085,930.126160 714.980103,931.311401 
	C712.755005,937.790833 708.070496,935.998596 703.559998,935.420410 
	C702.125916,935.236572 700.193542,934.900818 699.244934,935.624756 
	C693.073914,940.333618 685.965088,938.629578 679.143799,939.079773 
	C677.366638,939.197144 675.623169,940.202820 673.916870,940.924927 
	C671.646729,941.885559 669.510925,943.542725 667.170349,943.879761 
	C662.163635,944.600830 657.062500,944.666077 652.000000,945.000000 
	C652.000000,945.000000 651.500000,945.000000 651.000000,945.000000 
	C650.500000,945.000000 650.000000,945.000000 650.000000,945.000000 
	C648.493530,945.061707 646.987122,945.123413 645.240356,945.092529 
z"/>
<path fill="#2C2A2C" opacity="1.000000" stroke="none" 
	d="
M728.000000,929.000000 
	C729.176880,924.419373 732.081726,922.973633 736.801880,922.851807 
	C743.570007,922.677063 750.354309,921.730225 757.037537,920.570557 
	C758.895264,920.248169 760.442200,918.034607 762.089661,916.639099 
	C763.045776,915.829285 763.841370,914.814697 764.849060,914.085449 
	C765.900574,913.324524 767.711060,913.079590 768.171936,912.120178 
	C771.608704,904.964844 778.242310,906.551758 784.553955,906.441162 
	C784.666626,911.254822 784.333313,915.627441 784.000000,920.000000 
	C780.754761,920.335510 777.516113,920.814453 774.262329,920.962158 
	C771.553467,921.085022 769.597717,921.478821 768.773743,924.709717 
	C768.430481,926.055664 766.205078,927.676086 764.693054,927.837585 
	C758.457458,928.503418 752.167175,928.657715 744.980957,929.000000 
	C738.709900,929.000000 733.354919,929.000000 728.000000,929.000000 
z"/>
<path fill="#2F292B" opacity="1.000000" stroke="none" 
	d="
M784.386353,919.973877 
	C784.333313,915.627441 784.666626,911.254822 785.000000,906.441162 
	C786.978210,898.035400 787.340454,897.630127 795.781433,897.087219 
	C799.861145,896.824707 803.969910,897.014832 808.959412,897.000000 
	C814.902161,896.666687 819.951111,896.333374 825.000000,896.000000 
	C824.028625,901.050293 821.589478,903.523010 815.838135,903.288513 
	C813.199707,903.181030 809.699524,905.518982 807.940063,907.792603 
	C806.172729,910.076355 804.690735,911.214294 801.953247,911.008606 
	C797.110474,910.644653 793.377197,911.890625 790.949158,916.777649 
	C790.120422,918.445557 786.900146,918.925171 784.386353,919.973877 
z"/>
<path fill="#2C2A2C" opacity="1.000000" stroke="none" 
	d="
M652.348511,945.222412 
	C657.062500,944.666077 662.163635,944.600830 667.170349,943.879761 
	C669.510925,943.542725 671.646729,941.885559 673.916870,940.924927 
	C675.623169,940.202820 677.366638,939.197144 679.143799,939.079773 
	C685.965088,938.629578 693.073914,940.333618 699.244934,935.624756 
	C700.193542,934.900818 702.125916,935.236572 703.559998,935.420410 
	C708.070496,935.998596 712.755005,937.790833 714.980103,931.311401 
	C715.387085,930.126160 718.428223,929.845520 720.626709,929.517822 
	C721.000000,932.257996 721.000000,934.627808 721.000000,936.997559 
	C717.145447,936.774414 713.708008,937.023804 712.549500,941.839783 
	C712.325195,942.772034 710.302307,943.822693 709.067017,943.877991 
	C691.247986,944.675537 673.422119,945.322083 655.596313,945.959473 
	C654.637268,945.993774 653.663757,945.625671 652.348511,945.222412 
z"/>
<path fill="#2C2A2C" opacity="1.000000" stroke="none" 
	d="
M825.437500,896.000000 
	C819.951111,896.333374 814.902161,896.666687 809.426636,897.000000 
	C809.087646,891.535889 811.825745,889.314148 817.244934,889.966492 
	C821.644775,890.496033 824.794739,889.134521 826.454529,884.405762 
	C826.914062,883.096741 830.187073,882.775330 832.584595,882.000366 
	C832.666626,886.372559 832.333313,890.745178 832.000000,895.558838 
	C829.958313,896.000000 827.916687,896.000000 825.437500,896.000000 
z"/>
<path fill="#2E282A" opacity="1.000000" stroke="none" 
	d="
M1021.802734,760.098633 
	C1023.440308,761.512207 1025.459839,762.742310 1025.744751,764.291504 
	C1026.307495,767.352234 1026.444092,770.722351 1025.702271,773.702026 
	C1025.283447,775.384094 1022.655396,777.762451 1021.222290,777.632080 
	C1019.402527,777.466553 1016.956116,775.380493 1016.287292,773.566772 
	C1015.419556,771.213135 1016.036438,768.312195 1016.014282,765.646851 
	C1015.984192,762.024597 1017.563782,759.954041 1021.802734,760.098633 
z"/>
<path fill="#2F292B" opacity="1.000000" stroke="none" 
	d="
M721.409302,936.914368 
	C721.000000,934.627808 721.000000,932.257996 721.000000,929.444092 
	C723.041687,929.000000 725.083313,929.000000 727.562500,929.000000 
	C733.354919,929.000000 738.709900,929.000000 744.532410,929.000000 
	C743.798645,935.771301 743.553040,935.879456 736.690918,936.032349 
	C731.730042,936.142883 726.775818,936.554077 721.409302,936.914368 
z"/>
<path fill="#2E282A" opacity="1.000000" stroke="none" 
	d="
M1001.980957,792.857239 
	C1004.648682,793.168823 1007.439087,793.135010 1009.141968,794.479004 
	C1010.045715,795.192444 1009.713684,798.743164 1008.727234,800.181946 
	C1007.920288,801.358887 1005.226501,801.919312 1003.528809,801.669250 
	C999.811401,801.121582 999.145630,797.526855 1001.980957,792.857239 
z"/>
<path fill="#332D2F" opacity="1.000000" stroke="none" 
	d="
M975.637451,723.044189 
	C979.702454,723.813110 977.485962,725.698914 977.295959,727.295166 
	C976.766541,727.357178 976.237122,727.419250 975.707703,727.481262 
	C975.608582,726.086182 975.509460,724.691162 975.637451,723.044189 
z"/>
<path fill="#2C2A2C" opacity="1.000000" stroke="none" 
	d="
M650.111694,945.095032 
	C650.000000,945.000000 650.500000,945.000000 650.750000,945.000000 
	C651.000000,945.000000 650.639526,945.234558 650.639526,945.234558 
	C650.639526,945.234558 650.223450,945.190063 650.111694,945.095032 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M927.816406,359.730133 
	C928.689514,367.243439 929.333008,374.487000 930.118835,381.715088 
	C930.234924,382.782745 930.801208,384.452637 931.538696,384.696472 
	C936.486206,386.332245 935.810669,390.805878 936.890198,394.445557 
	C937.331848,395.934418 938.042114,398.351196 938.971558,398.525543 
	C945.386719,399.728760 945.332458,404.269287 944.621765,409.479248 
	C941.394958,408.610931 937.664673,409.622986 936.029053,405.383392 
	C933.572388,399.015747 930.442383,392.901031 927.421143,386.766266 
	C927.012573,385.936676 925.475098,385.734253 924.629272,385.036682 
	C920.811462,381.887665 916.134766,379.278015 913.497314,375.321045 
	C909.803528,369.779266 907.859985,363.101227 904.728333,357.140106 
	C903.396423,354.604706 900.851501,352.732574 899.036255,350.419037 
	C896.128296,346.712891 893.437439,342.835968 890.517395,339.139801 
	C889.084534,337.326050 887.175659,335.884705 885.768982,334.054382 
	C878.341125,324.389130 871.300964,314.418701 861.543884,306.790527 
	C857.438965,303.581238 853.718384,299.880585 849.821289,296.405273 
	C847.485168,294.321960 844.039917,292.677216 843.018188,290.078461 
	C840.131653,282.736755 830.635315,281.867981 828.259521,273.974609 
	C827.776489,272.369751 823.251099,272.055176 820.656067,270.983856 
	C819.185059,270.376526 817.742981,269.582825 816.470886,268.628998 
	C808.035461,262.304138 799.698303,255.847473 791.231934,249.564713 
	C784.559387,244.613083 777.747559,239.849136 771.357422,235.022385 
	C773.477661,235.029114 775.238037,235.015289 776.998474,235.001465 
	C782.746399,236.113007 788.437439,237.393921 793.565979,240.409760 
	C794.423767,240.914154 795.417297,241.224380 796.194275,241.823730 
	C802.426270,246.630814 808.486267,251.673172 814.867798,256.270355 
	C820.586792,260.390289 826.597900,264.114746 832.571045,267.868927 
	C838.384705,271.522827 844.508728,274.526550 848.540527,280.661926 
	C850.926941,284.293488 854.642517,287.135040 858.045410,289.992523 
	C861.690918,293.053864 865.248962,295.904266 865.053894,301.341217 
	C864.888550,305.952087 868.495911,304.780853 871.820618,305.203186 
	C873.338196,305.603363 874.167908,305.801697 874.997559,306.000000 
	C876.650085,307.760620 878.302612,309.521240 879.977905,312.062622 
	C880.301270,321.562256 883.116577,323.650879 890.998108,321.002014 
	C896.690735,319.703461 899.991699,323.946167 903.196289,327.085938 
	C907.477356,331.280640 913.251709,334.898346 912.784058,342.911987 
	C911.080139,352.002960 912.862427,353.961853 921.995117,354.001221 
	C923.874268,355.819275 925.753479,357.637329 927.816406,359.730133 
z"/>
<path fill="#393336" opacity="1.000000" stroke="none" 
	d="
M921.918457,353.612701 
	C912.862427,353.961853 911.080139,352.002960 912.783813,343.321655 
	C913.415466,343.262970 913.811768,343.706177 914.254517,343.758972 
	C919.905151,344.432861 921.619446,348.222137 921.918457,353.612701 
z"/>
<path fill="#393336" opacity="1.000000" stroke="none" 
	d="
M890.810608,320.689636 
	C883.116577,323.650879 880.301270,321.562256 880.000305,312.422058 
	C880.322937,312.068817 880.649353,312.209229 880.968384,312.194214 
	C886.735596,311.922516 889.282532,315.435211 890.810608,320.689636 
z"/>
<path fill="#393336" opacity="1.000000" stroke="none" 
	d="
M776.896362,234.681839 
	C775.238037,235.015289 773.477661,235.029114 771.024902,235.021912 
	C769.889160,234.998764 769.445801,234.996643 768.927246,234.655914 
	C766.901367,231.212631 764.950684,228.107956 763.000000,225.003281 
	C763.444458,225.002090 763.888916,225.000916 764.666687,224.999512 
	C768.931458,228.120285 772.862854,231.241257 776.896362,234.681839 
z"/>
<path fill="#393336" opacity="1.000000" stroke="none" 
	d="
M874.908081,305.717560 
	C874.167908,305.801697 873.338196,305.603363 872.255371,305.203918 
	C872.940979,305.146912 873.879822,305.291016 874.908081,305.717560 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M762.750977,224.999771 
	C764.950684,228.107956 766.901367,231.212631 768.588013,234.581390 
	C764.620422,232.042206 761.850647,227.267456 755.663208,229.005829 
	C754.887085,229.005280 754.443604,229.005508 753.999756,228.754547 
	C753.998840,228.335861 753.998230,228.168381 754.292847,227.866028 
	C755.461609,227.211258 756.335022,226.691360 758.349548,225.492249 
	C755.702515,225.250305 754.350037,225.126678 752.656372,224.924988 
	C749.876770,224.232285 747.438354,223.617661 744.667847,223.000549 
	C743.892883,222.995819 743.450134,222.993561 742.925842,222.642822 
	C741.157898,220.889282 739.626160,219.210938 737.746521,218.147263 
	C735.306091,216.766220 732.678650,215.451752 729.968689,214.875626 
	C726.354370,214.107239 723.453064,208.662888 718.613647,212.978607 
	C716.398071,212.042694 714.468079,211.284561 712.754578,210.188034 
	C710.315796,208.627319 708.286804,208.275406 706.489380,211.221130 
	C705.804810,210.919083 705.375671,210.650177 704.948242,209.984833 
	C704.058716,208.130524 703.495178,205.897202 702.216187,205.356522 
	C697.950500,203.553299 693.428345,202.356842 688.723389,200.726822 
	C681.678589,196.699814 675.216553,192.258179 666.948975,192.000809 
	C665.249023,191.947922 663.605286,190.087036 662.283203,188.829193 
	C669.255249,186.950455 675.393372,188.474396 681.436096,191.233093 
	C683.467285,192.160370 685.800903,192.425034 688.413208,192.995667 
	C690.219299,193.001999 691.608398,193.005203 693.213806,193.298767 
	C695.285095,194.059433 697.140076,194.529709 699.233154,195.240875 
	C701.313477,196.321579 703.155701,197.161407 705.272827,198.221664 
	C708.363464,199.293716 711.179321,200.145340 713.995117,200.996948 
	C714.743896,201.003281 715.492676,201.009613 716.696899,201.358414 
	C720.771484,204.770325 724.384521,207.847031 728.025452,210.890488 
	C728.234436,211.065216 728.669922,210.969055 729.134277,211.323807 
	C731.785156,213.078949 734.249023,215.656662 737.372192,212.026749 
	C744.696228,215.752975 751.647827,219.456131 758.598572,223.161072 
	C759.735840,223.767227 760.868835,224.381195 762.003845,224.991547 
	C762.003845,224.991531 762.501953,224.996246 762.750977,224.999771 
z"/>
<path fill="#3D383B" opacity="1.000000" stroke="none" 
	d="
M729.000000,210.998993 
	C728.669922,210.969055 728.234436,211.065216 728.025452,210.890488 
	C724.384521,207.847031 720.771484,204.770325 717.071289,201.360870 
	C725.014099,203.930984 733.039185,206.838104 741.060547,209.755219 
	C741.989868,210.093216 742.983337,210.939041 743.804810,210.785980 
	C754.778992,208.741302 756.853699,218.470093 761.933960,224.646423 
	C760.868835,224.381195 759.735840,223.767227 758.598572,223.161072 
	C751.647827,219.456131 744.696228,215.752975 736.993958,212.019821 
	C733.828674,211.659943 731.414368,211.329468 729.000000,210.998993 
z"/>
<path fill="#403B3D" opacity="1.000000" stroke="none" 
	d="
M713.896912,200.684387 
	C711.179321,200.145340 708.363464,199.293716 705.333984,197.913391 
	C705.747681,196.589005 706.375000,195.793320 707.002319,194.997650 
	C708.123352,195.238388 709.244385,195.479126 710.682068,195.861786 
	C711.932007,197.459717 712.865356,198.915771 713.896912,200.684387 
z"/>
<path fill="#302E30" opacity="1.000000" stroke="none" 
	d="
M170.374649,612.040283 
	C170.044250,611.214966 170.047455,610.475769 170.060303,609.365845 
	C170.387421,607.284668 170.528732,605.514893 171.077179,603.881897 
	C171.530991,602.530701 172.465439,601.340881 173.558167,600.009155 
	C174.214478,601.979858 174.383163,604.047241 174.832703,606.051514 
	C175.064362,607.084412 175.795715,608.005188 176.639587,609.484314 
	C177.288620,615.597229 176.652237,621.461548 178.100830,626.755432 
	C181.452042,639.002197 185.813339,650.972534 189.763947,663.055298 
	C191.353043,667.915466 191.968964,673.465088 194.794418,677.447510 
	C197.737778,681.596008 193.722778,688.505920 200.611893,690.657898 
	C201.042374,690.792358 200.878311,692.830078 200.786453,694.278015 
	C200.060013,696.381531 199.537979,698.189270 198.734634,699.827515 
	C196.970642,698.771973 195.487961,697.885986 194.005280,697.000000 
	C193.669159,695.586243 193.333038,694.172424 192.996246,691.965271 
	C192.259888,686.957336 191.743820,682.684021 190.642365,678.567383 
	C190.347321,677.464600 188.266510,676.839661 187.005493,675.995361 
	C187.005493,675.995361 186.996368,675.502625 186.995667,674.808960 
	C186.920685,667.207581 186.472244,660.444092 180.016815,655.993652 
	C182.132629,649.650391 179.123596,645.253906 174.262604,641.235718 
	C174.027023,638.629089 174.028793,636.257629 174.033783,633.441467 
	C174.139618,627.116394 174.433609,621.227539 174.177795,615.362732 
	C174.128632,614.235657 171.920441,613.202759 170.374649,612.040283 
z"/>
<path fill="#CFC891" opacity="1.000000" stroke="none" 
	d="
M496.948578,175.353546 
	C501.059387,174.716156 505.170197,174.078751 509.664307,173.295410 
	C510.480255,173.181580 510.912933,173.213684 512.069458,173.184265 
	C514.909485,172.859116 517.025757,172.595474 519.141968,172.331848 
	C519.565552,172.384888 519.989075,172.437927 521.169006,172.418823 
	C538.951599,172.883972 555.977905,173.421249 573.098083,174.084793 
	C573.192017,174.211060 573.637817,174.301483 573.637817,174.301483 
	C573.637817,174.301483 574.025879,174.065933 574.444336,174.061172 
	C576.523804,174.210052 578.184814,174.363693 579.944702,174.730652 
	C580.702209,174.966858 581.360779,174.989761 582.441772,175.021652 
	C584.525269,175.289871 586.186462,175.549103 588.105225,176.049500 
	C593.528748,177.279160 598.681885,178.344360 603.868042,179.211899 
	C605.538879,179.491409 607.296753,179.250519 609.328003,179.241867 
	C610.050232,179.311676 610.459106,179.387283 611.039978,179.768799 
	C613.680115,180.485336 616.148376,180.895966 618.986389,181.312683 
	C620.548889,181.623459 621.741638,181.928146 623.030334,182.562927 
	C623.753052,184.381943 624.379761,185.870850 624.590088,187.362732 
	C622.828064,187.051285 621.482422,186.736877 619.978760,186.132446 
	C614.477844,183.276031 609.047974,181.233459 602.550659,183.052887 
	C568.906921,178.287003 535.641785,175.927887 502.203979,180.850662 
	C498.834503,181.346725 495.390930,181.339233 491.928040,181.249603 
	C490.902740,179.362000 489.931091,177.790222 488.959412,176.218445 
	C490.991028,175.914978 493.022675,175.611511 495.534882,175.544052 
	C496.326508,175.637894 496.637543,175.495728 496.948578,175.353546 
z"/>
<path fill="#443825" opacity="1.000000" stroke="none" 
	d="
M610.868042,179.462891 
	C610.459106,179.387283 610.050232,179.311676 609.047974,178.987762 
	C605.243225,177.515564 602.031799,176.291656 598.820435,175.067734 
	C601.915588,174.738724 605.010742,174.409698 608.523315,174.099487 
	C608.940735,174.118301 608.953857,174.565048 609.127075,175.127167 
	C611.869934,176.117767 614.439514,176.546265 617.340149,176.983459 
	C618.112671,176.998993 618.554199,177.005844 619.289124,177.216461 
	C621.814270,177.690292 624.131531,177.664993 626.262573,178.282486 
	C634.624268,180.705475 642.924683,183.339493 651.253296,185.877487 
	C654.807312,186.960495 658.374451,188.000153 661.935669,189.059509 
	C663.605286,190.087036 665.249023,191.947922 666.948975,192.000809 
	C675.216553,192.258179 681.678589,196.699814 688.617920,201.104401 
	C688.721436,201.933563 688.649170,202.184143 688.188354,202.436829 
	C684.995300,201.518646 682.177429,200.636902 679.388977,199.670212 
	C673.223450,197.532837 667.072693,195.352783 660.602539,193.011444 
	C657.981445,192.111008 655.673828,191.389236 653.207886,190.403885 
	C652.315918,189.902023 651.582275,189.663757 650.469788,189.325226 
	C648.676453,188.771545 647.282104,188.237442 645.844116,187.878235 
	C638.213562,185.972229 630.572021,184.110138 622.934326,182.232819 
	C621.741638,181.928146 620.548889,181.623459 618.715088,181.087296 
	C615.671997,180.391510 613.270020,179.927200 610.868042,179.462891 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M579.845886,174.517349 
	C578.184814,174.363693 576.523804,174.210052 574.195557,173.969849 
	C573.353699,173.908371 573.178894,173.933456 573.004150,173.958527 
	C555.977905,173.421249 538.951599,172.883972 521.461426,172.276489 
	C522.650024,171.467026 524.281555,170.142609 525.958618,170.082108 
	C538.201660,169.640427 550.463135,169.049042 562.699768,169.330795 
	C570.474182,169.509781 578.228149,171.021652 585.960571,172.146591 
	C587.278992,172.338409 588.442627,173.593521 589.512329,174.820007 
	C588.847168,175.457870 588.347412,175.633102 587.847656,175.808350 
	C586.186462,175.549103 584.525269,175.289871 582.223633,174.815231 
	C581.004028,174.572327 580.424988,174.544830 579.845886,174.517349 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M469.857208,175.681030 
	C467.300262,176.362991 464.582001,176.700928 461.257874,176.773148 
	C459.450897,176.691406 458.249817,176.875366 457.048706,177.059326 
	C456.606384,177.056747 456.164062,177.054153 455.391174,177.050888 
	C453.322235,176.926453 451.583893,176.802689 449.845581,176.678940 
	C449.680939,176.066193 449.516296,175.453445 449.351685,174.840698 
	C452.166351,173.735092 455.061859,171.567123 457.776703,171.773666 
	C461.816772,172.081009 465.729187,174.065948 469.857208,175.681030 
z"/>
<path fill="#ADA16F" opacity="1.000000" stroke="none" 
	d="
M588.105225,176.049500 
	C588.347412,175.633102 588.847168,175.457870 589.711365,175.118774 
	C592.782654,175.095932 595.489441,175.236954 598.508362,175.222855 
	C602.031799,176.291656 605.243225,177.515564 608.734680,178.993561 
	C607.296753,179.250519 605.538879,179.491409 603.868042,179.211899 
	C598.681885,178.344360 593.528748,177.279160 588.105225,176.049500 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M518.937134,172.018250 
	C517.025757,172.595474 514.909485,172.859116 512.381348,173.080780 
	C513.656433,169.718582 515.833069,168.880081 518.937134,172.018250 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M442.719849,179.217865 
	C442.773346,178.953766 442.924072,178.795319 443.074829,178.636871 
	C443.157837,178.727585 443.240906,178.818314 443.323944,178.909042 
	C443.154968,179.047195 442.986023,179.185364 442.719849,179.217865 
z"/>
<path fill="#403B3D" opacity="1.000000" stroke="none" 
	d="
M662.283203,188.829193 
	C658.374451,188.000153 654.807312,186.960495 651.253296,185.877487 
	C642.924683,183.339493 634.624268,180.705475 626.262573,178.282486 
	C624.131531,177.664993 621.814270,177.690292 619.602173,176.997345 
	C625.126465,176.451736 630.639526,176.104141 636.132019,176.308105 
	C638.609131,176.400116 641.015503,177.737122 643.507446,178.248444 
	C646.978760,178.960693 650.500305,179.428284 654.000305,180.000626 
	C657.395447,179.004272 660.790649,178.007904 664.587280,177.011505 
	C668.107666,179.606308 671.226440,182.201187 674.793152,185.237000 
	C676.913513,186.486053 678.647522,187.189362 680.244629,188.126022 
	C682.875122,189.668762 685.417053,191.362473 687.996338,192.992554 
	C685.800903,192.425034 683.467285,192.160370 681.436096,191.233093 
	C675.393372,188.474396 669.255249,186.950455 662.283203,188.829193 
z"/>
<path fill="#3F3D40" opacity="1.000000" stroke="none" 
	d="
M688.413208,192.995667 
	C685.417053,191.362473 682.875122,189.668762 680.244629,188.126022 
	C678.647522,187.189362 676.913513,186.486053 675.119751,185.340942 
	C684.949341,187.616043 694.905212,190.208954 704.840088,192.880463 
	C705.552612,193.072052 706.110596,193.838516 706.871338,194.667664 
	C706.375000,195.793320 705.747681,196.589005 705.059082,197.692963 
	C703.155701,197.161407 701.313477,196.321579 699.118774,194.927200 
	C696.843323,193.917892 694.920471,193.463150 692.997559,193.008408 
	C691.608398,193.005203 690.219299,193.001999 688.413208,192.995667 
z"/>
<path fill="#3B3539" opacity="1.000000" stroke="none" 
	d="
M273.074280,833.335571 
	C275.369232,834.264587 278.203827,835.063782 280.017273,836.881592 
	C285.350830,842.227844 289.976868,848.297119 295.448303,853.483826 
	C301.289032,859.020569 307.778137,863.873474 313.985443,869.023438 
	C314.113037,869.129272 314.310852,869.186462 314.383484,869.317627 
	C321.080261,881.412842 335.102814,884.709778 344.087402,894.054382 
	C345.803253,895.839050 347.323822,897.903442 349.306610,899.316650 
	C351.876617,901.148254 354.884705,903.839478 357.589172,903.712341 
	C363.924316,903.414734 365.860046,908.720703 369.518372,911.613281 
	C374.416382,915.485962 378.900146,919.292542 385.371613,920.875610 
	C390.540009,922.139832 395.136871,925.721924 400.003052,928.245056 
	C405.137390,930.907288 410.708466,932.959839 415.326416,936.324829 
	C420.770752,940.292114 425.073120,945.758057 432.533691,946.546631 
	C433.497162,946.648499 434.089142,950.264771 434.926361,952.625366 
	C431.573944,952.287964 426.281616,952.644592 425.133423,950.634277 
	C423.099854,947.073853 420.418091,945.197876 417.221344,943.700745 
	C409.812073,940.230530 402.255280,937.075500 394.761353,933.785706 
	C394.311157,933.588074 393.688416,933.443542 393.481201,933.082092 
	C388.806335,924.928406 379.937866,924.074768 372.441528,920.763916 
	C370.686981,919.988953 369.112885,918.214111 368.078583,916.519165 
	C365.692017,912.608276 363.044128,909.696228 357.896912,909.862244 
	C356.206879,909.916687 354.147766,908.789917 352.807465,907.589233 
	C347.886414,903.180786 343.200226,898.510315 338.414032,893.950684 
	C337.935547,893.494934 337.416412,892.826477 336.852142,892.753845 
	C326.227478,891.385498 321.187378,881.608276 312.854279,876.789734 
	C311.582672,876.054443 310.916321,874.261536 309.989502,872.944092 
	C309.055664,871.616699 308.439941,869.815552 307.180756,869.005127 
	C293.034760,859.901489 283.181061,846.706970 273.074280,833.335571 
z"/>
<path fill="#3B3539" opacity="1.000000" stroke="none" 
	d="
M241.001831,784.997559 
	C242.965256,788.818176 244.584702,792.869934 246.964539,796.410645 
	C251.739380,803.514465 256.825500,810.417725 261.992096,817.245728 
	C264.573364,820.656982 267.743011,823.619202 270.389923,826.986267 
	C271.570801,828.488403 272.119171,830.487732 272.976685,832.630188 
	C268.350983,828.662659 263.315735,824.664001 259.161682,819.897400 
	C254.792969,814.884399 251.030380,809.288574 247.429001,803.674072 
	C242.048935,795.286560 237.127289,786.606323 231.834625,778.160889 
	C229.658707,774.688721 226.692184,771.692749 224.674545,768.145020 
	C221.254654,762.131775 218.302414,755.852539 215.415726,749.418152 
	C217.439072,751.235413 219.291718,753.250061 220.942352,755.418457 
	C224.353668,759.899841 227.657425,764.463013 231.294769,769.083496 
	C232.058151,769.781616 232.532211,770.388733 233.031799,771.376587 
	C233.858749,774.396606 234.228470,777.290039 235.588348,779.600708 
	C236.838943,781.725647 239.155106,783.223450 241.000916,784.998779 
	C241.000000,785.000000 241.001831,784.997559 241.001831,784.997559 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M231.005432,768.992493 
	C227.657425,764.463013 224.353668,759.899841 220.942352,755.418457 
	C219.291718,753.250061 217.439072,751.235413 215.340149,749.075562 
	C214.706375,748.520142 214.410324,748.040222 213.981201,746.941284 
	C212.234314,744.214233 210.620483,742.106201 209.006653,739.998169 
	C209.006104,739.249207 209.005554,738.500305 209.325073,737.302979 
	C210.203796,735.850952 211.501068,734.371948 211.207916,733.916443 
	C207.949005,728.852112 204.444046,723.946106 201.005493,718.997375 
	C201.003052,718.248657 201.000595,717.499939 200.999863,716.041199 
	C201.005157,714.883667 201.008728,714.436218 201.364395,714.064209 
	C202.873489,715.386047 204.031860,716.631104 205.187073,717.879089 
	C206.458115,719.252136 207.726303,720.627869 209.066650,722.363647 
	C212.558762,730.758240 215.745758,738.902161 219.468491,746.793274 
	C223.024002,754.329895 227.137329,761.603333 231.005432,768.992493 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M179.884613,656.311157 
	C186.472244,660.444092 186.920685,667.207581 186.991516,674.562561 
	C183.561249,673.734680 181.878357,671.596863 181.811569,667.615906 
	C181.749939,663.942993 180.486496,660.290344 179.884613,656.311157 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M187.003372,676.434448 
	C188.266510,676.839661 190.347321,677.464600 190.642365,678.567383 
	C191.743820,682.684021 192.259888,686.957336 192.993652,691.586426 
	C187.115494,688.494690 187.109314,682.663879 187.003372,676.434448 
z"/>
<path fill="#3B3539" opacity="1.000000" stroke="none" 
	d="
M201.010315,719.381592 
	C204.444046,723.946106 207.949005,728.852112 211.207916,733.916443 
	C211.501068,734.371948 210.203796,735.850952 209.325623,736.928528 
	C206.858551,732.779480 204.659912,728.581360 202.591980,724.319763 
	C201.895584,722.884583 201.531235,721.288391 201.010315,719.381592 
z"/>
<path fill="#3B3539" opacity="1.000000" stroke="none" 
	d="
M197.995636,708.002441 
	C196.679977,705.584045 195.364304,703.165588 194.338043,700.472534 
	C196.083496,701.131958 197.539551,702.065979 199.000732,702.997009 
	C199.005859,702.994019 199.017670,702.988770 199.010849,703.389771 
	C199.004654,704.859558 199.005264,705.928284 198.816330,707.142334 
	C198.385986,707.499084 198.175613,707.737366 197.995636,708.002441 
z"/>
<path fill="#3B3539" opacity="1.000000" stroke="none" 
	d="
M209.010681,740.375366 
	C210.620483,742.106201 212.234314,744.214233 213.921356,746.661499 
	C212.334610,744.917969 210.674667,742.835205 209.010681,740.375366 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M198.995605,703.000000 
	C197.539551,702.065979 196.083496,701.131958 194.314941,700.098999 
	C194.001846,699.250000 194.001236,698.500000 194.002960,697.375000 
	C195.487961,697.885986 196.970642,698.771973 198.733017,700.203003 
	C199.007004,701.498657 199.001297,702.249329 198.995605,703.000000 
z"/>
<path fill="#3B3539" opacity="1.000000" stroke="none" 
	d="
M201.012299,713.988708 
	C201.008728,714.436218 201.005157,714.883667 200.998962,715.666809 
	C200.042343,714.564575 199.088333,713.126709 198.442215,711.343872 
	C199.495575,711.000916 200.241074,711.002930 200.991058,711.377869 
	C201.001144,712.496826 201.006729,713.242737 201.012299,713.988708 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M200.986557,711.004883 
	C200.241074,711.002930 199.495575,711.000916 198.377487,710.998291 
	C198.002609,710.248779 198.000320,709.500000 197.996841,708.376831 
	C198.175613,707.737366 198.385986,707.499084 199.091309,707.335571 
	C200.032745,708.590637 200.509644,709.797791 200.986557,711.004883 
z"/>
<path fill="#302E30" opacity="1.000000" stroke="none" 
	d="
M755.995789,229.006592 
	C761.850647,227.267456 764.620422,232.042206 768.663208,234.920013 
	C769.445801,234.996643 769.889160,234.998764 770.665039,235.001358 
	C777.747559,239.849136 784.559387,244.613083 791.231934,249.564713 
	C799.698303,255.847473 808.035461,262.304138 816.470886,268.628998 
	C817.742981,269.582825 819.185059,270.376526 820.656067,270.983856 
	C823.251099,272.055176 827.776489,272.369751 828.259521,273.974609 
	C830.635315,281.867981 840.131653,282.736755 843.018188,290.078461 
	C844.039917,292.677216 847.485168,294.321960 849.821289,296.405273 
	C853.718384,299.880585 857.438965,303.581238 861.543884,306.790527 
	C871.300964,314.418701 878.341125,324.389130 885.768982,334.054382 
	C887.175659,335.884705 889.084534,337.326050 890.517395,339.139801 
	C893.437439,342.835968 896.128296,346.712891 899.036255,350.419037 
	C900.851501,352.732574 903.396423,354.604706 904.728333,357.140106 
	C907.859985,363.101227 909.803528,369.779266 913.497314,375.321045 
	C916.134766,379.278015 920.811462,381.887665 924.629272,385.036682 
	C925.475098,385.734253 927.012573,385.936676 927.421143,386.766266 
	C930.442383,392.901031 933.572388,399.015747 936.029053,405.383392 
	C937.664673,409.622986 941.394958,408.610931 944.621765,409.924530 
	C945.000366,412.699707 945.000732,415.399414 945.001770,418.548950 
	C945.002136,423.355743 945.001831,427.712677 945.001221,432.971924 
	C944.999756,435.916962 944.998657,437.959686 944.997559,440.002441 
	C938.898926,440.233154 937.441956,435.638336 936.095398,431.184692 
	C934.277649,425.172424 933.031982,418.970490 930.920166,413.072205 
	C930.106140,410.798920 927.928406,408.457825 925.759338,407.373688 
	C923.323303,406.156128 921.903015,405.408264 922.597229,402.367706 
	C923.425415,398.740265 923.820007,395.056549 919.251465,393.168884 
	C918.576294,392.889862 918.093933,391.534454 917.975769,390.618561 
	C917.337585,385.672974 914.266052,384.409149 909.028687,385.001770 
	C907.134521,384.999084 906.068481,384.997101 904.993774,384.593323 
	C904.306152,380.316071 903.875244,376.373749 902.855530,372.590149 
	C902.237549,370.297333 901.187866,367.721802 899.500427,366.188995 
	C893.787903,361.000092 892.176819,352.318573 884.499695,348.823029 
	C883.657349,348.439484 883.450256,346.809998 882.775208,345.884796 
	C879.321960,341.151855 876.116577,336.188629 872.232544,331.833893 
	C868.629272,327.793884 864.192566,324.508514 860.306580,320.703217 
	C858.948792,319.373657 858.143005,317.482117 857.077393,315.852264 
	C855.347778,313.207092 854.021423,310.141205 851.802734,308.001953 
	C844.775757,301.226746 837.513245,294.684814 830.149536,288.273529 
	C824.646362,283.482117 821.174072,276.219818 813.250977,274.294647 
	C812.419434,274.092590 811.845398,272.936859 811.090942,272.295319 
	C803.163940,265.555511 795.217041,258.838989 787.293640,252.094879 
	C785.368103,250.455963 783.616333,248.593979 781.591675,247.095215 
	C778.912903,245.112274 776.048889,243.378708 773.255188,241.552170 
	C771.938965,240.691635 770.543213,239.942276 769.283386,239.007889 
	C764.830872,235.705551 760.421509,232.345062 755.995789,229.006592 
z"/>
<path fill="#302E31" opacity="1.000000" stroke="none" 
	d="
M231.294769,769.083496 
	C227.137329,761.603333 223.024002,754.329895 219.468491,746.793274 
	C215.745758,738.902161 212.558762,730.758240 209.318741,722.362427 
	C209.667892,721.999207 209.835892,721.998352 210.278503,722.190186 
	C212.035812,723.921814 213.518509,725.460938 215.000610,727.000000 
	C215.000000,727.000000 215.000381,727.000000 215.088959,727.289734 
	C215.778976,728.062866 216.380432,728.546265 216.985672,729.268921 
	C216.989471,729.508179 216.997772,729.986816 217.134277,730.311951 
	C217.504303,732.714966 217.166519,735.097107 218.080383,736.812561 
	C220.639084,741.615784 223.587067,746.228760 226.614655,750.761108 
	C232.018188,758.850281 237.480225,766.905640 243.135178,774.819824 
	C246.983780,780.205994 251.267441,785.279602 255.207443,790.603394 
	C256.119568,791.835815 256.423309,793.518555 257.028290,795.356934 
	C257.507202,796.764038 257.757172,797.980652 258.457001,798.823975 
	C262.242859,803.386108 265.859741,808.137756 270.051666,812.304199 
	C282.334595,824.512329 294.806366,836.532532 307.319427,848.505981 
	C312.534729,853.496216 317.807220,858.482727 323.491180,862.909180 
	C327.913300,866.352844 333.353149,868.508606 337.688202,872.038086 
	C340.415955,874.259033 341.939728,877.958801 344.003632,880.995117 
	C340.425018,881.426147 337.766418,880.370300 335.478790,877.257324 
	C334.032043,875.288574 331.297150,874.273987 329.159698,872.802795 
	C327.693237,871.793579 326.230804,870.773743 324.821838,869.687012 
	C323.523651,868.685547 322.448608,866.988464 321.017761,866.632202 
	C312.497559,864.511047 308.015015,857.812378 302.872681,851.630981 
	C299.734192,847.858276 295.656647,844.881042 292.150757,841.395752 
	C288.190033,837.458435 284.432434,833.317627 280.513824,829.336853 
	C279.019836,827.819153 277.345520,826.474731 275.714264,825.097656 
	C274.085205,823.722595 272.079559,822.659180 270.808899,821.017883 
	C265.954071,814.747314 261.306458,808.315247 256.625275,801.911499 
	C253.039856,797.006714 249.625946,791.971497 245.893234,787.183289 
	C245.022339,786.066162 243.168671,785.715149 241.384491,785.004639 
	C241.001831,784.997559 241.000000,785.000000 240.994141,784.615295 
	C238.327606,779.819031 235.666946,775.407471 233.006271,770.995911 
	C232.532211,770.388733 232.058151,769.781616 231.294769,769.083496 
z"/>
<path fill="#2C2A2C" opacity="1.000000" stroke="none" 
	d="
M344.252136,880.996948 
	C341.939728,877.958801 340.415955,874.259033 337.688202,872.038086 
	C333.353149,868.508606 327.913300,866.352844 323.491180,862.909180 
	C317.807220,858.482727 312.534729,853.496216 307.319427,848.505981 
	C294.806366,836.532532 282.334595,824.512329 270.051666,812.304199 
	C265.859741,808.137756 262.242859,803.386108 258.457001,798.823975 
	C257.757172,797.980652 257.507202,796.764038 257.399292,795.383606 
	C260.814880,796.043518 263.880798,797.039490 267.077820,798.281860 
	C267.474640,799.018860 267.740387,799.509399 267.723633,800.097656 
	C267.160309,800.727112 266.502472,801.555603 266.653534,801.747620 
	C270.662628,806.843445 274.715240,811.906799 278.858429,816.894226 
	C280.058014,818.338440 281.564850,819.526001 282.918762,820.843628 
	C284.384705,822.270508 285.753906,823.809570 287.311707,825.126587 
	C290.488739,827.812500 293.764008,830.382324 296.836273,833.240479 
	C297.452911,834.824158 297.932587,836.535706 299.059174,837.451721 
	C307.164490,844.042419 315.344543,850.544922 323.612976,856.930359 
	C330.755371,862.446167 338.217865,867.561951 345.170258,873.301086 
	C347.387512,875.131348 348.424286,878.391602 350.001221,880.997559 
	C348.611359,880.998535 347.221527,880.999512 345.414612,881.001465 
	C344.997559,881.002441 344.500610,880.998779 344.252136,880.996948 
z"/>
<path fill="#434144" opacity="1.000000" stroke="none" 
	d="
M653.732910,179.783539 
	C650.500305,179.428284 646.978760,178.960693 643.507446,178.248444 
	C641.015503,177.737122 638.609131,176.400116 636.132019,176.308105 
	C630.639526,176.104141 625.126465,176.451736 619.308716,176.793549 
	C618.554199,177.005844 618.112671,176.998993 617.256653,176.692810 
	C616.225647,175.599121 615.609192,174.804749 614.993896,173.758163 
	C614.995911,173.337814 614.996643,173.169678 614.997375,173.001556 
	C620.761963,172.775940 626.526062,172.381287 632.291199,172.365967 
	C640.124939,172.345154 647.107422,175.105698 653.732910,179.783539 
z"/>
<path fill="#4B484B" opacity="1.000000" stroke="none" 
	d="
M627.762146,162.574707 
	C629.793213,159.262054 634.364746,159.041748 638.854553,162.127487 
	C635.150879,164.917099 631.616943,164.509354 627.762146,162.574707 
z"/>
<path fill="#434144" opacity="1.000000" stroke="none" 
	d="
M660.149475,170.701111 
	C659.213074,169.776810 658.606262,168.891052 657.999390,168.005310 
	C659.688232,167.860107 661.377014,167.714905 663.065857,167.569702 
	C663.187744,168.131012 663.309692,168.692322 663.431641,169.253616 
	C662.447449,169.748978 661.463257,170.244339 660.149475,170.701111 
z"/>
<path fill="#403B3D" opacity="1.000000" stroke="none" 
	d="
M614.992676,174.010376 
	C615.609192,174.804749 616.225647,175.599121 616.925598,176.684128 
	C614.439514,176.546265 611.869934,176.117767 609.138672,175.350342 
	C609.816345,174.808411 610.655823,174.605408 612.124390,174.201019 
	C613.499939,174.003220 614.246277,174.006790 614.992676,174.010376 
z"/>
<path fill="#4B484B" opacity="1.000000" stroke="none" 
	d="
M614.993896,173.758179 
	C614.246277,174.006790 613.499939,174.003220 612.380432,173.997986 
	C612.141235,172.201981 612.436951,170.709976 614.778076,172.816498 
	C614.996643,173.169678 614.995911,173.337814 614.993896,173.758179 
z"/>
<path fill="#332D2F" opacity="1.000000" stroke="none" 
	d="
M945.151001,440.361664 
	C944.998657,437.959686 944.999756,435.916962 945.001709,433.436493 
	C952.965515,433.115967 953.959717,434.103424 954.000671,441.920105 
	C954.013794,444.418091 953.869934,446.931671 954.099976,449.410065 
	C954.393127,452.569427 955.176392,455.136932 958.528503,457.089447 
	C961.721375,458.949188 964.984924,462.074066 966.287903,465.398895 
	C967.569153,468.668640 966.382263,472.888306 966.356384,476.691681 
	C966.317383,482.412964 966.346191,488.134705 966.346191,494.204315 
	C967.990356,493.767822 968.503723,493.746399 968.539062,493.605225 
	C969.320251,490.486572 970.665039,487.289825 974.431641,488.589966 
	C979.216614,490.241608 978.203247,494.526428 976.870483,498.507141 
	C971.540283,498.109863 966.770142,497.554962 962.000000,497.000000 
	C961.666199,493.292389 961.332336,489.584747 960.998901,484.971069 
	C960.999390,480.422272 961.009399,476.779510 960.997803,473.136810 
	C960.975281,466.079376 960.199890,465.296234 952.997559,465.001221 
	C951.371338,461.411896 949.371948,457.938049 948.216125,454.203125 
	C946.862122,449.827728 946.242554,445.225006 945.151001,440.361664 
z"/>
<path fill="#302E30" opacity="1.000000" stroke="none" 
	d="
M962.083618,497.409302 
	C966.770142,497.554962 971.540283,498.109863 976.655212,498.832397 
	C977.000000,502.030273 977.000000,505.060547 977.000000,508.544800 
	C976.999695,526.020691 976.999390,543.042664 976.998291,560.533508 
	C972.994507,559.795593 973.432922,556.294434 973.014648,553.253357 
	C971.625671,543.155457 970.271240,533.052734 968.845276,522.960083 
	C968.736389,522.189636 968.337463,521.012878 967.792114,520.819885 
	C961.487366,518.588989 963.213013,513.298035 962.981140,508.706970 
	C962.797607,505.073059 962.444763,501.447723 962.083618,497.409302 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M173.066513,565.982361 
	C172.966003,550.962341 172.865509,535.942322 172.734619,520.506836 
	C173.254898,515.374390 173.805573,510.657440 174.693359,505.356934 
	C174.997681,503.838196 174.964905,502.903076 174.932129,501.967957 
	C177.254410,487.802582 179.576691,473.637177 182.570465,459.205383 
	C184.440689,458.951508 185.639404,458.964111 186.950577,459.163757 
	C187.121094,459.648682 187.266586,459.894958 187.373932,460.433350 
	C187.220245,461.260773 187.192123,461.744385 187.000061,462.468842 
	C186.790359,463.483673 186.744583,464.257690 186.506866,465.307800 
	C186.251038,466.246826 186.187164,466.909698 185.916046,467.799561 
	C185.402008,469.009705 185.095215,469.992828 184.547241,471.057709 
	C184.300842,471.793671 184.295624,472.447906 184.261322,473.538330 
	C182.690170,484.304779 181.148117,494.635071 179.324265,505.123657 
	C179.066437,506.869965 179.090393,508.458008 179.193268,510.486328 
	C178.965149,513.272583 178.658142,515.618530 178.123413,518.108765 
	C177.978546,519.184692 178.061386,520.116333 178.201965,521.481506 
	C178.139648,523.949219 178.019592,525.983398 177.723251,528.157532 
	C177.332779,528.513245 177.162689,528.759460 177.033081,529.499756 
	C176.999557,534.627991 176.969650,539.292664 176.649567,543.992126 
	C175.874435,544.366882 175.389511,544.706848 174.904572,545.046753 
	C175.585434,545.377625 176.266281,545.708435 176.997620,546.312744 
	C177.324371,547.056519 177.600647,547.526855 177.937561,548.444153 
	C177.984146,551.593567 177.970062,554.296082 177.610504,557.163086 
	C176.847839,559.619507 176.579758,561.952271 175.964844,564.189758 
	C175.498566,565.886353 174.590439,567.461548 173.878937,569.090759 
	C173.878952,569.090759 173.650711,569.199341 173.648422,568.880737 
	C173.452927,567.702271 173.259720,566.842285 173.066513,565.982361 
z"/>
<path fill="#CEC087" opacity="1.000000" stroke="none" 
	d="
M213.391830,371.717438 
	C213.990768,370.478577 214.485184,369.553741 214.992310,368.310547 
	C215.005035,367.992218 215.185944,367.706177 215.199219,367.537964 
	C215.560455,367.199493 215.908432,367.029266 216.646118,367.129578 
	C218.138916,368.290131 219.241974,369.180084 220.280426,370.427917 
	C219.137955,373.185394 218.060104,375.584991 216.779037,378.280457 
	C215.489944,380.862274 214.404083,383.148224 213.086975,385.660431 
	C212.292480,387.273712 211.729218,388.660736 210.999878,390.316223 
	C210.542953,391.373322 210.252090,392.161987 209.623169,393.046112 
	C207.908859,396.446228 206.532608,399.750854 205.097839,403.426666 
	C203.594025,407.188354 202.148727,410.578857 200.404343,414.116425 
	C199.738174,415.506989 199.371109,416.750488 198.966400,418.372681 
	C198.835785,419.506866 198.742783,420.262360 198.502319,421.143738 
	C198.176224,421.464417 198.053223,421.689514 197.880341,422.319916 
	C196.492218,426.124023 195.209579,429.553192 193.454773,432.986908 
	C191.708344,432.980194 190.434097,432.968933 189.159821,432.957703 
	C190.447189,428.573029 191.734543,424.188354 193.464005,419.295715 
	C194.143631,418.022186 194.381149,417.256683 194.618683,416.491150 
	C199.138550,405.115143 203.654800,393.737701 208.182480,382.364807 
	C208.794220,380.828217 209.463409,379.314484 210.179291,377.423035 
	C211.264130,375.381042 212.275711,373.706238 213.391830,371.717438 
z"/>
<path fill="#CEC087" opacity="1.000000" stroke="none" 
	d="
M186.838135,458.976685 
	C185.639404,458.964111 184.440689,458.951508 182.787094,458.912079 
	C182.959396,455.880249 183.586563,452.875275 184.634750,449.315735 
	C185.180008,447.984314 185.304245,447.207428 185.428482,446.430542 
	C186.343430,442.752075 187.258377,439.073608 188.705643,435.204773 
	C190.250244,436.040894 191.262527,437.067352 192.271698,438.438354 
	C192.348450,439.227753 192.428345,439.672638 192.356033,440.455933 
	C192.117691,441.526855 192.031525,442.259399 191.686829,443.226562 
	C191.138641,445.022949 190.848999,446.584717 190.416504,448.440186 
	C190.189178,449.157166 190.104691,449.580414 189.780975,450.113495 
	C189.292938,450.470856 189.044144,450.718414 188.518356,450.988403 
	C187.778366,451.323883 187.315353,451.636993 186.852325,451.950073 
	C187.268951,452.308929 187.685577,452.667755 188.102875,453.360687 
	C187.973969,454.118805 187.844421,454.542877 187.484924,455.052246 
	C187.238419,455.783325 187.221893,456.429108 187.196899,457.401184 
	C187.071655,458.143860 186.954895,458.560272 186.838135,458.976685 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M192.274826,438.093811 
	C191.262527,437.067352 190.250244,436.040894 188.882721,434.889465 
	C188.624344,434.281128 188.721191,433.797791 188.988937,433.136047 
	C190.434097,432.968933 191.708344,432.980194 193.451691,433.364502 
	C193.803009,434.989807 193.685257,436.242035 193.341705,437.573181 
	C192.869125,437.863312 192.588760,438.010529 192.274826,438.093811 
z"/>
<path fill="#B7A170" opacity="1.000000" stroke="none" 
	d="
M174.762634,502.241272 
	C174.964905,502.903076 174.997681,503.838196 174.802094,504.911865 
	C174.580200,504.205139 174.586670,503.359863 174.762634,502.241272 
z"/>
<path fill="#B7A170" opacity="1.000000" stroke="none" 
	d="
M194.357376,416.661499 
	C194.381149,417.256683 194.143631,418.022186 193.635071,418.944000 
	C193.608063,418.344147 193.852066,417.587982 194.357376,416.661499 
z"/>
<path fill="#B7A170" opacity="1.000000" stroke="none" 
	d="
M185.195068,446.625183 
	C185.304245,447.207428 185.180008,447.984314 184.804153,448.941803 
	C184.688904,448.354889 184.825272,447.587372 185.195068,446.625183 
z"/>
<path fill="#D6D095" opacity="1.000000" stroke="none" 
	d="
M220.345062,370.070007 
	C219.241974,369.180084 218.138916,368.290131 216.920135,367.032227 
	C216.804413,366.664276 216.864777,366.309387 217.275467,366.024658 
	C218.037674,364.956360 218.389191,364.172729 218.740692,363.389130 
	C218.740692,363.389130 218.666077,363.169098 219.096130,362.950073 
	C219.918442,361.613708 220.310730,360.496338 220.703003,359.378967 
	C220.979263,358.704865 221.255524,358.030792 222.053574,357.098969 
	C223.237244,355.490997 223.899109,354.140747 224.560989,352.790497 
	C229.599701,344.797028 233.992432,336.293976 239.811920,328.916382 
	C251.836029,313.672821 264.620270,299.028839 277.377960,283.984558 
	C278.751862,284.593445 279.839691,285.356018 281.053375,286.730377 
	C279.083557,289.357361 276.987885,291.372528 274.532806,293.308594 
	C273.456726,293.453278 272.740021,293.677063 272.023346,293.900848 
	C272.466522,294.285736 272.909698,294.670593 273.275055,295.377258 
	C273.098297,296.127563 272.999359,296.556122 272.784058,297.051819 
	C272.667755,297.119019 272.579834,297.372864 272.249695,297.478119 
	C268.805145,301.021179 265.418701,304.253021 262.628723,307.936584 
	C255.141846,317.821411 247.910202,327.899567 240.482010,337.960876 
	C240.386826,338.020844 240.398041,338.245544 240.090744,338.398254 
	C236.931778,343.040070 234.080124,347.529205 230.984772,352.273254 
	C229.342743,355.197571 227.944412,357.867035 226.510590,360.537750 
	C226.475067,360.539032 226.451981,360.606262 226.162109,360.714417 
	C225.587021,361.550293 225.301819,362.277954 224.834595,363.248779 
	C224.210175,364.391632 223.767792,365.291321 223.044281,366.324432 
	C221.957108,367.661926 221.151077,368.865967 220.345062,370.070007 
z"/>
<path fill="#CEC087" opacity="1.000000" stroke="none" 
	d="
M224.278625,352.929016 
	C223.899109,354.140747 223.237244,355.490997 222.246490,356.902069 
	C222.610504,355.664459 223.303391,354.365997 224.278625,352.929016 
z"/>
<path fill="#CEC087" opacity="1.000000" stroke="none" 
	d="
M220.409576,359.583435 
	C220.310730,360.496338 219.918442,361.613708 219.168335,362.859070 
	C219.245712,361.920685 219.680923,360.854279 220.409576,359.583435 
z"/>
<path fill="#CEC087" opacity="1.000000" stroke="none" 
	d="
M218.457672,363.561035 
	C218.389191,364.172729 218.037674,364.956360 217.376251,365.875519 
	C217.435760,365.251678 217.805206,364.492310 218.457672,363.561035 
z"/>
<path fill="#DBD9B8" opacity="1.000000" stroke="none" 
	d="
M359.136292,219.492828 
	C374.375977,212.261276 389.400055,205.252792 404.519348,198.455978 
	C409.359497,196.280121 414.451447,194.664429 419.708679,192.549530 
	C422.797760,191.558731 425.606354,190.810852 429.081329,190.429581 
	C430.151917,192.117188 430.556152,193.438187 430.745575,195.070526 
	C420.993011,198.872086 411.308960,202.015381 401.961121,205.956207 
	C393.664429,209.453903 385.741058,213.837082 377.319275,217.937286 
	C370.694244,221.272980 364.157288,224.103806 358.163055,227.811996 
	C346.018066,235.325165 334.167114,243.313629 321.933838,250.769531 
	C321.336304,249.041000 320.999878,247.649048 320.663452,246.257111 
	C328.039917,240.970566 335.359955,235.602798 342.812042,230.425064 
	C348.101593,226.749878 353.569336,223.331146 359.136292,219.492828 
z"/>
<path fill="#DFD9AC" opacity="1.000000" stroke="none" 
	d="
M488.637207,176.171768 
	C489.931091,177.790222 490.902740,179.362000 491.520447,181.234192 
	C490.083679,181.609146 489.000885,181.683670 487.611938,181.557495 
	C481.855560,182.445511 476.405273,183.534210 470.638397,184.715088 
	C469.544830,185.177750 468.767883,185.548233 467.664185,185.835510 
	C466.525757,186.053680 465.714111,186.355042 464.492584,186.611664 
	C461.691437,186.965820 459.300171,187.364716 456.709198,187.599335 
	C455.649170,187.720016 454.788818,188.004959 453.630615,188.476501 
	C452.871552,188.710587 452.410400,188.758118 451.710327,188.654327 
	C449.954193,189.091370 448.436981,189.679749 446.677490,190.461060 
	C445.635559,190.770187 444.835907,190.886368 443.851807,190.651413 
	C442.727478,189.050232 441.787598,187.800201 440.847748,186.550171 
	C445.994324,185.090988 451.140930,183.631805 456.883026,182.338257 
	C458.344055,182.207764 459.209534,181.911621 460.075012,181.615479 
	C460.791595,181.393509 461.508179,181.171539 462.765015,181.131058 
	C463.896332,181.062988 464.487396,180.813461 465.078461,180.563934 
	C471.410614,179.324966 477.742798,178.085983 484.675995,177.038422 
	C485.870544,176.956360 486.464081,176.682922 487.057587,176.409485 
	C487.476746,176.314697 487.895874,176.219894 488.637207,176.171768 
z"/>
<path fill="#DFD9AC" opacity="1.000000" stroke="none" 
	d="
M430.960388,194.759186 
	C430.556152,193.438187 430.151917,192.117188 429.471497,190.452942 
	C431.211121,189.346466 433.226898,188.583221 435.639587,188.234421 
	C436.352234,190.073395 436.667938,191.497925 436.744507,193.185165 
	C434.657043,193.884979 432.808716,194.322083 430.960388,194.759186 
z"/>
<path fill="#DBD9B8" opacity="1.000000" stroke="none" 
	d="
M436.983643,192.922455 
	C436.667938,191.497925 436.352234,190.073395 436.017029,188.282043 
	C437.369446,187.448090 438.741364,186.980942 440.480530,186.531982 
	C441.787598,187.800201 442.727478,189.050232 443.596863,190.852142 
	C441.345490,191.910172 439.164551,192.416306 436.983643,192.922455 
z"/>
<path fill="#4B484B" opacity="1.000000" stroke="none" 
	d="
M457.345581,177.112579 
	C458.249817,176.875366 459.450897,176.691406 460.829590,176.776886 
	C459.885620,177.086166 458.764069,177.126007 457.345581,177.112579 
z"/>
<path fill="#CFC891" opacity="1.000000" stroke="none" 
	d="
M459.763123,181.501709 
	C459.209534,181.911621 458.344055,182.207764 457.275879,182.334167 
	C457.865875,181.905609 458.658569,181.646774 459.763123,181.501709 
z"/>
<path fill="#CFC891" opacity="1.000000" stroke="none" 
	d="
M464.801147,180.517212 
	C464.487396,180.813461 463.896332,181.062988 463.143127,181.152313 
	C463.495270,180.818237 464.009552,180.644363 464.801147,180.517212 
z"/>
<path fill="#CFC891" opacity="1.000000" stroke="none" 
	d="
M486.781555,176.314667 
	C486.464081,176.682922 485.870544,176.956360 485.115051,177.057251 
	C485.470551,176.663086 485.988037,176.441467 486.781555,176.314667 
z"/>
<path fill="#DFD9AC" opacity="1.000000" stroke="none" 
	d="
M496.620209,175.365051 
	C496.637543,175.495728 496.326508,175.637894 495.841309,175.680267 
	C495.875336,175.512512 496.083588,175.444534 496.620209,175.365051 
z"/>
<path fill="#2C2728" opacity="1.000000" stroke="none" 
	d="
M322.194946,251.106140 
	C334.167114,243.313629 346.018066,235.325165 358.163055,227.811996 
	C364.157288,224.103806 370.694244,221.272980 377.651703,218.085205 
	C380.551453,217.753067 382.785156,217.380890 385.025940,217.392975 
	C386.020020,218.799530 387.007080,219.821793 387.994110,220.844070 
	C388.989594,219.223450 389.985046,217.602829 390.989655,215.990646 
	C390.998779,215.999084 390.985352,215.983231 391.276794,215.899643 
	C392.041077,215.212677 392.513947,214.609283 392.992218,214.001373 
	C392.997650,213.996857 393.007446,213.986313 393.348633,213.972305 
	C394.803894,213.299698 395.917999,212.641098 397.261475,211.979706 
	C397.490845,211.976913 397.949615,211.981995 398.300812,211.922607 
	C400.097473,210.908325 401.542938,209.953445 403.316803,208.992264 
	C404.083038,208.983871 404.520905,208.981750 405.324371,208.978622 
	C406.788483,208.299728 407.886963,207.621841 409.276001,206.726929 
	C418.717438,203.344040 427.868317,200.178146 437.248169,197.191849 
	C438.315247,196.916534 439.153351,196.461624 440.286316,195.827118 
	C442.731750,194.781555 444.882263,193.915588 447.045532,193.390854 
	C447.753754,194.632919 448.449249,195.533752 449.144775,196.434586 
	C450.088135,195.296677 451.031494,194.158783 452.389343,193.012421 
	C454.185425,192.994415 455.567017,192.984879 457.149414,193.228912 
	C457.347809,194.270294 457.683258,195.407974 457.288391,195.789185 
	C454.684052,198.303467 454.059052,200.730255 456.130615,204.071976 
	C456.539307,204.731155 454.259766,208.399353 453.065430,208.483368 
	C447.060974,208.905838 447.768341,212.764023 448.215851,216.659180 
	C448.367279,217.977203 448.999908,219.314178 448.845520,220.572922 
	C448.680328,221.919128 448.119141,223.481018 447.177155,224.345367 
	C446.765717,224.722855 444.953156,223.651215 443.818481,223.144470 
	C442.060883,222.359543 440.378754,220.950394 438.590485,220.819565 
	C436.860626,220.692978 433.919434,221.401566 433.470764,222.525742 
	C432.274017,225.524216 434.901947,225.702789 437.173676,226.147278 
	C438.384949,226.384262 439.891479,227.533524 440.302460,228.631317 
	C440.601654,229.430496 439.477509,231.678589 438.728333,231.810898 
	C432.079926,232.984955 432.890411,237.896500 432.968445,242.552444 
	C433.040894,246.877182 430.823425,249.001740 426.512909,248.984421 
	C425.013489,248.978394 423.509857,249.074677 422.015289,248.993378 
	C418.378662,248.795624 416.801819,250.506973 416.990295,254.089218 
	C417.077728,255.749939 417.016510,257.421600 416.959778,259.086151 
	C416.829041,262.923767 414.779144,264.708344 410.980377,264.998871 
	C400.994568,265.762604 400.998871,265.819336 400.998749,276.123383 
	C400.998718,276.789856 400.866089,277.488434 401.018585,278.117981 
	C403.446167,288.140411 392.587219,289.906219 389.564575,296.536407 
	C389.471497,296.740601 388.869934,296.662506 388.657349,296.870972 
	C387.353424,298.149475 386.028137,299.416809 384.840729,300.800079 
	C383.999634,301.779968 383.493042,303.746368 382.617096,303.885284 
	C376.096832,304.919373 376.917114,309.724792 376.996246,314.188660 
	C377.076324,318.705231 374.984436,321.052216 370.376709,320.989319 
	C369.044159,320.971130 367.705292,321.080597 366.379211,320.988770 
	C362.594971,320.726807 360.820526,322.317902 360.971039,326.228424 
	C361.124725,330.220978 360.970184,334.224670 360.996063,338.223175 
	C361.017670,341.557617 359.936523,343.929443 356.405060,345.031128 
	C355.081085,345.444153 354.044220,346.959991 353.013336,348.097076 
	C349.348724,352.139282 346.449982,357.644043 341.918060,359.917389 
	C337.306641,362.230652 336.785431,364.833130 337.008667,368.790436 
	C337.249420,373.058167 336.278870,376.337646 331.367920,377.321899 
	C330.422150,377.511444 329.702484,379.122589 329.001007,380.160675 
	C328.184875,381.368439 327.678986,383.598938 326.735352,383.776733 
	C320.135742,385.020203 320.870819,389.859070 320.966583,394.535156 
	C321.055023,398.852753 318.847565,400.997955 314.530334,400.983337 
	C313.197510,400.978851 311.856720,401.092468 310.533203,400.984863 
	C306.432098,400.651581 304.780304,402.502747 304.944153,406.573059 
	C305.043091,409.030273 305.513275,412.501587 304.165771,413.788055 
	C300.637604,417.156464 297.617096,421.762390 293.840363,423.865417 
	C289.393127,426.341766 289.162170,428.894623 288.881592,432.806366 
	C288.705658,435.258881 287.806000,439.445038 286.529022,439.732239 
	C280.063477,441.186493 280.954895,445.813568 281.000763,450.236816 
	C281.047852,454.775909 278.913605,457.063660 274.328522,456.991028 
	C272.996063,456.969910 271.657043,457.081726 270.331024,456.989288 
	C266.508240,456.722778 264.719116,458.402740 264.989990,462.273834 
	C265.082764,463.599823 264.969025,464.938965 264.992859,466.271301 
	C265.074371,470.827454 262.861938,473.068481 258.294037,472.992798 
	C256.795105,472.967957 255.292847,473.059509 253.796097,472.999146 
	C250.421936,472.862946 248.924576,474.448730 248.989716,477.810120 
	C249.070389,481.973694 248.971466,486.140472 248.993927,490.305634 
	C249.012039,493.663879 247.845062,495.974518 244.325867,497.055450 
	C243.015701,497.457916 242.035004,499.046539 240.988174,500.172455 
	C237.168213,504.280792 233.385056,508.423340 229.578949,512.544617 
	C229.478226,512.653625 229.277191,512.700684 229.116394,512.718933 
	C225.316315,513.150024 224.993942,515.766602 224.962692,518.873901 
	C224.866592,528.434021 224.397018,529.071960 215.195053,528.909058 
	C210.287567,528.822266 208.702377,530.738037 208.900223,535.412720 
	C209.007935,537.957642 208.961746,541.910950 207.480988,542.845093 
	C203.786072,545.176147 201.796539,549.091431 198.805038,551.342285 
	C194.304703,554.728394 194.041992,558.385193 193.963287,562.914368 
	C193.908508,566.066589 193.516937,569.212952 193.317017,571.823914 
	C189.679947,571.595947 187.206604,571.440857 184.240128,571.254883 
	C184.105148,571.011780 183.547638,570.007385 182.775391,568.688110 
	C182.372726,566.578064 182.184814,564.782959 182.332336,562.873779 
	C186.001938,558.815125 185.373306,555.053406 181.031860,552.593689 
	C181.333054,544.856506 181.611160,537.573669 181.970551,530.294800 
	C182.495773,519.657532 182.746964,508.991943 183.741684,498.397278 
	C184.558090,489.701599 186.393494,481.105286 187.514420,472.431000 
	C187.712646,470.897064 186.619888,469.196289 186.123291,467.572571 
	C186.187164,466.909698 186.251038,466.246826 186.837570,465.217163 
	C187.892883,463.963470 188.425537,463.076569 189.246994,461.954376 
	C191.555161,456.979492 194.276123,452.349457 191.913574,446.656677 
	C192.209503,445.923401 192.440094,445.448578 193.028442,444.871216 
	C194.085907,443.521515 195.002014,442.347229 195.450165,441.015472 
	C198.347809,432.404510 200.968353,423.696594 204.027084,415.144684 
	C206.530930,408.144196 209.209793,401.171509 212.374298,394.451752 
	C215.658768,387.477264 219.750458,380.887573 223.238052,374.002411 
	C225.425385,369.684204 227.149841,365.131531 229.309570,360.484985 
	C230.781906,357.270233 232.026657,354.254608 233.524796,350.954712 
	C238.044098,347.625977 241.373657,344.082642 240.398041,338.245544 
	C240.398041,338.245544 240.386826,338.020844 240.830139,337.831299 
	C242.146698,337.173309 243.262543,336.909210 243.855789,336.204895 
	C250.090805,328.802765 256.320404,321.393036 262.385681,313.852081 
	C265.677795,309.758942 268.730347,305.463898 271.708008,301.134125 
	C272.386719,300.147247 272.309265,298.640411 272.579834,297.372864 
	C272.579834,297.372864 272.667755,297.119019 273.151855,297.038055 
	C274.424072,296.722534 275.429504,296.720490 275.967072,296.217926 
	C282.272644,290.323212 288.593323,284.438263 294.681915,278.323639 
	C295.757111,277.243866 295.786041,275.122314 296.298157,273.481873 
	C296.298157,273.481873 296.357544,273.319366 296.816833,273.407837 
	C301.737000,272.741943 304.243866,270.110474 304.829468,265.633392 
	C307.526337,263.322662 310.223236,261.011963 313.396667,258.340454 
	C315.230530,256.787659 316.587860,255.595657 318.246033,254.363480 
	C319.762909,253.250916 320.978943,252.178528 322.194946,251.106140 
M443.146729,199.767761 
	C443.097900,200.281403 443.049042,200.795044 443.000183,201.308685 
	C443.405609,201.159302 443.811005,201.009933 444.216431,200.860550 
	C444.001068,200.437485 443.785736,200.014404 443.146729,199.767761 
z"/>
<path fill="#554A32" opacity="1.000000" stroke="none" 
	d="
M295.977386,273.552429 
	C295.786041,275.122314 295.757111,277.243866 294.681915,278.323639 
	C288.593323,284.438263 282.272644,290.323212 275.967072,296.217926 
	C275.429504,296.720490 274.424072,296.722534 273.268188,296.970886 
	C272.999359,296.556122 273.098297,296.127563 273.547913,295.255981 
	C274.229797,294.337830 274.561035,293.862762 274.892242,293.387695 
	C276.987885,291.372528 279.083557,289.357361 281.292114,287.050323 
	C286.155548,282.379974 290.906097,278.001495 295.977386,273.552429 
z"/>
<path fill="#DBD9B8" opacity="1.000000" stroke="none" 
	d="
M317.945160,254.403671 
	C316.587860,255.595657 315.230530,256.787659 313.541779,258.040161 
	C312.176178,257.027618 311.142029,255.954544 310.082001,254.545685 
	C311.314545,253.103592 312.572998,251.997284 314.504150,250.928284 
	C316.099579,252.111618 317.022369,253.257645 317.945160,254.403671 
z"/>
<path fill="#554A32" opacity="1.000000" stroke="none" 
	d="
M304.511719,265.764862 
	C304.243866,270.110474 301.737000,272.741943 296.902710,273.417938 
	C299.084229,270.858521 301.639099,268.377441 304.511719,265.764862 
z"/>
<path fill="#D5D2A8" opacity="1.000000" stroke="none" 
	d="
M318.246033,254.363480 
	C317.022369,253.257645 316.099579,252.111618 314.823669,250.873795 
	C316.302979,249.328812 318.135345,247.875626 320.315582,246.339783 
	C320.999878,247.649048 321.336304,249.041000 321.933838,250.769531 
	C320.978943,252.178528 319.762909,253.250916 318.246033,254.363480 
z"/>
<path fill="#3A281B" opacity="1.000000" stroke="none" 
	d="
M200.990875,693.982300 
	C200.878311,692.830078 201.042374,690.792358 200.611893,690.657898 
	C193.722778,688.505920 197.737778,681.596008 194.794418,677.447510 
	C191.968964,673.465088 191.353043,667.915466 189.763947,663.055298 
	C185.813339,650.972534 181.452042,639.002197 178.100830,626.755432 
	C176.652237,621.461548 177.288620,615.597229 176.973236,609.532104 
	C177.079208,608.109131 177.189819,607.146118 177.636871,605.875000 
	C178.126495,605.680786 178.279663,605.794617 178.379639,606.302063 
	C179.209320,610.467346 180.092239,614.238953 180.965851,618.388306 
	C181.146774,619.493164 181.336975,620.220337 181.455063,621.219604 
	C181.581787,621.997131 181.780640,622.502563 181.971420,623.349304 
	C182.116425,624.114014 182.269501,624.537476 182.398102,625.344971 
	C183.721512,630.077820 185.069397,634.426697 186.351135,639.151489 
	C186.996384,642.003845 187.707794,644.480225 188.345810,647.240356 
	C188.516739,648.014954 188.761063,648.505798 189.045532,649.371704 
	C190.450912,653.824585 191.816147,657.902527 193.173889,662.305908 
	C193.650070,663.317444 194.133743,664.003479 194.573029,665.040955 
	C195.122314,666.917786 195.716019,668.443115 196.291199,670.321472 
	C197.068481,672.339539 197.864304,674.004517 198.660736,676.016235 
	C199.538116,678.565613 200.414886,680.768311 201.278564,683.290649 
	C201.690338,684.319275 202.115219,685.028198 202.491272,685.859070 
	C202.442429,685.981018 202.608963,686.184265 202.647690,686.550659 
	C206.566452,695.495239 210.446487,704.073364 214.003876,712.574829 
	C212.458572,711.986511 211.235901,711.474854 209.975616,710.607239 
	C206.955612,704.828247 203.973236,699.405273 200.990875,693.982300 
z"/>
<path fill="#B7A170" opacity="1.000000" stroke="none" 
	d="
M196.309723,669.968445 
	C195.716019,668.443115 195.122314,666.917786 194.620575,664.692017 
	C194.202133,663.321228 193.691742,662.650818 193.181366,661.980408 
	C191.816147,657.902527 190.450912,653.824585 189.199249,649.200317 
	C189.014938,648.088074 188.717072,647.522339 188.419205,646.956543 
	C187.707794,644.480225 186.996384,642.003845 186.491913,638.694214 
	C185.273422,633.560913 183.848007,629.260864 182.422577,624.960876 
	C182.269501,624.537476 182.116425,624.114014 182.129059,623.186768 
	C182.038910,622.104370 181.783051,621.525940 181.527176,620.947449 
	C181.336975,620.220337 181.146774,619.493164 181.169952,618.098938 
	C180.399841,613.590698 179.416336,609.749512 178.432831,605.908386 
	C178.279663,605.794617 178.126495,605.680786 177.739410,605.402222 
	C177.594711,604.675659 177.683929,604.113708 178.072021,603.070557 
	C178.394485,601.194824 178.021454,599.626465 178.537888,598.447815 
	C179.249390,596.823975 180.632874,595.494568 181.830841,594.396362 
	C181.980392,595.492310 182.027618,596.231018 181.866394,597.213379 
	C182.026932,598.645630 182.395920,599.834229 182.883408,601.336914 
	C183.014313,602.096619 183.026703,602.542175 182.851318,603.191467 
	C182.944641,604.275696 183.225739,605.156128 183.650696,606.348511 
	C183.848206,607.112366 183.901871,607.564209 183.753082,608.291626 
	C184.022247,610.046143 184.493866,611.525208 185.108643,613.324829 
	C185.416779,614.794495 185.581772,615.943481 185.585007,617.342285 
	C185.803650,618.742065 186.184036,619.892029 186.702957,621.350220 
	C186.899139,622.102844 186.956787,622.547424 186.803894,623.277710 
	C187.605698,626.721375 188.618057,629.879395 189.815216,633.359375 
	C190.342697,635.116943 190.685379,636.552490 190.830231,638.244995 
	C191.308426,640.345642 191.984467,642.189453 192.840591,644.376709 
	C193.792389,647.598572 194.564087,650.476990 195.291168,653.674683 
	C195.642242,654.676453 196.037933,655.359009 196.578217,656.355225 
	C196.789551,657.121216 196.856308,657.573608 196.760406,658.273376 
	C197.309540,660.022400 198.021332,661.524170 198.887238,663.286682 
	C199.155655,664.137146 199.269958,664.726746 199.335815,665.658203 
	C199.774902,666.675903 200.262451,667.351868 200.916748,668.362183 
	C202.495926,672.590515 203.908386,676.484558 205.251648,680.826538 
	C204.324631,682.911072 203.466797,684.547668 202.608963,686.184265 
	C202.608963,686.184265 202.442429,685.981018 202.551544,685.517761 
	C202.204315,684.359985 201.747986,683.665527 201.291656,682.971008 
	C200.414886,680.768311 199.538116,678.565613 198.701752,675.623291 
	C197.931320,673.245239 197.120514,671.606873 196.309723,669.968445 
z"/>
<path fill="#B7A170" opacity="1.000000" stroke="none" 
	d="
M173.778900,569.534912 
	C174.590439,567.461548 175.498566,565.886353 175.964844,564.189758 
	C176.579758,561.952271 176.847839,559.619507 177.737518,557.582886 
	C178.432083,561.574341 178.654160,565.310486 178.678986,569.365112 
	C178.868332,572.134949 179.254913,574.586426 179.765060,577.435303 
	C179.971405,579.214478 180.054199,580.596313 179.907562,582.268066 
	C178.274597,584.413330 180.646179,589.330505 175.284302,587.975525 
	C175.323883,587.385132 175.363464,586.794739 175.611481,585.523682 
	C175.406570,583.216614 174.993240,581.590210 174.579910,579.963745 
	C174.279556,576.635498 173.979202,573.307251 173.778900,569.534912 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M175.168915,588.338745 
	C180.646179,589.330505 178.274597,584.413330 180.089249,582.610229 
	C180.909729,586.454651 181.319107,590.246826 181.728485,594.039062 
	C180.632874,595.494568 179.249390,596.823975 178.537888,598.447815 
	C178.021454,599.626465 178.394485,601.194824 178.167114,602.800903 
	C176.993362,598.242188 176.023438,593.472046 175.168915,588.338745 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M174.470001,580.333984 
	C174.993240,581.590210 175.406570,583.216614 175.734131,585.253418 
	C175.218948,584.010620 174.789520,582.357422 174.470001,580.333984 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M172.863892,566.176025 
	C173.259720,566.842285 173.452927,567.702271 173.522156,568.885620 
	C173.152527,568.262634 172.906906,567.316162 172.863892,566.176025 
z"/>
<path fill="#2B292A" opacity="1.000000" stroke="none" 
	d="
M905.002441,384.995117 
	C906.068481,384.997101 907.134521,384.999084 908.600281,385.002380 
	C909.443909,388.317535 910.553284,390.906494 913.138916,393.577850 
	C915.657654,396.179993 916.007202,400.786957 917.647644,404.358154 
	C919.693054,408.811035 922.186401,413.056183 924.304016,417.478333 
	C927.608582,424.378998 930.730652,431.366852 933.986511,438.291199 
	C936.073486,442.729462 936.440552,447.648712 941.730286,450.929321 
	C944.548645,452.677277 945.308167,459.163727 945.380066,463.538025 
	C945.458313,468.293121 943.768372,473.020538 950.356506,474.572388 
	C950.765320,474.668671 951.285156,475.180328 951.381226,475.586700 
	C953.453430,484.353210 959.226379,491.733063 959.231567,501.477295 
	C959.238770,514.844116 960.966309,528.213867 962.099854,541.570190 
	C962.210449,542.873596 963.052673,544.880798 964.017883,545.211487 
	C967.958801,546.562073 968.154236,549.466309 968.022644,552.804993 
	C967.911194,555.632385 967.914001,558.470703 968.013977,561.298950 
	C968.653259,579.386353 969.851746,597.472290 969.852112,615.558716 
	C969.852478,633.145142 968.647034,650.731995 967.935791,668.317139 
	C967.896240,669.295044 967.533508,670.259888 967.160645,671.615356 
	C963.139160,672.748291 959.098083,673.044556 955.509216,674.475098 
	C954.139771,675.020935 953.034424,678.212036 953.146545,680.118896 
	C953.477417,685.747864 951.353638,689.004333 945.445740,689.537598 
	C945.106445,686.144409 945.053223,683.072205 945.000000,679.583374 
	C945.000000,677.777771 945.000000,676.388916 945.348083,674.920532 
	C952.772461,671.882019 948.776428,664.002136 952.163208,659.521667 
	C954.181641,660.608093 955.932983,661.550781 957.684326,662.493408 
	C958.122864,660.315430 958.934448,658.138794 958.942810,655.959167 
	C959.033936,632.147339 959.035522,608.334961 958.934814,584.523132 
	C958.926270,582.496948 958.684509,579.633850 957.394897,578.595459 
	C951.735596,574.038513 952.741821,567.350769 954.875916,563.098572 
	C957.221191,558.425415 960.217712,555.619141 954.369019,552.023743 
	C953.120178,542.193787 951.607788,532.387878 950.708740,522.526001 
	C950.032837,515.111877 950.169128,507.624878 949.867554,500.173431 
	C949.836365,499.402802 949.400208,498.576874 948.956604,497.905731 
	C944.939758,491.828125 941.488525,485.593506 941.937378,477.915253 
	C942.031555,476.304535 941.654907,474.579254 941.123779,473.034485 
	C939.141541,467.269043 936.865601,461.602264 934.969543,455.810211 
	C933.425232,451.092468 932.735596,446.052429 930.834412,441.503235 
	C927.297180,433.039154 923.184387,424.813843 919.251709,416.517334 
	C916.623840,410.973602 913.867432,405.490936 911.220825,399.955933 
	C910.165283,397.748352 909.264099,395.467468 908.268860,393.230530 
	C907.193726,390.813873 906.090820,388.409637 905.000488,385.748779 
	C905.001404,385.330078 905.001892,385.162598 905.002441,384.995117 
z"/>
<path fill="#292728" opacity="1.000000" stroke="none" 
	d="
M945.000000,680.000000 
	C945.053223,683.072205 945.106445,686.144409 945.081055,689.609497 
	C945.001709,695.023743 945.000916,700.044983 944.642822,705.616089 
	C937.845154,707.515503 936.084839,711.724426 936.947449,717.778259 
	C937.271912,720.055115 937.126831,722.425354 936.974976,724.738525 
	C936.601013,730.436768 938.950073,736.882629 932.588440,741.032410 
	C932.100159,741.350891 932.184021,742.567688 932.029114,743.372253 
	C931.347290,746.913757 930.675476,750.457275 929.583984,754.001831 
	C926.445251,754.335815 923.722656,754.667908 921.008789,754.582764 
	C921.274780,752.699036 921.549683,751.235535 921.785828,749.765747 
	C922.624634,744.544556 922.060547,738.730713 928.311646,735.944153 
	C929.190308,735.552551 929.750427,733.721619 929.881042,732.495422 
	C930.682129,724.972595 931.149292,717.410400 932.117859,709.911743 
	C932.381287,707.872559 933.502930,705.311340 935.082031,704.206970 
	C938.358093,701.915710 938.354309,699.963928 936.502197,696.742859 
	C935.496826,694.994568 935.073181,692.124146 935.795837,690.325989 
	C937.565918,685.922058 939.254578,681.026062 945.000000,680.000000 
z"/>
<path fill="#2B292A" opacity="1.000000" stroke="none" 
	d="
M921.000000,755.000000 
	C923.722656,754.667908 926.445251,754.335815 929.976562,753.995361 
	C936.986694,752.912354 937.115540,751.651428 938.193542,745.578003 
	C939.786987,736.599731 934.814636,725.776978 945.000000,719.000000 
	C944.983643,729.080383 945.055725,739.162659 944.848755,749.239136 
	C944.820007,750.635742 943.695923,752.894470 942.598694,753.243286 
	C934.458191,755.830872 932.691406,761.924622 932.964844,769.408813 
	C933.012024,770.701660 932.627075,772.010315 931.887512,773.655823 
	C930.888916,774.000000 930.444458,774.000000 930.000000,773.625000 
	C930.000000,772.500000 930.000000,771.750000 930.154785,770.633179 
	C930.309570,767.691528 930.309570,765.116699 930.309570,762.541931 
	C929.839844,762.455017 929.370117,762.368164 928.900452,762.281250 
	C927.967407,766.118958 926.582214,769.207458 922.000000,766.574585 
	C921.666626,763.766113 921.333313,761.383057 921.000000,758.600037 
	C921.000000,757.133362 921.000000,756.066711 921.000000,755.000000 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M930.000000,774.000000 
	C930.444458,774.000000 930.888916,774.000000 931.666687,774.000000 
	C931.753906,774.807068 931.507812,775.614136 930.741943,776.788696 
	C923.115906,776.499146 920.781006,785.300293 913.993286,785.425720 
	C913.646423,785.432068 913.330383,787.103699 913.000000,788.000000 
	C913.000000,791.030579 913.000000,794.061218 912.807617,797.756714 
	C912.410095,798.947693 912.205017,799.473816 911.577087,800.000366 
	C906.770325,801.000427 902.386353,802.000183 898.073730,802.633545 
	C899.055481,800.365784 899.691162,797.033142 900.921387,796.796997 
	C908.557373,795.330933 908.085815,789.881165 908.130493,784.259460 
	C908.141663,782.851074 909.441345,781.266052 910.507996,780.086853 
	C914.225098,775.977539 918.105774,772.016235 921.895203,767.971375 
	C922.073120,767.781555 921.971497,767.329773 922.000000,767.000000 
	C926.582214,769.207458 927.967407,766.118958 928.900452,762.281250 
	C929.370117,762.368164 929.839844,762.455017 930.309570,762.541931 
	C930.309570,765.116699 930.309570,767.691528 929.869751,770.729370 
	C929.619934,772.128235 929.809937,773.064148 930.000000,774.000000 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M897.000000,811.000000 
	C897.000000,814.692932 897.000000,818.385803 896.752930,822.782104 
	C893.379089,826.197144 890.634338,829.745300 887.022522,831.394470 
	C883.102356,833.184387 878.375244,833.206787 874.069275,833.648926 
	C874.862854,831.817261 875.201294,829.643494 876.383484,828.985413 
	C879.987183,826.979309 881.016174,823.710876 881.963745,820.170959 
	C883.408936,814.771973 885.639648,810.353577 892.621399,811.734497 
	C893.979431,812.003052 895.535339,811.270691 897.000000,811.000000 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M945.000000,718.536804 
	C934.814636,725.776978 939.786987,736.599731 938.193542,745.578003 
	C937.115540,751.651428 936.986694,752.912354 930.392578,753.993530 
	C930.675476,750.457275 931.347290,746.913757 932.029114,743.372253 
	C932.184021,742.567688 932.100159,741.350891 932.588440,741.032410 
	C938.950073,736.882629 936.601013,730.436768 936.974976,724.738525 
	C937.126831,722.425354 937.271912,720.055115 936.947449,717.778259 
	C936.084839,711.724426 937.845154,707.515503 944.642822,706.082947 
	C945.000000,710.024536 945.000000,714.049072 945.000000,718.536804 
z"/>
<path fill="#2B292A" opacity="1.000000" stroke="none" 
	d="
M874.000000,834.000000 
	C878.375244,833.206787 883.102356,833.184387 887.022522,831.394470 
	C890.634338,829.745300 893.379089,826.197144 896.752930,823.242737 
	C898.862000,828.969177 896.961609,831.971802 891.036316,833.143555 
	C886.740051,833.993286 882.581665,833.979309 879.373108,838.877136 
	C876.179871,843.751587 870.337463,846.890625 865.356812,850.496094 
	C865.371277,846.801086 865.685669,843.400513 866.002319,839.596069 
	C865.821045,833.074768 870.344177,834.205200 874.000000,834.000000 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M866.000000,840.000000 
	C865.685669,843.400513 865.371277,846.801086 865.028442,850.600830 
	C865.000000,852.066711 865.000000,853.133362 865.000000,854.600037 
	C862.580200,855.999817 860.217102,857.621460 857.726868,857.848389 
	C854.309387,858.159790 851.507812,858.472412 849.296021,861.676270 
	C848.342651,863.057251 845.682007,863.259583 843.467163,863.652710 
	C847.323853,859.465515 851.492188,855.602783 855.710693,851.795776 
	C856.667236,850.932617 858.020264,850.442688 858.808044,849.470764 
	C861.302734,846.392761 863.616333,843.167969 866.000000,840.000000 
z"/>
<path fill="#2B292A" opacity="1.000000" stroke="none" 
	d="
M913.373779,787.909912 
	C913.330383,787.103699 913.646423,785.432068 913.993286,785.425720 
	C920.781006,785.300293 923.115906,776.499146 930.609863,777.078125 
	C928.886780,785.574280 928.880859,785.483765 919.806091,786.077454 
	C917.757324,786.211426 915.765564,787.215942 913.373779,787.909912 
z"/>
<path fill="#2B292A" opacity="1.000000" stroke="none" 
	d="
M898.002441,803.000000 
	C902.386353,802.000183 906.770325,801.000427 911.577087,800.000305 
	C907.218384,803.597046 902.436768,807.194153 897.406006,810.505615 
	C897.438660,807.813293 897.720581,805.406616 898.002441,803.000000 
z"/>
<path fill="#2B292A" opacity="1.000000" stroke="none" 
	d="
M912.245361,799.937195 
	C912.205017,799.473816 912.410095,798.947693 912.807556,798.210815 
	C912.830261,798.624817 912.660583,799.249573 912.245361,799.937195 
z"/>
<path fill="#292728" opacity="1.000000" stroke="none" 
	d="
M350.375580,880.998779 
	C348.424286,878.391602 347.387512,875.131348 345.170258,873.301086 
	C338.217865,867.561951 330.755371,862.446167 323.612976,856.930359 
	C315.344543,850.544922 307.164490,844.042419 299.059174,837.451721 
	C297.932587,836.535706 297.452911,834.824158 297.119324,833.413940 
	C300.042175,835.231262 302.519440,837.115662 305.163940,839.348022 
	C307.021515,840.130310 308.713593,840.932190 310.401825,840.924133 
	C314.185150,840.906128 317.579376,840.853394 317.158051,846.221130 
	C317.090942,847.075928 318.115936,848.245483 318.931854,848.901794 
	C323.001038,852.174927 327.047028,855.501770 331.338013,858.466980 
	C335.294800,861.201355 339.556000,863.494324 343.675446,865.994385 
	C346.925140,867.966675 352.056183,869.299133 353.022705,872.074890 
	C355.214508,878.369141 359.893463,879.571167 365.032227,881.362793 
	C365.565704,882.540527 365.866516,883.715698 366.596680,884.125183 
	C373.672180,888.093445 380.456085,893.116638 388.056549,895.452454 
	C394.690002,897.491089 399.692596,901.237000 404.709717,905.207092 
	C402.473694,904.966797 400.461121,904.703003 398.599396,904.024963 
	C396.333282,903.199646 394.194183,902.025635 392.000153,901.002441 
	C388.956757,899.419739 385.951599,897.757141 382.859650,896.275757 
	C379.454193,894.643982 375.826599,893.429626 372.556458,891.574097 
	C368.898285,889.498413 365.637665,886.727661 362.003876,884.601501 
	C359.447479,883.105713 356.556610,882.181519 353.405396,881.003540 
	C352.247131,881.003235 351.498535,881.001648 350.375580,880.998779 
z"/>
<path fill="#302E30" opacity="1.000000" stroke="none" 
	d="
M952.998047,465.427917 
	C960.199890,465.296234 960.975281,466.079376 960.997803,473.136810 
	C961.009399,476.779510 960.999390,480.422272 960.998413,484.533142 
	C960.063049,484.387604 958.562744,483.962677 958.284912,483.129913 
	C956.379578,477.419250 954.730347,471.623169 952.998047,465.427917 
z"/>
<path fill="#292728" opacity="1.000000" stroke="none" 
	d="
M465.999298,929.002441 
	C465.999298,929.002441 465.499634,929.001038 465.155975,928.646545 
	C464.155090,927.617188 463.596008,926.516663 462.824219,926.338806 
	C458.866913,925.426514 454.802277,924.948792 450.885681,923.904602 
	C449.124390,923.435059 447.620605,921.999634 446.452637,921.001709 
	C450.496674,921.428467 454.203644,921.430969 457.656219,922.369324 
	C465.533752,924.510376 473.137787,928.081238 481.123627,929.305054 
	C494.515656,931.357239 506.903900,937.490417 520.865356,937.458557 
	C535.878601,937.424194 550.892334,939.140137 565.914429,939.929688 
	C568.524353,940.066895 571.180969,939.110474 573.808655,939.149841 
	C586.424133,939.339172 599.036743,939.708984 611.651245,939.975281 
	C612.799500,939.999512 614.226074,940.135925 615.060852,939.555298 
	C620.328064,935.891052 626.273254,937.260437 631.886841,937.331543 
	C628.591553,939.105286 625.424072,940.583618 622.221252,941.980835 
	C620.512512,942.726379 618.742432,943.331543 617.000000,944.000061 
	C609.759644,944.333374 602.522156,944.787842 595.278015,944.964661 
	C588.501770,945.130005 581.718262,945.000977 574.094177,945.001465 
	C572.500061,945.001831 571.749817,945.002136 570.999512,945.002441 
	C570.555176,945.002136 570.110840,945.001831 568.984985,944.778809 
	C550.845337,943.037903 533.365967,941.723083 515.939697,939.900635 
	C509.060089,939.181152 502.266968,937.473083 495.491089,935.961365 
	C485.633636,933.762146 475.827393,931.333313 465.999298,929.002441 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M617.284973,944.169495 
	C618.742432,943.331543 620.512512,942.726379 622.221252,941.980835 
	C625.424072,940.583618 628.591553,939.105286 632.336792,937.330811 
	C635.599182,937.000061 638.298523,936.999207 641.465759,936.998901 
	C652.287109,935.887512 663.188171,936.412537 672.833801,933.185181 
	C681.786255,930.189758 689.628845,931.872131 698.000000,933.000000 
	C695.107666,936.106934 691.646118,937.449890 687.323914,937.049744 
	C681.897461,936.547302 676.433533,936.140259 670.993225,936.217163 
	C667.932678,936.260376 664.911865,937.684021 661.829712,937.920532 
	C654.742981,938.464355 647.629395,938.910950 640.527100,938.904968 
	C636.439026,938.901428 633.726379,939.867554 633.073181,944.629639 
	C627.856628,944.779602 622.713318,944.559265 617.284973,944.169495 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M466.133606,929.327026 
	C475.827393,931.333313 485.633636,933.762146 495.491089,935.961365 
	C502.266968,937.473083 509.060089,939.181152 515.939697,939.900635 
	C533.365967,941.723083 550.845337,943.037903 568.651733,944.778748 
	C561.128784,945.054871 553.256348,945.201782 545.386963,945.106018 
	C542.948669,945.076416 540.518311,944.054993 538.084351,944.054932 
	C523.288757,944.054443 508.862915,942.041565 494.692871,937.588989 
	C486.360962,934.970947 477.710175,933.373352 469.223755,931.233704 
	C468.172363,930.968567 467.250031,930.191467 466.133606,929.327026 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M446.000000,921.002441 
	C447.620605,921.999634 449.124390,923.435059 450.885681,923.904602 
	C454.802277,924.948792 458.866913,925.426514 462.824219,926.338806 
	C463.596008,926.516663 464.155090,927.617188 464.906158,928.645996 
	C459.587799,928.283386 454.171692,927.594177 448.767792,926.819641 
	C447.985504,926.707581 447.018799,926.388733 446.548645,925.823547 
	C441.906250,920.242859 435.043335,920.543945 428.877136,918.956665 
	C426.998169,918.473022 424.791199,917.999207 423.503845,916.736511 
	C420.536835,913.825989 417.807709,911.447266 413.129456,912.700073 
	C412.145874,912.963562 410.503723,910.767761 409.477295,909.432495 
	C412.526794,909.439087 415.264008,909.718323 418.359497,910.066711 
	C420.144989,910.424683 421.572205,910.713562 423.079590,911.348816 
	C425.222687,913.082764 427.121124,914.893311 429.381836,915.772034 
	C434.492920,917.759033 439.782837,919.285950 445.250000,921.000427 
	C445.666656,921.001404 445.833344,921.001892 446.000000,921.002441 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M392.123505,901.314758 
	C394.194183,902.025635 396.333282,903.199646 398.599396,904.024963 
	C400.461121,904.703003 402.473694,904.966797 405.042908,905.207336 
	C406.110657,905.001099 406.554901,905.001770 407.091217,905.311157 
	C407.788849,906.746521 408.394409,907.873291 408.999390,909.001221 
	C401.575531,910.721558 397.186249,905.549622 392.123505,901.314758 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M571.095947,945.287109 
	C571.749817,945.002136 572.500061,945.001831 573.625549,945.001953 
	C573.064636,945.192261 572.128540,945.382019 571.095947,945.287109 
z"/>
<path fill="#292728" opacity="1.000000" stroke="none" 
	d="
M698.416626,933.000000 
	C689.628845,931.872131 681.786255,930.189758 672.833801,933.185181 
	C663.188171,936.412537 652.287109,935.887512 641.595764,936.649902 
	C643.488708,935.533936 645.684998,934.639893 647.957031,934.025940 
	C654.261108,932.322266 660.800171,931.271606 666.869507,928.969482 
	C674.886108,925.928772 682.451050,922.322388 691.026306,927.464478 
	C692.455017,928.321167 696.212219,927.669250 697.263062,926.382690 
	C702.673401,919.758423 711.190308,919.768066 717.996094,916.232971 
	C723.292175,913.481995 730.139954,913.784485 735.569397,911.199463 
	C738.930725,909.599060 741.627563,906.695618 745.770020,906.228149 
	C749.878357,905.764587 753.983276,904.923584 757.955444,903.781189 
	C759.434814,903.355713 761.574768,901.647095 761.619324,900.456360 
	C761.779053,896.186707 764.257141,894.599609 767.782104,893.888550 
	C771.462646,893.146179 775.251953,892.864014 778.866333,891.902039 
	C781.065308,891.316772 784.282776,890.275391 784.858459,888.660339 
	C786.742004,883.375977 790.776123,882.438232 795.318970,882.102234 
	C798.713623,881.851196 800.627014,880.266663 802.135071,877.214722 
	C802.870239,875.726990 805.484924,873.910889 806.848145,874.191284 
	C816.993591,876.277649 818.797119,865.532227 825.485291,862.468567 
	C825.700806,862.369812 825.642334,861.748291 825.867554,861.583374 
	C827.536499,860.361450 829.487732,857.936768 830.901917,858.210022 
	C841.017700,860.164856 842.856750,849.548462 849.494995,846.389893 
	C849.906921,846.193848 849.788086,845.074097 850.165833,844.571960 
	C850.826599,843.693420 851.635376,842.908875 852.462830,842.176025 
	C856.714111,838.410339 861.019897,834.705200 865.223877,830.887817 
	C865.767639,830.394104 865.692261,829.274292 866.151917,828.618408 
	C866.780823,827.721008 867.546753,826.434021 868.427734,826.274658 
	C876.438354,824.825439 877.355225,817.582581 880.188049,812.000061 
	C882.196167,808.042725 883.000854,804.086487 887.382568,800.853027 
	C890.847412,798.296204 893.830017,793.800476 897.405273,790.479919 
	C897.993286,789.933716 898.127136,788.547302 897.986633,787.622864 
	C896.760925,779.560486 904.265747,776.282837 907.634216,770.905029 
	C910.674805,766.050659 916.437683,762.901306 921.000000,759.000000 
	C921.333313,761.383057 921.666626,763.766113 922.000000,766.574585 
	C921.971497,767.329773 922.073120,767.781555 921.895203,767.971375 
	C918.105774,772.016235 914.225098,775.977539 910.507996,780.086853 
	C909.441345,781.266052 908.141663,782.851074 908.130493,784.259460 
	C908.085815,789.881165 908.557373,795.330933 900.921387,796.796997 
	C899.691162,797.033142 899.055481,800.365784 898.073730,802.633545 
	C897.720581,805.406616 897.438660,807.813293 897.078369,810.609924 
	C895.535339,811.270691 893.979431,812.003052 892.621399,811.734497 
	C885.639648,810.353577 883.408936,814.771973 881.963745,820.170959 
	C881.016174,823.710876 879.987183,826.979309 876.383484,828.985413 
	C875.201294,829.643494 874.862854,831.817261 874.069275,833.648926 
	C870.344177,834.205200 865.821045,833.074768 866.002319,839.596069 
	C863.616333,843.167969 861.302734,846.392761 858.808044,849.470764 
	C858.020264,850.442688 856.667236,850.932617 855.710693,851.795776 
	C851.492188,855.602783 847.323853,859.465515 843.068726,863.654053 
	C840.601318,864.666931 838.202637,865.333862 835.045471,866.072144 
	C831.597595,868.132263 828.987610,870.241089 826.201294,872.083130 
	C821.322937,875.308105 818.508911,881.456909 811.225769,881.480469 
	C808.614319,881.488953 806.028320,884.523743 803.400635,886.127869 
	C801.778198,887.118286 800.164490,888.165649 798.431580,888.923767 
	C794.563416,890.616028 790.616760,892.128052 786.714844,893.744324 
	C785.057373,894.430969 783.375305,895.921448 781.801819,895.798523 
	C774.880371,895.258118 771.400452,898.687195 770.000000,905.000000 
	C769.250000,905.000244 768.500000,905.000488 766.971802,905.002380 
	C763.127258,906.123901 759.244202,906.426514 757.150024,908.519226 
	C751.237610,914.427673 743.614685,914.669861 736.337524,915.851807 
	C732.807495,916.425110 730.850159,917.716492 730.000000,921.000000 
	C729.250000,921.000183 728.500000,921.000366 726.937378,921.000610 
	C714.815247,918.060303 707.732056,923.264587 703.000000,933.000000 
	C701.611084,933.000000 700.222229,933.000000 698.416626,933.000000 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M770.404785,904.998474 
	C771.400452,898.687195 774.880371,895.258118 781.801819,895.798523 
	C783.375305,895.921448 785.057373,894.430969 786.714844,893.744324 
	C790.616760,892.128052 794.563416,890.616028 798.431580,888.923767 
	C800.164490,888.165649 801.778198,887.118286 803.400635,886.127869 
	C806.028320,884.523743 808.614319,881.488953 811.225769,881.480469 
	C818.508911,881.456909 821.322937,875.308105 826.201294,872.083130 
	C828.987610,870.241089 831.597595,868.132263 834.643494,866.072998 
	C834.252502,869.932434 832.934143,873.382019 828.480103,874.541626 
	C827.778687,874.724243 826.881409,875.516663 826.716614,876.185364 
	C825.183960,882.403992 819.870178,882.043030 815.255615,882.987793 
	C813.695801,883.307190 811.148315,883.542664 810.833252,884.441101 
	C808.586731,890.846863 803.006042,890.200439 798.069275,891.032593 
	C797.104919,891.195129 795.681274,891.218811 795.297119,891.828247 
	C792.201050,896.739807 786.626099,896.121704 782.186523,898.007141 
	C780.751831,898.616455 778.765198,899.431396 778.335205,900.622864 
	C776.966431,904.415771 774.155945,904.998474 770.404785,904.998474 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M730.462646,921.000000 
	C730.850159,917.716492 732.807495,916.425110 736.337524,915.851807 
	C743.614685,914.669861 751.237610,914.427673 757.150024,908.519226 
	C759.244202,906.426514 763.127258,906.123901 766.596802,905.002930 
	C761.919861,910.070984 757.432434,916.187073 748.911011,915.153870 
	C748.467285,915.099976 747.701721,915.305603 747.546387,915.618774 
	C743.830322,923.111267 736.905579,920.591125 730.462646,921.000000 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M703.352478,932.930298 
	C707.732056,923.264587 714.815247,918.060303 726.562378,921.001221 
	C724.830383,921.542786 722.554138,922.779724 720.515320,922.467224 
	C717.181702,921.956360 715.793518,923.199097 713.705688,925.655396 
	C711.122192,928.694641 707.103516,930.514099 703.352478,932.930298 
z"/>
<path fill="#8E7857" opacity="1.000000" stroke="none" 
	d="
M660.915955,193.190155 
	C667.072693,195.352783 673.223450,197.532837 679.388977,199.670212 
	C682.177429,200.636902 684.995300,201.518646 688.226868,202.459778 
	C688.653870,202.480606 688.734192,202.434372 688.860413,202.688065 
	C689.667664,203.217606 690.348633,203.493439 691.319336,203.984940 
	C696.054871,206.260834 700.500671,208.321045 704.946472,210.381271 
	C705.375671,210.650177 705.804810,210.919083 706.714294,211.484161 
	C711.708374,214.040085 716.222168,216.299805 720.911499,218.820206 
	C727.938721,223.100418 734.790466,227.119949 741.794312,231.412720 
	C742.632446,232.080872 743.318481,232.475800 744.215942,233.106201 
	C745.492798,233.942856 746.558167,234.544052 747.774231,235.422897 
	C748.616943,236.116364 749.309082,236.532196 750.239441,237.172775 
	C752.475830,238.660049 754.473999,239.922562 756.640015,241.470703 
	C757.503662,242.262054 758.199463,242.767746 759.116943,243.514313 
	C779.791687,258.876709 798.382935,276.036346 815.402771,294.921448 
	C818.489319,298.346283 821.661438,301.694031 824.881287,305.416138 
	C825.308472,306.318726 825.616821,306.904663 825.993164,307.443054 
	C836.696777,322.753265 847.563171,337.952942 858.044739,353.413544 
	C862.660522,360.221985 866.496094,367.559418 870.668518,374.936737 
	C871.011841,375.845032 871.372620,376.473297 871.775635,377.475525 
	C873.498962,381.248871 875.180115,384.648224 876.905029,388.335083 
	C877.262207,389.097992 877.575684,389.573486 877.928467,390.401428 
	C878.892395,392.064392 879.816956,393.374878 880.578003,394.694458 
	C880.414429,394.703522 880.421509,395.031067 880.443237,395.434204 
	C882.752197,401.868408 885.039551,407.899506 887.280518,414.283325 
	C887.980225,416.291840 888.726135,417.947632 889.506592,419.966583 
	C890.174683,422.192352 890.808289,424.054962 891.398560,426.219513 
	C891.794617,427.174652 892.234009,427.827850 892.699463,428.853577 
	C893.502625,432.139069 894.279785,435.052032 895.123291,438.157440 
	C895.310486,438.595245 895.466614,438.815704 895.650452,439.406097 
	C896.114868,441.528259 896.586670,443.255554 897.138245,445.174988 
	C897.357056,445.609161 897.526489,445.827789 897.726074,446.441711 
	C899.704651,456.411774 901.683350,465.963013 903.530151,475.880249 
	C903.860046,477.510803 904.321716,478.775330 904.843628,480.473267 
	C905.541870,486.277618 906.179810,491.648529 906.471802,497.042419 
	C904.710754,498.392151 903.295654,499.718903 901.674072,500.704712 
	C900.610107,491.641235 899.752625,482.918701 898.921997,473.757782 
	C898.586243,470.971619 900.317444,467.722473 896.111450,466.594666 
	C894.640869,460.397522 893.647278,454.471313 891.848511,448.800537 
	C886.732300,432.672363 882.304077,416.215698 875.698425,400.700043 
	C868.510681,383.817047 859.501343,367.669861 850.641235,351.556488 
	C846.604858,344.215668 841.018188,337.727295 836.037659,330.524048 
	C831.369446,324.013977 826.855957,317.787842 822.178040,311.687866 
	C821.253662,310.482422 819.696350,309.762451 818.302979,308.515320 
	C817.249634,307.090668 816.324280,305.969299 815.259827,304.523682 
	C810.410461,299.098755 805.700256,293.998077 800.789673,288.721619 
	C800.162354,288.227386 799.735291,287.908966 799.209656,287.270020 
	C795.828186,283.498047 792.762817,279.802368 789.217529,276.645752 
	C781.661377,269.917938 773.856201,263.469788 765.968018,256.648926 
	C764.915649,255.818359 764.048340,255.245956 763.033386,254.383636 
	C761.374146,253.298096 759.862427,252.502441 758.254944,251.403076 
	C757.469360,250.350235 756.779541,249.601105 755.890747,248.584900 
	C749.483948,244.171265 743.276306,240.024704 736.858276,235.683762 
	C736.119324,235.279205 735.590698,235.069031 734.885498,234.574615 
	C731.157837,232.167831 727.606812,230.045273 723.856079,227.856171 
	C723.656311,227.789642 723.239624,227.729202 723.024780,227.470154 
	C721.774963,226.389328 720.833069,225.397980 719.691101,224.771088 
	C701.510986,214.790970 682.893250,205.777893 663.149658,199.248489 
	C661.664185,198.757233 660.358398,197.722321 659.180176,196.704300 
	C659.899475,195.375687 660.407715,194.282913 660.915955,193.190155 
z"/>
<path fill="#2A292B" opacity="1.000000" stroke="none" 
	d="
M904.993774,384.593323 
	C905.001892,385.162598 905.001404,385.330078 904.665771,385.675537 
	C901.887878,384.235229 899.445190,382.617004 896.978760,380.637390 
	C892.970886,375.850891 888.986694,371.425812 884.932251,366.647339 
	C880.507935,358.653839 876.800415,350.542358 871.652161,343.481323 
	C863.398926,332.161896 854.241943,321.496582 845.313354,310.681610 
	C844.361755,309.529022 842.298645,309.360809 840.912659,308.481445 
	C839.513062,307.593445 838.077454,306.574738 837.069153,305.290222 
	C832.874512,299.946869 833.847290,290.421051 824.190735,289.471497 
	C823.806030,289.433655 823.460876,288.858154 823.122192,288.508942 
	C820.711914,286.023804 818.524414,283.265411 815.854797,281.101349 
	C811.056763,277.211945 805.970642,273.677979 800.886597,269.676544 
	C798.037964,267.619507 795.307739,265.881470 792.577454,264.143433 
	C792.317749,264.503876 792.058105,264.864349 791.798401,265.224792 
	C791.544373,264.807953 791.369812,264.304779 791.024536,263.987305 
	C786.560425,259.882690 782.123596,255.745712 777.563110,251.750122 
	C775.873230,250.269547 773.830811,249.199524 772.035095,247.829697 
	C769.672852,246.027634 767.547302,243.873749 765.050537,242.298141 
	C759.492798,238.790894 753.747864,235.581451 748.139587,232.151917 
	C747.538513,231.784378 747.336182,230.764725 747.318115,230.059601 
	C749.791626,229.716309 751.895874,229.361023 754.000061,229.005753 
	C754.443604,229.005508 754.887085,229.005280 755.663208,229.005829 
	C760.421509,232.345062 764.830872,235.705551 769.283386,239.007889 
	C770.543213,239.942276 771.938965,240.691635 773.255188,241.552170 
	C776.048889,243.378708 778.912903,245.112274 781.591675,247.095215 
	C783.616333,248.593979 785.368103,250.455963 787.293640,252.094879 
	C795.217041,258.838989 803.163940,265.555511 811.090942,272.295319 
	C811.845398,272.936859 812.419434,274.092590 813.250977,274.294647 
	C821.174072,276.219818 824.646362,283.482117 830.149536,288.273529 
	C837.513245,294.684814 844.775757,301.226746 851.802734,308.001953 
	C854.021423,310.141205 855.347778,313.207092 857.077393,315.852264 
	C858.143005,317.482117 858.948792,319.373657 860.306580,320.703217 
	C864.192566,324.508514 868.629272,327.793884 872.232544,331.833893 
	C876.116577,336.188629 879.321960,341.151855 882.775208,345.884796 
	C883.450256,346.809998 883.657349,348.439484 884.499695,348.823029 
	C892.176819,352.318573 893.787903,361.000092 899.500427,366.188995 
	C901.187866,367.721802 902.237549,370.297333 902.855530,372.590149 
	C903.875244,376.373749 904.306152,380.316071 904.993774,384.593323 
z"/>
<path fill="#302E30" opacity="1.000000" stroke="none" 
	d="
M718.995850,213.005280 
	C723.453064,208.662888 726.354370,214.107239 729.968689,214.875626 
	C732.678650,215.451752 735.306091,216.766220 737.746521,218.147263 
	C739.626160,219.210938 741.157898,220.889282 742.566956,222.652893 
	C741.187195,223.662354 740.084839,224.313263 738.654419,224.966400 
	C737.888855,224.966949 737.451233,224.965271 736.896118,224.660431 
	C735.853333,223.909149 734.928223,223.461029 733.701599,222.853912 
	C731.932678,221.129486 730.465271,219.564056 728.998901,217.999542 
	C729.000000,218.000443 729.002441,218.002426 728.863098,217.814728 
	C728.517700,217.390518 728.282349,217.188110 727.700195,216.953613 
	C726.247498,216.272491 725.112244,215.657562 723.605469,215.037369 
	C722.490906,215.021667 721.747864,215.011215 720.818115,214.787292 
	C720.086182,214.050964 719.541016,213.528122 718.995850,213.005280 
z"/>
<path fill="#302E30" opacity="1.000000" stroke="none" 
	d="
M753.999756,228.754547 
	C751.895874,229.361023 749.791626,229.716309 746.997314,230.034088 
	C745.535706,229.667068 744.764221,229.337540 744.143555,228.657486 
	C744.529602,226.538986 744.764771,224.771011 745.000000,223.003052 
	C747.438354,223.617661 749.876770,224.232285 752.673584,225.250671 
	C753.353882,226.436615 753.675720,227.218765 753.997559,228.000916 
	C753.998230,228.168381 753.998840,228.335861 753.999756,228.754547 
z"/>
<path fill="#36271D" opacity="1.000000" stroke="none" 
	d="
M718.613647,212.978607 
	C719.541016,213.528122 720.086182,214.050964 720.805176,215.230499 
	C720.897949,216.777969 720.816956,217.668747 720.735962,218.559525 
	C716.222168,216.299805 711.708374,214.040085 706.969604,211.517334 
	C708.286804,208.275406 710.315796,208.627319 712.754578,210.188034 
	C714.468079,211.284561 716.398071,212.042694 718.613647,212.978607 
z"/>
<path fill="#36271D" opacity="1.000000" stroke="none" 
	d="
M704.948242,209.984802 
	C700.500671,208.321045 696.054871,206.260834 691.203003,203.714325 
	C690.109375,202.963486 689.421814,202.698929 688.734192,202.434372 
	C688.734192,202.434372 688.653870,202.480606 688.615356,202.457672 
	C688.649170,202.184143 688.721436,201.933563 688.899048,201.305420 
	C693.428345,202.356842 697.950500,203.553299 702.216187,205.356522 
	C703.495178,205.897202 704.058716,208.130524 704.948242,209.984802 
z"/>
<path fill="#2C2728" opacity="1.000000" stroke="none" 
	d="
M744.667847,223.000565 
	C744.764771,224.771011 744.529602,226.538986 743.869873,228.488754 
	C741.957764,227.435104 740.470093,226.199646 738.982483,224.964172 
	C740.084839,224.313263 741.187195,223.662354 742.648438,223.001389 
	C743.450134,222.993561 743.892883,222.995819 744.667847,223.000565 
z"/>
<path fill="#393336" opacity="1.000000" stroke="none" 
	d="
M729.134277,211.323822 
	C731.414368,211.329468 733.828674,211.659943 736.621216,211.997345 
	C734.249023,215.656662 731.785156,213.078949 729.134277,211.323822 
z"/>
<path fill="#403B3D" opacity="1.000000" stroke="none" 
	d="
M693.213806,193.298767 
	C694.920471,193.463150 696.843323,193.917892 698.880676,194.686310 
	C697.140076,194.529709 695.285095,194.059433 693.213806,193.298767 
z"/>
<path fill="#393336" opacity="1.000000" stroke="none" 
	d="
M754.292847,227.866043 
	C753.675720,227.218765 753.353882,226.436615 753.014771,225.328766 
	C754.350037,225.126678 755.702515,225.250305 758.349548,225.492249 
	C756.335022,226.691360 755.461609,227.211258 754.292847,227.866043 
z"/>
<path fill="#262428" opacity="1.000000" stroke="none" 
	d="
M200.786438,694.278015 
	C203.973236,699.405273 206.955612,704.828247 210.074677,710.945312 
	C211.807983,716.759583 213.404602,721.879761 215.001221,727.000000 
	C213.518509,725.460938 212.035812,723.921814 210.120804,721.819092 
	C209.778625,715.756348 206.835190,711.485901 204.402512,706.857605 
	C203.032623,704.251221 201.914993,702.784729 199.017670,702.988770 
	C199.017670,702.988770 199.005859,702.994019 199.000732,702.997009 
	C199.001297,702.249329 199.007004,701.498657 199.014328,700.372559 
	C199.537979,698.189270 200.060013,696.381531 200.786438,694.278015 
z"/>
<path fill="#3A3120" opacity="1.000000" stroke="none" 
	d="
M467.990936,185.918732 
	C468.767883,185.548233 469.544830,185.177750 471.058350,184.769562 
	C477.169281,183.740662 482.543671,182.749435 487.918060,181.758209 
	C489.000885,181.683670 490.083679,181.609146 491.574097,181.550018 
	C495.390930,181.339233 498.834503,181.346725 502.203979,180.850662 
	C535.641785,175.927887 568.906921,178.287003 602.853149,183.288361 
	C609.100891,184.483826 614.618896,185.453140 620.136841,186.422455 
	C621.482422,186.736877 622.828064,187.051285 624.814880,187.600983 
	C626.949097,188.241470 628.442139,188.646698 630.055115,189.376755 
	C631.173218,189.929779 632.171265,190.157959 633.493286,190.434723 
	C634.252197,190.522552 634.687256,190.561813 635.211182,190.920074 
	C635.864441,191.548798 636.428772,191.858490 636.723816,192.342285 
	C635.620972,192.697998 634.787476,192.879623 633.536255,193.050629 
	C630.412842,192.699356 627.707092,192.358688 624.615967,192.002991 
	C621.818787,191.325867 619.406982,190.663757 616.620972,189.920486 
	C609.835266,188.886017 603.423706,187.932709 596.796265,186.687164 
	C594.290894,185.605362 592.035706,184.268387 589.705994,184.122726 
	C580.483337,183.546127 571.237366,183.342499 561.760620,182.694275 
	C559.665710,181.930679 557.815308,181.103500 555.953857,181.077957 
	C546.048889,180.941956 536.140686,180.959167 526.234680,181.046631 
	C524.483887,181.062088 522.738281,181.660828 520.567139,181.992371 
	C515.336304,182.976974 510.568726,184.330185 505.711548,184.855759 
	C498.265503,185.661453 490.752686,185.830490 483.278412,186.403076 
	C479.511688,186.691635 475.775238,187.465546 472.008789,187.618423 
	C470.694672,187.671738 469.331635,186.520096 467.990936,185.918732 
z"/>
<path fill="#ADA16F" opacity="1.000000" stroke="none" 
	d="
M629.935120,189.051910 
	C628.442139,188.646698 626.949097,188.241470 625.231323,187.598007 
	C624.379761,185.870850 623.753052,184.381943 623.030334,182.562927 
	C630.572021,184.110138 638.213562,185.972229 645.844116,187.878235 
	C647.282104,188.237442 648.676453,188.771545 650.624695,189.585083 
	C651.894348,190.185974 652.630310,190.426727 653.366211,190.667480 
	C655.673828,191.389236 657.981445,192.111008 660.602539,193.011459 
	C660.407715,194.282913 659.899475,195.375687 658.848999,196.686646 
	C657.866333,196.860931 657.425781,196.817032 656.788391,196.584595 
	C656.070618,196.256302 655.549744,196.116547 654.708740,195.911148 
	C653.962769,195.753708 653.536926,195.661911 652.877747,195.330307 
	C646.803711,193.594025 640.963013,192.097549 635.122253,190.601074 
	C634.687256,190.561813 634.252197,190.522552 633.337769,190.166840 
	C631.883911,189.584198 630.909546,189.318054 629.935120,189.051910 
z"/>
<path fill="#ADA16F" opacity="1.000000" stroke="none" 
	d="
M619.978760,186.132431 
	C614.618896,185.453140 609.100891,184.483826 603.280396,183.279022 
	C609.047974,181.233459 614.477844,183.276031 619.978760,186.132431 
z"/>
<path fill="#ADA16F" opacity="1.000000" stroke="none" 
	d="
M611.039978,179.768799 
	C613.270020,179.927200 615.671997,180.391510 618.345276,181.081207 
	C616.148376,180.895966 613.680115,180.485336 611.039978,179.768799 
z"/>
<path fill="#ADA16F" opacity="1.000000" stroke="none" 
	d="
M579.944702,174.730652 
	C580.424988,174.544830 581.004028,174.572327 581.801270,174.806244 
	C581.360779,174.989761 580.702209,174.966858 579.944702,174.730652 
z"/>
<path fill="#ADA16F" opacity="1.000000" stroke="none" 
	d="
M573.098083,174.084793 
	C573.178894,173.933456 573.353699,173.908371 573.777161,173.974609 
	C574.025879,174.065933 573.637817,174.301483 573.637817,174.301483 
	C573.637817,174.301483 573.192017,174.211060 573.098083,174.084793 
z"/>
<path fill="#8E7857" opacity="1.000000" stroke="none" 
	d="
M653.207886,190.403900 
	C652.630310,190.426727 651.894348,190.185974 651.003540,189.685364 
	C651.582275,189.663757 652.315918,189.902023 653.207886,190.403900 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M233.031799,771.376587 
	C235.666946,775.407471 238.327606,779.819031 240.995056,784.614075 
	C239.155106,783.223450 236.838943,781.725647 235.588348,779.600708 
	C234.228470,777.290039 233.858749,774.396606 233.031799,771.376587 
z"/>
<path fill="#302E30" opacity="1.000000" stroke="none" 
	d="
M199.010849,703.389771 
	C201.914993,702.784729 203.032623,704.251221 204.402512,706.857605 
	C206.835190,711.485901 209.778625,715.756348 209.846191,721.626465 
	C209.835892,721.998352 209.667892,721.999207 209.247803,722.001221 
	C207.726303,720.627869 206.458115,719.252136 205.187073,717.879089 
	C204.031860,716.631104 202.873489,715.386047 201.364410,714.064209 
	C201.006729,713.242737 201.001144,712.496826 200.991058,711.377869 
	C200.509644,709.797791 200.032745,708.590637 199.280869,707.190308 
	C199.005264,705.928284 199.004654,704.859558 199.010849,703.389771 
z"/>
<path fill="#2F2015" opacity="1.000000" stroke="none" 
	d="
M215.000610,727.000000 
	C213.404602,721.879761 211.807983,716.759583 210.112305,711.301270 
	C211.235901,711.474854 212.458572,711.986511 214.010040,712.613403 
	C214.338837,712.728760 214.272247,712.769226 214.224609,713.146851 
	C215.156998,715.013916 216.137024,716.503296 217.370239,718.215088 
	C229.789963,736.453369 241.368042,754.907532 254.337250,772.325806 
	C262.506104,783.296936 272.757812,792.717163 282.238495,803.129150 
	C284.618530,805.903870 286.835266,808.382690 289.154572,811.181091 
	C289.984100,811.917725 290.711090,812.334717 291.733704,812.886780 
	C296.173462,816.407776 300.317596,819.793762 304.588074,823.469971 
	C304.893799,824.139954 305.073212,824.519592 305.023865,825.055908 
	C304.540131,825.478943 304.285126,825.745300 303.697083,826.010315 
	C302.920074,826.009888 302.476074,826.010742 301.689941,826.004761 
	C300.891632,825.997742 300.435455,825.997681 299.655701,825.846680 
	C297.218872,824.083313 294.968750,822.619080 293.023834,820.824036 
	C289.978027,818.012878 287.168823,814.947266 284.201233,812.048950 
	C282.165070,810.060303 279.929810,808.268799 277.963745,806.216492 
	C275.152008,803.281616 273.587555,798.465515 268.006104,800.000000 
	C267.740387,799.509399 267.474640,799.018860 267.110046,797.935669 
	C266.607483,795.569275 266.655823,793.491028 265.707855,792.083923 
	C263.596069,788.949036 261.011261,786.128723 258.574127,783.218018 
	C253.640213,777.325439 248.685364,771.450439 243.721329,765.583252 
	C240.905197,762.254761 237.627365,759.228088 235.306229,755.588013 
	C230.338882,747.798035 225.943527,739.644775 221.075165,731.788269 
	C220.429214,730.745850 218.391129,730.566101 216.997772,729.986816 
	C216.997772,729.986816 216.989471,729.508179 216.903992,728.974121 
	C216.212463,727.960022 215.606415,727.480042 215.000381,727.000000 
	C215.000381,727.000000 215.000000,727.000000 215.000610,727.000000 
z"/>
<path fill="#262428" opacity="1.000000" stroke="none" 
	d="
M217.134277,730.311951 
	C218.391129,730.566101 220.429214,730.745850 221.075165,731.788269 
	C225.943527,739.644775 230.338882,747.798035 235.306229,755.588013 
	C237.627365,759.228088 240.905197,762.254761 243.721329,765.583252 
	C248.685364,771.450439 253.640213,777.325439 258.574127,783.218018 
	C261.011261,786.128723 263.596069,788.949036 265.707855,792.083923 
	C266.655823,793.491028 266.607483,795.569275 266.978943,797.689209 
	C263.880798,797.039490 260.814880,796.043518 257.377991,795.020752 
	C256.423309,793.518555 256.119568,791.835815 255.207443,790.603394 
	C251.267441,785.279602 246.983780,780.205994 243.135178,774.819824 
	C237.480225,766.905640 232.018188,758.850281 226.614655,750.761108 
	C223.587067,746.228760 220.639084,741.615784 218.080383,736.812561 
	C217.166519,735.097107 217.504303,732.714966 217.134277,730.311951 
z"/>
<path fill="#262428" opacity="1.000000" stroke="none" 
	d="
M215.088959,727.289734 
	C215.606415,727.480042 216.212463,727.960022 216.900177,728.734863 
	C216.380432,728.546265 215.778976,728.062866 215.088959,727.289734 
z"/>
<path fill="#252325" opacity="1.000000" stroke="none" 
	d="
M267.723633,800.097656 
	C273.587555,798.465515 275.152008,803.281616 277.963745,806.216492 
	C279.929810,808.268799 282.165070,810.060303 284.201233,812.048950 
	C287.168823,814.947266 289.978027,818.012878 293.023834,820.824036 
	C294.968750,822.619080 297.218872,824.083313 299.732727,826.089722 
	C300.766235,826.326294 301.399139,826.169006 302.032074,826.011597 
	C302.476074,826.010742 302.920074,826.009888 303.700195,826.336792 
	C304.043396,827.100342 304.050476,827.536133 304.187195,828.165283 
	C304.517059,828.602051 304.756409,828.797302 305.363159,829.023071 
	C306.819733,830.371338 307.948029,831.641235 308.710510,832.905518 
	C306.563599,832.391541 304.782532,831.883240 303.060608,831.391846 
	C303.768585,834.173828 304.382660,836.586914 304.996735,839.000000 
	C302.519440,837.115662 300.042175,835.231262 297.281830,833.173462 
	C293.764008,830.382324 290.488739,827.812500 287.311707,825.126587 
	C285.753906,823.809570 284.384705,822.270508 282.918762,820.843628 
	C281.564850,819.526001 280.058014,818.338440 278.858429,816.894226 
	C274.715240,811.906799 270.662628,806.843445 266.653534,801.747620 
	C266.502472,801.555603 267.160309,800.727112 267.723633,800.097656 
z"/>
<path fill="#3D2B1C" opacity="1.000000" stroke="none" 
	d="
M181.830826,594.396362 
	C181.319107,590.246826 180.909729,586.454651 180.318665,582.320312 
	C180.054199,580.596313 179.971405,579.214478 179.838577,577.014343 
	C179.484421,573.812927 179.180328,571.429810 178.876221,569.046692 
	C178.654160,565.310486 178.432083,561.574341 178.083008,557.418335 
	C177.970062,554.296082 177.984146,551.593567 178.058594,547.989868 
	C178.088776,541.061401 178.058609,535.034302 178.051086,528.752563 
	C178.015656,528.337891 177.957581,528.177734 177.899536,528.017578 
	C178.019592,525.983398 178.139648,523.949219 178.390442,521.212830 
	C178.464478,519.661865 178.407806,518.813171 178.351120,517.964417 
	C178.658142,515.618530 178.965149,513.272583 179.391693,510.173035 
	C179.542831,507.934753 179.574448,506.450043 179.606064,504.965363 
	C181.148117,494.635071 182.690170,484.304779 184.469757,473.331299 
	C184.734329,472.117432 184.761383,471.546692 184.788422,470.975983 
	C185.095215,469.992828 185.402008,469.009705 185.916046,467.799561 
	C186.619888,469.196289 187.712646,470.897064 187.514420,472.431000 
	C186.393494,481.105286 184.558090,489.701599 183.741684,498.397278 
	C182.746964,508.991943 182.495773,519.657532 181.970551,530.294800 
	C181.611160,537.573669 181.333054,544.856506 181.019547,553.022949 
	C181.345947,556.934814 181.671417,559.961304 181.996887,562.987793 
	C182.184814,564.782959 182.372726,566.578064 182.775253,569.136169 
	C184.120560,582.348083 185.249588,594.797241 186.391251,607.245178 
	C186.420334,607.562195 186.602936,607.862488 186.689026,608.177368 
	C188.129883,613.448364 189.574509,618.718445 191.002640,623.992920 
	C192.886002,630.948792 194.633713,637.944336 196.670303,644.854919 
	C198.273026,650.293213 200.224930,655.628662 202.016510,661.408875 
	C202.015121,662.873291 202.018967,663.938599 202.106476,665.293884 
	C202.787613,666.067566 203.385101,666.551270 204.054565,667.393066 
	C206.101013,673.852051 208.075470,679.952942 209.897247,686.227234 
	C209.524277,686.617676 209.285706,686.812256 208.858063,686.687744 
	C207.565109,684.386963 206.442978,682.382812 205.320847,680.378601 
	C203.908386,676.484558 202.495926,672.590515 200.926315,668.040405 
	C200.307510,666.695068 199.845871,666.005737 199.384247,665.316406 
	C199.269958,664.726746 199.155655,664.137146 198.930573,662.942871 
	C198.187546,660.900879 197.555283,659.463440 196.923035,658.026062 
	C196.856308,657.573608 196.789551,657.121216 196.658478,656.049683 
	C196.174713,654.738892 195.755249,654.047180 195.335785,653.355469 
	C194.564087,650.476990 193.792389,647.598572 192.871918,644.020142 
	C192.158112,641.542847 191.593079,639.765503 191.028046,637.988098 
	C190.685379,636.552490 190.342697,635.116943 189.833160,632.984192 
	C188.782349,629.188660 187.898376,626.090332 187.014435,622.991943 
	C186.956787,622.547424 186.899139,622.102844 186.777313,620.999634 
	C186.391022,619.258118 186.068893,618.175293 185.746765,617.092529 
	C185.581772,615.943481 185.416779,614.794495 185.165039,612.947388 
	C184.704041,610.838318 184.329788,609.427185 183.955536,608.016113 
	C183.901871,607.564209 183.848206,607.112366 183.742661,606.038269 
	C183.473557,604.606628 183.256317,603.797180 183.039093,602.987732 
	C183.026703,602.542175 183.014313,602.096619 182.951859,600.988159 
	C182.626144,599.206726 182.350494,598.088196 182.074844,596.969666 
	C182.027618,596.231018 181.980392,595.492310 181.830826,594.396362 
z"/>
<path fill="#2F2015" opacity="1.000000" stroke="none" 
	d="
M191.848267,446.915161 
	C194.276123,452.349457 191.555161,456.979492 188.798401,461.945740 
	C187.762009,462.190887 187.462997,462.209442 187.164001,462.227997 
	C187.192123,461.744385 187.220245,461.260773 187.677246,460.197815 
	C188.744171,456.413544 189.382187,453.208618 190.020203,450.003693 
	C190.104691,449.580414 190.189178,449.157166 190.631516,448.359222 
	C191.275681,447.628113 191.561966,447.271637 191.848267,446.915161 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M178.028442,529.007141 
	C178.058609,535.034302 178.088776,541.061401 177.997940,547.542847 
	C177.600647,547.526855 177.324371,547.056519 177.011841,545.965576 
	C176.963638,544.882385 176.951706,544.419800 176.939758,543.957275 
	C176.969650,539.292664 176.999557,534.627991 177.280746,529.488708 
	C177.532028,529.014038 178.028442,529.007141 178.028442,529.007141 
z"/>
<path fill="#554A32" opacity="1.000000" stroke="none" 
	d="
M189.780975,450.113495 
	C189.382187,453.208618 188.744171,456.413544 187.802826,459.854004 
	C187.266586,459.894958 187.121094,459.648682 186.950592,459.163727 
	C186.954895,458.560272 187.071655,458.143860 187.401825,457.194214 
	C187.648468,456.096313 187.681671,455.531616 187.714890,454.966919 
	C187.844421,454.542877 187.973969,454.118805 188.302368,453.155151 
	C188.599243,452.065674 188.697281,451.515839 188.795319,450.966003 
	C189.044144,450.718414 189.292938,450.470856 189.780975,450.113495 
z"/>
<path fill="#B7A170" opacity="1.000000" stroke="none" 
	d="
M179.324265,505.123657 
	C179.574448,506.450043 179.542831,507.934753 179.312790,509.732727 
	C179.090393,508.458008 179.066437,506.869965 179.324265,505.123657 
z"/>
<path fill="#B7A170" opacity="1.000000" stroke="none" 
	d="
M176.649567,543.992126 
	C176.951706,544.419800 176.963638,544.882385 176.961365,545.692078 
	C176.266281,545.708435 175.585434,545.377625 174.904572,545.046753 
	C175.389511,544.706848 175.874435,544.366882 176.649567,543.992126 
z"/>
<path fill="#B7A170" opacity="1.000000" stroke="none" 
	d="
M178.123413,518.108765 
	C178.407806,518.813171 178.464478,519.661865 178.332703,520.779297 
	C178.061386,520.116333 177.978546,519.184692 178.123413,518.108765 
z"/>
<path fill="#3D2B1C" opacity="1.000000" stroke="none" 
	d="
M187.000061,462.468842 
	C187.462997,462.209442 187.762009,462.190887 188.509583,462.180969 
	C188.425537,463.076569 187.892883,463.963470 187.029526,464.941040 
	C186.744583,464.257690 186.790359,463.483673 187.000061,462.468842 
z"/>
<path fill="#B7A170" opacity="1.000000" stroke="none" 
	d="
M184.547241,471.057709 
	C184.761383,471.546692 184.734329,472.117432 184.498840,472.895142 
	C184.295624,472.447906 184.300842,471.793671 184.547241,471.057709 
z"/>
<path fill="#B7A170" opacity="1.000000" stroke="none" 
	d="
M178.051086,528.752563 
	C178.028442,529.007141 177.532028,529.014038 177.284363,529.025085 
	C177.162689,528.759460 177.332779,528.513245 177.723236,528.157532 
	C177.957581,528.177734 178.015656,528.337891 178.051086,528.752563 
z"/>
<path fill="#2F2015" opacity="1.000000" stroke="none" 
	d="
M229.082001,360.684082 
	C227.149841,365.131531 225.425385,369.684204 223.238052,374.002411 
	C219.750458,380.887573 215.658768,387.477264 212.374298,394.451752 
	C209.209793,401.171509 206.530930,408.144196 204.027084,415.144684 
	C200.968353,423.696594 198.347809,432.404510 195.450165,441.015472 
	C195.002014,442.347229 194.085907,443.521515 193.036469,444.591888 
	C192.439606,443.940735 192.192490,443.466309 191.945374,442.991913 
	C192.031525,442.259399 192.117691,441.526855 192.620270,440.292419 
	C193.213623,439.025055 193.390564,438.259644 193.567490,437.494232 
	C193.685257,436.242035 193.803009,434.989807 193.923859,433.359985 
	C195.209579,429.553192 196.492218,426.124023 198.127182,422.281921 
	C198.859665,421.649994 198.916412,421.366272 198.649780,421.017822 
	C198.742783,420.262360 198.835785,419.506866 199.257660,418.194458 
	C199.958847,416.414795 200.331131,415.192047 200.703430,413.969330 
	C202.148727,410.578857 203.594025,407.188354 205.404785,403.283325 
	C207.167221,399.496033 208.564209,396.223328 209.961212,392.950623 
	C210.252090,392.161987 210.542953,391.373322 211.319946,390.185333 
	C212.310135,388.335419 212.814178,386.884796 213.318222,385.434204 
	C214.404083,383.148224 215.489944,380.862274 217.100708,378.173401 
	C219.525543,373.910675 221.425476,370.050842 223.325409,366.191010 
	C223.767792,365.291321 224.210175,364.391632 225.139893,363.146088 
	C225.902161,362.068909 226.177078,361.337585 226.451981,360.606262 
	C226.451981,360.606262 226.475067,360.539032 226.930374,360.596497 
	C227.951126,360.663971 228.516556,360.674011 229.082001,360.684082 
z"/>
<path fill="#4F3D2B" opacity="1.000000" stroke="none" 
	d="
M223.044281,366.324432 
	C221.425476,370.050842 219.525543,373.910675 217.303925,377.877563 
	C218.060104,375.584991 219.137955,373.185394 220.280441,370.427917 
	C221.151077,368.865967 221.957108,367.661926 223.044281,366.324432 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M209.623138,393.046112 
	C208.564209,396.223328 207.167221,399.496033 205.463303,402.912109 
	C206.532608,399.750854 207.908859,396.446228 209.623138,393.046112 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M213.086975,385.660431 
	C212.814178,386.884796 212.310135,388.335419 211.486023,389.916901 
	C211.729218,388.660736 212.292480,387.273712 213.086975,385.660431 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M200.404327,414.116425 
	C200.331131,415.192047 199.958847,416.414795 199.295288,417.815735 
	C199.371109,416.750488 199.738174,415.506989 200.404327,414.116425 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M198.502319,421.143738 
	C198.916412,421.366272 198.859665,421.649994 198.232666,421.906982 
	C198.053223,421.689514 198.176224,421.464417 198.502319,421.143738 
z"/>
<path fill="#554A32" opacity="1.000000" stroke="none" 
	d="
M191.686829,443.226562 
	C192.192490,443.466309 192.439606,443.940735 192.678696,444.694458 
	C192.440094,445.448578 192.209503,445.923401 191.913589,446.656677 
	C191.561966,447.271637 191.275681,447.628113 190.774368,448.065491 
	C190.848999,446.584717 191.138641,445.022949 191.686829,443.226562 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M188.518341,450.988373 
	C188.697281,451.515839 188.599243,452.065674 188.301712,452.821075 
	C187.685577,452.667755 187.268951,452.308929 186.852325,451.950073 
	C187.315353,451.636993 187.778366,451.323883 188.518341,450.988373 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M187.484924,455.052246 
	C187.681671,455.531616 187.648468,456.096313 187.410309,456.867950 
	C187.221893,456.429108 187.238419,455.783325 187.484924,455.052246 
z"/>
<path fill="#554A32" opacity="1.000000" stroke="none" 
	d="
M193.341705,437.573181 
	C193.390564,438.259644 193.213623,439.025055 192.772461,439.953979 
	C192.428345,439.672638 192.348450,439.227753 192.271698,438.438354 
	C192.588760,438.010529 192.869125,437.863312 193.341705,437.573181 
z"/>
<path fill="#554A32" opacity="1.000000" stroke="none" 
	d="
M272.249695,297.478119 
	C272.309265,298.640411 272.386719,300.147247 271.708008,301.134125 
	C268.730347,305.463898 265.677795,309.758942 262.385681,313.852081 
	C256.320404,321.393036 250.090805,328.802765 243.855789,336.204895 
	C243.262543,336.909210 242.146698,337.173309 240.925323,337.771362 
	C247.910202,327.899567 255.141846,317.821411 262.628723,307.936584 
	C265.418701,304.253021 268.805145,301.021179 272.249695,297.478119 
z"/>
<path fill="#554A32" opacity="1.000000" stroke="none" 
	d="
M240.090744,338.398254 
	C241.373657,344.082642 238.044098,347.625977 233.343704,351.044556 
	C232.348969,351.618561 231.788712,351.818451 231.228455,352.018341 
	C234.080124,347.529205 236.931778,343.040070 240.090744,338.398254 
z"/>
<path fill="#4F3D2B" opacity="1.000000" stroke="none" 
	d="
M230.984772,352.273254 
	C231.788712,351.818451 232.348969,351.618561 233.090317,351.328827 
	C232.026657,354.254608 230.781906,357.270233 229.309570,360.484985 
	C228.516556,360.674011 227.951126,360.663971 226.965881,360.595215 
	C227.944412,357.867035 229.342743,355.197571 230.984772,352.273254 
z"/>
<path fill="#D5D2A8" opacity="1.000000" stroke="none" 
	d="
M274.532837,293.308594 
	C274.561035,293.862762 274.229797,294.337830 273.625732,294.934204 
	C272.909698,294.670593 272.466522,294.285736 272.023346,293.900848 
	C272.740021,293.677063 273.456726,293.453278 274.532837,293.308594 
z"/>
<path fill="#554A32" opacity="1.000000" stroke="none" 
	d="
M226.162109,360.714417 
	C226.177078,361.337585 225.902161,362.068909 225.321930,362.902954 
	C225.301819,362.277954 225.587021,361.550293 226.162109,360.714417 
z"/>
<path fill="#322818" opacity="1.000000" stroke="none" 
	d="
M456.948639,192.975342 
	C455.567017,192.984879 454.185425,192.994415 451.977783,193.008636 
	C449.778748,193.025436 448.405792,193.037537 447.032806,193.049622 
	C444.882263,193.915588 442.731750,194.781555 439.975555,195.880249 
	C438.586334,196.412735 437.802765,196.712494 437.019196,197.012268 
	C427.868317,200.178146 418.717438,203.344040 408.936493,206.757019 
	C407.190552,207.662628 406.074646,208.321136 404.958771,208.979630 
	C404.520905,208.981750 404.083038,208.983871 402.972290,209.057129 
	C400.849457,210.079529 399.399536,211.030762 397.949615,211.981995 
	C397.949615,211.981995 397.490845,211.976913 396.898682,211.987869 
	C395.206787,212.661316 394.107117,213.323807 393.007446,213.986298 
	C393.007446,213.986313 392.997650,213.996857 392.703613,214.089249 
	C391.934845,214.782166 391.460114,215.382706 390.985352,215.983231 
	C390.985352,215.983231 390.998779,215.999084 390.594666,215.988708 
	C388.466644,216.321808 386.742767,216.665253 385.018860,217.008698 
	C382.785156,217.380890 380.551453,217.753067 377.985321,217.977325 
	C385.741058,213.837082 393.664429,209.453903 401.961121,205.956207 
	C411.308960,202.015381 420.993011,198.872086 430.745605,195.070526 
	C432.808716,194.322083 434.657043,193.884979 436.744507,193.185165 
	C439.164551,192.416306 441.345490,191.910172 443.781311,191.203293 
	C444.835907,190.886368 445.635559,190.770187 447.010498,190.538727 
	C449.040283,189.884171 450.494751,189.344894 451.949219,188.805634 
	C452.410400,188.758118 452.871552,188.710587 453.936523,188.595367 
	C455.329865,188.272995 456.119385,188.018311 456.908905,187.763611 
	C459.300171,187.364716 461.691437,186.965820 464.435730,186.976624 
	C462.175385,189.249329 459.562012,191.112335 456.948639,192.975342 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M457.149414,193.228943 
	C459.562012,191.112335 462.175385,189.249329 464.845581,187.021362 
	C465.714111,186.355042 466.525757,186.053680 467.664185,185.835510 
	C469.331635,186.520096 470.694672,187.671738 472.008789,187.618423 
	C475.775238,187.465546 479.511688,186.691635 483.278412,186.403076 
	C490.752686,185.830490 498.265503,185.661453 505.711548,184.855759 
	C510.568726,184.330185 515.336304,182.976974 520.916077,182.216797 
	C531.387085,182.626923 541.087524,182.764679 550.783813,183.042526 
	C552.864990,183.102158 554.931580,183.668335 556.684814,184.216095 
	C550.912598,185.488861 545.460571,186.546692 539.482422,187.706604 
	C541.811279,189.209000 543.632568,190.383972 545.453857,191.558929 
	C545.386292,192.106216 545.318787,192.653503 545.251221,193.200790 
	C542.288635,193.467468 539.330261,193.887527 536.362610,193.969177 
	C530.000427,194.144165 529.054749,194.964951 529.009766,201.190933 
	C528.966431,207.186584 528.952026,213.183121 529.004395,219.178574 
	C529.038696,223.106812 527.240173,225.068405 523.264771,224.997543 
	C521.433960,224.964890 519.294983,224.438965 517.845764,225.193375 
	C515.959717,226.175262 514.168762,227.974472 513.192505,229.864044 
	C512.442078,231.316513 512.934631,233.455582 513.015076,235.283127 
	C513.154663,238.451614 512.518250,240.693619 508.699707,241.301834 
	C507.345795,241.517487 505.500153,243.472824 505.255005,244.874588 
	C504.637604,248.405090 502.609833,249.141769 499.604889,249.005402 
	C498.275330,248.945084 496.935822,249.074005 495.608948,248.989471 
	C490.988190,248.695114 488.799103,250.708496 488.969788,255.448532 
	C489.125488,259.773010 489.153442,264.118103 488.888519,268.432678 
	C488.800446,269.867249 487.821838,272.287384 486.921387,272.451416 
	C483.062805,273.154419 480.946503,274.978973 480.589111,278.946381 
	C480.533569,279.562622 479.559967,280.533813 478.941925,280.590637 
	C474.977600,280.955078 472.960327,283.010681 472.585022,286.957825 
	C472.526459,287.574066 471.544495,288.549286 470.930817,288.596588 
	C465.037842,289.050598 464.884857,293.056427 464.957001,297.546967 
	C465.075378,304.912781 464.913208,305.045105 457.354431,304.966400 
	C453.101288,304.922150 449.144318,304.918365 448.697632,310.637024 
	C448.645508,311.304016 447.840057,312.356445 447.243317,312.466217 
	C442.531952,313.333069 441.874420,316.683411 441.768555,320.640259 
	C441.671112,324.283112 441.122314,327.914520 441.039948,331.557007 
	C440.972839,334.522858 440.464447,336.564636 436.929901,337.265961 
	C435.443024,337.560944 434.361938,339.799805 433.050629,341.109222 
	C431.690033,342.467926 430.176941,343.685364 428.908295,345.121552 
	C427.509125,346.705444 425.851135,348.300659 425.161530,350.201385 
	C424.519287,351.971558 424.933502,354.152130 425.003967,356.147705 
	C425.121735,359.482025 423.646484,361.072327 420.246216,361.007019 
	C416.084137,360.927032 411.918518,361.046478 407.755371,360.999481 
	C403.791229,360.954742 402.083588,362.868469 401.963593,366.800446 
	C401.816956,371.605927 401.538727,376.432129 400.846588,381.180756 
	C400.645874,382.557983 398.945129,383.825867 397.734528,384.926666 
	C393.679291,388.614014 389.440857,392.106232 385.508698,395.916840 
	C384.778198,396.624756 385.039764,398.461273 385.021179,399.779449 
	C384.964783,403.775970 384.872070,407.778595 385.027435,411.769684 
	C385.179657,415.679138 383.405701,417.205170 379.621460,417.023163 
	C376.630493,416.879272 373.626923,416.994843 370.628937,417.000580 
	C362.941101,417.015320 362.906586,417.011688 361.865997,424.411713 
	C361.473877,427.200531 361.129425,430.012543 361.036865,432.822998 
	C360.906097,436.793274 361.238068,439.791748 355.897644,441.964050 
	C352.237122,443.453003 349.865509,448.927460 345.752899,451.778473 
	C344.312164,452.777252 345.053284,457.103668 345.027344,459.912628 
	C345.001221,462.743530 344.276794,464.509735 341.074799,465.233215 
	C339.539459,465.580139 337.593292,467.489410 337.246704,469.012726 
	C336.526581,472.177612 334.844147,473.088898 331.974670,473.005920 
	C329.644958,472.938507 327.311218,472.972595 324.979889,473.006287 
	C318.076477,473.106110 317.326050,475.291534 319.663361,481.657898 
	C321.160278,485.735138 320.854828,490.802185 320.029419,495.174835 
	C319.821228,496.277863 314.542755,496.728210 311.556488,496.936127 
	C306.103577,497.315857 305.099121,498.204742 305.001556,503.993042 
	C304.852478,512.837219 304.852936,512.877930 295.909149,512.980896 
	C292.516479,513.019958 289.396240,513.274963 289.074860,517.648743 
	C288.953064,519.306335 288.886566,520.988708 289.024658,522.640625 
	C289.378784,526.877747 288.116486,529.168762 283.266266,529.127930 
	C278.887634,529.091064 277.429321,531.990234 278.512390,535.714172 
	C279.723419,539.877991 280.540741,544.239380 280.778259,548.556091 
	C280.856323,549.974854 278.859558,552.344727 277.342163,552.839233 
	C274.232025,553.852844 272.979156,555.580688 273.010010,558.629578 
	C273.026886,560.294861 272.905762,561.966675 273.013336,563.624817 
	C273.258881,567.411255 271.674561,569.179260 267.768250,569.027405 
	C263.777130,568.872314 259.774414,568.956177 255.777863,569.012146 
	C250.140549,569.091003 249.276215,570.149719 248.961060,575.776001 
	C248.602341,582.180115 247.757401,588.560608 247.535782,594.965637 
	C247.489532,596.302429 249.243256,597.701416 250.133530,599.463745 
	C249.282211,608.563477 249.964844,609.575684 258.471436,612.947998 
	C260.144684,613.611328 261.976288,614.335388 263.183411,615.570435 
	C264.169159,616.578918 265.004913,618.573059 264.674408,619.797424 
	C264.331207,621.068604 262.223389,623.014648 261.380981,622.792542 
	C258.389191,622.003906 255.492294,620.613403 252.803741,619.034851 
	C251.724701,618.401245 251.278656,616.689514 250.500168,615.394775 
	C246.564331,617.251404 246.646942,619.918579 247.670105,622.976929 
	C242.435684,621.571899 241.465729,624.083984 239.569977,628.265930 
	C237.477600,632.881714 232.470505,636.231689 228.561752,639.953186 
	C224.608688,643.716858 220.410126,647.222046 216.423981,650.952454 
	C215.960205,651.386536 215.844131,652.659973 216.108353,653.331177 
	C218.557144,659.551086 213.318359,663.879150 212.220993,669.214417 
	C212.045425,670.067932 210.675247,671.257263 209.876358,671.242798 
	C209.020691,671.227356 207.868759,670.130554 207.417313,669.221985 
	C206.694809,667.767700 206.769867,665.864746 205.904343,664.548157 
	C204.972366,663.130493 203.345459,662.169800 202.021759,661.009766 
	C200.224930,655.628662 198.273026,650.293213 196.670303,644.854919 
	C194.633713,637.944336 192.886002,630.948792 191.002640,623.992920 
	C189.574509,618.718445 188.129883,613.448364 186.689026,608.177368 
	C186.602936,607.862488 186.420334,607.562195 186.391251,607.245178 
	C185.249588,594.797241 184.120560,582.348083 182.989990,569.451050 
	C183.547638,570.007385 184.105148,571.011780 184.240128,571.254883 
	C187.206604,571.440857 189.679947,571.595947 193.317017,571.823914 
	C193.516937,569.212952 193.908508,566.066589 193.963287,562.914368 
	C194.041992,558.385193 194.304703,554.728394 198.805038,551.342285 
	C201.796539,549.091431 203.786072,545.176147 207.480988,542.845093 
	C208.961746,541.910950 209.007935,537.957642 208.900223,535.412720 
	C208.702377,530.738037 210.287567,528.822266 215.195053,528.909058 
	C224.397018,529.071960 224.866592,528.434021 224.962692,518.873901 
	C224.993942,515.766602 225.316315,513.150024 229.116394,512.718933 
	C229.277191,512.700684 229.478226,512.653625 229.578949,512.544617 
	C233.385056,508.423340 237.168213,504.280792 240.988174,500.172455 
	C242.035004,499.046539 243.015701,497.457916 244.325867,497.055450 
	C247.845062,495.974518 249.012039,493.663879 248.993927,490.305634 
	C248.971466,486.140472 249.070389,481.973694 248.989716,477.810120 
	C248.924576,474.448730 250.421936,472.862946 253.796097,472.999146 
	C255.292847,473.059509 256.795105,472.967957 258.294037,472.992798 
	C262.861938,473.068481 265.074371,470.827454 264.992859,466.271301 
	C264.969025,464.938965 265.082764,463.599823 264.989990,462.273834 
	C264.719116,458.402740 266.508240,456.722778 270.331024,456.989288 
	C271.657043,457.081726 272.996063,456.969910 274.328522,456.991028 
	C278.913605,457.063660 281.047852,454.775909 281.000763,450.236816 
	C280.954895,445.813568 280.063477,441.186493 286.529022,439.732239 
	C287.806000,439.445038 288.705658,435.258881 288.881592,432.806366 
	C289.162170,428.894623 289.393127,426.341766 293.840363,423.865417 
	C297.617096,421.762390 300.637604,417.156464 304.165771,413.788055 
	C305.513275,412.501587 305.043091,409.030273 304.944153,406.573059 
	C304.780304,402.502747 306.432098,400.651581 310.533203,400.984863 
	C311.856720,401.092468 313.197510,400.978851 314.530334,400.983337 
	C318.847565,400.997955 321.055023,398.852753 320.966583,394.535156 
	C320.870819,389.859070 320.135742,385.020203 326.735352,383.776733 
	C327.678986,383.598938 328.184875,381.368439 329.001007,380.160675 
	C329.702484,379.122589 330.422150,377.511444 331.367920,377.321899 
	C336.278870,376.337646 337.249420,373.058167 337.008667,368.790436 
	C336.785431,364.833130 337.306641,362.230652 341.918060,359.917389 
	C346.449982,357.644043 349.348724,352.139282 353.013336,348.097076 
	C354.044220,346.959991 355.081085,345.444153 356.405060,345.031128 
	C359.936523,343.929443 361.017670,341.557617 360.996063,338.223175 
	C360.970184,334.224670 361.124725,330.220978 360.971039,326.228424 
	C360.820526,322.317902 362.594971,320.726807 366.379211,320.988770 
	C367.705292,321.080597 369.044159,320.971130 370.376709,320.989319 
	C374.984436,321.052216 377.076324,318.705231 376.996246,314.188660 
	C376.917114,309.724792 376.096832,304.919373 382.617096,303.885284 
	C383.493042,303.746368 383.999634,301.779968 384.840729,300.800079 
	C386.028137,299.416809 387.353424,298.149475 388.657349,296.870972 
	C388.869934,296.662506 389.471497,296.740601 389.564575,296.536407 
	C392.587219,289.906219 403.446167,288.140411 401.018585,278.117981 
	C400.866089,277.488434 400.998718,276.789856 400.998749,276.123383 
	C400.998871,265.819336 400.994568,265.762604 410.980377,264.998871 
	C414.779144,264.708344 416.829041,262.923767 416.959778,259.086151 
	C417.016510,257.421600 417.077728,255.749939 416.990295,254.089218 
	C416.801819,250.506973 418.378662,248.795624 422.015289,248.993378 
	C423.509857,249.074677 425.013489,248.978394 426.512909,248.984421 
	C430.823425,249.001740 433.040894,246.877182 432.968445,242.552444 
	C432.890411,237.896500 432.079926,232.984955 438.728333,231.810898 
	C439.477509,231.678589 440.601654,229.430496 440.302460,228.631317 
	C439.891479,227.533524 438.384949,226.384262 437.173676,226.147278 
	C434.901947,225.702789 432.274017,225.524216 433.470764,222.525742 
	C433.919434,221.401566 436.860626,220.692978 438.590485,220.819565 
	C440.378754,220.950394 442.060883,222.359543 443.818481,223.144470 
	C444.953156,223.651215 446.765717,224.722855 447.177155,224.345367 
	C448.119141,223.481018 448.680328,221.919128 448.845520,220.572922 
	C448.999908,219.314178 448.367279,217.977203 448.215851,216.659180 
	C447.768341,212.764023 447.060974,208.905838 453.065430,208.483368 
	C454.259766,208.399353 456.539307,204.731155 456.130615,204.071976 
	C454.059052,200.730255 454.684052,198.303467 457.288391,195.789185 
	C457.683258,195.407974 457.347809,194.270294 457.149414,193.228943 
z"/>
<path fill="#CFC891" opacity="1.000000" stroke="none" 
	d="
M487.611938,181.557510 
	C482.543671,182.749435 477.169281,183.740662 471.374939,184.677399 
	C476.405273,183.534210 481.855560,182.445511 487.611938,181.557510 
z"/>
<path fill="#CFC891" opacity="1.000000" stroke="none" 
	d="
M451.710327,188.654297 
	C450.494751,189.344894 449.040283,189.884171 447.252777,190.345795 
	C448.436981,189.679749 449.954193,189.091370 451.710327,188.654297 
z"/>
<path fill="#CFC891" opacity="1.000000" stroke="none" 
	d="
M456.709198,187.599335 
	C456.119385,188.018311 455.329865,188.272995 454.234406,188.408798 
	C454.788818,188.004959 455.649170,187.720016 456.709198,187.599335 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M182.332336,562.873779 
	C181.671417,559.961304 181.345947,556.934814 181.032806,553.479065 
	C185.373306,555.053406 186.001938,558.815125 182.332336,562.873779 
z"/>
<path fill="#312C2D" opacity="1.000000" stroke="none" 
	d="
M385.025940,217.392975 
	C386.742767,216.665253 388.466644,216.321808 390.585541,215.980286 
	C389.985046,217.602829 388.989594,219.223450 387.994110,220.844070 
	C387.007080,219.821793 386.020020,218.799530 385.025940,217.392975 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M447.045532,193.390854 
	C448.405792,193.037537 449.778748,193.025436 451.563293,193.017105 
	C451.031494,194.158783 450.088135,195.296677 449.144775,196.434586 
	C448.449249,195.533752 447.753754,194.632919 447.045532,193.390854 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M398.300812,211.922607 
	C399.399536,211.030762 400.849457,210.079529 402.643921,209.063416 
	C401.542938,209.953445 400.097473,210.908325 398.300812,211.922607 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M443.358551,199.679550 
	C443.785736,200.014404 444.001068,200.437485 444.216431,200.860550 
	C443.811005,201.009933 443.405609,201.159302 443.000183,201.308685 
	C443.049042,200.795044 443.097900,200.281403 443.358551,199.679550 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M393.348633,213.972290 
	C394.107117,213.323807 395.206787,212.661316 396.669312,211.990662 
	C395.917999,212.641098 394.803894,213.299698 393.348633,213.972290 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M405.324371,208.978622 
	C406.074646,208.321136 407.190552,207.662628 408.645935,206.974030 
	C407.886963,207.621841 406.788483,208.299728 405.324371,208.978622 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M437.248169,197.191864 
	C437.802765,196.712494 438.586334,196.412735 439.680664,196.059845 
	C439.153351,196.461624 438.315247,196.916534 437.248169,197.191864 
z"/>
<path fill="#312C2D" opacity="1.000000" stroke="none" 
	d="
M391.276794,215.899643 
	C391.460114,215.382706 391.934845,214.782166 392.698181,214.093781 
	C392.513947,214.609283 392.041077,215.212677 391.276794,215.899643 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M205.251648,680.826538 
	C206.442978,682.382812 207.565109,684.386963 208.835754,687.058105 
	C219.889191,712.462769 233.359894,735.731384 249.721420,757.235840 
	C256.935425,766.717468 265.009735,775.544495 272.713257,784.752319 
	C272.733093,784.835876 272.887543,784.911133 273.015564,785.236572 
	C277.508514,790.342041 281.873413,795.122009 285.910889,799.970337 
	C284.414032,800.970215 283.244629,801.901672 282.075195,802.833130 
	C272.757812,792.717163 262.506104,783.296936 254.337250,772.325806 
	C241.368042,754.907532 229.789963,736.453369 217.331726,717.873047 
	C216.117432,715.795593 215.194839,714.282410 214.272247,712.769226 
	C214.272247,712.769226 214.338837,712.728760 214.332672,712.690125 
	C210.446487,704.073364 206.566452,695.495239 202.647690,686.550659 
	C203.466797,684.547668 204.324631,682.911072 205.251648,680.826538 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M182.398102,625.344971 
	C183.848007,629.260864 185.273422,633.560913 186.558075,638.318237 
	C185.069397,634.426697 183.721512,630.077820 182.398102,625.344971 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M178.379623,606.302063 
	C179.416336,609.749512 180.399841,613.590698 181.179230,617.721191 
	C180.092239,614.238953 179.209320,610.467346 178.379623,606.302063 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M196.291199,670.321472 
	C197.120514,671.606873 197.931320,673.245239 198.701126,675.276611 
	C197.864304,674.004517 197.068481,672.339539 196.291199,670.321472 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M201.278564,683.290649 
	C201.747986,683.665527 202.204315,684.359985 202.600372,685.395813 
	C202.115219,685.028198 201.690338,684.319275 201.278564,683.290649 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M193.173874,662.305908 
	C193.691742,662.650818 194.202133,663.321228 194.664963,664.340576 
	C194.133743,664.003479 193.650070,663.317444 193.173874,662.305908 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M188.345810,647.240356 
	C188.717072,647.522339 189.014938,648.088074 189.159103,648.825256 
	C188.761063,648.505798 188.516739,648.014954 188.345810,647.240356 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M181.455063,621.219604 
	C181.783051,621.525940 182.038910,622.104370 182.137131,622.845459 
	C181.780640,622.502563 181.581787,621.997131 181.455063,621.219604 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M186.803879,623.277710 
	C187.898376,626.090332 188.782349,629.188660 189.648376,632.662231 
	C188.618057,629.879395 187.605698,626.721375 186.803879,623.277710 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M190.830231,638.244995 
	C191.593079,639.765503 192.158112,641.542847 192.691818,643.676758 
	C191.984467,642.189453 191.308426,640.345642 190.830231,638.244995 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M183.753082,608.291626 
	C184.329788,609.427185 184.704041,610.838318 185.021881,612.626831 
	C184.493866,611.525208 184.022247,610.046143 183.753082,608.291626 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M196.760406,658.273376 
	C197.555283,659.463440 198.187546,660.900879 198.776459,662.682068 
	C198.021332,661.524170 197.309540,660.022400 196.760406,658.273376 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M185.585007,617.342285 
	C186.068893,618.175293 186.391022,619.258118 186.638794,620.691467 
	C186.184036,619.892029 185.803650,618.742065 185.585007,617.342285 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M181.866394,597.213379 
	C182.350494,598.088196 182.626144,599.206726 182.833344,600.674011 
	C182.395920,599.834229 182.026932,598.645630 181.866394,597.213379 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M182.851318,603.191467 
	C183.256317,603.797180 183.473557,604.606628 183.598816,605.726318 
	C183.225739,605.156128 182.944641,604.275696 182.851318,603.191467 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M199.335815,665.658203 
	C199.845871,666.005737 200.307510,666.695068 200.759583,667.706116 
	C200.262451,667.351868 199.774902,666.675903 199.335815,665.658203 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M195.291168,653.674683 
	C195.755249,654.047180 196.174713,654.738892 196.513901,655.736084 
	C196.037933,655.359009 195.642242,654.676453 195.291168,653.674683 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M178.679001,569.365112 
	C179.180328,571.429810 179.484421,573.812927 179.715012,576.616943 
	C179.254913,574.586426 178.868332,572.134949 178.679001,569.365112 
z"/>
<path fill="#262425" opacity="1.000000" stroke="none" 
	d="
M921.000000,758.600037 
	C916.437683,762.901306 910.674805,766.050659 907.634216,770.905029 
	C904.265747,776.282837 896.760925,779.560486 897.986633,787.622864 
	C898.127136,788.547302 897.993286,789.933716 897.405273,790.479919 
	C893.830017,793.800476 890.847412,798.296204 887.382568,800.853027 
	C883.000854,804.086487 882.196167,808.042725 880.188049,812.000061 
	C877.355225,817.582581 876.438354,824.825439 868.427734,826.274658 
	C867.546753,826.434021 866.780823,827.721008 866.151917,828.618408 
	C865.692261,829.274292 865.767639,830.394104 865.223877,830.887817 
	C861.019897,834.705200 856.714111,838.410339 852.462830,842.176025 
	C851.635376,842.908875 850.826599,843.693420 850.165833,844.571960 
	C849.788086,845.074097 849.906921,846.193848 849.494995,846.389893 
	C842.856750,849.548462 841.017700,860.164856 830.901917,858.210022 
	C829.487732,857.936768 827.536499,860.361450 825.867554,861.583374 
	C825.642334,861.748291 825.700806,862.369812 825.485291,862.468567 
	C818.797119,865.532227 816.993591,876.277649 806.848145,874.191284 
	C805.484924,873.910889 802.870239,875.726990 802.135071,877.214722 
	C800.627014,880.266663 798.713623,881.851196 795.318970,882.102234 
	C790.776123,882.438232 786.742004,883.375977 784.858459,888.660339 
	C784.282776,890.275391 781.065308,891.316772 778.866333,891.902039 
	C775.251953,892.864014 771.462646,893.146179 767.782104,893.888550 
	C764.257141,894.599609 761.779053,896.186707 761.619324,900.456360 
	C761.574768,901.647095 759.434814,903.355713 757.955444,903.781189 
	C753.983276,904.923584 749.878357,905.764587 745.770020,906.228149 
	C741.627563,906.695618 738.930725,909.599060 735.569397,911.199463 
	C730.139954,913.784485 723.292175,913.481995 717.996094,916.232971 
	C711.190308,919.768066 702.673401,919.758423 697.263062,926.382690 
	C696.212219,927.669250 692.455017,928.321167 691.026306,927.464478 
	C682.451050,922.322388 674.886108,925.928772 666.869507,928.969482 
	C660.800171,931.271606 654.261108,932.322266 647.957031,934.025940 
	C645.684998,934.639893 643.488708,935.533936 641.127808,936.649292 
	C638.298523,936.999207 635.599182,937.000061 632.449951,937.001709 
	C626.273254,937.260437 620.328064,935.891052 615.060852,939.555298 
	C614.226074,940.135925 612.799500,939.999512 611.651245,939.975281 
	C599.036743,939.708984 586.424133,939.339172 573.808655,939.149841 
	C571.180969,939.110474 568.524353,940.066895 565.914429,939.929688 
	C550.892334,939.140137 535.878601,937.424194 520.865356,937.458557 
	C506.903900,937.490417 494.515656,931.357239 481.123627,929.305054 
	C473.137787,928.081238 465.533752,924.510376 457.656219,922.369324 
	C454.203644,921.430969 450.496674,921.428467 446.452637,921.001709 
	C445.833344,921.001892 445.666656,921.001404 445.174438,920.660034 
	C443.727142,918.920349 442.868011,916.737671 441.438171,916.258667 
	C435.384338,914.230713 429.160889,912.709106 422.999420,911.002441 
	C421.572205,910.713562 420.144989,910.424683 418.242676,909.734558 
	C414.178131,907.889648 410.588654,906.446045 406.999176,905.002441 
	C406.554901,905.001770 406.110657,905.001099 405.333191,905.000244 
	C399.692596,901.237000 394.690002,897.491089 388.056549,895.452454 
	C380.456085,893.116638 373.672180,888.093445 366.596680,884.125183 
	C365.866516,883.715698 365.565704,882.540527 365.467468,881.360352 
	C371.055725,880.220398 374.985931,882.831787 379.001587,885.577637 
	C382.907379,888.248413 386.892212,890.974731 391.203308,892.844055 
	C398.948059,896.202209 407.996887,897.506409 414.610504,902.284973 
	C423.411133,908.643799 434.442902,907.133911 443.027222,912.865173 
	C443.502960,913.182739 444.333405,912.968933 445.078857,913.348877 
	C446.902863,915.069275 448.460602,917.183350 450.425751,917.677490 
	C456.198151,919.129150 462.132355,919.937378 468.377625,920.997070 
	C469.500977,920.996094 470.249878,920.995605 471.227295,921.249939 
	C477.982300,928.237183 486.972351,927.376526 495.089233,928.878052 
	C502.471924,930.243774 510.043884,930.579712 517.529297,931.398254 
	C528.376404,932.584534 539.207642,934.648071 550.068848,934.813477 
	C570.173584,935.119751 590.295471,934.341064 610.408936,933.947571 
	C612.041443,933.915649 613.655701,933.314270 615.292603,933.074829 
	C622.257751,932.056091 628.721313,926.824890 636.352600,930.785645 
	C637.416260,931.337708 639.441833,930.134583 640.985779,929.632324 
	C642.238770,929.224670 643.639893,927.966980 644.626709,928.259949 
	C650.708008,930.065796 655.420044,928.968384 660.656494,924.760254 
	C664.145813,921.956299 670.009399,921.938293 674.879761,921.056396 
	C685.242249,919.179993 695.725952,917.218994 704.122986,910.571655 
	C707.964966,907.530212 711.815186,907.739685 715.883057,906.741028 
	C721.509583,905.359741 729.022827,907.340332 731.950317,899.479614 
	C732.284973,898.580872 734.163208,898.163574 735.394714,897.710693 
	C738.493591,896.571167 742.232788,896.275696 744.634644,894.316895 
	C750.390930,889.622192 757.268860,888.200989 763.903320,885.728149 
	C769.295166,883.718445 773.647461,879.119080 779.822205,878.552979 
	C780.215393,878.516968 780.519531,877.857605 780.927490,877.579773 
	C784.016541,875.476257 787.319885,873.627136 790.137024,871.209900 
	C791.544861,870.001892 792.073608,867.769226 793.322632,865.783936 
	C795.762756,865.378418 797.880188,865.188904 800.342102,864.832520 
	C803.415222,864.110168 806.127502,863.223083 808.876770,863.088013 
	C811.901367,862.939392 814.434631,862.734192 815.633240,859.327942 
	C816.039612,858.172852 817.151428,856.900879 818.260742,856.419983 
	C823.629028,854.092590 824.690308,849.100830 825.806824,844.312134 
	C826.535645,841.186279 827.861206,840.002014 830.994690,840.749939 
	C834.568726,841.603210 837.382996,840.479919 839.847717,837.649048 
	C845.685303,830.944519 851.564575,824.257690 857.796692,817.927795 
	C859.430115,816.268677 862.399597,815.209351 864.782471,815.159485 
	C869.134277,815.068359 870.949524,813.824524 871.215881,809.140137 
	C871.382629,806.209229 873.470825,803.285034 875.054138,800.565857 
	C876.676819,797.778931 879.156433,795.440125 880.491089,792.549683 
	C882.009705,789.260803 882.440186,785.605652 887.106750,784.827393 
	C888.230164,784.640076 889.037659,782.065125 889.825806,780.505005 
	C890.553284,779.065063 890.946716,777.455139 891.685486,776.022339 
	C893.689819,772.135132 895.830750,768.318481 897.849304,764.438354 
	C898.515808,763.157104 898.601501,761.097839 899.593323,760.546448 
	C903.331360,758.468140 903.241516,755.528564 902.551331,751.990112 
	C901.680786,747.526917 902.883545,744.200012 907.944031,742.935608 
	C909.389343,742.574402 911.027954,740.985718 911.606812,739.564453 
	C912.908691,736.368042 912.545959,731.647156 914.746826,729.826172 
	C920.735352,724.871155 919.542053,718.513611 918.773682,712.830383 
	C917.413574,702.771484 926.332397,696.952515 927.564270,688.178467 
	C927.594788,687.961243 928.068909,687.646790 928.336731,687.646179 
	C932.982300,687.635254 931.741211,684.130066 931.998291,681.520874 
	C932.628479,675.123840 933.354187,668.734131 933.855164,662.326965 
	C934.501221,654.063538 944.221680,646.781006 936.536194,637.624939 
	C937.160095,631.022278 936.835083,624.151672 938.607849,617.873413 
	C942.158691,605.298218 943.856384,592.613159 944.023682,579.625610 
	C944.067993,576.190552 943.995850,572.661011 944.738464,569.345459 
	C946.884949,559.762085 945.196411,550.544800 942.717834,541.358093 
	C942.482727,540.486816 941.723755,539.582642 940.958252,539.058228 
	C937.149109,536.449097 937.732056,532.916687 938.611450,529.212097 
	C939.177856,526.826355 939.931458,524.392212 939.925842,521.981323 
	C939.910095,515.163269 939.829529,508.767944 936.599182,501.929474 
	C933.139526,494.605530 933.363953,485.542236 931.972168,477.239502 
	C931.808411,476.262360 931.687622,475.262878 931.383606,474.326813 
	C928.842224,466.504089 926.152527,458.727692 923.729126,450.869019 
	C921.780457,444.549713 920.585022,437.988861 915.114563,433.314453 
	C914.014099,432.374146 914.071594,430.045776 913.657288,428.342285 
	C912.732300,424.539764 912.479065,420.417664 910.799316,416.990845 
	C908.151001,411.588379 903.951660,406.916626 901.535095,401.438721 
	C899.265198,396.293152 898.454956,390.503693 897.351929,385.089905 
	C899.976990,388.648102 902.728027,391.896088 904.435425,395.622314 
	C908.856262,405.270630 913.161743,415.003601 916.804749,424.962006 
	C918.433716,429.414764 921.885315,432.659912 923.522827,436.597015 
	C927.767212,446.801575 930.839111,457.521393 933.933472,468.165039 
	C935.481323,473.489227 935.857849,479.142883 937.130493,484.561462 
	C938.217590,489.190308 937.608276,494.736786 943.371765,497.250275 
	C944.235413,497.626923 944.855652,499.509308 944.874268,500.706268 
	C944.941406,505.022156 944.523010,509.347260 944.630127,513.660400 
	C944.740234,518.093201 944.981018,522.556885 945.643616,526.934570 
	C946.916992,535.347656 949.202515,543.659912 949.825378,552.105591 
	C950.566345,562.153809 950.227234,572.305298 949.922424,582.399048 
	C949.708618,589.476685 948.598450,596.524536 948.266541,603.603394 
	C948.176880,605.515015 949.105530,607.727234 950.198303,609.385193 
	C952.077698,612.236511 952.935486,629.844116 950.960266,632.625793 
	C950.769714,632.894226 950.570374,633.227173 950.294312,633.353516 
	C946.211731,635.221313 946.909607,638.447144 947.674194,641.866577 
	C948.351013,644.893188 948.308960,647.667297 945.049561,649.712158 
	C943.902405,650.431824 943.180237,652.791748 943.265808,654.347656 
	C943.644958,661.242737 944.388428,668.117737 945.000000,675.000000 
	C945.000000,676.388916 945.000000,677.777771 945.000000,679.583374 
	C939.254578,681.026062 937.565918,685.922058 935.795837,690.325989 
	C935.073181,692.124146 935.496826,694.994568 936.502197,696.742859 
	C938.354309,699.963928 938.358093,701.915710 935.082031,704.206970 
	C933.502930,705.311340 932.381287,707.872559 932.117859,709.911743 
	C931.149292,717.410400 930.682129,724.972595 929.881042,732.495422 
	C929.750427,733.721619 929.190308,735.552551 928.311646,735.944153 
	C922.060547,738.730713 922.624634,744.544556 921.785828,749.765747 
	C921.549683,751.235535 921.274780,752.699036 921.008789,754.582703 
	C921.000000,756.066711 921.000000,757.133362 921.000000,758.600037 
z"/>
<path fill="#292728" opacity="1.000000" stroke="none" 
	d="
M945.348083,674.920532 
	C944.388428,668.117737 943.644958,661.242737 943.265808,654.347656 
	C943.180237,652.791748 943.902405,650.431824 945.049561,649.712158 
	C948.308960,647.667297 948.351013,644.893188 947.674194,641.866577 
	C946.909607,638.447144 946.211731,635.221313 950.294312,633.353516 
	C950.570374,633.227173 950.769714,632.894226 950.960266,632.625793 
	C952.935486,629.844116 952.077698,612.236511 950.198303,609.385193 
	C949.105530,607.727234 948.176880,605.515015 948.266541,603.603394 
	C948.598450,596.524536 949.708618,589.476685 949.922424,582.399048 
	C950.227234,572.305298 950.566345,562.153809 949.825378,552.105591 
	C949.202515,543.659912 946.916992,535.347656 945.643616,526.934570 
	C944.981018,522.556885 944.740234,518.093201 944.630127,513.660400 
	C944.523010,509.347260 944.941406,505.022156 944.874268,500.706268 
	C944.855652,499.509308 944.235413,497.626923 943.371765,497.250275 
	C937.608276,494.736786 938.217590,489.190308 937.130493,484.561462 
	C935.857849,479.142883 935.481323,473.489227 933.933472,468.165039 
	C930.839111,457.521393 927.767212,446.801575 923.522827,436.597015 
	C921.885315,432.659912 918.433716,429.414764 916.804749,424.962006 
	C913.161743,415.003601 908.856262,405.270630 904.435425,395.622314 
	C902.728027,391.896088 899.976990,388.648102 897.351196,384.715515 
	C896.999878,383.500031 896.998718,382.751221 896.998657,381.751526 
	C897.000671,381.333344 897.001587,381.166046 897.002441,380.998779 
	C899.445190,382.617004 901.887878,384.235229 904.665344,385.926758 
	C906.090820,388.409637 907.193726,390.813873 908.268860,393.230530 
	C909.264099,395.467468 910.165283,397.748352 911.220825,399.955933 
	C913.867432,405.490936 916.623840,410.973602 919.251709,416.517334 
	C923.184387,424.813843 927.297180,433.039154 930.834412,441.503235 
	C932.735596,446.052429 933.425232,451.092468 934.969543,455.810211 
	C936.865601,461.602264 939.141541,467.269043 941.123779,473.034485 
	C941.654907,474.579254 942.031555,476.304535 941.937378,477.915253 
	C941.488525,485.593506 944.939758,491.828125 948.956604,497.905731 
	C949.400208,498.576874 949.836365,499.402802 949.867554,500.173431 
	C950.169128,507.624878 950.032837,515.111877 950.708740,522.526001 
	C951.607788,532.387878 953.120178,542.193787 954.369019,552.023743 
	C960.217712,555.619141 957.221191,558.425415 954.875916,563.098572 
	C952.741821,567.350769 951.735596,574.038513 957.394897,578.595459 
	C958.684509,579.633850 958.926270,582.496948 958.934814,584.523132 
	C959.035522,608.334961 959.033936,632.147339 958.942810,655.959167 
	C958.934448,658.138794 958.122864,660.315430 957.684326,662.493408 
	C955.932983,661.550781 954.181641,660.608093 952.163208,659.521667 
	C948.776428,664.002136 952.772461,671.882019 945.348083,674.920532 
z"/>
<path fill="#292728" opacity="1.000000" stroke="none" 
	d="
M930.000000,773.625000 
	C929.809937,773.064148 929.619934,772.128235 929.714966,771.096191 
	C930.000000,771.750000 930.000000,772.500000 930.000000,773.625000 
z"/>
<path fill="#1E1C1D" opacity="1.000000" stroke="none" 
	d="
M470.998779,920.995117 
	C470.249878,920.995605 469.500977,920.996094 468.254425,920.671265 
	C460.864197,915.749146 453.748383,911.844116 444.998779,913.002441 
	C444.333405,912.968933 443.502960,913.182739 443.027222,912.865173 
	C434.442902,907.133911 423.411133,908.643799 414.610504,902.284973 
	C407.996887,897.506409 398.948059,896.202209 391.203308,892.844055 
	C386.892212,890.974731 382.907379,888.248413 379.001587,885.577637 
	C374.985931,882.831787 371.055725,880.220398 365.434021,881.002441 
	C359.893463,879.571167 355.214508,878.369141 353.022705,872.074890 
	C352.056183,869.299133 346.925140,867.966675 343.675446,865.994385 
	C339.556000,863.494324 335.294800,861.201355 331.338013,858.466980 
	C327.047028,855.501770 323.001038,852.174927 318.931854,848.901794 
	C318.115936,848.245483 317.090942,847.075928 317.158051,846.221130 
	C317.579376,840.853394 314.185150,840.906128 310.401825,840.924133 
	C308.713593,840.932190 307.021515,840.130310 305.163940,839.348022 
	C304.382660,836.586914 303.768585,834.173828 303.060608,831.391846 
	C304.782532,831.883240 306.563599,832.391541 309.079285,832.948364 
	C313.720551,835.689087 317.606018,838.412659 321.538330,841.066895 
	C329.412262,846.381592 337.311829,851.658264 345.224304,857.302979 
	C346.609558,859.582031 348.179535,863.282654 349.299622,863.151184 
	C357.376892,862.203003 362.531891,867.822144 368.629150,871.098755 
	C373.749359,873.850342 378.220337,877.835388 383.394012,880.453857 
	C387.412292,882.487427 392.128021,883.098938 396.417511,884.661560 
	C398.235260,885.323669 399.878448,886.657593 401.386841,887.938354 
	C405.259003,891.226013 408.096710,896.043640 414.831757,893.375366 
	C417.206970,892.434326 420.759583,894.354431 423.743835,895.092651 
	C426.720947,895.829102 429.636414,896.819946 432.621002,897.519775 
	C436.087433,898.332642 439.657135,898.744934 443.078217,899.702026 
	C447.906403,901.052734 452.548462,903.177795 457.431702,904.200134 
	C467.898651,906.391418 478.469147,908.088379 488.692688,910.201477 
	C483.327148,909.799316 478.265900,909.185974 473.205353,908.566772 
	C468.658600,908.010498 464.065796,907.017517 459.783569,910.165833 
	C463.705017,911.512634 467.665985,911.928040 471.601440,912.514709 
	C473.880005,912.854370 476.130280,913.418274 478.369934,913.975220 
	C480.902069,914.605042 483.369202,915.607239 485.932587,915.980225 
	C493.946198,917.146301 501.992859,918.084290 510.023132,919.138062 
	C510.671356,919.223145 511.282135,919.561584 511.927338,919.706665 
	C522.295776,922.038574 532.710388,923.662109 543.424316,922.911621 
	C547.293884,922.640503 551.225586,923.339844 555.133057,923.487610 
	C556.737549,923.548279 558.685669,923.872070 559.907043,923.133240 
	C565.896484,919.510254 572.010681,920.841187 578.209412,922.060669 
	C579.778687,922.369324 581.457397,922.566406 583.025879,922.359558 
	C590.877319,921.324097 598.691345,919.991943 606.552368,919.045410 
	C614.092834,918.137512 621.679382,917.617981 629.234009,916.817688 
	C630.520081,916.681458 631.741516,915.973083 633.002991,915.559937 
	C638.650146,913.710266 644.250610,911.693237 649.964600,910.079102 
	C655.778259,908.436829 661.689270,907.123535 667.593018,905.827148 
	C669.126587,905.490356 670.809265,905.899109 672.393311,905.711182 
	C675.322693,905.363403 678.589966,905.514526 681.065491,904.216492 
	C685.229431,902.033081 688.650269,898.429993 692.817932,896.256836 
	C697.209290,893.967102 702.001465,891.621399 706.808899,891.117737 
	C718.546265,889.888000 726.989990,881.599670 737.535950,877.995422 
	C739.704346,877.254395 741.853088,876.424927 743.937927,875.476074 
	C748.642334,873.335083 753.378784,871.239136 757.929932,868.802979 
	C759.252808,868.094849 759.992798,866.297852 761.385559,864.922668 
	C766.480164,863.048279 771.771301,862.081177 775.772461,859.274902 
	C783.220703,854.050720 789.442261,847.064087 798.578979,844.362427 
	C799.545288,844.076660 800.090088,842.584045 801.043762,841.999573 
	C805.512451,839.261169 809.853088,836.149902 814.653870,834.183716 
	C817.234680,833.126831 817.776001,832.001831 818.089539,829.659912 
	C818.972168,823.068726 826.153625,816.055725 832.537292,814.853577 
	C834.191956,814.541931 835.771118,813.337585 837.165039,812.258972 
	C838.867310,810.941833 840.117249,808.472595 841.950073,807.998474 
	C847.346802,806.602356 848.031250,802.125183 848.721985,798.098511 
	C849.282776,794.829102 849.948730,792.968811 853.597229,792.606873 
	C854.775269,792.489990 855.939392,790.591614 856.808655,789.304688 
	C859.683594,785.048645 862.409058,780.691833 865.231812,776.399963 
	C865.485840,776.013672 865.994202,775.647705 866.441772,775.558044 
	C871.698303,774.504883 872.853577,770.124695 873.843750,765.958801 
	C874.561218,762.940247 875.732117,761.054077 878.923706,761.382202 
	C878.046692,764.640564 876.877380,767.473083 876.530151,770.403015 
	C875.986389,774.991089 873.885376,777.144775 869.172974,777.405151 
	C867.834351,777.479187 865.548828,779.565491 865.502625,780.796692 
	C865.184692,789.266785 857.434937,792.537659 853.508118,798.419495 
	C849.912903,803.804443 846.710693,809.593994 840.495850,813.039673 
	C835.866821,815.606079 832.424561,820.358154 827.752014,822.786743 
	C823.936584,824.769897 821.933533,826.825134 821.990417,831.000183 
	C822.055298,835.752136 819.600952,837.725891 814.922424,838.144287 
	C812.693665,838.343628 809.692566,839.498657 808.622375,841.209778 
	C805.759888,845.786194 801.993469,847.900269 796.789001,848.224915 
	C795.834717,848.284485 794.957275,849.455200 794.029968,850.092041 
	C791.980957,851.499207 789.922791,852.893127 787.868164,854.292297 
	C786.655823,855.117981 784.646545,855.732117 784.362122,856.803833 
	C782.717285,863.002808 777.789612,863.435669 772.767456,864.169312 
	C769.235840,864.685181 766.682495,866.739807 767.517822,870.635742 
	C768.340942,874.474792 766.752380,875.430237 763.387329,876.319275 
	C759.525085,877.339661 755.891968,879.303101 752.249695,881.045715 
	C750.597229,881.836304 749.294800,883.444153 747.606812,884.060242 
	C739.399109,887.056030 731.018677,889.596436 722.887756,892.779114 
	C716.360229,895.334106 710.138977,898.661926 703.706299,901.473816 
	C701.142578,902.594543 698.424438,903.469604 695.692261,904.077515 
	C686.515503,906.119507 678.513245,913.408569 668.111511,910.216553 
	C667.092957,909.904114 665.604736,910.877625 664.411133,911.422119 
	C659.516541,913.654968 654.833740,916.577576 649.728821,918.066101 
	C643.577698,919.859741 637.102051,920.630249 630.723022,921.534485 
	C617.124695,923.461975 603.531616,925.535767 589.869812,926.864563 
	C582.168823,927.613647 574.334595,926.936523 566.562012,927.034729 
	C563.753662,927.070190 560.391357,928.611572 558.244812,927.538818 
	C550.455200,923.645874 542.515991,926.459839 534.688232,925.996704 
	C523.314270,925.323853 511.938416,924.675049 500.572815,923.880798 
	C499.166595,923.782593 497.854919,922.535095 496.436279,922.373291 
	C491.305420,921.787842 486.147644,921.438416 480.605438,920.993042 
	C478.478241,920.517090 476.755463,919.736145 475.009735,919.680908 
	C473.687927,919.639099 472.336853,920.523132 470.998779,920.995117 
z"/>
<path fill="#292728" opacity="1.000000" stroke="none" 
	d="
M423.079590,911.348816 
	C429.160889,912.709106 435.384338,914.230713 441.438171,916.258667 
	C442.868011,916.737671 443.727142,918.920349 444.924438,920.659668 
	C439.782837,919.285950 434.492920,917.759033 429.381836,915.772034 
	C427.121124,914.893311 425.222687,913.082764 423.079590,911.348816 
z"/>
<path fill="#292728" opacity="1.000000" stroke="none" 
	d="
M407.091217,905.311157 
	C410.588654,906.446045 414.178131,907.889648 417.884399,909.665405 
	C415.264008,909.718323 412.526794,909.439087 409.394775,909.079956 
	C408.394409,907.873291 407.788849,906.746521 407.091217,905.311157 
z"/>
<path fill="#403B3D" opacity="1.000000" stroke="none" 
	d="
M625.001404,192.018021 
	C627.707092,192.358688 630.412842,192.699356 633.628235,193.367432 
	C635.170349,194.866379 636.202759,196.037918 637.208923,197.179703 
	C633.626160,202.489273 636.051025,207.649231 642.752747,208.193970 
	C642.148071,204.307434 641.560242,200.529099 640.929321,196.474030 
	C643.079041,196.634384 645.153809,196.789139 647.567383,197.014038 
	C650.898560,198.364014 653.891052,199.643845 657.084717,201.088959 
	C657.900513,201.138977 658.515015,201.023712 659.516846,200.908356 
	C664.337402,202.633301 668.829956,204.222443 673.185913,206.124680 
	C676.933777,207.761383 680.363098,210.761581 684.219910,211.384735 
	C692.636414,212.744659 699.077942,217.995178 706.527954,221.187057 
	C712.028687,223.543762 716.319519,228.999100 723.239624,227.729202 
	C723.239624,227.729202 723.656311,227.789642 723.935059,228.186417 
	C725.672180,230.140350 726.885925,232.125900 728.656250,233.136963 
	C730.513550,234.197708 732.904419,234.324127 735.062134,234.858856 
	C735.590698,235.069031 736.119324,235.279205 736.909912,236.036438 
	C738.326721,237.916321 739.315125,239.456345 740.667725,240.543015 
	C744.706238,243.787384 748.887390,246.854050 752.609863,249.985138 
	C750.261414,249.568283 748.238586,249.370911 746.385132,248.703476 
	C741.241821,246.851395 740.905273,247.164825 740.708618,252.409943 
	C740.648621,254.011597 739.183044,255.638580 738.143555,257.099121 
	C737.405518,258.136108 735.743347,258.765320 735.460999,259.832428 
	C734.367065,263.967590 731.291931,263.980621 728.014099,264.013306 
	C720.127808,264.091980 720.357300,264.137085 719.863892,272.064514 
	C719.708557,274.559601 718.464844,278.592804 716.849121,279.088989 
	C712.062622,280.558960 712.076416,283.607544 711.866394,287.466583 
	C711.725647,290.052277 711.019470,293.958527 709.322571,294.817749 
	C706.110413,296.444305 704.919800,299.220825 702.764038,301.344543 
	C699.534119,304.526642 695.576721,306.928650 693.291931,311.135254 
	C692.943237,311.777222 691.425049,311.700378 690.568237,312.173920 
	C689.741882,312.630585 688.535706,313.280792 688.417175,313.998138 
	C687.453857,319.827728 683.345398,320.120422 678.689087,320.044373 
	C672.343323,319.940765 672.338013,320.398010 671.933228,326.898743 
	C671.285645,337.297638 674.410889,348.073029 668.553955,358.124664 
	C654.893127,358.301727 641.694092,358.379211 628.162842,358.271759 
	C622.465393,357.542908 618.125305,358.721344 614.275635,363.201691 
	C604.460693,374.624512 593.997681,385.487885 584.034912,396.787445 
	C578.363098,403.220306 573.164124,410.070068 567.454102,416.880341 
	C562.737305,421.614532 558.261597,426.147278 553.915649,430.801208 
	C549.487244,435.543488 545.372681,439.737000 537.684814,439.403442 
	C530.369385,439.086029 522.882751,440.675842 515.613281,442.134399 
	C511.524078,442.954834 508.275574,443.485199 506.226379,438.582611 
	C506.200989,436.444305 506.251709,434.729156 506.610474,432.814667 
	C507.213196,430.691162 507.757324,428.767944 507.764313,426.842743 
	C507.838959,406.256287 507.828613,385.669525 508.193298,365.125549 
	C509.816437,365.546936 511.087860,365.925629 512.435181,366.326935 
	C513.272949,360.037231 512.596008,359.389893 507.513367,361.017944 
	C506.929749,360.612976 506.528473,360.370819 505.839905,360.010193 
	C505.344940,359.542572 505.137268,359.193420 504.982788,358.582520 
	C505.009552,357.964447 504.983124,357.608185 504.971436,356.938354 
	C504.991974,356.415588 504.997803,356.206421 505.009888,355.654053 
	C505.142059,353.406891 505.343262,351.749115 507.918976,353.394196 
	C508.819916,353.969635 510.117340,354.634247 511.009399,354.400818 
	C517.850891,352.610809 519.881042,349.750122 520.016602,342.382965 
	C520.064148,339.801453 519.399719,336.775238 523.147339,335.998138 
	C527.024231,335.194183 527.938660,332.499054 528.028625,328.969269 
	C528.248230,320.357330 528.317566,320.359100 537.081360,319.993988 
	C542.421387,319.771545 544.239746,317.902588 543.932007,312.503021 
	C543.820801,310.551575 543.346191,308.530975 542.602356,306.720703 
	C539.060303,298.100464 540.367004,296.014130 549.548523,296.002136 
	C559.430359,295.989227 559.768555,295.713867 559.967041,285.532440 
	C560.024658,282.575623 560.663208,280.743713 563.884888,279.550995 
	C565.672119,278.889343 567.546936,276.121429 567.813477,274.096375 
	C569.044189,264.745605 568.841797,264.650665 577.932800,264.005676 
	C582.029785,263.715027 584.118958,261.671356 583.924866,257.651428 
	C583.814575,255.366013 583.488098,252.966934 582.648743,250.862442 
	C579.127197,242.032761 580.311462,240.107422 589.398071,239.956985 
	C593.187988,239.894257 597.216553,241.041153 599.875916,236.272903 
	C601.693115,233.014786 605.117310,230.659607 607.773254,227.853592 
	C608.074951,227.534821 607.934753,226.824417 608.208069,226.446472 
	C608.766785,225.673920 609.382202,224.496826 610.123291,224.372147 
	C616.026245,223.379135 616.266418,219.129013 615.993774,214.552414 
	C615.790466,211.140076 616.136108,208.329376 620.385010,207.439819 
	C621.457581,207.215286 622.549744,205.128479 622.885864,203.719254 
	C623.804077,199.869659 624.323853,195.925018 625.001404,192.018021 
z"/>
<path fill="#464446" opacity="1.000000" stroke="none" 
	d="
M669.015686,358.025085 
	C674.410889,348.073029 671.285645,337.297638 671.933228,326.898743 
	C672.338013,320.398010 672.343323,319.940765 678.689087,320.044373 
	C683.345398,320.120422 687.453857,319.827728 688.417175,313.998138 
	C688.535706,313.280792 689.741882,312.630585 690.568237,312.173920 
	C691.425049,311.700378 692.943237,311.777222 693.291931,311.135254 
	C695.576721,306.928650 699.534119,304.526642 702.764038,301.344543 
	C704.919800,299.220825 706.110413,296.444305 709.322571,294.817749 
	C711.019470,293.958527 711.725647,290.052277 711.866394,287.466583 
	C712.076416,283.607544 712.062622,280.558960 716.849121,279.088989 
	C718.464844,278.592804 719.708557,274.559601 719.863892,272.064514 
	C720.357300,264.137085 720.127808,264.091980 728.014099,264.013306 
	C731.291931,263.980621 734.367065,263.967590 735.460999,259.832428 
	C735.743347,258.765320 737.405518,258.136108 738.143555,257.099121 
	C739.183044,255.638580 740.648621,254.011597 740.708618,252.409943 
	C740.905273,247.164825 741.241821,246.851395 746.385132,248.703476 
	C748.238586,249.370911 750.261414,249.568283 752.602783,249.987503 
	C752.998718,249.994843 753.009033,249.983017 753.004272,250.378052 
	C752.355896,252.383423 751.828064,254.053680 751.035095,255.586731 
	C750.288086,257.030823 748.864685,258.230255 748.487488,259.726074 
	C748.201477,260.860413 749.091675,262.291351 749.458496,263.590271 
	C750.585327,262.901123 751.863525,262.373352 752.806763,261.488464 
	C754.644165,259.764862 756.293945,257.841187 758.377197,256.068909 
	C760.147156,256.433167 761.561340,256.728882 763.049438,257.370300 
	C766.695068,261.221710 770.144897,264.863251 773.865723,268.202759 
	C780.226318,273.911560 787.991943,278.133484 791.844788,286.418457 
	C793.469360,289.911896 796.430969,289.627167 799.308228,287.590576 
	C799.735291,287.908966 800.162354,288.227386 800.791260,289.129456 
	C801.836548,291.945038 802.598572,294.212433 803.550537,296.397034 
	C804.240540,297.980194 805.182312,299.453674 805.862427,301.320038 
	C804.715698,305.950470 804.348511,311.074158 797.682373,306.747894 
	C797.158203,306.407684 795.013977,308.365784 793.751038,309.410797 
	C792.073425,310.798889 790.755676,312.910004 788.863403,313.684631 
	C785.815247,314.932404 785.594788,316.568665 786.852478,319.101440 
	C790.397217,326.239960 789.655029,327.690491 782.090576,329.838501 
	C780.478516,330.296265 779.033875,331.786896 777.808960,333.082367 
	C774.076233,337.030334 771.174438,342.469482 766.649231,344.762115 
	C762.221008,347.005554 762.324768,349.539246 761.837524,353.215729 
	C761.467346,356.009033 760.326599,359.539215 758.280579,361.041473 
	C756.126343,362.623169 753.834839,363.453613 753.244202,366.432678 
	C753.072937,367.296509 752.252869,368.583405 751.558289,368.706512 
	C744.614624,369.937439 745.429321,375.883270 743.934326,380.563904 
	C743.307373,382.527039 741.723511,384.235382 740.388245,385.910950 
	C737.466736,389.577057 735.224304,393.968170 730.298462,395.603760 
	C729.923645,395.728180 730.040222,396.964142 729.630432,397.409088 
	C725.949585,401.406250 722.203247,405.343201 718.467163,409.289368 
	C718.364502,409.397766 718.128235,409.372528 718.009705,409.474213 
	C714.131958,412.800079 710.237122,416.106995 706.425476,419.507324 
	C706.035767,419.855011 706.125122,420.765930 706.037048,421.421539 
	C705.375244,426.348083 704.473206,431.259369 704.143860,436.208557 
	C703.836365,440.830688 701.412903,442.058044 697.254822,442.048096 
	C690.116699,442.030975 690.116333,442.162811 689.997986,449.077362 
	C689.872925,456.380585 688.411804,457.862427 681.202393,457.997650 
	C674.122009,458.130432 674.048889,458.130157 674.024719,465.130554 
	C674.009827,469.445801 673.371155,473.002319 668.236755,474.223724 
	C667.208984,474.468231 666.399048,476.972198 666.081604,478.553406 
	C665.563293,481.135315 666.538452,484.839935 665.099060,486.252930 
	C661.430847,489.853729 658.244934,494.282166 654.163818,496.986084 
	C650.923401,499.133026 649.932068,500.794739 649.951050,504.226746 
	C649.998169,512.743164 648.727234,514.031738 640.054749,513.933411 
	C636.474426,513.892883 633.982605,514.419434 633.119019,518.520020 
	C632.862671,519.737183 630.981506,521.100952 629.591675,521.487793 
	C626.802185,522.264221 626.106873,523.831360 625.868408,526.520020 
	C625.704285,528.369690 624.760437,530.890808 623.343506,531.738647 
	C620.119141,533.667969 621.149292,535.170837 622.850769,537.248047 
	C623.694458,538.278137 624.175232,539.605469 625.252380,541.597412 
	C620.903320,539.789001 617.799866,538.348267 614.573792,537.276489 
	C613.879944,537.045959 612.743408,538.148071 611.455444,538.458130 
	C609.953430,536.158630 608.521057,534.135864 607.744507,531.886780 
	C607.037720,529.839783 607.043213,527.546875 606.721191,524.877197 
	C607.880188,509.724487 608.455811,494.976166 610.425110,480.416382 
	C611.853149,469.858551 610.163635,461.866547 599.670410,457.588867 
	C599.601318,457.538086 599.424316,457.435547 598.709717,456.955627 
	C597.022827,455.952393 595.873596,455.326508 594.803833,454.675354 
	C594.883301,454.650085 594.753174,454.427277 594.753174,454.427277 
	C594.753174,454.427277 594.646606,454.670410 593.572632,453.951355 
	C591.776550,452.762085 591.054443,452.291870 590.634888,451.589600 
	C593.354614,448.234100 595.602173,444.960144 598.217285,442.012238 
	C612.425537,425.995880 626.724426,410.059998 641.198364,393.917542 
	C641.637573,393.459106 641.870544,393.179535 642.157532,392.836304 
	C642.211487,392.772644 642.344971,392.872925 642.715088,392.825073 
	C643.558289,392.272858 644.031372,391.768463 644.376831,391.275604 
	C644.249146,391.287109 644.228516,391.031555 644.604858,391.213104 
	C649.173462,390.819092 649.676270,387.696350 649.805420,384.185822 
	C649.762817,384.026489 650.003357,383.800964 650.003357,383.800964 
	C650.003357,383.800964 650.327271,383.861633 650.716980,383.842957 
	C654.332581,382.357758 657.681702,380.992645 657.189209,376.334351 
	C657.207458,376.216370 657.442200,376.172791 657.802673,376.315918 
	C662.030151,372.308044 665.910217,368.168854 669.725342,363.970764 
	C669.913696,363.763550 669.536194,363.042175 669.396179,362.524048 
	C669.370117,362.487854 669.425659,362.418091 669.761475,362.513489 
	C670.841675,362.156036 671.585999,361.703247 672.330322,361.250458 
	C671.918457,360.822479 671.506592,360.394470 670.921753,359.737610 
	C670.171021,359.014191 669.593323,358.519653 669.015686,358.025085 
z"/>
<path fill="#1B1818" opacity="1.000000" stroke="none" 
	d="
M877.889099,390.048950 
	C877.575684,389.573486 877.262207,389.097992 877.259338,388.319061 
	C878.041687,387.989777 878.513550,387.963928 879.324585,387.935059 
	C880.620361,387.458191 881.576782,386.984283 882.533264,386.510376 
	C882.374634,386.124908 882.215942,385.739441 882.057312,385.353973 
	C881.031738,385.582062 880.006104,385.810181 878.692688,385.821533 
	C877.879456,384.264130 877.646301,382.673676 876.762390,381.639374 
	C875.307739,379.937286 873.431763,378.595306 871.733398,377.101562 
	C871.372620,376.473297 871.011841,375.845032 870.739685,374.477814 
	C870.056641,370.766602 869.703918,367.598450 868.424805,364.863586 
	C865.885864,359.435333 862.836182,354.245880 860.326416,348.913940 
	C861.472778,348.797485 862.791077,348.370667 863.029297,348.707550 
	C865.137146,351.688171 867.164917,354.740082 868.985352,357.903809 
	C870.119873,359.875458 870.655457,363.745270 871.805481,363.883301 
	C876.557861,364.453674 877.070984,368.660187 879.007568,371.340942 
	C881.514709,374.811249 884.039673,378.821259 884.728455,382.907166 
	C885.519287,387.598297 886.915466,391.395721 890.171936,394.802124 
	C891.800232,396.505402 892.966125,398.764771 893.942383,400.950653 
	C895.417175,404.252686 896.515869,407.721100 897.826416,411.098663 
	C898.881226,413.817230 900.123657,416.467285 901.085327,419.216827 
	C902.559875,423.432892 903.044434,428.250031 905.417114,431.842499 
	C909.941956,438.693512 913.496155,445.567200 913.003296,454.466919 
	C913.001221,455.434601 912.974243,455.935364 913.004028,456.432709 
	C913.565735,465.817749 910.805725,475.870422 918.559570,483.829102 
	C919.047058,484.329529 919.029480,485.405945 919.089539,486.225952 
	C919.743652,495.149902 920.366272,504.076141 920.629089,512.898071 
	C919.507202,510.611816 917.910706,508.331573 918.150024,506.263916 
	C919.055847,498.438873 914.360168,492.290985 912.429077,485.346832 
	C912.230896,484.634247 910.195862,484.432434 908.980591,483.594177 
	C906.231323,470.553436 903.559570,457.902191 900.773376,445.276215 
	C899.810120,440.910828 898.847961,436.509125 897.386780,432.296967 
	C894.094727,422.806702 890.490234,413.424866 887.018188,403.996948 
	C886.846802,403.531616 886.523132,403.005646 886.611938,402.578979 
	C887.675354,397.470612 883.799622,396.462799 880.421509,395.031067 
	C880.421509,395.031067 880.414429,394.703522 880.639160,394.295959 
	C880.703064,391.964905 881.668762,389.168549 877.889099,390.048950 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M901.880493,501.045654 
	C903.295654,499.718903 904.710754,498.392151 906.716675,497.371338 
	C908.149414,505.487122 908.991394,513.296936 909.787415,521.541870 
	C909.858521,524.321289 909.975525,526.665588 910.025085,529.473633 
	C909.993408,537.626343 910.029236,545.315430 909.951050,553.364685 
	C909.863708,554.474487 909.890381,555.224060 909.791443,556.371948 
	C907.970581,579.922485 905.600769,602.987793 899.962646,625.574280 
	C898.168640,632.761108 895.890747,639.827148 893.647583,646.664429 
	C894.002686,642.297485 894.544434,638.214600 895.086182,634.131653 
	C894.484680,634.040894 893.883118,633.950134 893.281616,633.859375 
	C892.542847,636.192017 891.921204,638.569946 891.041931,640.848328 
	C888.917175,646.354553 886.672241,651.814453 884.395264,656.846863 
	C884.677429,653.707397 884.778076,650.946350 885.453735,648.333923 
	C887.200012,641.581970 889.200073,634.895569 891.425659,628.031372 
	C892.497620,626.261108 893.554871,624.711853 893.932678,623.011597 
	C895.407715,616.372070 897.044556,609.734375 897.930420,603.005005 
	C899.855347,588.382507 902.010803,573.741333 902.846252,559.038208 
	C903.681885,544.331360 903.084106,529.539307 902.975220,514.785645 
	C902.951111,511.516846 902.342102,508.252350 902.000244,504.733337 
	C901.995728,504.312378 901.993408,504.144043 901.983765,503.608887 
	C901.944519,502.509949 901.912537,501.777802 901.880493,501.045654 
z"/>
<path fill="#645747" opacity="1.000000" stroke="none" 
	d="
M901.674072,500.704712 
	C901.912537,501.777802 901.944519,502.509949 901.874634,503.721130 
	C901.620605,504.514862 901.697144,504.776764 902.002441,504.985901 
	C902.342102,508.252350 902.951111,511.516846 902.975220,514.785645 
	C903.084106,529.539307 903.681885,544.331360 902.846252,559.038208 
	C902.010803,573.741333 899.855347,588.382507 897.930420,603.005005 
	C897.044556,609.734375 895.407715,616.372070 893.932678,623.011597 
	C893.554871,624.711853 892.497620,626.261108 891.402466,627.594910 
	C893.336975,614.888916 896.274719,602.544067 897.737671,590.026917 
	C899.482666,575.096680 899.963928,560.018799 900.997437,544.535706 
	C901.493408,524.861084 900.810791,505.740631 896.894897,486.850677 
	C895.496033,480.102600 894.146484,473.344269 892.737976,466.598267 
	C892.207214,464.056030 891.573059,461.535309 890.914001,458.616852 
	C890.203979,455.132355 889.871033,451.931915 888.860107,448.962372 
	C887.133545,443.890839 884.981812,438.964050 883.008179,433.734497 
	C883.007629,433.492493 883.002441,433.008545 883.003906,432.610291 
	C882.671021,430.475098 882.336731,428.738159 881.901367,426.675964 
	C879.793030,421.618896 877.728638,416.910248 875.797791,412.147430 
	C874.439453,408.797058 873.262634,405.373108 871.825562,401.691345 
	C870.111145,399.260529 868.575867,397.120422 866.916260,394.732056 
	C866.534973,393.992493 866.277954,393.501129 866.019775,392.596741 
	C865.141724,388.839691 864.783752,385.241180 863.291565,382.199036 
	C858.652527,372.741333 853.728394,363.413727 848.598145,354.212219 
	C846.910645,351.185516 844.257507,348.697174 841.968079,345.626343 
	C839.603333,340.859161 837.316528,336.428284 835.233887,331.828064 
	C835.670227,331.389893 835.902466,331.121063 836.134705,330.852264 
	C841.018188,337.727295 846.604858,344.215668 850.641235,351.556488 
	C859.501343,367.669861 868.510681,383.817047 875.698425,400.700043 
	C882.304077,416.215698 886.732300,432.672363 891.848511,448.800537 
	C893.647278,454.471313 894.640869,460.397522 896.127563,466.968262 
	C897.133667,469.885040 898.014404,472.040619 898.895142,474.196198 
	C899.752625,482.918701 900.610107,491.641235 901.674072,500.704712 
z"/>
<path fill="#2D2017" opacity="1.000000" stroke="none" 
	d="
M738.654419,224.966400 
	C740.470093,226.199646 741.957764,227.435104 743.719116,228.839294 
	C744.764221,229.337540 745.535706,229.667068 746.627991,230.022095 
	C747.336182,230.764725 747.538513,231.784378 748.139587,232.151917 
	C753.747864,235.581451 759.492798,238.790894 765.050537,242.298141 
	C767.547302,243.873749 769.672852,246.027634 772.035095,247.829697 
	C773.830811,249.199524 775.873230,250.269547 777.563110,251.750122 
	C782.123596,255.745712 786.560425,259.882690 791.024536,263.987305 
	C791.369812,264.304779 791.544373,264.807953 791.798401,265.224792 
	C792.058105,264.864349 792.317749,264.503876 792.577454,264.143433 
	C795.307739,265.881470 798.037964,267.619507 800.677734,269.969177 
	C799.492981,273.193207 800.192017,274.948883 802.851135,276.169861 
	C810.504822,279.684296 814.953552,286.666565 819.928955,292.879974 
	C822.690918,296.329224 824.050232,300.901611 825.729675,304.993744 
	C825.209839,305.038879 825.001953,305.058105 824.794128,305.077332 
	C821.661438,301.694031 818.489319,298.346283 815.402771,294.921448 
	C798.382935,276.036346 779.791687,258.876709 759.042358,243.163437 
	C757.988037,242.109482 757.230103,241.647278 756.472107,241.185074 
	C754.473999,239.922562 752.475830,238.660049 750.129456,236.868958 
	C749.062012,235.941986 748.342773,235.543610 747.623596,235.145248 
	C746.558167,234.544052 745.492798,233.942856 744.101074,232.812149 
	C743.063965,231.901581 742.353088,231.520523 741.642212,231.139465 
	C734.790466,227.119949 727.938721,223.100418 720.911499,218.820206 
	C720.816956,217.668747 720.897949,216.777969 720.991943,215.443985 
	C721.747864,215.011215 722.490906,215.021667 723.642700,215.371338 
	C725.127625,217.711319 726.003662,220.144424 728.997803,217.998627 
	C730.465271,219.564056 731.932678,221.129486 733.873901,223.115021 
	C735.236267,224.011261 736.125000,224.487427 737.013672,224.963593 
	C737.451233,224.965271 737.888855,224.966949 738.654419,224.966400 
z"/>
<path fill="#23150C" opacity="1.000000" stroke="none" 
	d="
M909.833374,521.106750 
	C908.991394,513.296936 908.149414,505.487122 907.062622,497.348389 
	C906.179810,491.648529 905.541870,486.277618 904.908936,480.123169 
	C904.496582,478.064484 904.079285,476.789398 903.661987,475.514282 
	C901.683350,465.963013 899.704651,456.411774 897.744080,446.207001 
	C897.636963,445.228546 897.402405,445.038330 897.058533,444.982849 
	C896.586670,443.255554 896.114868,441.528259 895.686157,439.176544 
	C895.622192,438.222534 895.398071,438.026825 895.057007,437.964996 
	C894.279785,435.052032 893.502625,432.139069 892.736572,428.537476 
	C892.312439,427.205078 891.877197,426.561340 891.441895,425.917572 
	C890.808289,424.054962 890.174683,422.192352 889.585938,419.584167 
	C888.862732,417.202637 888.094788,415.566620 887.326782,413.930634 
	C885.039551,407.899506 882.752197,401.868408 880.443237,395.434204 
	C883.799622,396.462799 887.675354,397.470612 886.611938,402.578979 
	C886.523132,403.005646 886.846802,403.531616 887.018188,403.996948 
	C890.490234,413.424866 894.094727,422.806702 897.386780,432.296967 
	C898.847961,436.509125 899.810120,440.910828 900.773376,445.276215 
	C903.559570,457.902191 906.231323,470.553436 908.975403,484.048462 
	C909.509277,488.996429 910.128723,493.080475 910.497986,497.187042 
	C911.216675,505.178558 912.796021,513.211487 909.833374,521.106750 
z"/>
<path fill="#2F2015" opacity="1.000000" stroke="none" 
	d="
M824.881287,305.416138 
	C825.001953,305.058105 825.209839,305.038879 826.135254,304.999512 
	C828.710938,305.519012 831.013611,305.549469 832.335205,306.703522 
	C835.151672,309.162964 837.458984,312.205505 840.048462,315.367920 
	C841.087036,317.483154 842.052063,319.239594 843.253662,321.002319 
	C843.490173,321.008606 843.963379,321.015289 843.971924,321.411377 
	C846.639771,326.872894 849.299133,331.938293 852.140259,337.294373 
	C854.878845,341.071686 857.435791,344.558289 859.992371,348.273102 
	C859.992065,348.501343 859.997559,348.957764 859.997559,348.957764 
	C862.836182,354.245880 865.885864,359.435333 868.424805,364.863586 
	C869.703918,367.598450 870.056641,370.766602 870.757080,374.197754 
	C866.496094,367.559418 862.660522,360.221985 858.044739,353.413544 
	C847.563171,337.952942 836.696777,322.753265 825.993164,307.443054 
	C825.616821,306.904663 825.308472,306.318726 824.881287,305.416138 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M723.024780,227.470154 
	C716.319519,228.999100 712.028687,223.543762 706.527954,221.187057 
	C699.077942,217.995178 692.636414,212.744659 684.219910,211.384735 
	C680.363098,210.761581 676.933777,207.761383 673.185913,206.124680 
	C668.829956,204.222443 664.337402,202.633301 659.310791,200.754608 
	C658.106140,200.708542 657.494812,200.816101 656.883484,200.923660 
	C653.891052,199.643845 650.898560,198.364014 647.528198,196.689621 
	C647.099976,195.861877 647.049622,195.428711 647.385376,195.013260 
	C649.551392,195.210693 651.331177,195.390411 653.111023,195.570129 
	C653.536926,195.661911 653.962769,195.753708 654.764404,196.165497 
	C655.755188,196.581375 656.370239,196.677246 656.985291,196.773117 
	C657.425781,196.817032 657.866333,196.860931 658.637939,196.922485 
	C660.358398,197.722321 661.664185,198.757233 663.149658,199.248489 
	C682.893250,205.777893 701.510986,214.790970 719.691101,224.771088 
	C720.833069,225.397980 721.774963,226.389328 723.024780,227.470154 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M799.209656,287.270020 
	C796.430969,289.627167 793.469360,289.911896 791.844788,286.418457 
	C787.991943,278.133484 780.226318,273.911560 773.865723,268.202759 
	C770.144897,264.863251 766.695068,261.221710 763.078125,257.341858 
	C763.032837,256.967743 763.090210,256.910858 763.470703,256.926941 
	C764.618469,256.931030 765.385742,256.919037 766.153076,256.907043 
	C773.856201,263.469788 781.661377,269.917938 789.217529,276.645752 
	C792.762817,279.802368 795.828186,283.498047 799.209656,287.270020 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M836.037659,330.524048 
	C835.902466,331.121063 835.670227,331.389893 834.911804,331.729614 
	C831.598755,329.195129 828.811890,326.589813 826.001343,323.618927 
	C824.324158,321.834229 822.670593,320.415039 820.804504,318.801758 
	C820.062378,318.397919 819.532837,318.188202 818.813599,317.662201 
	C818.559509,314.503448 818.495178,311.661041 818.430908,308.818604 
	C819.696350,309.762451 821.253662,310.482422 822.178040,311.687866 
	C826.855957,317.787842 831.369446,324.013977 836.037659,330.524048 
z"/>
<path fill="#494543" opacity="1.000000" stroke="none" 
	d="
M818.302917,308.515320 
	C818.495178,311.661041 818.559509,314.503448 818.874146,317.911560 
	C819.755310,318.650146 820.386169,318.822998 821.017090,318.995880 
	C822.670593,320.415039 824.324158,321.834229 825.669922,323.764099 
	C823.432129,323.870209 821.000427,324.069519 819.659546,322.955627 
	C814.125549,318.358337 812.981079,311.662537 812.424194,304.989380 
	C813.696960,304.940002 814.547974,304.893982 815.398926,304.847931 
	C816.324280,305.969299 817.249634,307.090668 818.302917,308.515320 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M815.259827,304.523682 
	C814.547974,304.893982 813.696960,304.940002 812.246521,304.701630 
	C809.768616,303.270142 807.890198,302.123077 806.011780,300.976044 
	C805.182312,299.453674 804.240540,297.980194 803.550537,296.397034 
	C802.598572,294.212433 801.836548,291.945038 800.991577,289.305237 
	C805.700256,293.998077 810.410461,299.098755 815.259827,304.523682 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M753.005737,249.992477 
	C748.887390,246.854050 744.706238,243.787384 740.667725,240.543015 
	C739.315125,239.456345 738.326721,237.916321 737.120239,236.230804 
	C743.276306,240.024704 749.483948,244.171265 755.678101,248.796463 
	C755.125366,249.509598 754.586182,249.744095 753.787476,249.977783 
	C753.354980,249.978989 753.182007,249.981003 753.009033,249.983017 
	C753.009033,249.983017 752.998718,249.994843 753.005737,249.992477 
z"/>
<path fill="#2F2015" opacity="1.000000" stroke="none" 
	d="
M871.775635,377.475525 
	C873.431763,378.595306 875.307739,379.937286 876.762390,381.639374 
	C877.646301,382.673676 877.879456,384.264130 878.694336,386.138031 
	C878.984314,387.093567 878.984863,387.515808 878.985352,387.938080 
	C878.513550,387.963928 878.041687,387.989777 877.215576,388.031616 
	C875.180115,384.648224 873.498962,381.248871 871.775635,377.475525 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M734.885498,234.574631 
	C732.904419,234.324127 730.513550,234.197708 728.656250,233.136963 
	C726.885925,232.125900 725.672180,230.140350 724.134766,228.252960 
	C727.606812,230.045273 731.157837,232.167831 734.885498,234.574631 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M763.004150,256.996185 
	C761.561340,256.728882 760.147156,256.433167 758.374146,255.671646 
	C757.998474,254.148788 757.981628,253.091721 758.060425,251.951691 
	C758.156067,251.868729 758.350769,251.706802 758.350769,251.706802 
	C759.862427,252.502441 761.374146,253.298096 762.998047,254.758636 
	C763.103577,255.919296 763.096863,256.415070 763.090210,256.910858 
	C763.090210,256.910858 763.032837,256.967743 763.004150,256.996185 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M898.922058,473.757782 
	C898.014404,472.040619 897.133667,469.885040 896.236816,467.355835 
	C900.317444,467.722473 898.586243,470.971619 898.922058,473.757782 
z"/>
<path fill="#23150C" opacity="1.000000" stroke="none" 
	d="
M877.928467,390.401428 
	C881.668762,389.168549 880.703064,391.964905 880.802734,394.286865 
	C879.816956,393.374878 878.892395,392.064392 877.928467,390.401428 
z"/>
<path fill="#3D2B1C" opacity="1.000000" stroke="none" 
	d="
M887.280518,414.283325 
	C888.094788,415.566620 888.862732,417.202637 889.551392,419.221008 
	C888.726135,417.947632 887.980225,416.291840 887.280518,414.283325 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M754.046936,249.978577 
	C754.586182,249.744095 755.125366,249.509598 755.877197,249.063538 
	C756.779541,249.601105 757.469360,250.350235 758.254944,251.403076 
	C758.350769,251.706802 758.156067,251.868729 757.725098,251.924683 
	C756.211670,251.313293 755.129333,250.645935 754.046936,249.978577 
z"/>
<path fill="#635F43" opacity="1.000000" stroke="none" 
	d="
M903.530151,475.880249 
	C904.079285,476.789398 904.496582,478.064484 904.848633,479.689728 
	C904.321716,478.775330 903.860046,477.510803 903.530151,475.880249 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M763.470703,256.926941 
	C763.096863,256.415070 763.103577,255.919296 763.145630,255.048523 
	C764.048340,255.245956 764.915649,255.818359 765.968018,256.648926 
	C765.385742,256.919037 764.618469,256.931030 763.470703,256.926941 
z"/>
<path fill="#443825" opacity="1.000000" stroke="none" 
	d="
M688.860413,202.688065 
	C689.421814,202.698929 690.109375,202.963486 690.913330,203.498657 
	C690.348633,203.493439 689.667664,203.217606 688.860413,202.688065 
z"/>
<path fill="#3D2B1C" opacity="1.000000" stroke="none" 
	d="
M891.398560,426.219513 
	C891.877197,426.561340 892.312439,427.205078 892.710571,428.164948 
	C892.234009,427.827850 891.794617,427.174652 891.398560,426.219513 
z"/>
<path fill="#36271D" opacity="1.000000" stroke="none" 
	d="
M747.774231,235.422897 
	C748.342773,235.543610 749.062012,235.941986 749.891174,236.644196 
	C749.309082,236.532196 748.616943,236.116364 747.774231,235.422897 
z"/>
<path fill="#36271D" opacity="1.000000" stroke="none" 
	d="
M756.640015,241.470703 
	C757.230103,241.647278 757.988037,242.109482 758.820679,242.922577 
	C758.199463,242.767746 757.503662,242.262054 756.640015,241.470703 
z"/>
<path fill="#36271D" opacity="1.000000" stroke="none" 
	d="
M741.794312,231.412704 
	C742.353088,231.520523 743.063965,231.901581 743.889648,232.576691 
	C743.318481,232.475800 742.632446,232.080872 741.794312,231.412704 
z"/>
<path fill="#635F43" opacity="1.000000" stroke="none" 
	d="
M897.138245,445.174988 
	C897.402405,445.038330 897.636963,445.228546 897.744141,445.788208 
	C897.526489,445.827789 897.357056,445.609161 897.138245,445.174988 
z"/>
<path fill="#635F43" opacity="1.000000" stroke="none" 
	d="
M895.123291,438.157440 
	C895.398071,438.026825 895.622192,438.222534 895.693604,438.781677 
	C895.466614,438.815704 895.310486,438.595245 895.123291,438.157440 
z"/>
<path fill="#252325" opacity="1.000000" stroke="none" 
	d="
M839.974976,315.009094 
	C837.458984,312.205505 835.151672,309.162964 832.335205,306.703522 
	C831.013611,305.549469 828.710938,305.519012 826.447266,304.973572 
	C824.050232,300.901611 822.690918,296.329224 819.928955,292.879974 
	C814.953552,286.666565 810.504822,279.684296 802.851135,276.169861 
	C800.192017,274.948883 799.492981,273.193207 800.796082,270.288177 
	C805.970642,273.677979 811.056763,277.211945 815.854797,281.101349 
	C818.524414,283.265411 820.711914,286.023804 823.122192,288.508942 
	C823.460876,288.858154 823.806030,289.433655 824.190735,289.471497 
	C833.847290,290.421051 832.874512,299.946869 837.069153,305.290222 
	C838.077454,306.574738 839.513062,307.593445 840.912659,308.481445 
	C842.298645,309.360809 844.361755,309.529022 845.313354,310.681610 
	C854.241943,321.496582 863.398926,332.161896 871.652161,343.481323 
	C876.800415,350.542358 880.507935,358.653839 884.930908,366.981537 
	C884.998047,368.114777 884.996338,368.560455 884.615356,368.995178 
	C883.589966,368.872131 882.627319,368.993652 882.345886,368.612396 
	C878.885193,363.923523 875.498474,359.179138 872.148071,354.410187 
	C870.453003,351.997467 868.932678,349.461273 867.219482,347.062256 
	C865.388428,344.498260 863.551392,341.916626 861.468445,339.562622 
	C860.224915,338.157166 858.503906,337.174133 856.996338,335.753174 
	C856.995117,335.505127 856.992676,335.009094 856.887695,334.683624 
	C854.742981,331.341949 852.993530,328.060913 850.565308,325.399078 
	C848.838257,323.505829 846.196716,322.446716 843.963379,321.015289 
	C843.963379,321.015289 843.490173,321.008606 843.248230,320.610016 
	C841.995850,318.477325 840.985413,316.743195 839.974976,315.009094 
z"/>
<path fill="#262425" opacity="1.000000" stroke="none" 
	d="
M884.994629,369.006104 
	C884.996338,368.560455 884.998047,368.114777 885.001099,367.334900 
	C888.986694,371.425812 892.970886,375.850891 896.978760,380.637390 
	C897.001587,381.166046 897.000671,381.333344 896.665771,381.750916 
	C895.887878,382.000824 895.443909,382.000427 894.924927,381.659424 
	C891.568970,377.879761 888.288147,374.440704 885.005249,370.669067 
	C885.000305,369.892975 884.997437,369.449554 884.994629,369.006104 
z"/>
<path fill="#36271D" opacity="1.000000" stroke="none" 
	d="
M728.998901,217.999527 
	C726.003662,220.144424 725.127625,217.711319 724.014282,215.376602 
	C725.112244,215.657562 726.247498,216.272491 727.840942,217.139191 
	C728.506287,217.626205 728.740723,217.830017 729.002441,218.002426 
	C729.002441,218.002426 729.000000,218.000443 728.998901,217.999527 
z"/>
<path fill="#2C2728" opacity="1.000000" stroke="none" 
	d="
M736.896118,224.660431 
	C736.125000,224.487427 735.236267,224.011261 734.175293,223.274002 
	C734.928223,223.461029 735.853333,223.909149 736.896118,224.660431 
z"/>
<path fill="#2D2017" opacity="1.000000" stroke="none" 
	d="
M728.863098,217.814728 
	C728.740723,217.830017 728.506287,217.626205 728.158386,217.205383 
	C728.282349,217.188110 728.517700,217.390518 728.863098,217.814728 
z"/>
<path fill="#373133" opacity="1.000000" stroke="none" 
	d="
M624.615967,192.002991 
	C624.323853,195.925018 623.804077,199.869659 622.885864,203.719254 
	C622.549744,205.128479 621.457581,207.215286 620.385010,207.439819 
	C616.136108,208.329376 615.790466,211.140076 615.993774,214.552414 
	C616.266418,219.129013 616.026245,223.379135 610.123291,224.372147 
	C609.382202,224.496826 608.766785,225.673920 608.208069,226.446472 
	C607.934753,226.824417 608.074951,227.534821 607.773254,227.853592 
	C605.117310,230.659607 601.693115,233.014786 599.875916,236.272903 
	C597.216553,241.041153 593.187988,239.894257 589.398071,239.956985 
	C580.311462,240.107422 579.127197,242.032761 582.648743,250.862442 
	C583.488098,252.966934 583.814575,255.366013 583.924866,257.651428 
	C584.118958,261.671356 582.029785,263.715027 577.932800,264.005676 
	C568.841797,264.650665 569.044189,264.745605 567.813477,274.096375 
	C567.546936,276.121429 565.672119,278.889343 563.884888,279.550995 
	C560.663208,280.743713 560.024658,282.575623 559.967041,285.532440 
	C559.768555,295.713867 559.430359,295.989227 549.548523,296.002136 
	C540.367004,296.014130 539.060303,298.100464 542.602356,306.720703 
	C543.346191,308.530975 543.820801,310.551575 543.932007,312.503021 
	C544.239746,317.902588 542.421387,319.771545 537.081360,319.993988 
	C528.317566,320.359100 528.248230,320.357330 528.028625,328.969269 
	C527.938660,332.499054 527.024231,335.194183 523.147339,335.998138 
	C519.399719,336.775238 520.064148,339.801453 520.016602,342.382965 
	C519.881042,349.750122 517.850891,352.610809 511.009399,354.400818 
	C510.117340,354.634247 508.819916,353.969635 507.918976,353.394196 
	C505.343262,351.749115 505.142059,353.406891 504.695190,355.603088 
	C503.239197,356.292755 502.104187,356.690277 500.583954,357.116821 
	C499.169464,357.127075 498.140198,357.108276 496.753571,356.854828 
	C488.559113,356.776581 480.721985,356.932983 472.501831,357.127838 
	C469.909943,357.650238 467.649353,358.697906 465.502960,358.501007 
	C461.009888,358.088684 460.125153,360.482330 460.129761,364.081146 
	C460.131836,365.713593 460.053314,367.346130 460.116364,369.170898 
	C460.381775,369.607758 460.569885,369.828766 460.799011,370.491333 
	C460.731415,375.971466 460.650513,380.986481 460.221222,386.019348 
	C458.167847,388.465454 456.462891,390.893738 454.757904,393.321991 
	C454.278015,393.390289 453.798157,393.458588 453.318268,393.526886 
	C452.914276,391.025574 452.212097,388.527618 452.183594,386.022034 
	C452.149872,383.055603 452.786011,380.088074 452.970764,377.110992 
	C453.216339,373.153992 451.841309,370.884125 447.280762,370.924164 
	C442.529053,370.965912 442.916443,374.010468 443.017365,377.037537 
	C443.171570,381.661621 442.678802,385.764557 437.127991,387.131653 
	C436.251556,387.347473 435.655884,388.690094 434.923340,389.505371 
	C431.135193,393.721588 427.355560,397.945618 423.534454,402.131775 
	C423.229645,402.465729 422.454742,402.402985 422.202820,402.747375 
	C421.076813,404.286469 419.603302,405.777893 419.112671,407.527069 
	C418.366150,410.188782 419.346985,414.115051 417.783295,415.609497 
	C414.230133,419.005493 411.174042,423.295288 407.309174,425.799377 
	C403.613007,428.194244 403.098419,430.363251 402.859528,434.001953 
	C402.688568,436.606537 401.746246,440.931580 400.244904,441.365662 
	C394.318451,443.079346 395.027740,447.250214 394.935974,451.582062 
	C394.827881,456.685852 392.649628,458.713928 387.513123,458.971283 
	C385.851379,459.054596 384.182251,458.980286 382.516876,459.003998 
	C376.925446,459.083588 376.670074,459.764282 377.769623,465.212036 
	C378.269409,467.688232 378.297180,471.825165 376.877136,472.808319 
	C373.583740,475.088501 370.528625,477.118744 369.044586,481.019745 
	C368.807251,481.643524 367.768555,482.222443 367.020447,482.348694 
	C362.970734,483.032166 363.125763,485.910156 362.883484,489.068542 
	C362.666779,491.893433 362.317871,495.825409 360.489685,497.155426 
	C357.530182,499.308502 355.585175,501.994751 353.171570,504.426239 
	C350.296600,507.322571 346.470184,509.246002 344.713715,513.334900 
	C344.456573,513.933472 343.354675,514.422241 342.591217,514.511047 
	C335.082916,515.384460 334.407715,521.342102 332.892181,526.954407 
	C331.918762,530.559204 331.027985,535.843506 328.515594,537.035706 
	C323.036255,539.635803 322.572601,543.290222 322.914520,548.138489 
	C322.992065,549.238281 322.790161,550.741089 322.095642,551.448120 
	C318.336975,555.274353 314.375336,558.900696 310.500885,562.613892 
	C309.178101,563.881592 307.531830,564.998169 306.691620,566.542053 
	C305.192963,569.295715 303.541351,571.235535 300.088623,570.443909 
	C293.961426,569.039001 293.998291,569.047791 292.907745,575.501587 
	C292.691010,576.784424 292.066162,577.998352 291.629364,579.244019 
	C289.489716,585.345398 291.096924,590.334290 296.009857,593.396118 
	C300.582581,596.245972 302.848480,597.131104 297.919067,602.864136 
	C297.434631,603.427551 297.439178,604.411438 297.173218,605.189209 
	C297.129700,605.178284 297.091339,605.097229 296.718933,605.096924 
	C292.519073,606.523804 290.775360,609.021912 292.205017,613.555725 
	C292.154175,615.305176 292.105103,616.650574 291.721558,618.071472 
	C290.577820,619.141724 289.768555,620.136475 288.709534,620.913452 
	C287.245880,618.900024 286.031982,617.104370 285.022583,614.998047 
	C286.273285,612.829773 290.799316,612.530579 287.854065,608.530945 
	C288.228882,605.131348 286.893738,604.188660 283.973724,604.879028 
	C283.444885,604.434753 282.994171,604.245544 282.543488,604.056274 
	C282.734070,604.730896 282.924652,605.405457 282.944885,606.405151 
	C281.914886,610.464722 280.710663,613.878845 275.990173,613.557739 
	C275.770233,607.114868 275.551819,601.138916 275.333405,595.162964 
	C274.642487,595.046448 273.951569,594.929993 273.260651,594.813477 
	C272.528870,596.556274 271.288757,598.263611 271.164398,600.048706 
	C270.841949,604.677002 271.010742,609.339478 270.741577,613.870972 
	C270.025452,613.502747 269.551117,613.251892 268.951691,612.680725 
	C265.198151,610.277588 261.569702,608.194824 258.014160,605.755066 
	C258.057220,603.262451 258.027374,601.126770 258.340942,599.050049 
	C259.413727,599.474915 260.193665,599.766296 260.862915,600.220642 
	C262.823364,601.551819 265.029449,602.442993 266.119232,599.700745 
	C266.466583,598.826660 264.770721,596.629150 263.515625,595.717590 
	C257.863037,591.611938 254.142242,596.008301 250.167480,599.071777 
	C249.243256,597.701416 247.489532,596.302429 247.535782,594.965637 
	C247.757401,588.560608 248.602341,582.180115 248.961060,575.776001 
	C249.276215,570.149719 250.140549,569.091003 255.777863,569.012146 
	C259.774414,568.956177 263.777130,568.872314 267.768250,569.027405 
	C271.674561,569.179260 273.258881,567.411255 273.013336,563.624817 
	C272.905762,561.966675 273.026886,560.294861 273.010010,558.629578 
	C272.979156,555.580688 274.232025,553.852844 277.342163,552.839233 
	C278.859558,552.344727 280.856323,549.974854 280.778259,548.556091 
	C280.540741,544.239380 279.723419,539.877991 278.512390,535.714172 
	C277.429321,531.990234 278.887634,529.091064 283.266266,529.127930 
	C288.116486,529.168762 289.378784,526.877747 289.024658,522.640625 
	C288.886566,520.988708 288.953064,519.306335 289.074860,517.648743 
	C289.396240,513.274963 292.516479,513.019958 295.909149,512.980896 
	C304.852936,512.877930 304.852478,512.837219 305.001556,503.993042 
	C305.099121,498.204742 306.103577,497.315857 311.556488,496.936127 
	C314.542755,496.728210 319.821228,496.277863 320.029419,495.174835 
	C320.854828,490.802185 321.160278,485.735138 319.663361,481.657898 
	C317.326050,475.291534 318.076477,473.106110 324.979889,473.006287 
	C327.311218,472.972595 329.644958,472.938507 331.974670,473.005920 
	C334.844147,473.088898 336.526581,472.177612 337.246704,469.012726 
	C337.593292,467.489410 339.539459,465.580139 341.074799,465.233215 
	C344.276794,464.509735 345.001221,462.743530 345.027344,459.912628 
	C345.053284,457.103668 344.312164,452.777252 345.752899,451.778473 
	C349.865509,448.927460 352.237122,443.453003 355.897644,441.964050 
	C361.238068,439.791748 360.906097,436.793274 361.036865,432.822998 
	C361.129425,430.012543 361.473877,427.200531 361.865997,424.411713 
	C362.906586,417.011688 362.941101,417.015320 370.628937,417.000580 
	C373.626923,416.994843 376.630493,416.879272 379.621460,417.023163 
	C383.405701,417.205170 385.179657,415.679138 385.027435,411.769684 
	C384.872070,407.778595 384.964783,403.775970 385.021179,399.779449 
	C385.039764,398.461273 384.778198,396.624756 385.508698,395.916840 
	C389.440857,392.106232 393.679291,388.614014 397.734528,384.926666 
	C398.945129,383.825867 400.645874,382.557983 400.846588,381.180756 
	C401.538727,376.432129 401.816956,371.605927 401.963593,366.800446 
	C402.083588,362.868469 403.791229,360.954742 407.755371,360.999481 
	C411.918518,361.046478 416.084137,360.927032 420.246216,361.007019 
	C423.646484,361.072327 425.121735,359.482025 425.003967,356.147705 
	C424.933502,354.152130 424.519287,351.971558 425.161530,350.201385 
	C425.851135,348.300659 427.509125,346.705444 428.908295,345.121552 
	C430.176941,343.685364 431.690033,342.467926 433.050629,341.109222 
	C434.361938,339.799805 435.443024,337.560944 436.929901,337.265961 
	C440.464447,336.564636 440.972839,334.522858 441.039948,331.557007 
	C441.122314,327.914520 441.671112,324.283112 441.768555,320.640259 
	C441.874420,316.683411 442.531952,313.333069 447.243317,312.466217 
	C447.840057,312.356445 448.645508,311.304016 448.697632,310.637024 
	C449.144318,304.918365 453.101288,304.922150 457.354431,304.966400 
	C464.913208,305.045105 465.075378,304.912781 464.957001,297.546967 
	C464.884857,293.056427 465.037842,289.050598 470.930817,288.596588 
	C471.544495,288.549286 472.526459,287.574066 472.585022,286.957825 
	C472.960327,283.010681 474.977600,280.955078 478.941925,280.590637 
	C479.559967,280.533813 480.533569,279.562622 480.589111,278.946381 
	C480.946503,274.978973 483.062805,273.154419 486.921387,272.451416 
	C487.821838,272.287384 488.800446,269.867249 488.888519,268.432678 
	C489.153442,264.118103 489.125488,259.773010 488.969788,255.448532 
	C488.799103,250.708496 490.988190,248.695114 495.608948,248.989471 
	C496.935822,249.074005 498.275330,248.945084 499.604889,249.005402 
	C502.609833,249.141769 504.637604,248.405090 505.255005,244.874588 
	C505.500153,243.472824 507.345795,241.517487 508.699707,241.301834 
	C512.518250,240.693619 513.154663,238.451614 513.015076,235.283127 
	C512.934631,233.455582 512.442078,231.316513 513.192505,229.864044 
	C514.168762,227.974472 515.959717,226.175262 517.845764,225.193375 
	C519.294983,224.438965 521.433960,224.964890 523.264771,224.997543 
	C527.240173,225.068405 529.038696,223.106812 529.004395,219.178574 
	C528.952026,213.183121 528.966431,207.186584 529.009766,201.190933 
	C529.054749,194.964951 530.000427,194.144165 536.362610,193.969177 
	C539.330261,193.887527 542.288635,193.467468 545.251221,193.200790 
	C545.318787,192.653503 545.386292,192.106216 545.453857,191.558929 
	C543.632568,190.383972 541.811279,189.209000 539.482422,187.706604 
	C545.460571,186.546692 550.912598,185.488861 557.063110,184.215164 
	C559.174377,183.666412 560.587219,183.333511 562.000000,183.000610 
	C571.237366,183.342499 580.483337,183.546127 589.705994,184.122726 
	C592.035706,184.268387 594.290894,185.605362 596.843079,187.069031 
	C597.716675,188.431488 598.273987,189.655991 598.946838,189.723175 
	C604.199890,190.247635 609.472900,190.590988 614.746033,190.872696 
	C615.478149,190.911804 616.244629,190.307877 616.995239,190.001648 
	C619.406982,190.663757 621.818787,191.325867 624.615967,192.002991 
z"/>
<path fill="#373133" opacity="1.000000" stroke="none" 
	d="
M646.999207,194.995544 
	C647.049622,195.428711 647.099976,195.861877 647.189453,196.619476 
	C645.153809,196.789139 643.079041,196.634384 640.929321,196.474030 
	C641.560242,200.529099 642.148071,204.307434 642.752747,208.193970 
	C636.051025,207.649231 633.626160,202.489273 637.208923,197.179703 
	C636.202759,196.037918 635.170349,194.866379 634.045898,193.378036 
	C634.787476,192.879623 635.620972,192.697998 637.082153,192.407471 
	C640.806213,193.197556 643.902710,194.096558 646.999207,194.995544 
z"/>
<path fill="#363032" opacity="1.000000" stroke="none" 
	d="
M561.760620,182.694275 
	C560.587219,183.333511 559.174377,183.666412 557.383240,184.000244 
	C554.931580,183.668335 552.864990,183.102158 550.783813,183.042526 
	C541.087524,182.764679 531.387085,182.626923 521.339233,182.214630 
	C522.738281,181.660828 524.483887,181.062088 526.234680,181.046631 
	C536.140686,180.959167 546.048889,180.941956 555.953857,181.077957 
	C557.815308,181.103500 559.665710,181.930679 561.760620,182.694275 
z"/>
<path fill="#3D383B" opacity="1.000000" stroke="none" 
	d="
M616.620972,189.920471 
	C616.244629,190.307877 615.478149,190.911804 614.746033,190.872696 
	C609.472900,190.590988 604.199890,190.247635 598.946838,189.723175 
	C598.273987,189.655991 597.716675,188.431488 597.059021,187.361267 
	C603.423706,187.932709 609.835266,188.886017 616.620972,189.920471 
z"/>
<path fill="#8E7857" opacity="1.000000" stroke="none" 
	d="
M647.385376,195.013260 
	C643.902710,194.096558 640.806213,193.197556 637.351440,192.233383 
	C636.428772,191.858490 635.864441,191.548798 635.211182,190.920074 
	C640.963013,192.097549 646.803711,193.594025 652.877747,195.330322 
	C651.331177,195.390411 649.551392,195.210693 647.385376,195.013260 
z"/>
<path fill="#8E7857" opacity="1.000000" stroke="none" 
	d="
M630.055115,189.376740 
	C630.909546,189.318054 631.883911,189.584198 633.013855,190.118256 
	C632.171265,190.157959 631.173218,189.929779 630.055115,189.376740 
z"/>
<path fill="#8E7857" opacity="1.000000" stroke="none" 
	d="
M656.788391,196.584595 
	C656.370239,196.677246 655.755188,196.581375 655.084473,196.231140 
	C655.549744,196.116547 656.070618,196.256302 656.788391,196.584595 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M282.238525,803.129150 
	C283.244629,801.901672 284.414032,800.970215 286.158386,800.102295 
	C287.377991,800.541809 288.022644,800.917786 288.786835,801.602905 
	C294.236053,806.472900 299.565735,811.033691 304.918945,815.915710 
	C308.165588,819.079895 311.289551,822.045959 314.633423,824.738953 
	C320.686829,829.613953 326.871368,834.326050 332.763763,839.351318 
	C331.981598,840.803345 331.436859,842.010254 330.540222,843.172485 
	C322.416992,837.195129 314.645721,831.262512 306.797913,824.978516 
	C305.968170,824.144592 305.214966,823.662170 304.461731,823.179688 
	C300.317596,819.793762 296.173462,816.407776 291.602325,812.587891 
	C290.467560,811.723083 289.759766,811.292236 289.052002,810.861450 
	C286.835266,808.382690 284.618530,805.903870 282.238525,803.129150 
z"/>
<path fill="#3A281B" opacity="1.000000" stroke="none" 
	d="
M306.874451,825.329895 
	C314.645721,831.262512 322.416992,837.195129 330.640137,843.369690 
	C331.274475,843.860229 331.515076,844.023010 331.964355,844.408691 
	C334.683136,846.265747 337.251312,847.814087 339.961975,849.560608 
	C340.542450,849.947083 340.980377,850.135376 341.603271,850.617432 
	C344.146179,852.333618 346.504120,853.755981 348.930725,855.108337 
	C348.999420,855.038330 348.847961,855.162109 348.975159,855.409058 
	C349.479156,856.063477 349.855957,856.470947 349.809509,856.903687 
	C347.991058,856.936157 346.595917,856.943420 345.200745,856.950684 
	C337.311829,851.658264 329.412262,846.381592 321.538330,841.066895 
	C317.606018,838.412659 313.720551,835.689087 309.445099,832.953979 
	C307.948029,831.641235 306.819733,830.371338 305.218170,828.843750 
	C304.538269,828.356079 304.309174,828.151367 304.057556,827.971863 
	C304.050476,827.536133 304.043396,827.100342 304.033203,826.338135 
	C304.285126,825.745300 304.540131,825.478943 305.300323,825.102417 
	C306.161835,825.104858 306.518158,825.217407 306.874451,825.329895 
z"/>
<path fill="#3A281B" opacity="1.000000" stroke="none" 
	d="
M214.224609,713.146851 
	C215.194839,714.282410 216.117432,715.795593 217.078552,717.650757 
	C216.137024,716.503296 215.156998,715.013916 214.224609,713.146851 
z"/>
<path fill="#1E1C1D" opacity="1.000000" stroke="none" 
	d="
M301.689941,826.004761 
	C301.399139,826.169006 300.766235,826.326294 300.056274,826.240601 
	C300.435455,825.997681 300.891632,825.997742 301.689941,826.004761 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M306.797943,824.978516 
	C306.518158,825.217407 306.161835,825.104858 305.529053,824.945801 
	C305.073212,824.519592 304.893799,824.139954 304.588074,823.469971 
	C305.214966,823.662170 305.968170,824.144592 306.797943,824.978516 
z"/>
<path fill="#443825" opacity="1.000000" stroke="none" 
	d="
M289.154572,811.181091 
	C289.759766,811.292236 290.467560,811.723083 291.306702,812.452759 
	C290.711090,812.334717 289.984100,811.917725 289.154572,811.181091 
z"/>
<path fill="#2F2015" opacity="1.000000" stroke="none" 
	d="
M304.187195,828.165283 
	C304.309174,828.151367 304.538269,828.356079 304.889893,828.765259 
	C304.756409,828.797302 304.517059,828.602051 304.187195,828.165283 
z"/>
<path fill="#383335" opacity="1.000000" stroke="none" 
	d="
M288.959290,621.131287 
	C289.768555,620.136475 290.577820,619.141724 291.766418,618.439941 
	C294.185760,625.769714 297.073273,627.834900 305.011414,628.310059 
	C305.156555,629.358704 305.474091,630.050293 305.358185,630.659424 
	C304.735199,633.933838 304.881500,639.279358 303.128693,640.014343 
	C299.451935,641.556152 296.714050,644.738281 293.872986,645.858582 
	C286.813110,648.642395 286.844757,653.387634 287.002838,658.966431 
	C287.089508,662.025452 285.567017,663.066711 282.711456,663.012268 
	C278.724121,662.936340 274.644897,662.538879 270.780029,663.241699 
	C268.690613,663.621765 265.631836,665.759644 265.310455,667.523560 
	C264.045105,674.468689 263.215088,681.576477 263.160736,688.631042 
	C263.121613,693.705444 261.583313,695.326904 256.562469,695.111267 
	C250.177002,694.837158 249.275513,696.309021 248.949646,702.638550 
	C248.422882,712.870605 247.615204,723.089355 246.811981,733.304749 
	C246.660843,735.226685 245.976501,737.106628 245.525986,739.058960 
	C243.828262,738.295471 242.405609,737.655701 240.991180,737.007935 
	C240.999390,737.000000 240.987350,737.017212 240.956665,736.666687 
	C240.095261,734.997070 239.264542,733.678040 238.285629,732.123657 
	C238.987274,731.597900 240.009064,731.233276 240.166321,730.639648 
	C240.645813,728.829529 240.792130,726.931091 241.068115,725.067078 
	C239.588120,725.701172 238.095901,726.308777 236.634109,726.982361 
	C236.054688,727.249390 235.542740,727.662964 234.807465,727.732056 
	C230.606812,724.041931 228.667313,719.768982 228.428452,714.444702 
	C228.299088,711.560852 227.610092,708.537109 223.708984,707.789551 
	C222.523560,707.562378 221.209961,706.147217 220.628021,704.949219 
	C218.119858,699.785889 215.971680,694.446838 213.436752,689.297729 
	C212.788010,687.979980 211.204163,687.122620 210.049927,686.053772 
	C208.075470,679.952942 206.101013,673.852051 203.974609,667.098511 
	C203.222717,665.965149 202.622772,665.484558 202.022827,665.003906 
	C202.018967,663.938599 202.015121,662.873291 202.016510,661.408875 
	C203.345459,662.169800 204.972366,663.130493 205.904343,664.548157 
	C206.769867,665.864746 206.694809,667.767700 207.417313,669.221985 
	C207.868759,670.130554 209.020691,671.227356 209.876358,671.242798 
	C210.675247,671.257263 212.045425,670.067932 212.220993,669.214417 
	C213.318359,663.879150 218.557144,659.551086 216.108353,653.331177 
	C215.844131,652.659973 215.960205,651.386536 216.423981,650.952454 
	C220.410126,647.222046 224.608688,643.716858 228.561752,639.953186 
	C232.470505,636.231689 237.477600,632.881714 239.569977,628.265930 
	C241.465729,624.083984 242.435684,621.571899 247.945221,623.168701 
	C255.385361,630.442200 260.249176,630.421326 266.027710,623.281738 
	C266.948822,622.143616 267.860352,620.997742 269.144714,619.893433 
	C270.001923,619.956787 270.490723,619.982117 270.989136,620.434326 
	C271.013275,622.285034 271.027863,623.708740 270.921631,625.202515 
	C270.641174,625.553528 270.720734,625.768555 271.035126,625.943481 
	C271.030762,625.969299 271.082520,625.961548 271.204163,626.217163 
	C272.183167,626.592834 273.040497,626.712830 274.189026,626.787048 
	C274.971252,626.443176 275.462250,626.145020 276.075714,625.778809 
	C276.357574,625.434326 276.287994,625.206970 275.996765,624.586426 
	C276.013153,621.790527 276.022156,619.436768 276.372009,617.109741 
	C278.989197,619.623535 281.128265,622.259644 283.600189,624.533997 
	C284.819611,625.655945 287.098206,627.114868 288.119781,626.663025 
	C290.456360,625.629456 291.111115,623.356873 288.959290,621.131287 
M216.501266,689.472229 
	C216.501266,689.472229 216.516891,689.514343 216.501266,689.472229 
z"/>
<path fill="#4F3D2B" opacity="1.000000" stroke="none" 
	d="
M209.897247,686.227234 
	C211.204163,687.122620 212.788010,687.979980 213.436752,689.297729 
	C215.971680,694.446838 218.119858,699.785889 220.628021,704.949219 
	C221.209961,706.147217 222.523560,707.562378 223.708984,707.789551 
	C227.610092,708.537109 228.299088,711.560852 228.428452,714.444702 
	C228.667313,719.768982 230.606812,724.041931 234.876144,728.093750 
	C236.590759,731.845215 236.104401,736.327026 240.987350,737.017212 
	C240.987350,737.017212 240.999390,737.000000 241.063751,737.355225 
	C244.184937,742.807190 246.939957,748.118225 250.370941,752.949219 
	C255.252533,759.822815 260.620270,766.351990 265.804047,773.009766 
	C267.104462,774.679932 268.733765,776.141418 269.782440,777.948059 
	C270.999664,780.044983 271.743927,782.416382 272.693451,784.668701 
	C265.009735,775.544495 256.935425,766.717468 249.721420,757.235840 
	C233.359894,735.731384 219.889191,712.462769 209.006561,687.354736 
	C209.285706,686.812256 209.524277,686.617676 209.897247,686.227234 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M202.106476,665.293884 
	C202.622772,665.484558 203.222717,665.965149 203.902618,666.740356 
	C203.385101,666.551270 202.787613,666.067566 202.106476,665.293884 
z"/>
<path fill="#0A0808" opacity="1.000000" stroke="none" 
	d="
M268.776337,619.855469 
	C267.860352,620.997742 266.948822,622.143616 266.027710,623.281738 
	C260.249176,630.421326 255.385361,630.442200 248.330765,623.167236 
	C246.646942,619.918579 246.564331,617.251404 250.500168,615.394775 
	C251.278656,616.689514 251.724701,618.401245 252.803741,619.034851 
	C255.492294,620.613403 258.389191,622.003906 261.380981,622.792542 
	C262.223389,623.014648 264.331207,621.068604 264.674408,619.797424 
	C265.004913,618.573059 264.169159,616.578918 263.183411,615.570435 
	C261.976288,614.335388 260.144684,613.611328 258.471436,612.947998 
	C249.964844,609.575684 249.282211,608.563477 250.133514,599.463745 
	C254.142242,596.008301 257.863037,591.611938 263.515625,595.717590 
	C264.770721,596.629150 266.466583,598.826660 266.119232,599.700745 
	C265.029449,602.442993 262.823364,601.551819 260.862915,600.220642 
	C260.193665,599.766296 259.413727,599.474915 257.966766,599.059509 
	C256.221893,600.170654 254.133072,601.721619 254.386688,602.411499 
	C254.927292,603.881897 256.683258,604.905396 257.941223,606.112061 
	C261.569702,608.194824 265.198151,610.277588 268.932953,613.112183 
	C268.951660,615.861206 268.864014,617.858337 268.776337,619.855469 
z"/>
<path fill="#3D373A" opacity="1.000000" stroke="none" 
	d="
M272.713257,784.752319 
	C271.743927,782.416382 270.999664,780.044983 269.782440,777.948059 
	C268.733765,776.141418 267.104462,774.679932 265.804047,773.009766 
	C260.620270,766.351990 255.252533,759.822815 250.370941,752.949219 
	C246.939957,748.118225 244.184937,742.807190 241.055527,737.363220 
	C242.405609,737.655701 243.828262,738.295471 245.525986,739.058960 
	C245.976501,737.106628 246.660843,735.226685 246.811981,733.304749 
	C247.615204,723.089355 248.422882,712.870605 248.949646,702.638550 
	C249.275513,696.309021 250.177002,694.837158 256.562469,695.111267 
	C261.583313,695.326904 263.121613,693.705444 263.160736,688.631042 
	C263.215088,681.576477 264.045105,674.468689 265.310455,667.523560 
	C265.631836,665.759644 268.690613,663.621765 270.780029,663.241699 
	C274.644897,662.538879 278.724121,662.936340 282.711456,663.012268 
	C285.567017,663.066711 287.089508,662.025452 287.002838,658.966431 
	C286.844757,653.387634 286.813110,648.642395 293.872986,645.858582 
	C296.714050,644.738281 299.451935,641.556152 303.128693,640.014343 
	C304.881500,639.279358 304.735199,633.933838 305.358185,630.659424 
	C305.474091,630.050293 305.156555,629.358704 305.282227,628.155396 
	C306.676300,626.729065 307.827209,625.852539 309.190033,625.275757 
	C310.189789,628.339172 310.894318,631.224121 315.739563,629.032349 
	C314.305145,626.885559 312.969940,624.887329 311.647034,622.546387 
	C311.648651,621.524902 311.637970,620.846069 312.019867,620.134033 
	C312.936646,620.067993 313.460846,620.035217 314.095917,620.342773 
	C317.288300,628.113647 325.173553,630.221680 331.177765,625.151855 
	C336.408081,620.735596 336.300171,615.118530 334.994263,609.151001 
	C336.326569,609.072754 337.215576,609.033142 338.125824,609.461426 
	C338.161438,614.224121 337.959381,618.536926 338.315338,622.803223 
	C338.429688,624.173767 340.016541,625.421509 340.929749,626.725403 
	C341.921051,625.444824 343.606659,624.247253 343.771637,622.867859 
	C344.239868,618.952698 344.086792,614.963196 344.445251,610.905151 
	C345.141785,610.215454 345.571381,609.623596 346.413513,608.977356 
	C351.465668,608.382019 353.117279,610.728088 352.982391,615.069580 
	C352.857788,619.079346 352.955872,623.095947 352.955872,627.109558 
	C353.701385,627.305298 354.446869,627.500977 355.192383,627.696716 
	C356.123932,625.968628 357.619110,624.316223 357.863556,622.495850 
	C358.301208,619.236694 358.044006,615.875061 357.972595,612.558533 
	C357.836090,606.217957 354.743805,604.062012 348.534576,605.612854 
	C347.063873,605.980225 345.381439,605.500122 343.964050,605.062683 
	C346.762817,597.949890 352.078766,602.969177 356.206635,602.211182 
	C354.882019,600.684814 353.190033,599.538696 352.696533,598.006531 
	C351.509277,594.320618 348.892914,594.236389 345.731445,593.864075 
	C342.421082,593.474243 339.238464,592.000183 336.000305,590.583435 
	C335.762268,586.893555 336.876160,585.227600 340.140320,583.378052 
	C344.487488,580.914917 347.803284,576.549377 351.327087,572.781250 
	C352.070801,571.985901 351.865601,570.245605 351.980774,568.930542 
	C352.357361,564.630249 352.470703,560.292603 353.133789,556.039246 
	C353.374634,554.494446 354.931549,551.873596 355.797424,551.909729 
	C362.850006,552.204651 367.164703,548.221741 371.793671,543.675415 
	C373.749847,541.754211 375.291534,540.503967 374.986298,537.803467 
	C373.925354,528.416870 383.878906,526.148987 386.872375,519.385132 
	C386.958862,519.189636 387.476410,519.140442 387.802277,519.102356 
	C391.380005,518.683960 390.944824,516.652344 390.076233,514.069092 
	C387.238922,505.631042 389.146606,503.040955 397.994781,503.000732 
	C398.661377,502.997681 399.328003,503.001556 399.994598,502.999756 
	C406.902435,502.980835 406.950897,502.980927 406.983948,496.167969 
	C407.005585,491.710205 407.595367,487.822815 413.003632,486.690186 
	C413.829651,486.517181 414.764130,484.780670 414.889526,483.680817 
	C415.712555,476.461761 416.470886,469.228333 416.943939,461.979614 
	C417.153839,458.763763 418.159485,457.251892 421.597168,456.896423 
	C425.815125,456.460327 430.130829,455.820221 432.282410,451.015625 
	C432.769867,449.927155 435.165649,449.828522 436.423462,448.916351 
	C438.088440,447.708893 439.509674,446.161560 441.014038,444.737396 
	C442.299957,443.520050 443.494781,441.302856 444.835785,441.218903 
	C448.663940,440.979218 449.572571,438.751312 449.938477,435.692200 
	C450.477844,431.183136 451.011230,426.673370 451.547180,422.163910 
	C453.096680,427.963165 452.412415,435.034149 461.485718,435.547546 
	C461.642426,440.640747 461.642548,445.297180 461.308838,450.226898 
	C460.904572,456.999023 460.834167,463.497864 460.438629,470.033813 
	C454.842651,473.398346 454.432526,474.921783 458.107849,477.315613 
	C452.070465,486.300903 446.663330,495.287231 444.611359,505.681213 
	C441.364227,522.129272 440.819092,538.644775 444.772858,554.995300 
	C438.768219,553.312073 442.559387,559.675842 439.248993,560.566223 
	C438.052460,560.011536 436.484711,559.284790 434.451202,558.342163 
	C433.749603,560.039124 432.746613,561.480103 432.601440,563.002869 
	C432.185852,567.362122 429.731873,568.517944 425.830353,568.022949 
	C424.687134,567.877930 423.508301,568.015686 422.345795,568.020081 
	C418.003479,568.036316 415.982910,570.295715 416.012543,574.554321 
	C416.022949,576.048889 415.927979,577.547974 416.013123,579.037476 
	C416.172699,581.829773 415.593903,583.716675 412.257233,584.296814 
	C408.618927,584.929443 408.122955,587.746155 408.195801,591.148865 
	C408.318451,596.876099 411.158508,600.975037 414.685730,604.790405 
	C413.223969,605.231323 412.098999,605.562317 410.839966,605.573486 
	C408.216217,604.389893 405.726501,603.526062 403.236816,602.662292 
	C402.938324,603.524841 402.639801,604.387390 402.341309,605.249939 
	C404.304962,605.864868 406.268616,606.479736 408.166077,607.396851 
	C407.495911,608.486145 406.891907,609.273193 405.812286,610.044556 
	C404.385101,610.005737 403.433533,609.982605 402.314758,609.699707 
	C398.170654,603.799194 392.977173,603.448853 389.195343,608.566162 
	C397.311493,608.520020 398.383698,610.065369 394.841919,617.117432 
	C393.388153,617.885315 392.117828,618.504517 390.485199,619.029602 
	C389.070892,616.395508 388.018860,613.855652 387.039246,611.490540 
	C383.419495,616.021484 383.951508,620.847351 388.732971,627.107727 
	C385.478943,627.451904 382.512299,627.567383 379.740906,627.361267 
	C380.570404,622.028809 381.659515,617.014771 381.625275,612.008484 
	C381.610657,609.868530 379.697388,606.621704 377.851501,605.893311 
	C375.460907,604.949951 372.275574,606.020447 369.432404,606.223999 
	C369.086700,606.923096 368.740997,607.622192 368.395294,608.321289 
	C378.311615,607.723694 375.845520,614.157593 376.261322,619.134338 
	C376.502930,622.025818 377.394073,624.863098 377.984802,628.135803 
	C376.556396,633.774353 375.049225,638.980225 373.745392,644.236572 
	C372.822235,647.958191 371.039581,649.517212 366.949921,648.579651 
	C362.469635,647.552490 360.390656,649.372681 360.055756,653.925537 
	C359.897339,656.078918 360.176239,658.274780 359.900818,660.405273 
	C359.750977,661.564209 359.017700,663.454895 358.270020,663.601624 
	C352.287140,664.776245 351.657440,669.139343 352.063171,673.867981 
	C352.480835,678.735168 350.571442,680.466248 345.798126,680.058655 
	C341.189392,679.665222 336.468842,679.606873 335.778076,686.069336 
	C335.720245,686.610291 335.075867,687.476135 334.625153,687.529907 
	C327.086975,688.428894 328.084991,694.028015 327.903198,699.189819 
	C327.848297,700.749451 326.879669,703.466858 325.990143,703.614990 
	C319.996460,704.612976 319.917877,708.770020 319.969452,713.352783 
	C320.043732,719.953003 319.954590,719.953979 313.324646,720.000122 
	C305.574158,720.054077 304.036499,721.543213 304.028748,729.404419 
	C304.025330,732.862549 304.280396,736.048523 299.427155,736.651123 
	C298.187134,736.805115 297.151215,739.143738 296.161407,740.575317 
	C295.535278,741.480896 295.282928,743.358765 294.667145,743.447937 
	C288.081360,744.401001 288.932312,749.293457 289.009552,753.750977 
	C289.076569,757.615356 288.054077,760.003052 283.432556,760.111633 
	C277.786224,760.244202 276.341003,763.194885 278.386444,768.532776 
	C279.109344,770.419250 279.481934,772.599854 279.370667,774.610535 
	C279.178009,778.093201 278.483368,781.548157 277.575043,785.011719 
	C275.729736,784.976257 274.308624,784.943726 272.887543,784.911133 
	C272.887543,784.911133 272.733093,784.835876 272.713257,784.752319 
M365.383087,626.277222 
	C365.895905,625.852539 366.408722,625.427856 367.036316,624.111572 
	C367.047546,621.420898 367.058807,618.730225 367.371368,615.496826 
	C367.274170,614.315247 367.177002,613.133606 367.137360,611.233887 
	C366.871490,609.836365 366.605652,608.438904 366.503967,606.534973 
	C364.075226,604.036377 363.091919,605.680359 362.212158,608.916870 
	C362.164398,613.306458 361.884491,617.713013 362.203308,622.075806 
	C362.305450,623.473206 363.991394,624.754822 365.383087,626.277222 
z"/>
<path fill="#4F3D2B" opacity="1.000000" stroke="none" 
	d="
M273.015564,785.236572 
	C274.308624,784.943726 275.729736,784.976257 277.753784,785.297119 
	C279.322998,786.458679 280.359833,787.266357 281.243042,788.216797 
	C284.690491,791.926025 290.840057,793.983276 288.986694,801.004395 
	C288.880798,801.101501 288.667297,801.293762 288.667297,801.293762 
	C288.022644,800.917786 287.377991,800.541809 286.485840,800.033813 
	C281.873413,795.122009 277.508514,790.342041 273.015564,785.236572 
z"/>
<path fill="#211F20" opacity="1.000000" stroke="none" 
	d="
M884.615356,368.995178 
	C884.997437,369.449554 885.000305,369.892975 884.945374,370.996796 
	C885.258423,373.438110 885.629211,375.219055 886.067627,377.347656 
	C888.551270,382.784210 894.107117,386.423187 893.190491,393.094666 
	C893.109924,393.680908 894.237793,394.355408 894.580688,395.092865 
	C895.459290,396.982605 896.213928,398.930054 897.014099,400.856140 
	C897.633972,402.348083 897.968018,404.056458 898.915833,405.296600 
	C902.047424,409.394135 905.252441,413.281555 904.317627,419.078888 
	C904.120667,420.300262 905.442444,422.362549 906.640198,423.091919 
	C909.345886,424.739563 910.323242,426.560974 909.467651,429.696625 
	C909.183655,430.737305 909.885132,432.338226 910.624695,433.311890 
	C912.778381,436.147400 916.167175,438.449860 917.265625,441.592621 
	C919.212280,447.162170 919.868469,453.189514 920.972839,459.042603 
	C921.308960,460.823639 920.772095,463.002136 921.614014,464.415405 
	C925.612366,471.126770 928.914856,477.962250 928.562378,486.051849 
	C928.520447,487.012573 929.043945,487.985901 929.209351,488.969574 
	C930.877930,498.893280 934.919006,508.362793 934.125977,518.785095 
	C933.701965,524.358032 934.231262,530.072266 934.935181,535.646240 
	C936.009705,544.155090 937.686768,552.586365 938.913208,561.078735 
	C939.138733,562.640442 938.292358,564.326782 938.190125,565.971069 
	C937.636963,574.867920 937.076904,583.766052 936.694702,592.671265 
	C936.488464,597.474854 937.987122,602.854004 936.284485,606.966614 
	C934.039124,612.389893 934.156372,617.606079 934.001160,623.032776 
	C933.929810,625.528687 934.108948,628.058105 933.766418,630.515442 
	C932.246155,641.422668 930.630615,652.317505 928.931030,663.198242 
	C928.426819,666.426270 928.590698,671.098083 926.610291,672.486633 
	C921.996277,675.721619 922.397034,679.852966 921.914612,684.266113 
	C921.489807,688.151611 921.432800,692.767822 919.292664,695.620422 
	C915.697327,700.412781 913.210571,705.163452 912.934875,711.132263 
	C912.728149,715.608398 912.510193,720.103394 911.881714,724.531006 
	C911.631104,726.296326 910.684143,728.452820 909.331970,729.489014 
	C902.641052,734.616882 898.917786,741.417297 897.036560,749.443054 
	C895.852722,754.493652 897.405151,760.791626 890.679993,763.389587 
	C890.124268,763.604248 890.109375,764.962036 889.581055,765.555237 
	C888.300720,766.993164 887.093506,768.846985 885.442444,769.526672 
	C882.538208,770.721985 882.053772,772.691101 881.939575,775.409729 
	C881.830139,778.013855 881.975098,781.158325 880.610840,783.066101 
	C878.037048,786.665344 874.896423,790.273254 871.171021,792.513306 
	C867.753235,794.568481 866.271240,797.187866 865.215210,800.538818 
	C864.042358,804.260620 863.436157,808.349854 858.383484,809.067871 
	C857.667664,809.169556 856.749023,810.421814 856.573059,811.275269 
	C855.967468,814.212036 854.500732,815.959656 851.432617,816.534729 
	C850.458984,816.717224 849.468506,817.779053 848.842346,818.685059 
	C845.416748,823.642334 842.245972,828.781677 838.689392,833.640076 
	C836.880188,836.111389 834.939575,838.593872 830.837585,836.486877 
	C829.245056,835.668945 825.799377,836.355530 824.394592,837.652710 
	C819.302734,842.354553 814.735046,847.622009 809.930176,852.638245 
	C808.831543,853.785278 807.655640,855.582581 806.378174,855.710510 
	C800.338257,856.315308 799.713501,860.261047 799.997559,864.999390 
	C797.880188,865.188904 795.762756,865.378418 792.943848,865.859375 
	C790.295288,866.586060 787.742859,866.388672 786.509216,867.569275 
	C781.913025,871.967651 776.615295,874.267212 770.616821,876.470276 
	C759.950623,880.387695 749.932617,886.123413 739.775330,891.354553 
	C732.799377,894.947266 723.250854,893.502319 718.749023,902.083923 
	C718.387146,902.773804 716.650391,902.788513 715.533386,903.027893 
	C712.145508,903.753967 708.720947,904.325989 705.360474,905.159119 
	C699.142761,906.700684 692.951965,908.353455 686.769470,910.031921 
	C685.240173,910.447021 682.893494,910.750244 682.450317,911.769287 
	C680.439514,916.392578 676.676941,916.091614 672.783447,916.032043 
	C670.969299,916.004211 669.074036,916.042236 667.345886,916.520813 
	C659.382629,918.726318 651.587219,921.750488 643.503601,923.260559 
	C630.549683,925.680481 617.438171,927.321167 604.347778,928.918091 
	C598.268311,929.659729 592.068726,929.354004 585.946472,929.822021 
	C573.677673,930.759827 561.360840,933.570740 549.344543,928.304016 
	C548.634399,927.992798 547.708679,928.135986 546.882935,928.131348 
	C534.442810,928.061279 521.996704,928.204712 509.565063,927.846252 
	C504.135223,927.689697 498.643921,926.902649 493.349915,925.672791 
	C489.100769,924.685608 485.107086,922.598755 481.000000,921.000000 
	C486.147644,921.438416 491.305420,921.787842 496.436279,922.373291 
	C497.854919,922.535095 499.166595,923.782593 500.572815,923.880798 
	C511.938416,924.675049 523.314270,925.323853 534.688232,925.996704 
	C542.515991,926.459839 550.455200,923.645874 558.244812,927.538818 
	C560.391357,928.611572 563.753662,927.070190 566.562012,927.034729 
	C574.334595,926.936523 582.168823,927.613647 589.869812,926.864563 
	C603.531616,925.535767 617.124695,923.461975 630.723022,921.534485 
	C637.102051,920.630249 643.577698,919.859741 649.728821,918.066101 
	C654.833740,916.577576 659.516541,913.654968 664.411133,911.422119 
	C665.604736,910.877625 667.092957,909.904114 668.111511,910.216553 
	C678.513245,913.408569 686.515503,906.119507 695.692261,904.077515 
	C698.424438,903.469604 701.142578,902.594543 703.706299,901.473816 
	C710.138977,898.661926 716.360229,895.334106 722.887756,892.779114 
	C731.018677,889.596436 739.399109,887.056030 747.606812,884.060242 
	C749.294800,883.444153 750.597229,881.836304 752.249695,881.045715 
	C755.891968,879.303101 759.525085,877.339661 763.387329,876.319275 
	C766.752380,875.430237 768.340942,874.474792 767.517822,870.635742 
	C766.682495,866.739807 769.235840,864.685181 772.767456,864.169312 
	C777.789612,863.435669 782.717285,863.002808 784.362122,856.803833 
	C784.646545,855.732117 786.655823,855.117981 787.868164,854.292297 
	C789.922791,852.893127 791.980957,851.499207 794.029968,850.092041 
	C794.957275,849.455200 795.834717,848.284485 796.789001,848.224915 
	C801.993469,847.900269 805.759888,845.786194 808.622375,841.209778 
	C809.692566,839.498657 812.693665,838.343628 814.922424,838.144287 
	C819.600952,837.725891 822.055298,835.752136 821.990417,831.000183 
	C821.933533,826.825134 823.936584,824.769897 827.752014,822.786743 
	C832.424561,820.358154 835.866821,815.606079 840.495850,813.039673 
	C846.710693,809.593994 849.912903,803.804443 853.508118,798.419495 
	C857.434937,792.537659 865.184692,789.266785 865.502625,780.796692 
	C865.548828,779.565491 867.834351,777.479187 869.172974,777.405151 
	C873.885376,777.144775 875.986389,774.991089 876.530151,770.403015 
	C876.877380,767.473083 878.046692,764.640564 879.313232,761.374390 
	C880.967285,760.519714 882.927185,760.339905 883.220581,759.544983 
	C885.424194,753.574829 888.112305,748.015564 891.501709,742.511353 
	C894.482483,737.671021 894.615906,731.090332 896.075806,725.295532 
	C897.002258,721.618469 896.928284,716.423523 899.320557,714.683899 
	C902.743469,712.194763 904.255127,709.807739 905.862732,705.985657 
	C908.903687,698.755859 912.215332,691.890869 911.389832,683.765198 
	C911.295776,682.838928 912.445251,681.828308 912.879211,680.798218 
	C913.962219,678.228088 915.225708,675.694275 915.944824,673.019043 
	C917.354919,667.773071 917.722900,662.115662 919.914429,657.256531 
	C921.424927,653.907410 921.767151,651.333923 920.996338,647.897095 
	C920.524658,645.794006 921.584717,643.271301 922.237305,641.018372 
	C922.683411,639.478577 923.982971,638.147156 924.267151,636.605652 
	C926.257935,625.805359 928.538696,615.028687 929.833191,604.138245 
	C931.104187,593.445129 931.996887,582.615356 931.820007,571.865906 
	C931.616394,559.492737 930.077271,547.140381 929.081177,534.781921 
	C928.256836,524.554077 927.817139,514.270020 926.418030,504.119629 
	C924.480469,490.062317 921.706299,476.126068 916.739563,462.748993 
	C915.636963,459.779327 914.257141,456.912567 913.004883,453.998413 
	C913.496155,445.567200 909.941956,438.693512 905.417114,431.842499 
	C903.044434,428.250031 902.559875,423.432892 901.085327,419.216827 
	C900.123657,416.467285 898.881226,413.817230 897.826416,411.098663 
	C896.515869,407.721100 895.417175,404.252686 893.942383,400.950653 
	C892.966125,398.764771 891.800232,396.505402 890.171936,394.802124 
	C886.915466,391.395721 885.519287,387.598297 884.728455,382.907166 
	C884.039673,378.821259 881.514709,374.811249 879.007568,371.340942 
	C877.070984,368.660187 876.557861,364.453674 871.805481,363.883301 
	C870.655457,363.745270 870.119873,359.875458 868.985352,357.903809 
	C867.164917,354.740082 865.137146,351.688171 863.029297,348.707550 
	C862.791077,348.370667 861.472778,348.797485 860.326416,348.913940 
	C859.997559,348.957764 859.992065,348.501343 859.995605,347.857727 
	C859.180054,344.906830 858.258789,342.629059 857.580566,340.281067 
	C857.185242,338.912506 857.178894,337.431580 856.997559,336.001221 
	C858.503906,337.174133 860.224915,338.157166 861.468445,339.562622 
	C863.551392,341.916626 865.388428,344.498260 867.219482,347.062256 
	C868.932678,349.461273 870.453003,351.997467 872.148071,354.410187 
	C875.498474,359.179138 878.885193,363.923523 882.345886,368.612396 
	C882.627319,368.993652 883.589966,368.872131 884.615356,368.995178 
z"/>
<path fill="#242223" opacity="1.000000" stroke="none" 
	d="
M800.342102,864.832581 
	C799.713501,860.261047 800.338257,856.315308 806.378174,855.710510 
	C807.655640,855.582581 808.831543,853.785278 809.930176,852.638245 
	C814.735046,847.622009 819.302734,842.354553 824.394592,837.652710 
	C825.799377,836.355530 829.245056,835.668945 830.837585,836.486877 
	C834.939575,838.593872 836.880188,836.111389 838.689392,833.640076 
	C842.245972,828.781677 845.416748,823.642334 848.842346,818.685059 
	C849.468506,817.779053 850.458984,816.717224 851.432617,816.534729 
	C854.500732,815.959656 855.967468,814.212036 856.573059,811.275269 
	C856.749023,810.421814 857.667664,809.169556 858.383484,809.067871 
	C863.436157,808.349854 864.042358,804.260620 865.215210,800.538818 
	C866.271240,797.187866 867.753235,794.568481 871.171021,792.513306 
	C874.896423,790.273254 878.037048,786.665344 880.610840,783.066101 
	C881.975098,781.158325 881.830139,778.013855 881.939575,775.409729 
	C882.053772,772.691101 882.538208,770.721985 885.442444,769.526672 
	C887.093506,768.846985 888.300720,766.993164 889.581055,765.555237 
	C890.109375,764.962036 890.124268,763.604248 890.679993,763.389587 
	C897.405151,760.791626 895.852722,754.493652 897.036560,749.443054 
	C898.917786,741.417297 902.641052,734.616882 909.331970,729.489014 
	C910.684143,728.452820 911.631104,726.296326 911.881714,724.531006 
	C912.510193,720.103394 912.728149,715.608398 912.934875,711.132263 
	C913.210571,705.163452 915.697327,700.412781 919.292664,695.620422 
	C921.432800,692.767822 921.489807,688.151611 921.914612,684.266113 
	C922.397034,679.852966 921.996277,675.721619 926.610291,672.486633 
	C928.590698,671.098083 928.426819,666.426270 928.931030,663.198242 
	C930.630615,652.317505 932.246155,641.422668 933.766418,630.515442 
	C934.108948,628.058105 933.929810,625.528687 934.001160,623.032776 
	C934.156372,617.606079 934.039124,612.389893 936.284485,606.966614 
	C937.987122,602.854004 936.488464,597.474854 936.694702,592.671265 
	C937.076904,583.766052 937.636963,574.867920 938.190125,565.971069 
	C938.292358,564.326782 939.138733,562.640442 938.913208,561.078735 
	C937.686768,552.586365 936.009705,544.155090 934.935181,535.646240 
	C934.231262,530.072266 933.701965,524.358032 934.125977,518.785095 
	C934.919006,508.362793 930.877930,498.893280 929.209351,488.969574 
	C929.043945,487.985901 928.520447,487.012573 928.562378,486.051849 
	C928.914856,477.962250 925.612366,471.126770 921.614014,464.415405 
	C920.772095,463.002136 921.308960,460.823639 920.972839,459.042603 
	C919.868469,453.189514 919.212280,447.162170 917.265625,441.592621 
	C916.167175,438.449860 912.778381,436.147400 910.624695,433.311890 
	C909.885132,432.338226 909.183655,430.737305 909.467651,429.696625 
	C910.323242,426.560974 909.345886,424.739563 906.640198,423.091919 
	C905.442444,422.362549 904.120667,420.300262 904.317627,419.078888 
	C905.252441,413.281555 902.047424,409.394135 898.915833,405.296600 
	C897.968018,404.056458 897.633972,402.348083 897.014099,400.856140 
	C896.213928,398.930054 895.459290,396.982605 894.580688,395.092865 
	C894.237793,394.355408 893.109924,393.680908 893.190491,393.094666 
	C894.107117,386.423187 888.551270,382.784210 886.406494,377.422058 
	C889.451782,378.765900 892.225891,380.382935 895.000000,382.000000 
	C895.443909,382.000427 895.887878,382.000824 896.664673,382.001831 
	C896.998718,382.751221 896.999878,383.500031 897.001709,384.623169 
	C898.454956,390.503693 899.265198,396.293152 901.535095,401.438721 
	C903.951660,406.916626 908.151001,411.588379 910.799316,416.990845 
	C912.479065,420.417664 912.732300,424.539764 913.657288,428.342285 
	C914.071594,430.045776 914.014099,432.374146 915.114563,433.314453 
	C920.585022,437.988861 921.780457,444.549713 923.729126,450.869019 
	C926.152527,458.727692 928.842224,466.504089 931.383606,474.326813 
	C931.687622,475.262878 931.808411,476.262360 931.972168,477.239502 
	C933.363953,485.542236 933.139526,494.605530 936.599182,501.929474 
	C939.829529,508.767944 939.910095,515.163269 939.925842,521.981323 
	C939.931458,524.392212 939.177856,526.826355 938.611450,529.212097 
	C937.732056,532.916687 937.149109,536.449097 940.958252,539.058228 
	C941.723755,539.582642 942.482727,540.486816 942.717834,541.358093 
	C945.196411,550.544800 946.884949,559.762085 944.738464,569.345459 
	C943.995850,572.661011 944.067993,576.190552 944.023682,579.625610 
	C943.856384,592.613159 942.158691,605.298218 938.607849,617.873413 
	C936.835083,624.151672 937.160095,631.022278 936.536194,637.624939 
	C944.221680,646.781006 934.501221,654.063538 933.855164,662.326965 
	C933.354187,668.734131 932.628479,675.123840 931.998291,681.520874 
	C931.741211,684.130066 932.982300,687.635254 928.336731,687.646179 
	C928.068909,687.646790 927.594788,687.961243 927.564270,688.178467 
	C926.332397,696.952515 917.413574,702.771484 918.773682,712.830383 
	C919.542053,718.513611 920.735352,724.871155 914.746826,729.826172 
	C912.545959,731.647156 912.908691,736.368042 911.606812,739.564453 
	C911.027954,740.985718 909.389343,742.574402 907.944031,742.935608 
	C902.883545,744.200012 901.680786,747.526917 902.551331,751.990112 
	C903.241516,755.528564 903.331360,758.468140 899.593323,760.546448 
	C898.601501,761.097839 898.515808,763.157104 897.849304,764.438354 
	C895.830750,768.318481 893.689819,772.135132 891.685486,776.022339 
	C890.946716,777.455139 890.553284,779.065063 889.825806,780.505005 
	C889.037659,782.065125 888.230164,784.640076 887.106750,784.827393 
	C882.440186,785.605652 882.009705,789.260803 880.491089,792.549683 
	C879.156433,795.440125 876.676819,797.778931 875.054138,800.565857 
	C873.470825,803.285034 871.382629,806.209229 871.215881,809.140137 
	C870.949524,813.824524 869.134277,815.068359 864.782471,815.159485 
	C862.399597,815.209351 859.430115,816.268677 857.796692,817.927795 
	C851.564575,824.257690 845.685303,830.944519 839.847717,837.649048 
	C837.382996,840.479919 834.568726,841.603210 830.994690,840.749939 
	C827.861206,840.002014 826.535645,841.186279 825.806824,844.312134 
	C824.690308,849.100830 823.629028,854.092590 818.260742,856.419983 
	C817.151428,856.900879 816.039612,858.172852 815.633240,859.327942 
	C814.434631,862.734192 811.901367,862.939392 808.876770,863.088013 
	C806.127502,863.223083 803.415222,864.110168 800.342102,864.832581 
z"/>
<path fill="#232123" opacity="1.000000" stroke="none" 
	d="
M480.605438,920.993042 
	C485.107086,922.598755 489.100769,924.685608 493.349915,925.672791 
	C498.643921,926.902649 504.135223,927.689697 509.565063,927.846252 
	C521.996704,928.204712 534.442810,928.061279 546.882935,928.131348 
	C547.708679,928.135986 548.634399,927.992798 549.344543,928.304016 
	C561.360840,933.570740 573.677673,930.759827 585.946472,929.822021 
	C592.068726,929.354004 598.268311,929.659729 604.347778,928.918091 
	C617.438171,927.321167 630.549683,925.680481 643.503601,923.260559 
	C651.587219,921.750488 659.382629,918.726318 667.345886,916.520813 
	C669.074036,916.042236 670.969299,916.004211 672.783447,916.032043 
	C676.676941,916.091614 680.439514,916.392578 682.450317,911.769287 
	C682.893494,910.750244 685.240173,910.447021 686.769470,910.031921 
	C692.951965,908.353455 699.142761,906.700684 705.360474,905.159119 
	C708.720947,904.325989 712.145508,903.753967 715.533386,903.027893 
	C716.650391,902.788513 718.387146,902.773804 718.749023,902.083923 
	C723.250854,893.502319 732.799377,894.947266 739.775330,891.354553 
	C749.932617,886.123413 759.950623,880.387695 770.616821,876.470276 
	C776.615295,874.267212 781.913025,871.967651 786.509216,867.569275 
	C787.742859,866.388672 790.295288,866.586060 792.621094,866.075378 
	C792.073608,867.769226 791.544861,870.001892 790.137024,871.209900 
	C787.319885,873.627136 784.016541,875.476257 780.927490,877.579773 
	C780.519531,877.857605 780.215393,878.516968 779.822205,878.552979 
	C773.647461,879.119080 769.295166,883.718445 763.903320,885.728149 
	C757.268860,888.200989 750.390930,889.622192 744.634644,894.316895 
	C742.232788,896.275696 738.493591,896.571167 735.394714,897.710693 
	C734.163208,898.163574 732.284973,898.580872 731.950317,899.479614 
	C729.022827,907.340332 721.509583,905.359741 715.883057,906.741028 
	C711.815186,907.739685 707.964966,907.530212 704.122986,910.571655 
	C695.725952,917.218994 685.242249,919.179993 674.879761,921.056396 
	C670.009399,921.938293 664.145813,921.956299 660.656494,924.760254 
	C655.420044,928.968384 650.708008,930.065796 644.626709,928.259949 
	C643.639893,927.966980 642.238770,929.224670 640.985779,929.632324 
	C639.441833,930.134583 637.416260,931.337708 636.352600,930.785645 
	C628.721313,926.824890 622.257751,932.056091 615.292603,933.074829 
	C613.655701,933.314270 612.041443,933.915649 610.408936,933.947571 
	C590.295471,934.341064 570.173584,935.119751 550.068848,934.813477 
	C539.207642,934.648071 528.376404,932.584534 517.529297,931.398254 
	C510.043884,930.579712 502.471924,930.243774 495.089233,928.878052 
	C486.972351,927.376526 477.982300,928.237183 471.227295,921.249939 
	C472.336853,920.523132 473.687927,919.639099 475.009735,919.680908 
	C476.755463,919.736145 478.478241,920.517090 480.605438,920.993042 
z"/>
<path fill="#232123" opacity="1.000000" stroke="none" 
	d="
M445.078857,913.348877 
	C453.748383,911.844116 460.864197,915.749146 467.879974,920.671692 
	C462.132355,919.937378 456.198151,919.129150 450.425751,917.677490 
	C448.460602,917.183350 446.902863,915.069275 445.078857,913.348877 
z"/>
<path fill="#191718" opacity="1.000000" stroke="none" 
	d="
M761.000000,865.000000 
	C759.992798,866.297852 759.252808,868.094849 757.929932,868.802979 
	C753.378784,871.239136 748.642334,873.335083 743.937927,875.476074 
	C741.853088,876.424927 739.704346,877.254395 737.535950,877.995422 
	C726.989990,881.599670 718.546265,889.888000 706.808899,891.117737 
	C702.001465,891.621399 697.209290,893.967102 692.817932,896.256836 
	C688.650269,898.429993 685.229431,902.033081 681.065491,904.216492 
	C678.589966,905.514526 675.322693,905.363403 672.393311,905.711182 
	C670.809265,905.899109 669.126587,905.490356 667.593018,905.827148 
	C661.689270,907.123535 655.778259,908.436829 649.964600,910.079102 
	C644.250610,911.693237 638.650146,913.710266 633.002991,915.559937 
	C631.741516,915.973083 630.520081,916.681458 629.234009,916.817688 
	C621.679382,917.617981 614.092834,918.137512 606.552368,919.045410 
	C598.691345,919.991943 590.877319,921.324097 583.025879,922.359558 
	C581.457397,922.566406 579.778687,922.369324 578.209412,922.060669 
	C572.010681,920.841187 565.896484,919.510254 559.907043,923.133240 
	C558.685669,923.872070 556.737549,923.548279 555.133057,923.487610 
	C551.225586,923.339844 547.293884,922.640503 543.424316,922.911621 
	C532.710388,923.662109 522.295776,922.038574 511.927338,919.706665 
	C511.282135,919.561584 510.671356,919.223145 510.023132,919.138062 
	C501.992859,918.084290 493.946198,917.146301 485.932587,915.980225 
	C483.369202,915.607239 480.902069,914.605042 478.369934,913.975220 
	C476.130280,913.418274 473.880005,912.854370 471.601440,912.514709 
	C467.665985,911.928040 463.705017,911.512634 459.783569,910.165833 
	C464.065796,907.017517 468.658600,908.010498 473.205353,908.566772 
	C478.265900,909.185974 483.327148,909.799316 489.084930,910.203125 
	C493.489807,910.310242 497.192871,910.722229 500.907318,910.922302 
	C504.561310,911.119019 508.234039,911.274109 511.885101,911.123169 
	C514.933350,910.997131 517.962097,910.402222 521.436646,909.974548 
	C523.307922,909.761475 524.742065,909.588867 526.530273,909.501160 
	C527.589050,909.737061 528.293762,909.888062 529.024170,910.428284 
	C530.644531,911.866821 532.185486,913.708557 533.843750,913.821045 
	C539.447754,914.201416 545.094604,913.900513 550.722717,913.998108 
	C553.613770,914.048218 555.559326,913.127808 556.318359,909.911438 
	C557.095032,909.844604 557.548523,909.910828 558.118591,910.310059 
	C560.336792,911.283936 562.460632,912.524963 564.535461,912.447754 
	C569.730713,912.254456 574.907532,911.497498 580.084656,910.904236 
	C586.661194,910.150513 593.278198,909.606750 599.789490,908.467957 
	C605.948364,907.390686 611.931824,904.911743 618.103394,904.407837 
	C625.773682,903.781555 633.205750,903.630554 640.367493,899.884277 
	C645.089050,897.414490 650.663635,896.407593 655.968506,895.268250 
	C661.256226,894.132507 666.442383,889.667419 672.789978,895.693542 
	C672.172668,892.493164 671.854980,890.846191 671.582336,889.432861 
	C679.944580,885.847534 687.962830,882.409729 696.097412,879.071289 
	C696.213684,879.170715 696.647217,879.215576 696.647217,879.215576 
	C696.647217,879.215576 697.020752,878.966614 697.246033,878.958923 
	C697.471313,878.951233 697.922119,878.950012 698.283447,879.039551 
	C700.958313,880.009521 703.558716,880.479675 705.525757,881.855469 
	C709.655090,884.743652 713.649963,884.821045 717.512024,881.932312 
	C721.001160,879.322449 725.874878,876.797546 726.987427,873.247681 
	C728.228149,869.289062 730.336304,868.809143 733.077759,868.271667 
	C736.422485,867.615906 740.095886,868.072815 743.209778,866.920715 
	C749.721863,864.511292 752.305725,856.149475 760.044983,855.272522 
	C760.170105,855.258423 760.249207,854.990601 760.388855,854.927307 
	C762.554382,853.945190 764.679016,852.846680 766.908203,852.036987 
	C770.280823,850.811829 773.763306,849.884155 777.113281,848.605347 
	C777.807922,848.340149 778.695862,846.872192 778.492432,846.345703 
	C778.146362,845.449829 777.115784,844.575012 776.172180,844.209473 
	C774.688782,843.634888 773.038879,843.490234 771.459412,843.163574 
	C771.470215,842.670959 771.480957,842.178345 771.491760,841.685730 
	C776.584290,840.351074 781.676880,839.016418 786.028137,837.876038 
	C788.915710,835.736389 791.504150,833.970947 793.909851,831.984253 
	C795.629456,830.564209 797.975830,829.104370 798.532104,827.217102 
	C800.590210,820.234863 806.184265,817.288391 812.062012,815.208069 
	C819.753113,812.485840 823.557312,806.710632 821.505554,798.216980 
	C823.209106,797.834412 824.922485,797.090393 826.596741,797.170105 
	C828.438965,797.257751 830.245056,798.103088 833.414978,798.999512 
	C830.586609,793.828491 833.847900,792.565674 836.630615,790.591125 
	C838.402893,789.333496 839.415405,786.992004 840.734680,785.112732 
	C842.409363,782.727356 843.942871,780.235046 845.725403,777.934631 
	C850.592590,771.653625 852.597717,763.482910 858.859375,758.106079 
	C859.349915,757.684875 858.939026,756.297852 859.405457,755.749695 
	C861.069458,753.794312 862.739258,750.706604 864.755432,750.408936 
	C870.063721,749.625488 871.271851,745.799316 871.737854,742.006165 
	C872.266846,737.700195 874.310608,736.663452 877.923340,737.391968 
	C877.120422,741.013550 876.554993,744.293823 875.595459,747.454407 
	C875.205811,748.738098 874.150635,750.505859 873.071045,750.784119 
	C865.350098,752.774109 864.374939,758.901306 862.791565,765.377686 
	C860.880310,773.195312 858.092590,780.952332 849.379028,784.294189 
	C848.389771,784.673584 847.848206,786.201355 847.080627,787.182312 
	C845.868042,788.732056 844.659424,790.285645 843.413452,791.808472 
	C841.834778,793.738098 839.752319,795.420532 838.735779,797.604553 
	C837.358093,800.564819 837.009583,803.992188 835.757935,807.028870 
	C835.250916,808.259033 833.449951,809.886414 832.370911,809.793152 
	C826.943359,809.324402 824.259521,812.660645 821.620239,816.481018 
	C820.502808,818.098633 819.180603,820.034485 817.500549,820.718750 
	C812.169434,822.890137 807.041321,824.955688 806.254333,831.700378 
	C806.212524,832.058777 805.565125,832.378357 805.159485,832.657654 
	C800.885986,835.600342 796.606567,838.534546 792.324036,841.464172 
	C788.930847,843.785400 785.543518,846.115723 782.131226,848.408813 
	C776.014648,852.519043 769.813599,856.509216 763.808472,860.775574 
	C762.521484,861.689880 761.918213,863.566589 761.000000,865.000000 
z"/>
<path fill="#1D1B1C" opacity="1.000000" stroke="none" 
	d="
M761.385498,864.922668 
	C761.918213,863.566589 762.521484,861.689880 763.808472,860.775574 
	C769.813599,856.509216 776.014648,852.519043 782.131226,848.408813 
	C785.543518,846.115723 788.930847,843.785400 792.324036,841.464172 
	C796.606567,838.534546 800.885986,835.600342 805.159485,832.657654 
	C805.565125,832.378357 806.212524,832.058777 806.254333,831.700378 
	C807.041321,824.955688 812.169434,822.890137 817.500549,820.718750 
	C819.180603,820.034485 820.502808,818.098633 821.620239,816.481018 
	C824.259521,812.660645 826.943359,809.324402 832.370911,809.793152 
	C833.449951,809.886414 835.250916,808.259033 835.757935,807.028870 
	C837.009583,803.992188 837.358093,800.564819 838.735779,797.604553 
	C839.752319,795.420532 841.834778,793.738098 843.413452,791.808472 
	C844.659424,790.285645 845.868042,788.732056 847.080627,787.182312 
	C847.848206,786.201355 848.389771,784.673584 849.379028,784.294189 
	C858.092590,780.952332 860.880310,773.195312 862.791565,765.377686 
	C864.374939,758.901306 865.350098,752.774109 873.071045,750.784119 
	C874.150635,750.505859 875.205811,748.738098 875.595459,747.454407 
	C876.554993,744.293823 877.120422,741.013550 878.333557,737.389404 
	C882.350708,737.302429 883.781616,735.733093 884.202332,732.158630 
	C884.541992,729.272888 885.183594,725.418030 887.155579,723.917908 
	C892.990173,719.479370 892.504639,713.030945 894.209656,707.157959 
	C895.191589,703.775696 896.894409,700.220581 899.301697,697.743042 
	C901.644226,695.332153 902.062500,693.255554 901.655579,690.239075 
	C901.362915,688.069885 901.053345,684.729492 902.256958,683.736206 
	C907.309143,679.567200 906.892456,673.680237 908.772278,668.370789 
	C912.440796,658.009583 910.239868,646.705994 915.737549,636.609802 
	C920.894226,627.140076 919.647644,615.936401 920.960999,605.451843 
	C921.904602,597.918579 923.299500,590.428711 923.930359,582.872498 
	C925.072449,569.194031 922.037354,555.317444 925.830566,541.722534 
	C926.189819,540.434875 925.405334,538.829163 925.156250,537.370422 
	C923.769226,529.247620 922.383667,521.124695 920.997559,513.001770 
	C920.366272,504.076141 919.743652,495.149902 919.089539,486.225952 
	C919.029480,485.405945 919.047058,484.329529 918.559570,483.829102 
	C910.805725,475.870422 913.565735,465.817749 913.004028,456.432709 
	C912.974243,455.935364 913.001221,455.434601 913.003296,454.466919 
	C914.257141,456.912567 915.636963,459.779327 916.739563,462.748993 
	C921.706299,476.126068 924.480469,490.062317 926.418030,504.119629 
	C927.817139,514.270020 928.256836,524.554077 929.081177,534.781921 
	C930.077271,547.140381 931.616394,559.492737 931.820007,571.865906 
	C931.996887,582.615356 931.104187,593.445129 929.833191,604.138245 
	C928.538696,615.028687 926.257935,625.805359 924.267151,636.605652 
	C923.982971,638.147156 922.683411,639.478577 922.237305,641.018372 
	C921.584717,643.271301 920.524658,645.794006 920.996338,647.897095 
	C921.767151,651.333923 921.424927,653.907410 919.914429,657.256531 
	C917.722900,662.115662 917.354919,667.773071 915.944824,673.019043 
	C915.225708,675.694275 913.962219,678.228088 912.879211,680.798218 
	C912.445251,681.828308 911.295776,682.838928 911.389832,683.765198 
	C912.215332,691.890869 908.903687,698.755859 905.862732,705.985657 
	C904.255127,709.807739 902.743469,712.194763 899.320557,714.683899 
	C896.928284,716.423523 897.002258,721.618469 896.075806,725.295532 
	C894.615906,731.090332 894.482483,737.671021 891.501709,742.511353 
	C888.112305,748.015564 885.424194,753.574829 883.220581,759.544983 
	C882.927185,760.339905 880.967285,760.519714 879.389526,760.992188 
	C875.732117,761.054077 874.561218,762.940247 873.843750,765.958801 
	C872.853577,770.124695 871.698303,774.504883 866.441772,775.558044 
	C865.994202,775.647705 865.485840,776.013672 865.231812,776.399963 
	C862.409058,780.691833 859.683594,785.048645 856.808655,789.304688 
	C855.939392,790.591614 854.775269,792.489990 853.597229,792.606873 
	C849.948730,792.968811 849.282776,794.829102 848.721985,798.098511 
	C848.031250,802.125183 847.346802,806.602356 841.950073,807.998474 
	C840.117249,808.472595 838.867310,810.941833 837.165039,812.258972 
	C835.771118,813.337585 834.191956,814.541931 832.537292,814.853577 
	C826.153625,816.055725 818.972168,823.068726 818.089539,829.659912 
	C817.776001,832.001831 817.234680,833.126831 814.653870,834.183716 
	C809.853088,836.149902 805.512451,839.261169 801.043762,841.999573 
	C800.090088,842.584045 799.545288,844.076660 798.578979,844.362427 
	C789.442261,847.064087 783.220703,854.050720 775.772461,859.274902 
	C771.771301,862.081177 766.480164,863.048279 761.385498,864.922668 
z"/>
<path fill="#322818" opacity="1.000000" stroke="none" 
	d="
M520.999512,910.015015 
	C517.962097,910.402222 514.933350,910.997131 511.885101,911.123169 
	C508.234039,911.274109 504.561310,911.119019 500.907318,910.922302 
	C497.192871,910.722229 493.489807,910.310242 489.389526,909.989441 
	C478.469147,908.088379 467.898651,906.391418 457.431702,904.200134 
	C452.548462,903.177795 447.906403,901.052734 443.078217,899.702026 
	C439.657135,898.744934 436.087433,898.332642 432.621002,897.519775 
	C429.636414,896.819946 426.720947,895.829102 423.743835,895.092651 
	C420.759583,894.354431 417.206970,892.434326 414.831757,893.375366 
	C408.096710,896.043640 405.259003,891.226013 401.386841,887.938354 
	C399.878448,886.657593 398.235260,885.323669 396.417511,884.661560 
	C392.128021,883.098938 387.412292,882.487427 383.394012,880.453857 
	C378.220337,877.835388 373.749359,873.850342 368.629150,871.098755 
	C362.531891,867.822144 357.376892,862.203003 349.299622,863.151184 
	C348.179535,863.282654 346.609558,859.582031 345.224304,857.303040 
	C346.595917,856.943420 347.991058,856.936157 350.133240,856.972900 
	C352.263489,857.654053 353.646698,858.291260 355.240875,859.186340 
	C357.387451,860.478638 359.323059,861.513062 361.463593,862.793579 
	C362.450958,863.347229 363.233368,863.654724 364.195038,864.227051 
	C365.721802,865.103333 367.069305,865.714783 368.626862,866.606812 
	C372.236420,868.529358 375.635895,870.171326 379.284363,872.041565 
	C381.178070,872.940369 382.822845,873.610840 384.630493,874.551147 
	C385.548584,875.085083 386.303772,875.349060 387.294067,875.807617 
	C388.103119,876.180725 388.677124,876.359375 389.451477,876.795166 
	C390.438416,877.340088 391.224976,877.627991 392.287201,878.143494 
	C395.150543,879.388672 397.738251,880.406189 400.517822,881.679199 
	C401.492706,882.159790 402.275696,882.384949 403.377686,882.810608 
	C408.130920,884.671204 412.565125,886.331421 417.195862,888.268494 
	C418.258148,888.712036 419.123810,888.878662 420.259338,889.179810 
	C421.117493,889.432434 421.705780,889.550476 422.488281,889.914917 
	C423.471863,890.356262 424.261292,890.551331 425.367188,890.932373 
	C427.090576,891.483704 428.497559,891.849121 430.210938,892.417908 
	C436.676208,894.384644 442.835114,896.147949 449.223450,898.049805 
	C449.629395,898.160339 449.805847,898.132263 450.158661,898.166992 
	C450.334991,898.229736 450.706146,898.181335 450.878601,898.506104 
	C452.399231,899.056213 453.747467,899.281555 455.496765,899.623535 
	C459.441528,900.343079 462.985199,900.945923 466.518921,901.617615 
	C466.509003,901.686462 466.639496,901.734741 466.811432,901.965454 
	C467.707489,902.306641 468.431580,902.417114 469.524109,902.657715 
	C470.925720,902.889954 471.958893,902.992126 473.200562,903.308289 
	C473.983734,903.523010 474.558411,903.523743 475.430817,903.657349 
	C476.158020,903.837891 476.587463,903.885498 477.278839,904.181396 
	C478.728058,904.510010 479.915405,904.590271 481.512756,904.754272 
	C485.270691,905.244446 488.618561,905.650940 492.282776,906.270142 
	C495.764435,906.823669 498.929779,907.164490 502.536774,907.611328 
	C508.320618,908.036987 513.662781,908.356689 519.317993,908.813843 
	C520.085083,908.985229 520.539124,909.019165 520.973999,909.293701 
	C520.954773,909.534302 520.999512,910.015015 520.999512,910.015015 
z"/>
<path fill="#F5EB69" opacity="1.000000" stroke="none" 
	d="
M506.302429,433.014038 
	C506.251709,434.729156 506.200989,436.444305 506.075500,438.990173 
	C505.988831,441.855927 505.976990,443.890900 505.851532,445.920380 
	C505.737946,445.914856 505.538849,446.026581 505.239868,446.093689 
	C503.553436,447.019287 502.165955,447.877777 500.489441,448.757385 
	C499.814941,448.791962 499.429535,448.805420 498.956268,448.423004 
	C498.881165,442.441376 498.901794,436.855560 498.905182,431.269775 
	C498.918396,409.359039 498.964294,387.448151 498.856659,365.537994 
	C498.848877,363.954712 497.708405,362.376984 497.080200,360.565186 
	C497.059204,360.019745 497.028931,359.708130 497.411133,359.305359 
	C500.208008,359.089355 502.568787,358.966827 504.929565,358.844299 
	C505.137268,359.193420 505.344940,359.542572 505.736298,360.455627 
	C505.902771,379.404114 505.851166,397.788727 505.888977,416.173157 
	C505.900513,421.787048 506.157745,427.400421 506.302429,433.014038 
z"/>
<path fill="#71612B" opacity="1.000000" stroke="none" 
	d="
M567.752258,416.732117 
	C573.164124,410.070068 578.363098,403.220306 584.034912,396.787445 
	C593.997681,385.487885 604.460693,374.624512 614.275635,363.201691 
	C618.125305,358.721344 622.465393,357.542908 627.999207,358.461060 
	C627.816833,359.179626 627.465942,359.523926 626.828552,359.960907 
	C625.782593,360.273529 625.023071,360.493469 623.996582,360.578918 
	C622.799011,360.610565 621.868530,360.776733 620.618286,361.052002 
	C613.538330,368.428589 606.778015,375.696045 599.712524,383.097107 
	C598.618164,384.494934 597.828918,385.759186 596.720825,387.105164 
	C595.284729,388.450836 594.167542,389.714783 592.797241,391.054657 
	C592.328979,391.400299 592.113953,391.669983 591.610229,392.106506 
	C589.267090,394.832306 587.212708,397.391266 584.910400,400.121582 
	C584.401733,400.595886 584.140991,400.898865 583.694885,401.240356 
	C583.264648,401.418304 583.146179,401.628967 583.026489,402.052673 
	C582.898926,402.194489 582.835327,402.570648 582.567383,402.722900 
	C581.823120,403.514130 581.346863,404.153046 580.630981,404.906097 
	C580.192505,405.306335 579.993530,405.592438 579.538086,406.000275 
	C578.856812,406.736420 578.432007,407.350800 577.912231,408.061157 
	C577.817200,408.157166 577.899597,408.414368 577.606812,408.503052 
	C576.935303,409.038788 576.556580,409.485870 575.905945,410.136414 
	C573.781067,412.517944 571.928040,414.696014 569.749146,416.886597 
	C568.997498,416.982178 568.571716,417.065338 568.047302,417.044617 
	C567.948608,416.940765 567.752258,416.732117 567.752258,416.732117 
z"/>
<path fill="#71612B" opacity="1.000000" stroke="none" 
	d="
M504.982788,358.582520 
	C502.568787,358.966827 500.208008,359.089355 496.962402,359.177917 
	C488.708710,359.102814 481.338104,358.964233 473.972321,359.094971 
	C472.310150,359.124451 470.664612,360.092010 468.649170,360.561981 
	C465.336700,360.452820 462.539825,360.421814 462.569916,364.672119 
	C462.688232,381.392029 462.708221,398.112610 462.494080,414.894714 
	C462.038757,414.968475 461.854065,414.980499 461.386292,415.038391 
	C460.912689,415.068298 460.722198,415.052307 460.617981,414.571106 
	C460.657410,406.739319 460.610565,399.372711 460.628021,391.580536 
	C460.651428,389.437134 460.610535,387.719299 460.569641,386.001495 
	C460.650513,380.986481 460.731415,375.971466 460.719299,370.285217 
	C460.468323,369.356720 460.263306,369.144958 460.011353,368.978638 
	C460.053314,367.346130 460.131836,365.713593 460.129761,364.081146 
	C460.125153,360.482330 461.009888,358.088684 465.502960,358.501007 
	C467.649353,358.697906 469.909943,357.650238 472.983643,357.158752 
	C481.602600,357.130615 489.356781,357.110046 497.110931,357.089447 
	C498.140198,357.108276 499.169464,357.127075 500.983765,357.139374 
	C502.831390,357.172577 503.894043,357.212250 504.956665,357.251923 
	C504.983124,357.608185 505.009552,357.964447 504.982788,358.582520 
z"/>
<path fill="#71612B" opacity="1.000000" stroke="none" 
	d="
M505.965118,445.925903 
	C505.976990,443.890900 505.988831,441.855927 506.151581,439.413361 
	C508.275574,443.485199 511.524078,442.954834 515.613281,442.134399 
	C522.882751,440.675842 530.369385,439.086029 537.684814,439.403442 
	C545.372681,439.737000 549.487244,435.543488 553.915649,430.801208 
	C558.261597,426.147278 562.737305,421.614532 567.454102,416.880341 
	C567.752258,416.732117 567.948608,416.940765 567.981201,417.380524 
	C567.174072,419.186127 566.334290,420.551941 565.335449,421.935547 
	C564.961792,422.074249 564.875366,422.256104 564.604004,422.674774 
	C562.913879,424.280548 561.575500,425.750183 560.152710,427.132996 
	C555.074036,432.068878 549.966797,436.975342 544.416565,441.804108 
	C536.998779,442.080505 530.004639,442.163116 523.079895,442.898438 
	C517.332642,443.508728 511.666962,444.887512 505.965118,445.925903 
z"/>
<path fill="#71612B" opacity="1.000000" stroke="none" 
	d="
M506.610474,432.814667 
	C506.157745,427.400421 505.900513,421.787048 505.888977,416.173157 
	C505.851166,397.788727 505.902771,379.404114 506.023590,360.574127 
	C506.528473,360.370819 506.929749,360.612976 507.540955,361.404633 
	C507.781097,362.997040 507.811340,364.039917 507.841583,365.082825 
	C507.828613,385.669525 507.838959,406.256287 507.764313,426.842743 
	C507.757324,428.767944 507.213196,430.691162 506.610474,432.814667 
z"/>
<path fill="#CEC86E" opacity="1.000000" stroke="none" 
	d="
M627.114990,359.868164 
	C627.465942,359.523926 627.816833,359.179626 628.331360,358.645996 
	C641.694092,358.379211 654.893127,358.301727 668.553955,358.124664 
	C669.593323,358.519653 670.171021,359.014191 670.711792,359.968140 
	C670.258484,361.091064 669.842102,361.754578 669.425659,362.418091 
	C669.425659,362.418091 669.370117,362.487854 669.095398,362.614258 
	C666.878601,364.492371 664.936584,366.244141 662.878418,367.886597 
	C662.762329,367.777344 662.529724,367.559326 662.674561,367.254700 
	C664.103943,364.967010 665.388428,362.983917 667.284302,360.057007 
	C663.233459,360.057007 660.295959,360.057007 656.867432,360.047119 
	C646.622620,359.980835 636.868774,359.924500 627.114990,359.868164 
z"/>
<path fill="#373133" opacity="1.000000" stroke="none" 
	d="
M508.193298,365.125549 
	C507.811340,364.039917 507.781097,362.997040 507.723328,361.567444 
	C512.596008,359.389893 513.272949,360.037231 512.435181,366.326935 
	C511.087860,365.925629 509.816437,365.546936 508.193298,365.125549 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M504.971436,356.938354 
	C503.894043,357.212250 502.831390,357.172577 501.368958,357.110352 
	C502.104187,356.690277 503.239197,356.292755 504.688934,355.946228 
	C504.997803,356.206421 504.991974,356.415588 504.971436,356.938354 
z"/>
<path fill="#373133" opacity="1.000000" stroke="none" 
	d="
M657.084717,201.088959 
	C657.494812,200.816101 658.106140,200.708542 658.923462,200.754715 
	C658.515015,201.023712 657.900513,201.138977 657.084717,201.088959 
z"/>
<path fill="#4B494C" opacity="1.000000" stroke="none" 
	d="
M805.862427,301.320038 
	C807.890198,302.123077 809.768616,303.270142 811.824707,304.704956 
	C812.981079,311.662537 814.125549,318.358337 819.659546,322.955627 
	C821.000427,324.069519 823.432129,323.870209 825.693604,324.129639 
	C828.811890,326.589813 831.598755,329.195129 834.707642,331.898926 
	C837.316528,336.428284 839.603333,340.859161 841.749023,345.878601 
	C841.147278,348.771088 840.686707,351.075043 840.186035,353.579437 
	C839.230835,353.492218 838.370056,353.586975 837.643860,353.318237 
	C833.865967,351.920441 832.562622,352.826691 830.422302,356.856812 
	C828.354553,360.750336 823.881165,363.905365 820.744446,367.665405 
	C820.361450,368.124390 819.156372,367.952148 818.329651,367.974976 
	C816.666077,368.020935 815.000549,368.016785 813.336060,368.002075 
	C810.660645,367.978455 808.181335,368.246246 808.059937,371.755066 
	C807.960815,374.621552 807.658997,377.441101 811.766357,378.174530 
	C813.122986,378.416748 814.189575,380.283264 815.388794,381.407104 
	C813.910034,382.262695 812.494324,383.272430 810.927612,383.912750 
	C810.068848,384.263702 808.955139,383.993622 807.956421,383.999390 
	C800.055725,384.045013 800.057861,384.044769 799.997192,392.134705 
	C799.988464,393.299408 800.301147,394.634857 799.839905,395.589203 
	C799.108276,397.103027 797.961365,399.423065 796.794250,399.546234 
	C791.568726,400.097900 792.009155,403.623962 791.973206,407.204681 
	C791.936035,410.906921 792.738464,414.565247 787.378113,416.336151 
	C784.489746,417.290375 782.602539,421.269836 780.249939,423.871979 
	C780.162781,423.968414 779.903015,423.887756 779.770996,423.967804 
	C775.284851,426.686829 773.736877,432.623688 768.327209,434.448547 
	C767.892639,434.595184 768.025269,436.630463 768.014893,437.789185 
	C767.979126,441.784149 767.938843,445.780640 768.011719,449.774506 
	C768.074219,453.197632 767.045593,455.545135 763.222107,456.144073 
	C762.143738,456.313019 760.464722,457.497345 760.376343,458.352020 
	C759.789551,464.024597 755.943359,464.085266 751.671570,464.036072 
	C744.094421,463.948730 744.041199,464.091766 744.000122,471.420349 
	C743.955200,479.429047 743.434998,479.956421 735.535156,479.999756 
	C734.702820,480.004303 733.867615,480.052551 733.038635,480.001129 
	C729.424988,479.776978 727.887268,481.467468 727.979187,485.062714 
	C728.093994,489.554138 728.226196,494.070465 727.860901,498.535980 
	C727.720337,500.254150 726.488647,503.044983 725.310486,503.300293 
	C721.651367,504.093384 721.137634,507.362305 719.165039,509.167938 
	C715.611877,512.420288 712.741882,517.569092 709.138184,519.006775 
	C703.472168,521.267273 704.048279,524.580566 704.000122,528.630005 
	C703.924316,535.000244 703.038879,535.914490 696.822571,535.997192 
	C695.324585,536.017151 693.826172,536.037964 692.328308,536.023560 
	C689.456360,535.995911 688.004272,537.371460 688.026001,540.272522 
	C688.037231,541.770508 687.934326,543.272644 688.013489,544.765991 
	C688.219849,548.662292 687.449036,551.621033 682.749756,552.306274 
	C681.698669,552.459473 680.519165,554.498474 680.151428,555.871033 
	C679.692688,557.583252 679.947876,559.506592 680.011536,561.334717 
	C680.134521,564.866455 678.546997,566.392029 675.349304,568.383667 
	C671.158752,570.993591 668.721680,576.227295 664.117310,578.897400 
	C664.024658,578.951111 664.049438,579.211060 664.023743,579.375549 
	C663.369751,583.573120 661.124329,583.257690 658.434875,581.267151 
	C656.293884,579.682617 654.258789,577.954956 652.176697,576.290833 
	C651.726624,576.560669 651.276550,576.830505 650.826538,577.100281 
	C652.051514,579.785889 653.552429,582.383667 654.441345,585.176270 
	C656.053223,590.239868 657.300415,595.419495 658.386780,600.385498 
	C655.636658,597.349792 653.045776,594.594055 650.798523,591.581970 
	C647.371582,586.988525 644.182434,582.217712 640.884766,577.112793 
	C639.844910,575.777527 638.812744,574.851624 637.580200,573.672302 
	C635.216736,571.637146 633.053650,569.855408 630.744629,567.881897 
	C630.406433,567.432678 630.226379,567.166809 630.059998,566.562500 
	C629.673096,564.982971 629.284790,563.733643 628.909546,562.062988 
	C629.053772,558.714294 628.147644,556.542725 624.880249,556.074768 
	C624.881348,556.056763 624.917053,556.061890 624.943420,555.657349 
	C626.041260,548.146057 625.620789,547.186096 621.564453,546.030823 
	C621.564453,548.299927 621.564453,550.767578 621.292358,553.042175 
	C617.950439,548.111450 614.880615,543.373901 611.810791,538.636292 
	C612.743408,538.148071 613.879944,537.045959 614.573792,537.276489 
	C617.799866,538.348267 620.903320,539.789001 625.252380,541.597412 
	C624.175232,539.605469 623.694458,538.278137 622.850769,537.248047 
	C621.149292,535.170837 620.119141,533.667969 623.343506,531.738647 
	C624.760437,530.890808 625.704285,528.369690 625.868408,526.520020 
	C626.106873,523.831360 626.802185,522.264221 629.591675,521.487793 
	C630.981506,521.100952 632.862671,519.737183 633.119019,518.520020 
	C633.982605,514.419434 636.474426,513.892883 640.054749,513.933411 
	C648.727234,514.031738 649.998169,512.743164 649.951050,504.226746 
	C649.932068,500.794739 650.923401,499.133026 654.163818,496.986084 
	C658.244934,494.282166 661.430847,489.853729 665.099060,486.252930 
	C666.538452,484.839935 665.563293,481.135315 666.081604,478.553406 
	C666.399048,476.972198 667.208984,474.468231 668.236755,474.223724 
	C673.371155,473.002319 674.009827,469.445801 674.024719,465.130554 
	C674.048889,458.130157 674.122009,458.130432 681.202393,457.997650 
	C688.411804,457.862427 689.872925,456.380585 689.997986,449.077362 
	C690.116333,442.162811 690.116699,442.030975 697.254822,442.048096 
	C701.412903,442.058044 703.836365,440.830688 704.143860,436.208557 
	C704.473206,431.259369 705.375244,426.348083 706.037048,421.421539 
	C706.125122,420.765930 706.035767,419.855011 706.425476,419.507324 
	C710.237122,416.106995 714.131958,412.800079 718.009705,409.474213 
	C718.128235,409.372528 718.364502,409.397766 718.467163,409.289368 
	C722.203247,405.343201 725.949585,401.406250 729.630432,397.409088 
	C730.040222,396.964142 729.923645,395.728180 730.298462,395.603760 
	C735.224304,393.968170 737.466736,389.577057 740.388245,385.910950 
	C741.723511,384.235382 743.307373,382.527039 743.934326,380.563904 
	C745.429321,375.883270 744.614624,369.937439 751.558289,368.706512 
	C752.252869,368.583405 753.072937,367.296509 753.244202,366.432678 
	C753.834839,363.453613 756.126343,362.623169 758.280579,361.041473 
	C760.326599,359.539215 761.467346,356.009033 761.837524,353.215729 
	C762.324768,349.539246 762.221008,347.005554 766.649231,344.762115 
	C771.174438,342.469482 774.076233,337.030334 777.808960,333.082367 
	C779.033875,331.786896 780.478516,330.296265 782.090576,329.838501 
	C789.655029,327.690491 790.397217,326.239960 786.852478,319.101440 
	C785.594788,316.568665 785.815247,314.932404 788.863403,313.684631 
	C790.755676,312.910004 792.073425,310.798889 793.751038,309.410797 
	C795.013977,308.365784 797.158203,306.407684 797.682373,306.747894 
	C804.348511,311.074158 804.715698,305.950470 805.862427,301.320038 
M816.660217,330.035187 
	C815.928040,328.434784 815.388000,325.603302 814.429260,325.453613 
	C810.861816,324.896637 807.122559,324.940704 803.523438,325.379456 
	C802.392761,325.517273 800.917297,327.501434 800.681702,328.832092 
	C800.492249,329.902069 801.818787,332.227661 802.673462,332.330780 
	C807.286865,332.887299 812.195496,334.773987 816.660217,330.035187 
z"/>
<path fill="#E6B40D" opacity="1.000000" stroke="none" 
	d="
M590.332397,451.821716 
	C591.054443,452.291870 591.776550,452.762085 593.570435,453.934998 
	C594.642212,454.637756 594.724304,454.700592 594.724304,454.700623 
	C595.873596,455.326508 597.022827,455.952393 598.716797,456.947144 
	C599.261536,457.315979 599.562561,457.537140 599.562561,457.537140 
	C610.163635,461.866547 611.853149,469.858551 610.425110,480.416382 
	C608.455811,494.976166 607.880188,509.724487 606.529663,525.203247 
	C606.051636,529.111755 605.751648,532.209045 605.107178,535.380859 
	C597.709900,534.931030 590.640503,534.549438 583.622070,533.730713 
	C582.555359,533.606262 581.305786,531.586487 580.872070,530.212585 
	C579.941589,527.264587 579.488525,524.165833 578.848328,520.727783 
	C578.745300,519.534546 578.633972,518.742493 578.626038,517.543823 
	C578.052368,510.826294 577.426086,504.509003 576.634460,498.212494 
	C576.535034,497.422180 575.564026,496.741455 574.999634,496.004395 
	C575.002441,495.998779 574.990479,496.001617 574.992249,495.718811 
	C574.957153,495.061890 574.920288,494.687805 575.074219,493.967804 
	C576.272156,485.969971 572.864319,479.575989 566.209412,476.827698 
	C565.729370,476.406616 565.452637,476.170197 565.118103,475.647583 
	C564.903137,475.006561 564.746033,474.651733 564.675964,473.919708 
	C565.544739,472.106140 566.112183,470.488922 567.145569,469.265106 
	C571.316406,464.325897 575.495056,459.377899 579.962097,454.712006 
	C581.655151,452.943512 584.135376,451.928528 586.573181,450.669434 
	C587.542786,450.778229 588.196106,450.787689 588.968689,450.941345 
	C589.502808,451.330902 589.917603,451.576294 590.332397,451.821716 
z"/>
<path fill="#CEC86E" opacity="1.000000" stroke="none" 
	d="
M590.634888,451.589630 
	C589.917603,451.576294 589.502808,451.330902 589.099915,450.634613 
	C597.039490,441.028656 604.936707,431.846954 612.903015,422.725586 
	C621.750793,412.595093 630.581543,402.447723 639.569702,392.442444 
	C647.111084,384.047546 654.868103,375.846283 662.529724,367.559326 
	C662.529724,367.559326 662.762329,367.777344 662.837341,368.234314 
	C661.088989,371.185120 659.265625,373.678955 657.442200,376.172791 
	C657.442200,376.172791 657.207458,376.216370 656.903381,376.472717 
	C654.508667,379.106628 652.417969,381.484131 650.327271,383.861633 
	C650.327271,383.861633 650.003357,383.800964 650.003357,383.800964 
	C650.003357,383.800964 649.762817,384.026489 649.470703,384.336365 
	C647.528564,386.774658 645.878540,388.903107 644.228516,391.031555 
	C644.228516,391.031555 644.249146,391.287109 644.008179,391.249054 
	C643.293091,391.764954 642.819031,392.318939 642.344971,392.872925 
	C642.344971,392.872925 642.211487,392.772644 641.864807,392.916016 
	C641.342773,393.405029 641.167419,393.750702 640.992126,394.096405 
	C626.724426,410.059998 612.425537,425.995880 598.217285,442.012238 
	C595.602173,444.960144 593.354614,448.234100 590.634888,451.589630 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M605.451599,535.306396 
	C605.751648,532.209045 606.051636,529.111755 606.543213,525.688354 
	C607.043213,527.546875 607.037720,529.839783 607.744507,531.886780 
	C608.521057,534.135864 609.953430,536.158630 611.455444,538.458130 
	C614.880615,543.373901 617.950439,548.111450 621.482178,553.302673 
	C622.935120,554.524780 623.926086,555.293335 624.917053,556.061890 
	C624.917053,556.061890 624.881348,556.056763 624.822998,556.437378 
	C624.838745,557.549011 624.912781,558.280090 624.821533,559.172607 
	C624.656189,559.334045 624.325012,559.656372 624.060547,559.465576 
	C621.924316,557.138123 620.052612,555.001526 618.133667,552.868774 
	C618.086487,552.872559 618.171265,552.914734 618.136047,552.626282 
	C617.738098,551.904663 617.375366,551.471497 617.020874,551.010254 
	C617.029175,550.982239 616.971008,550.975464 616.898560,550.733643 
	C616.571655,550.284546 616.317322,550.077271 615.953735,549.674377 
	C615.660095,549.236267 615.431274,549.047546 615.079956,548.598694 
	C614.036682,547.163269 613.071655,546.041748 612.062744,544.929199 
	C612.018921,544.938171 612.098694,544.895508 612.015747,544.564941 
	C609.772339,541.258423 607.611938,538.282410 605.451599,535.306396 
z"/>
<path fill="#403B3D" opacity="1.000000" stroke="none" 
	d="
M753.787476,249.977768 
	C755.129333,250.645935 756.211670,251.313293 757.629395,252.007660 
	C757.981628,253.091721 757.998474,254.148788 758.018311,255.603088 
	C756.293945,257.841187 754.644165,259.764862 752.806763,261.488464 
	C751.863525,262.373352 750.585327,262.901123 749.458496,263.590271 
	C749.091675,262.291351 748.201477,260.860413 748.487488,259.726074 
	C748.864685,258.230255 750.288086,257.030823 751.035095,255.586731 
	C751.828064,254.053680 752.355896,252.383423 753.004272,250.378052 
	C753.182007,249.981003 753.354980,249.978989 753.787476,249.977768 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M657.802673,376.315918 
	C659.265625,373.678955 661.088989,371.185120 662.953430,368.343567 
	C664.936584,366.244141 666.878601,364.492371 669.121460,362.650421 
	C669.536194,363.042175 669.913696,363.763550 669.725342,363.970764 
	C665.910217,368.168854 662.030151,372.308044 657.802673,376.315918 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M650.716980,383.842957 
	C652.417969,381.484131 654.508667,379.106628 656.885132,376.590698 
	C657.681702,380.992645 654.332581,382.357758 650.716980,383.842957 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M644.604858,391.213104 
	C645.878540,388.903107 647.528564,386.774658 649.513306,384.495667 
	C649.676270,387.696350 649.173462,390.819092 644.604858,391.213104 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M669.761536,362.513489 
	C669.842102,361.754578 670.258484,361.091064 670.884766,360.196991 
	C671.506592,360.394470 671.918457,360.822479 672.330322,361.250458 
	C671.585999,361.703247 670.841675,362.156036 669.761536,362.513489 
z"/>
<path fill="#CEC86E" opacity="1.000000" stroke="none" 
	d="
M599.670410,457.588867 
	C599.562561,457.537140 599.261536,457.315979 599.254456,457.324493 
	C599.424316,457.435547 599.601318,457.538086 599.670410,457.588867 
z"/>
<path fill="#CEC86E" opacity="1.000000" stroke="none" 
	d="
M594.803833,454.675354 
	C594.724304,454.700592 594.642212,454.637756 594.644409,454.654083 
	C594.646606,454.670410 594.753174,454.427277 594.753174,454.427277 
	C594.753174,454.427277 594.883301,454.650085 594.803833,454.675354 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M642.715088,392.825073 
	C642.819031,392.318939 643.293091,391.764954 644.135864,391.237549 
	C644.031372,391.768463 643.558289,392.272858 642.715088,392.825073 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M641.198364,393.917542 
	C641.167419,393.750702 641.342773,393.405029 641.810852,392.979675 
	C641.870544,393.179535 641.637573,393.459106 641.198364,393.917542 
z"/>
<path fill="#1A1715" opacity="1.000000" stroke="none" 
	d="
M920.629150,512.898071 
	C922.383667,521.124695 923.769226,529.247620 925.156250,537.370422 
	C925.405334,538.829163 926.189819,540.434875 925.830566,541.722534 
	C922.037354,555.317444 925.072449,569.194031 923.930359,582.872498 
	C923.299500,590.428711 921.904602,597.918579 920.960999,605.451843 
	C919.647644,615.936401 920.894226,627.140076 915.737549,636.609802 
	C910.239868,646.705994 912.440796,658.009583 908.772278,668.370789 
	C906.892456,673.680237 907.309143,679.567200 902.256958,683.736206 
	C901.053345,684.729492 901.362915,688.069885 901.655579,690.239075 
	C902.062500,693.255554 901.644226,695.332153 899.301697,697.743042 
	C896.894409,700.220581 895.191589,703.775696 894.209656,707.157959 
	C892.504639,713.030945 892.990173,719.479370 887.155579,723.917908 
	C885.183594,725.418030 884.541992,729.272888 884.202332,732.158630 
	C883.781616,735.733093 882.350708,737.302429 878.412598,736.999939 
	C874.310608,736.663452 872.266846,737.700195 871.737854,742.006165 
	C871.271851,745.799316 870.063721,749.625488 864.755432,750.408936 
	C862.739258,750.706604 861.069458,753.794312 859.405457,755.749695 
	C858.939026,756.297852 859.349915,757.684875 858.859375,758.106079 
	C852.597717,763.482910 850.592590,771.653625 845.725403,777.934631 
	C843.942871,780.235046 842.409363,782.727356 840.734680,785.112732 
	C839.415405,786.992004 838.402893,789.333496 836.630615,790.591125 
	C833.847900,792.565674 830.586609,793.828491 833.414978,798.999512 
	C830.245056,798.103088 828.438965,797.257751 826.596741,797.170105 
	C824.922485,797.090393 823.209106,797.834412 821.505554,798.216980 
	C823.557312,806.710632 819.753113,812.485840 812.062012,815.208069 
	C806.184265,817.288391 800.590210,820.234863 798.532104,827.217102 
	C797.975830,829.104370 795.629456,830.564209 793.909851,831.984253 
	C791.504150,833.970947 788.915710,835.736389 786.028137,837.876038 
	C781.676880,839.016418 776.584290,840.351074 771.491760,841.685730 
	C771.480957,842.178345 771.470215,842.670959 771.459412,843.163574 
	C773.038879,843.490234 774.688782,843.634888 776.172180,844.209473 
	C777.115784,844.575012 778.146362,845.449829 778.492432,846.345703 
	C778.695862,846.872192 777.807922,848.340149 777.113281,848.605347 
	C773.763306,849.884155 770.280823,850.811829 766.908203,852.036987 
	C764.679016,852.846680 762.554382,853.945190 760.388855,854.927307 
	C760.249207,854.990601 760.170105,855.258423 760.044983,855.272522 
	C752.305725,856.149475 749.721863,864.511292 743.209778,866.920715 
	C740.095886,868.072815 736.422485,867.615906 733.077759,868.271667 
	C730.336304,868.809143 728.228149,869.289062 726.987427,873.247681 
	C725.874878,876.797546 721.001160,879.322449 717.512024,881.932312 
	C713.649963,884.821045 709.655090,884.743652 705.525757,881.855469 
	C703.558716,880.479675 700.958313,880.009521 698.494568,878.755432 
	C699.516785,876.681519 700.689209,874.981262 702.037476,873.055176 
	C702.743896,872.686462 703.274475,872.543579 703.874756,872.510071 
	C703.944336,872.619385 704.094666,872.647949 704.094666,872.647949 
	C704.094666,872.647949 703.838989,872.409607 703.950806,872.247559 
	C704.062622,872.085449 704.447876,872.167175 704.853455,872.321106 
	C710.992371,872.648743 716.521057,869.398376 718.125244,864.731445 
	C718.979065,864.111328 719.686218,863.756958 720.723755,863.485840 
	C721.701782,863.067688 722.349487,862.566284 723.198730,861.841064 
	C724.130188,861.302063 724.860046,860.986938 725.915405,860.725525 
	C726.828491,860.213074 727.416199,859.646973 728.152710,858.951172 
	C728.301514,858.821533 728.691528,858.760315 729.012207,858.802368 
	C729.907776,858.305969 730.482666,857.767578 731.264771,857.060913 
	C731.807861,856.781494 732.143738,856.670349 732.813416,856.614319 
	C733.759521,856.110718 734.371887,855.552002 735.123230,854.761475 
	C735.719421,854.262085 736.176697,853.994568 736.998047,853.786133 
	C738.573975,852.909607 739.785828,851.973938 741.128540,850.905884 
	C741.259399,850.773560 741.627808,850.719971 741.985474,850.798157 
	C743.138428,850.156250 743.933655,849.436157 744.857056,848.856812 
	C744.985352,848.997559 744.735535,848.710938 745.055725,848.731689 
	C745.932556,848.192627 746.489258,847.632935 747.147827,846.935364 
	C747.249695,846.797546 747.584595,846.724426 747.925903,846.777100 
	C748.852234,846.226501 749.437256,845.623352 750.229553,844.750122 
	C753.923340,841.879211 757.409790,839.278381 761.164307,836.602295 
	C761.902100,836.009705 762.371887,835.492249 763.024414,834.960693 
	C763.207153,834.946533 763.337402,834.603760 763.674988,834.559082 
	C765.542603,833.327148 767.072632,832.139893 768.837769,830.801880 
	C769.429504,830.229736 769.786194,829.808350 770.493530,829.325562 
	C773.989807,826.548523 777.135315,823.832825 780.500122,821.075928 
	C781.007385,820.865479 781.140259,820.614136 781.457031,820.193420 
	C790.187744,812.054932 798.579285,804.003906 807.280884,795.845642 
	C809.295715,793.795288 811.000366,791.852173 812.993591,789.788574 
	C813.892273,788.859863 814.502380,788.051575 815.434448,787.138184 
	C822.693970,778.588684 830.278442,770.586731 836.422424,761.599976 
	C846.496948,746.863953 855.635742,731.488159 865.468994,716.206299 
	C866.487366,714.341675 867.205872,712.652954 868.175903,710.745728 
	C869.595947,708.683838 870.764465,706.840454 872.304199,704.974487 
	C873.929077,703.803162 875.694946,702.890930 876.352234,701.466919 
	C880.379883,692.740417 884.375000,683.985657 887.952454,675.068359 
	C891.245178,666.860596 894.274414,658.522034 896.893372,650.077881 
	C898.644653,644.431274 899.317871,638.459900 900.754089,632.703918 
	C903.015930,623.639221 906.331055,614.756287 907.711060,605.572571 
	C909.553345,593.312805 909.972168,580.838989 910.998413,568.456787 
	C911.217590,565.812561 911.659058,563.155884 911.538513,560.526001 
	C911.467896,558.987061 910.487915,557.489746 909.917114,555.973694 
	C909.890381,555.224060 909.863708,554.474487 910.294189,553.189392 
	C910.940369,545.048096 911.142212,537.442505 911.265991,529.835632 
	C911.270386,529.566833 910.501221,529.285461 910.092529,529.009949 
	C909.975525,526.665588 909.858521,524.321289 909.787415,521.541870 
	C912.796021,513.211487 911.216675,505.178558 910.497986,497.187042 
	C910.128723,493.080475 909.509277,488.996429 909.007996,484.447876 
	C910.195862,484.432434 912.230896,484.634247 912.429077,485.346832 
	C914.360168,492.290985 919.055847,498.438873 918.150024,506.263916 
	C917.910706,508.331573 919.507202,510.611816 920.629150,512.898071 
M705.893921,876.642639 
	C705.875061,876.859009 705.856201,877.075378 705.837341,877.291748 
	C705.959900,877.295715 706.082397,877.299683 706.204956,877.303650 
	C706.197693,877.086243 706.190369,876.868835 705.893921,876.642639 
z"/>
<path fill="#211F20" opacity="1.000000" stroke="none" 
	d="
M879.324585,387.935059 
	C878.984863,387.515808 878.984314,387.093567 878.982117,386.354797 
	C880.006104,385.810181 881.031738,385.582062 882.057312,385.353973 
	C882.215942,385.739441 882.374634,386.124908 882.533264,386.510376 
	C881.576782,386.984283 880.620361,387.458191 879.324585,387.935059 
z"/>
<path fill="#5D5C5E" opacity="1.000000" stroke="none" 
	d="
M900.992676,545.004883 
	C899.963928,560.018799 899.482666,575.096680 897.737671,590.026917 
	C896.274719,602.544067 893.336975,614.888916 891.075500,627.746521 
	C889.200073,634.895569 887.200012,641.581970 885.453735,648.333923 
	C884.778076,650.946350 884.677429,653.707397 884.277710,657.195740 
	C882.602173,663.019287 880.963806,668.047852 879.065186,673.205444 
	C876.766968,677.160461 874.504089,680.886230 872.743530,684.835938 
	C870.064331,690.846497 867.748901,697.018616 865.246948,703.109009 
	C864.138916,705.806213 862.952759,708.471313 861.597046,711.443970 
	C860.062622,713.833923 858.771545,715.956421 857.397644,718.023926 
	C847.518372,732.890320 837.797668,747.866638 827.656067,762.552246 
	C822.726990,769.689941 817.000549,776.276917 811.312744,783.301025 
	C810.376770,784.259949 809.763184,785.031067 808.896667,785.866821 
	C808.126770,786.629578 807.609741,787.327698 807.060059,788.019653 
	C807.027466,788.013428 807.046021,788.077209 806.761658,788.224609 
	C805.153015,789.960754 803.828735,791.549438 802.166992,793.234192 
	C793.566406,801.233521 785.303223,809.136780 776.717712,817.143066 
	C774.633179,818.851318 772.871033,820.456543 771.071045,822.047607 
	C771.033203,822.033386 771.061462,822.109070 770.734741,822.179810 
	C768.995178,823.523621 767.582336,824.796814 765.848999,826.170898 
	C764.716919,827.029907 763.905396,827.787964 762.770508,828.576294 
	C760.623413,830.073547 758.799622,831.540527 756.733459,833.091431 
	C756.360718,833.286255 756.230469,833.397095 755.772705,833.557495 
	C752.085388,836.101624 748.725403,838.596191 745.039673,841.242310 
	C743.174194,842.537903 741.634399,843.682007 739.804077,844.824707 
	C738.602173,845.513733 737.690796,846.204102 736.436157,846.971802 
	C732.195190,849.276123 728.297424,851.503052 724.152710,853.448669 
	C723.307983,852.957642 722.542236,852.396606 722.137085,852.590271 
	C714.269409,856.350220 706.419250,860.149109 698.624817,864.058472 
	C696.324402,865.212219 694.340027,867.057556 691.980042,868.020081 
	C683.433228,871.505981 674.774658,874.716675 666.183960,878.096558 
	C656.072571,882.074707 646.017639,886.224670 634.838928,886.746338 
	C637.051941,880.959900 639.421082,875.427124 641.935669,869.554626 
	C645.473816,872.207764 647.381165,872.710754 649.792603,867.372192 
	C651.294556,864.046997 655.174744,861.110352 656.924683,857.134033 
	C657.202454,856.502930 658.651550,856.118896 659.575867,856.085693 
	C664.310913,855.915466 665.589233,853.559753 664.612915,849.138550 
	C664.123352,846.921326 663.615967,842.878357 664.428284,842.503662 
	C669.301392,840.254944 670.929749,834.419312 675.042603,832.461304 
	C679.138245,830.511353 680.233582,828.401062 679.987915,824.607300 
	C679.711975,820.344788 680.084534,816.579346 685.183472,815.199585 
	C686.108887,814.949158 687.180908,813.697327 687.415833,812.722351 
	C688.183105,809.538452 689.576416,807.285095 693.060913,806.532776 
	C693.865845,806.359009 694.358582,804.800781 695.023376,803.900635 
	C695.880798,802.739685 696.621826,800.768799 697.664185,800.586975 
	C704.039307,799.475403 704.093323,794.996643 704.112671,790.043579 
	C704.120056,788.146362 705.045105,784.866577 706.122375,784.609863 
	C709.926392,783.703369 712.022522,781.983643 712.433105,778.034912 
	C712.505249,777.341553 713.730347,776.353394 714.541565,776.219299 
	C719.489380,775.401550 719.619568,771.498291 719.936768,767.876770 
	C720.599731,760.307373 720.669312,760.041138 728.174011,760.043091 
	C732.291138,760.044189 735.271912,759.106995 736.283142,754.482544 
	C736.566772,753.185608 738.540833,752.330261 739.512817,751.094177 
	C740.790222,749.469604 742.633728,747.756165 742.835999,745.926453 
	C743.327332,741.481018 742.667786,736.906616 743.182739,732.466553 
	C743.400391,730.590088 745.252258,728.787415 746.641235,727.195496 
	C748.009583,725.627258 749.729370,724.365112 751.299988,722.974304 
	C752.378174,722.019714 753.340637,720.492920 754.570129,720.230408 
	C758.906738,719.304871 760.006653,716.555969 760.039795,712.576660 
	C760.104736,704.774048 760.321472,704.641479 767.877014,703.282593 
	C774.807007,702.036072 774.880615,702.036987 774.969971,694.553650 
	C775.009399,691.244751 775.731262,688.944824 779.387695,687.739075 
	C781.218567,687.135315 782.510437,684.800781 783.958130,683.170959 
	C784.693481,682.343079 785.161804,680.604187 785.873962,680.534485 
	C792.364319,679.899475 792.304626,675.355103 791.980286,670.672546 
	C791.723877,666.971741 792.333801,664.185120 796.750122,663.328674 
	C797.960266,663.093933 798.891663,661.057739 799.795044,659.741211 
	C800.340515,658.946472 800.379578,657.348938 800.999695,657.134521 
	C804.993286,655.753967 807.934387,653.570068 808.951050,649.188416 
	C809.082397,648.622681 810.604248,648.175293 811.536133,648.036011 
	C815.143738,647.497192 816.005249,645.127625 816.019592,641.941895 
	C816.031494,639.291748 815.311401,635.059204 816.589233,634.283081 
	C820.804321,631.722778 822.971985,626.430298 826.413208,624.819336 
	C831.462891,622.455383 832.081116,619.747375 832.038940,615.337708 
	C831.977051,608.870483 832.871765,608.122253 839.719727,607.999695 
	C846.407837,607.879883 846.452087,607.541809 847.129578,600.958740 
	C847.451721,597.828430 848.341736,592.813660 850.211548,592.152710 
	C855.638428,590.234619 855.098328,586.502686 854.973694,582.562317 
	C854.858582,578.924377 856.012024,576.655579 859.877869,575.625183 
	C861.441040,575.208557 862.613647,573.108398 863.822144,571.656311 
	C864.629578,570.686096 865.095581,568.640137 865.870483,568.558472 
	C872.762878,567.832581 872.116272,562.891174 872.006897,558.179077 
	C871.940002,555.298340 872.483643,552.655762 875.867004,552.144409 
	C880.900146,551.383728 885.973511,550.891174 891.026184,550.256348 
	C892.223633,550.105896 893.406921,549.843079 894.596741,549.631958 
	C893.917236,548.343811 893.474854,546.832214 892.512268,545.811035 
	C889.978027,543.122620 890.463196,539.460388 894.196106,537.431396 
	C895.273560,541.003174 894.525818,546.526184 900.992676,545.004883 
z"/>
<path fill="#23150C" opacity="1.000000" stroke="none" 
	d="
M909.791504,556.371948 
	C910.487915,557.489746 911.467896,558.987061 911.538513,560.526001 
	C911.659058,563.155884 911.217590,565.812561 910.998413,568.456787 
	C909.972168,580.838989 909.553345,593.312805 907.711060,605.572571 
	C906.331055,614.756287 903.015930,623.639221 900.754089,632.703918 
	C899.317871,638.459900 898.644653,644.431274 896.893372,650.077881 
	C894.274414,658.522034 891.245178,666.860596 887.952454,675.068359 
	C884.375000,683.985657 880.379883,692.740417 876.352234,701.466919 
	C875.694946,702.890930 873.929077,703.803162 872.283813,704.624268 
	C872.261414,703.200439 872.630798,702.104126 873.276001,700.832764 
	C874.122498,699.249329 874.693176,697.841064 875.502380,696.258423 
	C875.970520,695.360962 876.200073,694.637695 876.627930,693.632263 
	C877.330505,692.078430 877.834900,690.806763 878.611084,689.345459 
	C879.426453,687.741638 879.970154,686.327576 880.669800,684.639038 
	C881.004333,683.761658 881.182861,683.158630 881.629517,682.325745 
	C882.787231,679.373108 883.676880,676.650452 884.764038,673.660400 
	C885.200073,672.564514 885.438660,671.735962 885.935852,670.727844 
	C886.660339,669.396057 887.126221,668.243835 887.817932,666.775635 
	C888.388062,664.981201 888.732361,663.502808 889.182434,661.784302 
	C889.343262,661.200195 889.398254,660.856140 889.742554,660.307373 
	C891.189819,656.352905 892.347839,652.603271 893.633789,648.552551 
	C893.785950,647.817200 893.810120,647.382812 893.834290,646.948486 
	C895.890747,639.827148 898.168640,632.761108 899.962646,625.574280 
	C905.600769,602.987793 907.970581,579.922485 909.791504,556.371948 
z"/>
<path fill="#ADA16F" opacity="1.000000" stroke="none" 
	d="
M889.453247,660.512146 
	C889.398254,660.856140 889.343262,661.200195 888.920776,661.886047 
	C888.079346,662.526733 887.605469,662.825500 887.026062,662.853821 
	C885.654114,663.657104 884.080139,664.524353 883.189880,665.850647 
	C881.675171,668.107117 880.590637,670.652405 879.325439,673.076416 
	C880.963806,668.047852 882.602173,663.019287 884.358154,657.641846 
	C886.672241,651.814453 888.917175,646.354553 891.041931,640.848328 
	C891.921204,638.569946 892.542847,636.192017 893.281616,633.859375 
	C893.883118,633.950134 894.484680,634.040894 895.086182,634.131653 
	C894.544434,638.214600 894.002686,642.297485 893.647583,646.664429 
	C893.810120,647.382812 893.785950,647.817200 893.345215,648.716797 
	C891.770264,652.958801 890.611755,656.735474 889.453247,660.512146 
z"/>
<path fill="#23150C" opacity="1.000000" stroke="none" 
	d="
M910.025085,529.473633 
	C910.501221,529.285461 911.270386,529.566833 911.265991,529.835632 
	C911.142212,537.442505 910.940369,545.048096 910.408203,552.829224 
	C910.029236,545.315430 909.993408,537.626343 910.025085,529.473633 
z"/>
<path fill="#8E7857" opacity="1.000000" stroke="none" 
	d="
M902.000244,504.733337 
	C901.697144,504.776764 901.620605,504.514862 901.881958,504.087952 
	C901.993408,504.144043 901.995728,504.312378 902.000244,504.733337 
z"/>
<path fill="#585659" opacity="1.000000" stroke="none" 
	d="
M900.997375,544.535706 
	C894.525818,546.526184 895.273560,541.003174 894.196106,537.431396 
	C890.463196,539.460388 889.978027,543.122620 892.512268,545.811035 
	C893.474854,546.832214 893.917236,548.343811 894.596741,549.631958 
	C893.406921,549.843079 892.223633,550.105896 891.026184,550.256348 
	C885.973511,550.891174 880.900146,551.383728 875.867004,552.144409 
	C872.483643,552.655762 871.940002,555.298340 872.006897,558.179077 
	C872.116272,562.891174 872.762878,567.832581 865.870483,568.558472 
	C865.095581,568.640137 864.629578,570.686096 863.822144,571.656311 
	C862.613647,573.108398 861.441040,575.208557 859.877869,575.625183 
	C856.012024,576.655579 854.858582,578.924377 854.973694,582.562317 
	C855.098328,586.502686 855.638428,590.234619 850.211548,592.152710 
	C848.341736,592.813660 847.451721,597.828430 847.129578,600.958740 
	C846.452087,607.541809 846.407837,607.879883 839.719727,607.999695 
	C832.871765,608.122253 831.977051,608.870483 832.038940,615.337708 
	C832.081116,619.747375 831.462891,622.455383 826.413208,624.819336 
	C822.971985,626.430298 820.804321,631.722778 816.589233,634.283081 
	C815.311401,635.059204 816.031494,639.291748 816.019592,641.941895 
	C816.005249,645.127625 815.143738,647.497192 811.536133,648.036011 
	C810.604248,648.175293 809.082397,648.622681 808.951050,649.188416 
	C807.934387,653.570068 804.993286,655.753967 800.999695,657.134521 
	C800.379578,657.348938 800.340515,658.946472 799.795044,659.741211 
	C798.891663,661.057739 797.960266,663.093933 796.750122,663.328674 
	C792.333801,664.185120 791.723877,666.971741 791.980286,670.672546 
	C792.304626,675.355103 792.364319,679.899475 785.873962,680.534485 
	C785.161804,680.604187 784.693481,682.343079 783.958130,683.170959 
	C782.510437,684.800781 781.218567,687.135315 779.387695,687.739075 
	C775.731262,688.944824 775.009399,691.244751 774.969971,694.553650 
	C774.880615,702.036987 774.807007,702.036072 767.877014,703.282593 
	C760.321472,704.641479 760.104736,704.774048 760.039795,712.576660 
	C760.006653,716.555969 758.906738,719.304871 754.570129,720.230408 
	C753.340637,720.492920 752.378174,722.019714 751.299988,722.974304 
	C749.729370,724.365112 748.009583,725.627258 746.641235,727.195496 
	C745.252258,728.787415 743.400391,730.590088 743.182739,732.466553 
	C742.667786,736.906616 743.327332,741.481018 742.835999,745.926453 
	C742.633728,747.756165 740.790222,749.469604 739.512817,751.094177 
	C738.540833,752.330261 736.566772,753.185608 736.283142,754.482544 
	C735.271912,759.106995 732.291138,760.044189 728.174011,760.043091 
	C720.669312,760.041138 720.599731,760.307373 719.936768,767.876770 
	C719.619568,771.498291 719.489380,775.401550 714.541565,776.219299 
	C713.730347,776.353394 712.505249,777.341553 712.433105,778.034912 
	C712.022522,781.983643 709.926392,783.703369 706.122375,784.609863 
	C705.045105,784.866577 704.120056,788.146362 704.112671,790.043579 
	C704.093323,794.996643 704.039307,799.475403 697.664185,800.586975 
	C696.621826,800.768799 695.880798,802.739685 695.023376,803.900635 
	C694.358582,804.800781 693.865845,806.359009 693.060913,806.532776 
	C689.576416,807.285095 688.183105,809.538452 687.415833,812.722351 
	C687.180908,813.697327 686.108887,814.949158 685.183472,815.199585 
	C680.084534,816.579346 679.711975,820.344788 679.987915,824.607300 
	C680.233582,828.401062 679.138245,830.511353 675.042603,832.461304 
	C670.929749,834.419312 669.301392,840.254944 664.428284,842.503662 
	C663.615967,842.878357 664.123352,846.921326 664.612915,849.138550 
	C665.589233,853.559753 664.310913,855.915466 659.575867,856.085693 
	C658.651550,856.118896 657.202454,856.502930 656.924683,857.134033 
	C655.174744,861.110352 651.294556,864.046997 649.792603,867.372192 
	C647.381165,872.710754 645.473816,872.207764 641.935669,869.554626 
	C639.421082,875.427124 637.051941,880.959900 634.625610,887.038879 
	C632.257874,888.357056 629.997070,889.389099 627.625549,889.842712 
	C623.651611,890.602905 619.463074,890.519836 615.635254,891.682251 
	C612.195435,892.726685 609.256714,895.351685 605.844421,896.575684 
	C602.368835,897.822571 598.585083,898.210449 594.557373,898.904358 
	C592.390808,898.741760 590.601685,898.548096 588.815796,898.574219 
	C581.214172,898.685364 573.613525,898.861694 565.743408,898.794556 
	C563.347107,896.533203 561.219849,894.491516 559.092590,892.449829 
	C561.466431,890.011353 563.840271,887.572876 565.430908,885.938965 
	C563.496948,884.844238 560.285950,883.415161 557.569702,881.338867 
	C556.017822,880.152649 555.271851,877.912170 554.163025,876.146423 
	C556.058289,875.110413 557.871521,873.398315 559.863098,873.156860 
	C567.941772,872.177490 567.958008,872.344177 568.006409,864.053589 
	C568.014221,862.721863 567.670471,861.188538 568.209717,860.107971 
	C568.948486,858.627563 570.335815,856.433899 571.515747,856.388733 
	C576.819763,856.185791 576.816711,852.952393 577.202271,848.954468 
	C577.448364,846.402649 579.343506,843.884705 580.894287,841.622253 
	C582.197266,839.721191 584.072083,838.212280 585.692139,836.528259 
	C586.576538,835.608948 587.319702,834.119385 588.364624,833.865112 
	C592.568848,832.842224 593.102783,830.038025 593.066467,826.325378 
	C592.990051,818.532349 594.368347,817.065125 601.893799,817.072510 
	C605.927002,817.076477 607.810181,816.019592 608.256897,811.543762 
	C608.589294,808.213806 609.960754,803.388916 612.343201,802.211182 
	C616.652100,800.081177 617.191528,797.408447 617.034607,793.520142 
	C616.878479,789.652527 617.517456,786.274170 622.120483,784.963867 
	C623.404114,784.598450 624.221497,782.689392 625.319275,781.552734 
	C626.731873,780.090027 628.219360,778.700012 629.659729,777.263794 
	C630.884460,776.042603 632.159668,774.861328 633.278931,773.548523 
	C634.212891,772.453125 634.766174,770.382507 635.810547,770.141479 
	C641.119995,768.916321 641.285156,765.175598 641.032288,760.932678 
	C640.873962,758.276489 641.015381,755.602966 641.008606,752.937195 
	C640.998962,749.144592 641.908325,746.077271 646.212646,744.968384 
	C647.290161,744.690674 648.637756,743.429321 648.902100,742.378845 
	C650.046814,737.828979 653.282166,737.052002 657.257874,736.981384 
	C664.760620,736.848145 664.735840,736.782898 665.019958,729.144897 
	C665.138489,725.957458 665.065613,722.112366 669.138123,721.639038 
	C673.955505,721.079224 676.253235,719.005920 676.575378,714.210266 
	C676.716858,712.103149 677.238281,709.878296 678.173706,707.998779 
	C679.908203,704.513672 681.310547,699.959778 684.253845,698.209656 
	C687.891052,696.046875 689.124573,694.054504 689.072327,690.130005 
	C688.971008,682.516052 690.500000,681.145935 698.089355,681.003235 
	C704.804688,680.877014 704.857239,680.822449 705.004028,673.812927 
	C705.156250,666.545532 706.479431,665.058838 713.907043,665.073120 
	C717.954041,665.080872 719.873901,664.008667 720.240356,659.525330 
	C720.504822,656.289856 721.689026,651.385559 723.880920,650.409424 
	C728.661682,648.280273 728.893372,645.252319 729.205139,641.022583 
	C729.392883,638.476074 731.285522,635.917969 732.831543,633.651184 
	C734.127625,631.750793 736.039429,630.272461 737.663391,628.592590 
	C738.549988,627.675415 739.262390,626.151733 740.299377,625.895081 
	C744.448059,624.867920 745.109314,622.151245 745.069946,618.397156 
	C744.986084,610.401428 746.239624,609.174744 754.322632,609.052856 
	C757.849670,608.999573 759.840942,608.196655 760.130493,604.101807 
	C760.477356,599.196411 760.874451,593.946777 767.303650,592.437805 
	C768.204468,592.226379 768.671692,590.332642 769.423584,589.277527 
	C770.256104,588.109253 771.012146,586.260803 772.101685,586.009338 
	C776.925049,584.896179 777.174500,581.571167 777.063660,577.616638 
	C776.976379,574.503357 777.217285,571.704590 780.560547,569.494507 
	C784.982483,566.571472 788.731018,562.617249 792.686829,559.016052 
	C793.237183,558.515137 793.184937,557.405701 793.648438,556.743652 
	C794.376282,555.703979 795.168091,554.172546 796.172913,553.954285 
	C801.069214,552.890625 801.194397,549.428101 801.035461,545.532593 
	C800.877808,541.670288 801.501648,538.281677 806.108826,536.968689 
	C807.394226,536.602356 808.304016,534.776245 809.290100,533.543152 
	C810.191223,532.416138 810.772949,530.385254 811.823181,530.136658 
	C816.768250,528.965759 817.128418,525.602478 817.075500,521.423218 
	C816.990967,514.751282 818.707092,513.199524 825.296753,513.007324 
	C832.835510,512.787415 832.964172,512.787354 832.960632,505.605743 
	C832.958130,500.591766 834.348938,497.217865 840.102173,496.918579 
	C843.747559,496.728943 844.230774,494.120941 843.500244,491.123688 
	C842.714966,487.901978 841.389893,484.738251 841.117249,481.480865 
	C840.599060,475.291138 842.647339,473.285065 848.730164,473.012054 
	C856.640381,472.657043 856.640381,472.657043 857.013916,464.673065 
	C857.283936,458.901062 858.849426,457.299622 864.548828,457.024780 
	C865.879089,456.960632 867.214294,456.965668 868.546631,456.984558 
	C871.358154,457.024384 872.668152,455.778381 873.038086,452.861603 
	C873.639404,448.120300 875.114685,443.465210 875.440613,438.720154 
	C875.803101,433.443359 878.586792,432.397858 883.002441,433.008545 
	C883.002441,433.008545 883.007629,433.492493 882.924927,434.136902 
	C882.194458,440.428802 879.407410,446.299164 883.055420,451.628204 
	C885.045105,454.534668 888.301147,456.574280 890.986084,459.004822 
	C891.573059,461.535309 892.207214,464.056030 892.737976,466.598267 
	C894.146484,473.344269 895.496033,480.102600 896.894897,486.850677 
	C900.810791,505.740631 901.493408,524.861084 900.997375,544.535706 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M883.003906,432.610291 
	C878.586792,432.397858 875.803101,433.443359 875.440613,438.720154 
	C875.114685,443.465210 873.639404,448.120300 873.038086,452.861603 
	C872.668152,455.778381 871.358154,457.024384 868.546631,456.984558 
	C867.214294,456.965668 865.879089,456.960632 864.548828,457.024780 
	C858.849426,457.299622 857.283936,458.901062 857.013916,464.673065 
	C856.640381,472.657043 856.640381,472.657043 848.730164,473.012054 
	C842.647339,473.285065 840.599060,475.291138 841.117249,481.480865 
	C841.389893,484.738251 842.714966,487.901978 843.500244,491.123688 
	C844.230774,494.120941 843.747559,496.728943 840.102173,496.918579 
	C834.348938,497.217865 832.958130,500.591766 832.960632,505.605743 
	C832.964172,512.787354 832.835510,512.787415 825.296753,513.007324 
	C818.707092,513.199524 816.990967,514.751282 817.075500,521.423218 
	C817.128418,525.602478 816.768250,528.965759 811.823181,530.136658 
	C810.772949,530.385254 810.191223,532.416138 809.290100,533.543152 
	C808.304016,534.776245 807.394226,536.602356 806.108826,536.968689 
	C801.501648,538.281677 800.877808,541.670288 801.035461,545.532593 
	C801.194397,549.428101 801.069214,552.890625 796.172913,553.954285 
	C795.168091,554.172546 794.376282,555.703979 793.648438,556.743652 
	C793.184937,557.405701 793.237183,558.515137 792.686829,559.016052 
	C788.731018,562.617249 784.982483,566.571472 780.560547,569.494507 
	C777.217285,571.704590 776.976379,574.503357 777.063660,577.616638 
	C777.174500,581.571167 776.925049,584.896179 772.101685,586.009338 
	C771.012146,586.260803 770.256104,588.109253 769.423584,589.277527 
	C768.671692,590.332642 768.204468,592.226379 767.303650,592.437805 
	C760.874451,593.946777 760.477356,599.196411 760.130493,604.101807 
	C759.840942,608.196655 757.849670,608.999573 754.322632,609.052856 
	C746.239624,609.174744 744.986084,610.401428 745.069946,618.397156 
	C745.109314,622.151245 744.448059,624.867920 740.299377,625.895081 
	C739.262390,626.151733 738.549988,627.675415 737.663391,628.592590 
	C736.039429,630.272461 734.127625,631.750793 732.831543,633.651184 
	C731.285522,635.917969 729.392883,638.476074 729.205139,641.022583 
	C728.893372,645.252319 728.661682,648.280273 723.880920,650.409424 
	C721.689026,651.385559 720.504822,656.289856 720.240356,659.525330 
	C719.873901,664.008667 717.954041,665.080872 713.907043,665.073120 
	C706.479431,665.058838 705.156250,666.545532 705.004028,673.812927 
	C704.857239,680.822449 704.804688,680.877014 698.089355,681.003235 
	C690.500000,681.145935 688.971008,682.516052 689.072327,690.130005 
	C689.124573,694.054504 687.891052,696.046875 684.253845,698.209656 
	C681.310547,699.959778 679.908203,704.513672 678.173706,707.998779 
	C677.238281,709.878296 676.716858,712.103149 676.575378,714.210266 
	C676.253235,719.005920 673.955505,721.079224 669.138123,721.639038 
	C665.065613,722.112366 665.138489,725.957458 665.019958,729.144897 
	C664.735840,736.782898 664.760620,736.848145 657.257874,736.981384 
	C653.282166,737.052002 650.046814,737.828979 648.902100,742.378845 
	C648.637756,743.429321 647.290161,744.690674 646.212646,744.968384 
	C641.908325,746.077271 640.998962,749.144592 641.008606,752.937195 
	C641.015381,755.602966 640.873962,758.276489 641.032288,760.932678 
	C641.285156,765.175598 641.119995,768.916321 635.810547,770.141479 
	C634.766174,770.382507 634.212891,772.453125 633.278931,773.548523 
	C632.159668,774.861328 630.884460,776.042603 629.659729,777.263794 
	C628.219360,778.700012 626.731873,780.090027 625.319275,781.552734 
	C624.221497,782.689392 623.404114,784.598450 622.120483,784.963867 
	C617.517456,786.274170 616.878479,789.652527 617.034607,793.520142 
	C617.191528,797.408447 616.652100,800.081177 612.343201,802.211182 
	C609.960754,803.388916 608.589294,808.213806 608.256897,811.543762 
	C607.810181,816.019592 605.927002,817.076477 601.893799,817.072510 
	C594.368347,817.065125 592.990051,818.532349 593.066467,826.325378 
	C593.102783,830.038025 592.568848,832.842224 588.364624,833.865112 
	C587.319702,834.119385 586.576538,835.608948 585.692139,836.528259 
	C584.072083,838.212280 582.197266,839.721191 580.894287,841.622253 
	C579.343506,843.884705 577.448364,846.402649 577.202271,848.954468 
	C576.816711,852.952393 576.819763,856.185791 571.515747,856.388733 
	C570.335815,856.433899 568.948486,858.627563 568.209717,860.107971 
	C567.670471,861.188538 568.014221,862.721863 568.006409,864.053589 
	C567.958008,872.344177 567.941772,872.177490 559.863098,873.156860 
	C557.871521,873.398315 556.058289,875.110413 554.163025,876.146423 
	C555.271851,877.912170 556.017822,880.152649 557.569702,881.338867 
	C560.285950,883.415161 563.496948,884.844238 565.430908,885.938965 
	C563.840271,887.572876 561.466431,890.011353 559.092590,892.449829 
	C561.219849,894.491516 563.347107,896.533203 565.424194,899.011353 
	C562.116760,899.966431 558.866882,900.879150 555.600891,900.941223 
	C530.216919,901.424194 504.919769,900.609985 479.892639,894.689575 
	C478.831665,893.937927 477.886566,893.496704 476.613647,893.056030 
	C475.848816,893.047546 475.411804,893.038574 475.101624,892.701416 
	C478.171356,890.961426 481.011871,888.996460 484.109955,888.423157 
	C485.271362,888.208252 486.778442,891.744324 488.588165,892.635315 
	C490.509064,893.580994 492.976990,893.607910 495.204620,893.600830 
	C495.753143,893.599121 496.970551,891.409790 496.725769,891.078064 
	C495.638611,889.604492 494.177917,888.385742 492.756866,887.188049 
	C492.065155,886.605042 491.163940,886.270630 489.368774,885.275391 
	C491.679169,884.278748 493.219788,883.835632 494.495636,882.992126 
	C495.479187,882.341858 496.818695,881.182800 496.811005,880.259888 
	C496.775848,876.025269 497.949738,873.194824 502.704529,872.558228 
	C503.588440,872.439941 504.582764,870.434326 504.859863,869.155396 
	C505.239868,867.401428 505.049133,865.507385 504.985901,863.677368 
	C504.863922,860.146240 506.342926,858.548157 509.669037,856.631775 
	C514.087830,854.085876 517.266113,849.385742 520.966248,845.600464 
	C521.061584,845.502991 520.968567,845.221191 521.063904,845.124023 
	C525.006653,841.104431 528.485962,836.365540 533.096863,833.368103 
	C535.742920,831.647949 537.022888,830.489197 537.028381,827.530579 
	C537.033325,824.885498 536.313965,820.940735 537.705750,819.845093 
	C541.556519,816.813660 544.244873,811.741455 547.841614,810.022644 
	C552.774109,807.665466 553.057312,805.037170 553.026306,800.922363 
	C552.972046,793.733643 553.755432,793.041443 561.133240,792.998108 
	C561.966431,792.993225 562.803772,792.938538 563.632080,793.001099 
	C567.426270,793.287964 569.249023,791.656555 569.014648,787.754822 
	C568.895020,785.763794 568.676880,783.669006 569.153381,781.785522 
	C569.555115,780.197632 570.554626,778.201477 571.878906,777.502014 
	C578.004883,774.266846 578.078613,774.406433 575.010681,768.859375 
	C575.780090,766.137085 576.404907,763.926331 576.647156,763.069153 
	C579.968628,760.888245 582.965759,759.690796 584.786194,757.510742 
	C586.649170,755.279785 587.359009,752.085938 588.869629,748.637756 
	C590.754578,749.989075 592.555603,751.280273 594.428528,752.623047 
	C594.907471,751.946533 595.510071,751.403748 595.410217,751.191101 
	C593.168213,746.419800 595.177185,743.927490 599.726013,742.405884 
	C601.057007,741.960693 601.953186,740.325073 603.269897,739.704346 
	C605.842163,738.491455 608.495056,737.188416 611.252808,736.694824 
	C614.840637,736.052551 618.562988,736.161987 622.344482,735.941101 
	C622.175598,735.438049 622.150330,734.867676 621.853516,734.554932 
	C618.760132,731.296326 617.643799,728.855530 623.615051,727.632996 
	C626.471619,727.048218 627.252625,721.810791 624.572510,719.790955 
	C621.504822,717.479065 622.970276,715.705078 624.086182,713.459534 
	C624.876709,711.868469 625.145813,709.976257 626.106018,708.521057 
	C627.646667,706.186401 629.633240,704.151245 631.298462,701.891479 
	C632.130737,700.761902 633.452515,699.237732 633.216064,698.195251 
	C631.280701,689.660522 632.016052,688.685608 641.250000,689.170654 
	C641.361145,690.554443 641.664612,692.212036 640.987427,692.890076 
	C637.630615,696.250854 638.632263,699.064514 642.163879,700.939636 
	C644.521301,702.191406 647.431885,702.527344 650.145203,702.952148 
	C653.843689,703.531067 655.207764,701.644104 655.040649,698.026245 
	C654.856323,694.036987 654.504028,689.937439 655.215759,686.068176 
	C655.655090,683.679321 657.470703,680.686523 659.518311,679.659790 
	C662.306580,678.261658 663.188049,676.742004 663.028442,673.956787 
	C662.953064,672.640991 662.697205,670.939209 663.366943,670.042908 
	C667.021362,665.152161 670.885193,660.413391 674.792480,655.718384 
	C676.252991,653.963440 678.043640,652.486023 679.647888,650.846863 
	C680.559021,649.915894 681.298157,648.201355 682.285461,648.063721 
	C687.269836,647.368652 687.771606,643.204651 689.017151,639.736389 
	C689.513611,638.354126 689.259827,636.602478 688.935608,635.108521 
	C688.869507,634.804199 686.448547,634.424500 685.995422,634.907349 
	C684.904785,636.069702 683.729797,637.698730 683.671753,639.182007 
	C683.495544,643.681091 682.115662,644.660095 677.880859,643.297485 
	C675.726318,642.604187 673.224670,642.370728 670.990112,642.693115 
	C667.794006,643.154358 666.141113,642.312073 666.382568,638.998108 
	C670.677429,638.450439 674.094421,632.729858 679.158325,639.649597 
	C679.868042,635.550903 680.293152,633.096130 680.630249,630.365845 
	C679.540405,628.511841 678.538452,626.933472 677.937378,625.291931 
	C680.892151,626.152527 683.446045,627.076233 686.283569,628.212158 
	C687.480530,628.821289 688.605713,629.001221 689.272400,629.651306 
	C692.950745,633.238586 697.791626,633.025024 701.282898,631.063843 
	C703.279663,629.942322 703.219482,625.073669 703.966003,621.864258 
	C704.186584,620.915833 703.999756,619.872620 704.000061,618.872864 
	C704.002930,608.906738 704.338684,608.453491 714.229553,608.035461 
	C718.177368,607.868713 720.079163,606.162537 719.997681,602.213867 
	C719.959839,600.381958 720.045898,598.547424 720.003784,596.715698 
	C719.942017,594.023010 720.959900,592.600037 723.789307,591.777893 
	C725.445801,591.296570 726.609253,589.094666 727.977722,587.650818 
	C731.620178,583.807800 734.663330,578.893860 739.076294,576.442688 
	C742.632751,574.467224 744.161499,572.826599 744.016724,569.088135 
	C743.920166,566.592468 743.995911,564.089966 744.000671,561.590515 
	C744.016479,553.260193 745.020569,552.186157 753.510986,552.027466 
	C756.816162,551.965637 759.284607,551.352783 760.150208,547.494019 
	C760.453735,546.140991 762.206421,544.466187 763.588257,544.128357 
	C767.115356,543.266174 768.003906,541.091309 768.019958,537.914673 
	C768.033386,535.265076 767.333801,531.002136 768.596375,530.261841 
	C772.874207,527.753601 774.924866,522.603088 778.430969,520.733032 
	C784.271606,517.617676 786.609802,513.163025 788.034302,507.716858 
	C789.980713,500.275055 793.708557,496.031097 799.300903,496.000153 
	C807.550293,495.954498 807.967041,495.509460 808.008423,487.139648 
	C808.029053,482.978363 806.622009,477.781494 808.582031,474.911560 
	C811.192017,471.090088 815.097473,467.524475 818.703186,464.504303 
	C824.296753,459.819092 830.577759,456.626282 831.367188,448.108307 
	C832.111145,440.080658 832.575256,439.943390 840.908325,440.068481 
	C845.646423,440.139618 848.150757,438.538361 848.213501,433.555786 
	C848.233826,431.941986 848.597168,429.231384 849.487976,428.907990 
	C855.380554,426.769104 857.365540,420.858643 861.568909,417.325775 
	C863.987915,415.292664 868.453308,415.694305 872.119629,415.322968 
	C873.369324,417.535614 874.251099,419.643433 875.685669,421.260895 
	C877.562622,423.377106 879.876526,425.105804 882.002441,427.001221 
	C882.336731,428.738159 882.671021,430.475098 883.003906,432.610291 
M607.765991,750.141907 
	C608.094910,748.349243 608.423767,746.556580 608.752686,744.763916 
	C608.355286,744.516602 607.957947,744.269226 607.560608,744.021851 
	C606.124207,745.131592 604.200378,745.974976 603.403076,747.434021 
	C602.746094,748.636292 603.423706,750.567810 603.509949,752.176208 
	C604.830688,751.726929 606.151428,751.277649 607.765991,750.141907 
M672.526855,639.500916 
	C672.526855,639.500916 672.486389,639.515991 672.526855,639.500916 
z"/>
<path fill="#4F4D50" opacity="1.000000" stroke="none" 
	d="
M686.000000,628.000000 
	C683.446045,627.076233 680.892151,626.152527 677.619507,625.266724 
	C676.246277,625.221558 675.591614,625.138428 674.863525,624.781494 
	C674.435425,624.087646 674.080688,623.667725 673.661255,622.900024 
	C672.649048,621.028870 671.757690,619.466858 670.744385,617.988525 
	C666.750366,612.161682 662.718018,606.361084 658.699890,600.550720 
	C657.300415,595.419495 656.053223,590.239868 654.441345,585.176270 
	C653.552429,582.383667 652.051514,579.785889 650.826538,577.100281 
	C651.276550,576.830505 651.726624,576.560669 652.176697,576.290833 
	C654.258789,577.954956 656.293884,579.682617 658.434875,581.267151 
	C661.124329,583.257690 663.369751,583.573120 664.023743,579.375549 
	C664.049438,579.211060 664.024658,578.951111 664.117310,578.897400 
	C668.721680,576.227295 671.158752,570.993591 675.349304,568.383667 
	C678.546997,566.392029 680.134521,564.866455 680.011536,561.334717 
	C679.947876,559.506592 679.692688,557.583252 680.151428,555.871033 
	C680.519165,554.498474 681.698669,552.459473 682.749756,552.306274 
	C687.449036,551.621033 688.219849,548.662292 688.013489,544.765991 
	C687.934326,543.272644 688.037231,541.770508 688.026001,540.272522 
	C688.004272,537.371460 689.456360,535.995911 692.328308,536.023560 
	C693.826172,536.037964 695.324585,536.017151 696.822571,535.997192 
	C703.038879,535.914490 703.924316,535.000244 704.000122,528.630005 
	C704.048279,524.580566 703.472168,521.267273 709.138184,519.006775 
	C712.741882,517.569092 715.611877,512.420288 719.165039,509.167938 
	C721.137634,507.362305 721.651367,504.093384 725.310486,503.300293 
	C726.488647,503.044983 727.720337,500.254150 727.860901,498.535980 
	C728.226196,494.070465 728.093994,489.554138 727.979187,485.062714 
	C727.887268,481.467468 729.424988,479.776978 733.038635,480.001129 
	C733.867615,480.052551 734.702820,480.004303 735.535156,479.999756 
	C743.434998,479.956421 743.955200,479.429047 744.000122,471.420349 
	C744.041199,464.091766 744.094421,463.948730 751.671570,464.036072 
	C755.943359,464.085266 759.789551,464.024597 760.376343,458.352020 
	C760.464722,457.497345 762.143738,456.313019 763.222107,456.144073 
	C767.045593,455.545135 768.074219,453.197632 768.011719,449.774506 
	C767.938843,445.780640 767.979126,441.784149 768.014893,437.789185 
	C768.025269,436.630463 767.892639,434.595184 768.327209,434.448547 
	C773.736877,432.623688 775.284851,426.686829 779.770996,423.967804 
	C779.903015,423.887756 780.162781,423.968414 780.249939,423.871979 
	C782.602539,421.269836 784.489746,417.290375 787.378113,416.336151 
	C792.738464,414.565247 791.936035,410.906921 791.973206,407.204681 
	C792.009155,403.623962 791.568726,400.097900 796.794250,399.546234 
	C797.961365,399.423065 799.108276,397.103027 799.839905,395.589203 
	C800.301147,394.634857 799.988464,393.299408 799.997192,392.134705 
	C800.057861,384.044769 800.055725,384.045013 807.956421,383.999390 
	C808.955139,383.993622 810.068848,384.263702 810.927612,383.912750 
	C812.494324,383.272430 813.910034,382.262695 815.388794,381.407104 
	C814.189575,380.283264 813.122986,378.416748 811.766357,378.174530 
	C807.658997,377.441101 807.960815,374.621552 808.059937,371.755066 
	C808.181335,368.246246 810.660645,367.978455 813.336060,368.002075 
	C815.000549,368.016785 816.666077,368.020935 818.329651,367.974976 
	C819.156372,367.952148 820.361450,368.124390 820.744446,367.665405 
	C823.881165,363.905365 828.354553,360.750336 830.422302,356.856812 
	C832.562622,352.826691 833.865967,351.920441 837.643860,353.318237 
	C838.370056,353.586975 839.230835,353.492218 840.186035,353.579437 
	C840.686707,351.075043 841.147278,348.771088 841.826904,346.214905 
	C844.257507,348.697174 846.910645,351.185516 848.598145,354.212219 
	C853.728394,363.413727 858.652527,372.741333 863.291565,382.199036 
	C864.783752,385.241180 865.141724,388.839691 865.620911,392.566650 
	C860.681458,391.752258 856.434875,391.039917 855.666748,397.666260 
	C855.579834,398.416199 854.458740,399.577881 853.739563,399.639252 
	C847.687988,400.155731 847.936890,404.405457 847.972961,408.751373 
	C848.031555,415.815491 847.812988,415.959167 840.643555,416.000305 
	C832.560059,416.046661 832.043579,416.549408 832.000305,424.416595 
	C831.995728,425.249756 831.947693,426.085876 831.999695,426.915588 
	C832.224548,430.499939 830.610657,432.210480 826.977661,432.004089 
	C825.649170,431.928650 824.310974,431.966248 822.979431,432.014313 
	C817.693970,432.205170 816.158386,433.684113 816.027100,438.957153 
	C815.923401,443.120605 816.312012,447.326752 815.821838,451.435181 
	C815.627380,453.065033 813.820374,454.660248 812.453491,455.962585 
	C808.480347,459.748108 804.323181,463.340424 800.339661,467.115570 
	C799.844604,467.584656 800.085083,468.753204 799.758301,469.498413 
	C799.442871,470.217865 798.883850,471.327484 798.347656,471.378357 
	C790.802368,472.094513 791.967590,477.684937 792.020264,482.581970 
	C792.052490,485.576660 791.362244,487.444244 787.923157,488.319672 
	C786.316772,488.728607 784.898682,491.321930 784.153076,493.222473 
	C783.528503,494.814636 783.901245,496.845337 784.015625,498.670380 
	C784.255554,502.497528 782.591431,504.249451 778.720825,504.017883 
	C776.729858,503.898743 774.550659,503.549683 772.773376,504.187531 
	C771.011780,504.819763 769.600586,506.508301 768.123352,507.831421 
	C766.589905,509.204742 765.114075,510.650635 763.701111,512.147888 
	C762.432068,513.492554 760.732056,514.777039 760.221313,516.402100 
	C759.599243,518.381226 759.961853,520.690125 760.008179,522.854065 
	C760.068115,525.656067 758.991638,526.920044 756.193848,528.270752 
	C751.388123,530.590759 749.509644,536.652527 744.407837,539.100647 
	C743.927368,539.331177 744.054565,540.972900 744.022888,541.963440 
	C743.959106,543.961365 744.223755,546.004944 743.880981,547.946350 
	C743.650330,549.252563 742.722107,551.395447 741.914551,551.487671 
	C735.676697,552.199585 735.914246,556.525452 735.967041,561.052185 
	C736.045044,567.740967 735.730225,567.956299 728.842773,567.999878 
	C720.456787,568.052979 720.048706,568.455261 720.000000,576.717285 
	C719.958130,583.816406 719.815125,583.958557 712.677307,584.000000 
	C704.453857,584.047729 704.034119,584.498657 703.991577,592.883667 
	C703.970398,597.044922 705.391052,602.281372 703.411255,605.103088 
	C700.748474,608.898132 696.890686,612.621704 693.206482,615.378052 
	C690.127319,617.681763 687.900879,618.934448 687.871338,622.982849 
	C687.859070,624.659729 686.659546,626.327881 686.000000,628.000000 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M686.283508,628.212158 
	C686.659546,626.327881 687.859070,624.659729 687.871338,622.982849 
	C687.900879,618.934448 690.127319,617.681763 693.206482,615.378052 
	C696.890686,612.621704 700.748474,608.898132 703.411255,605.103088 
	C705.391052,602.281372 703.970398,597.044922 703.991577,592.883667 
	C704.034119,584.498657 704.453857,584.047729 712.677307,584.000000 
	C719.815125,583.958557 719.958130,583.816406 720.000000,576.717285 
	C720.048706,568.455261 720.456787,568.052979 728.842773,567.999878 
	C735.730225,567.956299 736.045044,567.740967 735.967041,561.052185 
	C735.914246,556.525452 735.676697,552.199585 741.914551,551.487671 
	C742.722107,551.395447 743.650330,549.252563 743.880981,547.946350 
	C744.223755,546.004944 743.959106,543.961365 744.022888,541.963440 
	C744.054565,540.972900 743.927368,539.331177 744.407837,539.100647 
	C749.509644,536.652527 751.388123,530.590759 756.193848,528.270752 
	C758.991638,526.920044 760.068115,525.656067 760.008179,522.854065 
	C759.961853,520.690125 759.599243,518.381226 760.221313,516.402100 
	C760.732056,514.777039 762.432068,513.492554 763.701111,512.147888 
	C765.114075,510.650635 766.589905,509.204742 768.123352,507.831421 
	C769.600586,506.508301 771.011780,504.819763 772.773376,504.187531 
	C774.550659,503.549683 776.729858,503.898743 778.720825,504.017883 
	C782.591431,504.249451 784.255554,502.497528 784.015625,498.670380 
	C783.901245,496.845337 783.528503,494.814636 784.153076,493.222473 
	C784.898682,491.321930 786.316772,488.728607 787.923157,488.319672 
	C791.362244,487.444244 792.052490,485.576660 792.020264,482.581970 
	C791.967590,477.684937 790.802368,472.094513 798.347656,471.378357 
	C798.883850,471.327484 799.442871,470.217865 799.758301,469.498413 
	C800.085083,468.753204 799.844604,467.584656 800.339661,467.115570 
	C804.323181,463.340424 808.480347,459.748108 812.453491,455.962585 
	C813.820374,454.660248 815.627380,453.065033 815.821838,451.435181 
	C816.312012,447.326752 815.923401,443.120605 816.027100,438.957153 
	C816.158386,433.684113 817.693970,432.205170 822.979431,432.014313 
	C824.310974,431.966248 825.649170,431.928650 826.977661,432.004089 
	C830.610657,432.210480 832.224548,430.499939 831.999695,426.915588 
	C831.947693,426.085876 831.995728,425.249756 832.000305,424.416595 
	C832.043579,416.549408 832.560059,416.046661 840.643555,416.000305 
	C847.812988,415.959167 848.031555,415.815491 847.972961,408.751373 
	C847.936890,404.405457 847.687988,400.155731 853.739563,399.639252 
	C854.458740,399.577881 855.579834,398.416199 855.666748,397.666260 
	C856.434875,391.039917 860.681458,391.752258 865.622070,392.979675 
	C866.277954,393.501129 866.534973,393.992493 866.558228,394.819153 
	C859.952576,398.315094 863.926514,403.554901 864.505005,407.475616 
	C865.183777,412.076019 869.387451,408.630066 872.000000,409.428467 
	C871.999207,411.571289 871.998352,413.285645 871.997559,415.000000 
	C868.453308,415.694305 863.987915,415.292664 861.568909,417.325775 
	C857.365540,420.858643 855.380554,426.769104 849.487976,428.907990 
	C848.597168,429.231384 848.233826,431.941986 848.213501,433.555786 
	C848.150757,438.538361 845.646423,440.139618 840.908325,440.068481 
	C832.575256,439.943390 832.111145,440.080658 831.367188,448.108307 
	C830.577759,456.626282 824.296753,459.819092 818.703186,464.504303 
	C815.097473,467.524475 811.192017,471.090088 808.582031,474.911560 
	C806.622009,477.781494 808.029053,482.978363 808.008423,487.139648 
	C807.967041,495.509460 807.550293,495.954498 799.300903,496.000153 
	C793.708557,496.031097 789.980713,500.275055 788.034302,507.716858 
	C786.609802,513.163025 784.271606,517.617676 778.430969,520.733032 
	C774.924866,522.603088 772.874207,527.753601 768.596375,530.261841 
	C767.333801,531.002136 768.033386,535.265076 768.019958,537.914673 
	C768.003906,541.091309 767.115356,543.266174 763.588257,544.128357 
	C762.206421,544.466187 760.453735,546.140991 760.150208,547.494019 
	C759.284607,551.352783 756.816162,551.965637 753.510986,552.027466 
	C745.020569,552.186157 744.016479,553.260193 744.000671,561.590515 
	C743.995911,564.089966 743.920166,566.592468 744.016724,569.088135 
	C744.161499,572.826599 742.632751,574.467224 739.076294,576.442688 
	C734.663330,578.893860 731.620178,583.807800 727.977722,587.650818 
	C726.609253,589.094666 725.445801,591.296570 723.789307,591.777893 
	C720.959900,592.600037 719.942017,594.023010 720.003784,596.715698 
	C720.045898,598.547424 719.959839,600.381958 719.997681,602.213867 
	C720.079163,606.162537 718.177368,607.868713 714.229553,608.035461 
	C704.338684,608.453491 704.002930,608.906738 704.000061,618.872864 
	C703.999756,619.872620 704.186584,620.915833 703.966003,621.864258 
	C703.219482,625.073669 703.279663,629.942322 701.282898,631.063843 
	C697.791626,633.025024 692.950745,633.238586 689.272400,629.651306 
	C688.605713,629.001221 687.480530,628.821289 686.283508,628.212158 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M890.914001,458.616882 
	C888.301147,456.574280 885.045105,454.534668 883.055420,451.628204 
	C879.407410,446.299164 882.194458,440.428802 882.925415,434.378876 
	C884.981812,438.964050 887.133545,443.890839 888.860107,448.962372 
	C889.871033,451.931915 890.203979,455.132355 890.914001,458.616882 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M872.000000,409.000000 
	C869.387451,408.630066 865.183777,412.076019 864.505005,407.475616 
	C863.926514,403.554901 859.952576,398.315094 866.682495,395.067383 
	C868.575867,397.120422 870.111145,399.260529 871.824585,402.130859 
	C872.001892,404.907379 872.000916,406.953705 872.000000,409.000000 
z"/>
<path fill="#4F4D50" opacity="1.000000" stroke="none" 
	d="
M872.000000,409.428467 
	C872.000916,406.953705 872.001892,404.907379 872.003784,402.421539 
	C873.262634,405.373108 874.439453,408.797058 875.797791,412.147430 
	C877.728638,416.910248 879.793030,421.618896 881.901367,426.675964 
	C879.876526,425.105804 877.562622,423.377106 875.685669,421.260895 
	C874.251099,419.643433 873.369324,417.535614 872.119629,415.322968 
	C871.998352,413.285645 871.999207,411.571289 872.000000,409.428467 
z"/>
<path fill="#211F20" opacity="1.000000" stroke="none" 
	d="
M843.971924,321.411377 
	C846.196716,322.446716 848.838257,323.505829 850.565308,325.399078 
	C852.993530,328.060913 854.742981,331.341949 856.555298,334.753906 
	C854.871460,335.767700 853.414978,336.385712 851.958496,337.003662 
	C849.299133,331.938293 846.639771,326.872894 843.971924,321.411377 
z"/>
<path fill="#1B1818" opacity="1.000000" stroke="none" 
	d="
M852.140259,337.294403 
	C853.414978,336.385712 854.871460,335.767700 856.660278,335.079407 
	C856.992676,335.009094 856.995117,335.505127 856.996338,335.753174 
	C857.178894,337.431580 857.185242,338.912506 857.580566,340.281067 
	C858.258789,342.629059 859.180054,344.906830 859.995972,347.629486 
	C857.435791,344.558289 854.878845,341.071686 852.140259,337.294403 
z"/>
<path fill="#211F20" opacity="1.000000" stroke="none" 
	d="
M840.048523,315.367889 
	C840.985413,316.743195 841.995850,318.477325 843.011719,320.603699 
	C842.052063,319.239594 841.087036,317.483154 840.048523,315.367889 
z"/>
<path fill="#4B494C" opacity="1.000000" stroke="none" 
	d="
M820.804504,318.801758 
	C820.386169,318.822998 819.755310,318.650146 819.063843,318.227905 
	C819.532837,318.188202 820.062378,318.397919 820.804504,318.801758 
z"/>
<path fill="#252325" opacity="1.000000" stroke="none" 
	d="
M894.924927,381.659393 
	C892.225891,380.382935 889.451782,378.765900 886.338806,377.074402 
	C885.629211,375.219055 885.258423,373.438110 884.947510,371.329407 
	C888.288147,374.440704 891.568970,377.879761 894.924927,381.659393 
z"/>
<path fill="#383335" opacity="1.000000" stroke="none" 
	d="
M460.531677,415.036346 
	C460.722198,415.052307 460.912689,415.068298 461.458435,415.501282 
	C461.652161,422.315796 461.490601,428.713287 461.329071,435.110809 
	C452.412415,435.034149 453.096680,427.963165 451.547180,422.163910 
	C451.011230,426.673370 450.477844,431.183136 449.938477,435.692200 
	C449.572571,438.751312 448.663940,440.979218 444.835785,441.218903 
	C443.494781,441.302856 442.299957,443.520050 441.014038,444.737396 
	C439.509674,446.161560 438.088440,447.708893 436.423462,448.916351 
	C435.165649,449.828522 432.769867,449.927155 432.282410,451.015625 
	C430.130829,455.820221 425.815125,456.460327 421.597168,456.896423 
	C418.159485,457.251892 417.153839,458.763763 416.943939,461.979614 
	C416.470886,469.228333 415.712555,476.461761 414.889526,483.680817 
	C414.764130,484.780670 413.829651,486.517181 413.003632,486.690186 
	C407.595367,487.822815 407.005585,491.710205 406.983948,496.167969 
	C406.950897,502.980927 406.902435,502.980835 399.994598,502.999756 
	C399.328003,503.001556 398.661377,502.997681 397.994781,503.000732 
	C389.146606,503.040955 387.238922,505.631042 390.076233,514.069092 
	C390.944824,516.652344 391.380005,518.683960 387.802277,519.102356 
	C387.476410,519.140442 386.958862,519.189636 386.872375,519.385132 
	C383.878906,526.148987 373.925354,528.416870 374.986298,537.803467 
	C375.291534,540.503967 373.749847,541.754211 371.793671,543.675415 
	C367.164703,548.221741 362.850006,552.204651 355.797424,551.909729 
	C354.931549,551.873596 353.374634,554.494446 353.133789,556.039246 
	C352.470703,560.292603 352.357361,564.630249 351.980774,568.930542 
	C351.865601,570.245605 352.070801,571.985901 351.327087,572.781250 
	C347.803284,576.549377 344.487488,580.914917 340.140320,583.378052 
	C336.876160,585.227600 335.762268,586.893555 335.596436,590.665710 
	C329.390472,592.148987 323.439880,592.648499 317.861084,594.359863 
	C315.673279,595.030884 314.318146,598.416626 312.583801,600.565918 
	C314.175293,601.549744 315.759308,602.545959 317.361145,603.512695 
	C318.321930,604.092529 319.310608,604.626099 320.052551,605.435547 
	C314.548157,609.105774 313.447021,614.220032 313.985077,620.002441 
	C313.460846,620.035217 312.936646,620.067993 311.697540,620.165222 
	C309.750092,620.692261 308.462463,621.046082 307.294983,621.637329 
	C303.344971,623.637512 300.159637,622.165771 297.543579,619.279236 
	C295.401825,616.916016 295.622559,610.550720 298.032593,609.787598 
	C300.137787,609.120972 303.254883,609.566406 305.107849,610.774109 
	C306.956421,611.979004 308.364227,613.704834 309.735199,611.200500 
	C310.337585,610.100098 309.501740,607.519775 308.476501,606.349060 
	C305.232574,602.644775 301.056000,604.962769 297.216736,605.200134 
	C297.439178,604.411438 297.434631,603.427551 297.919067,602.864136 
	C302.848480,597.131104 300.582581,596.245972 296.009857,593.396118 
	C291.096924,590.334290 289.489716,585.345398 291.629364,579.244019 
	C292.066162,577.998352 292.691010,576.784424 292.907745,575.501587 
	C293.998291,569.047791 293.961426,569.039001 300.088623,570.443909 
	C303.541351,571.235535 305.192963,569.295715 306.691620,566.542053 
	C307.531830,564.998169 309.178101,563.881592 310.500885,562.613892 
	C314.375336,558.900696 318.336975,555.274353 322.095642,551.448120 
	C322.790161,550.741089 322.992065,549.238281 322.914520,548.138489 
	C322.572601,543.290222 323.036255,539.635803 328.515594,537.035706 
	C331.027985,535.843506 331.918762,530.559204 332.892181,526.954407 
	C334.407715,521.342102 335.082916,515.384460 342.591217,514.511047 
	C343.354675,514.422241 344.456573,513.933472 344.713715,513.334900 
	C346.470184,509.246002 350.296600,507.322571 353.171570,504.426239 
	C355.585175,501.994751 357.530182,499.308502 360.489685,497.155426 
	C362.317871,495.825409 362.666779,491.893433 362.883484,489.068542 
	C363.125763,485.910156 362.970734,483.032166 367.020447,482.348694 
	C367.768555,482.222443 368.807251,481.643524 369.044586,481.019745 
	C370.528625,477.118744 373.583740,475.088501 376.877136,472.808319 
	C378.297180,471.825165 378.269409,467.688232 377.769623,465.212036 
	C376.670074,459.764282 376.925446,459.083588 382.516876,459.003998 
	C384.182251,458.980286 385.851379,459.054596 387.513123,458.971283 
	C392.649628,458.713928 394.827881,456.685852 394.935974,451.582062 
	C395.027740,447.250214 394.318451,443.079346 400.244904,441.365662 
	C401.746246,440.931580 402.688568,436.606537 402.859528,434.001953 
	C403.098419,430.363251 403.613007,428.194244 407.309174,425.799377 
	C411.174042,423.295288 414.230133,419.005493 417.783295,415.609497 
	C419.346985,414.115051 418.366150,410.188782 419.112671,407.527069 
	C419.603302,405.777893 421.076813,404.286469 422.202820,402.747375 
	C422.454742,402.402985 423.229645,402.465729 423.534454,402.131775 
	C427.355560,397.945618 431.135193,393.721588 434.923340,389.505371 
	C435.655884,388.690094 436.251556,387.347473 437.127991,387.131653 
	C442.678802,385.764557 443.171570,381.661621 443.017365,377.037537 
	C442.916443,374.010468 442.529053,370.965912 447.280762,370.924164 
	C451.841309,370.884125 453.216339,373.153992 452.970764,377.110992 
	C452.786011,380.088074 452.149872,383.055603 452.183594,386.022034 
	C452.212097,388.527618 452.914276,391.025574 453.318268,393.526886 
	C453.798157,393.458588 454.278015,393.390289 454.757904,393.321991 
	C456.462891,390.893738 458.167847,388.465454 460.221222,386.019348 
	C460.610535,387.719299 460.651428,389.437134 460.297913,391.631958 
	C458.984436,393.819275 457.337616,395.508636 457.287659,397.243988 
	C457.156342,401.803955 457.542999,406.406128 458.110657,410.942993 
	C458.291199,412.385834 459.690216,413.676208 460.531677,415.036346 
z"/>
<path fill="#0D0B0E" opacity="1.000000" stroke="none" 
	d="
M297.173218,605.189209 
	C301.056000,604.962769 305.232574,602.644775 308.476501,606.349060 
	C309.501740,607.519775 310.337585,610.100098 309.735199,611.200500 
	C308.364227,613.704834 306.956421,611.979004 305.107849,610.774109 
	C303.254883,609.566406 300.137787,609.120972 298.032593,609.787598 
	C295.622559,610.550720 295.401825,616.916016 297.543579,619.279236 
	C300.159637,622.165771 303.344971,623.637512 307.294983,621.637329 
	C308.462463,621.046082 309.750092,620.692261 311.304993,620.198486 
	C311.637970,620.846069 311.648651,621.524902 311.406281,622.683960 
	C310.428223,623.768127 309.703156,624.372070 308.978119,624.975952 
	C307.827209,625.852539 306.676300,626.729065 305.254608,627.760254 
	C297.073273,627.834900 294.185760,625.769714 292.100891,618.364502 
	C292.105103,616.650574 292.154175,615.305176 292.410034,613.289185 
	C294.108337,610.111450 295.599854,607.604370 297.091339,605.097229 
	C297.091339,605.097229 297.129700,605.178284 297.173218,605.189209 
z"/>
<path fill="#0A0808" opacity="1.000000" stroke="none" 
	d="
M275.953247,625.846863 
	C275.462250,626.145020 274.971252,626.443176 273.945251,626.617432 
	C272.634338,626.316223 271.858429,626.138916 271.082520,625.961548 
	C271.082520,625.961548 271.030762,625.969299 271.031921,625.747559 
	C271.033112,625.525818 271.042450,625.132446 271.042450,625.132446 
	C271.027863,623.708740 271.013275,622.285034 271.019653,620.005371 
	C271.021515,617.429016 271.002472,615.708679 270.983429,613.988342 
	C271.010742,609.339478 270.841949,604.677002 271.164398,600.048706 
	C271.288757,598.263611 272.528870,596.556274 273.260651,594.813477 
	C273.951569,594.929993 274.642487,595.046448 275.333405,595.162964 
	C275.551819,601.138916 275.770233,607.114868 275.992615,613.939026 
	C276.008087,615.552490 276.019623,616.317688 276.031158,617.082947 
	C276.022156,619.436768 276.013153,621.790527 275.990143,624.791016 
	C275.976135,625.437622 275.953217,625.846863 275.953247,625.846863 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M276.372009,617.109741 
	C276.019623,616.317688 276.008087,615.552490 275.994110,614.405884 
	C280.710663,613.878845 281.914886,610.464722 283.128510,606.276123 
	C283.715790,605.628662 283.905579,605.399353 284.051880,605.134033 
	C286.893738,604.188660 288.228882,605.131348 287.591248,608.725159 
	C286.562225,611.313904 281.310242,611.509827 284.818085,615.308716 
	C286.031982,617.104370 287.245880,618.900024 288.709534,620.913452 
	C291.111115,623.356873 290.456360,625.629456 288.119781,626.663025 
	C287.098206,627.114868 284.819611,625.655945 283.600189,624.533997 
	C281.128265,622.259644 278.989197,619.623535 276.372009,617.109741 
z"/>
<path fill="#2C2A2C" opacity="1.000000" stroke="none" 
	d="
M258.014160,605.755127 
	C256.683258,604.905396 254.927292,603.881897 254.386688,602.411499 
	C254.133072,601.721619 256.221893,600.170654 257.623352,599.000488 
	C258.027374,601.126770 258.057220,603.262451 258.014160,605.755127 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M496.753571,356.854828 
	C489.356781,357.110046 481.602600,357.130615 473.366669,357.120300 
	C480.721985,356.932983 488.559113,356.776581 496.753571,356.854828 
z"/>
<path fill="#383335" opacity="1.000000" stroke="none" 
	d="
M296.718933,605.096924 
	C295.599854,607.604370 294.108337,610.111450 292.411804,612.885132 
	C290.775360,609.021912 292.519073,606.523804 296.718933,605.096924 
z"/>
<path fill="#383335" opacity="1.000000" stroke="none" 
	d="
M285.022583,614.998047 
	C281.310242,611.509827 286.562225,611.313904 287.567474,609.068970 
	C290.799316,612.530579 286.273285,612.829773 285.022583,614.998047 
z"/>
<path fill="#2C2A2C" opacity="1.000000" stroke="none" 
	d="
M270.741577,613.870972 
	C271.002472,615.708679 271.021515,617.429016 271.010071,619.578369 
	C270.490723,619.982117 270.001923,619.956787 269.144714,619.893433 
	C268.864014,617.858337 268.951660,615.861206 269.058044,613.432617 
	C269.551117,613.251892 270.025452,613.502747 270.741577,613.870972 
z"/>
<path fill="#383335" opacity="1.000000" stroke="none" 
	d="
M283.973724,604.879028 
	C283.905579,605.399353 283.715790,605.628662 283.298859,605.951050 
	C282.924652,605.405457 282.734070,604.730896 282.543488,604.056274 
	C282.994171,604.245544 283.444885,604.434753 283.973724,604.879028 
z"/>
<path fill="#4F3D2B" opacity="1.000000" stroke="none" 
	d="
M460.116333,369.170898 
	C460.263306,369.144958 460.468323,369.356720 460.705994,369.820068 
	C460.569885,369.828766 460.381775,369.607758 460.116333,369.170898 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M400.325989,881.423706 
	C397.738251,880.406189 395.150543,879.388672 392.150604,877.825317 
	C390.909271,877.032288 390.080170,876.785156 389.251099,876.538086 
	C388.677124,876.359375 388.103119,876.180725 387.145996,875.529541 
	C385.997803,874.798401 385.232727,874.539856 384.467621,874.281311 
	C382.822845,873.610840 381.178070,872.940369 379.190857,871.716919 
	C375.371216,869.551453 371.894012,867.938843 368.416809,866.326294 
	C367.069305,865.714783 365.721802,865.103333 364.067230,863.898682 
	C362.926331,863.052856 362.092468,862.800171 361.258636,862.547485 
	C359.323059,861.513062 357.387451,860.478638 355.169281,858.839355 
	C352.873810,857.210388 350.860870,856.186279 348.847961,855.162109 
	C348.847961,855.162109 348.999420,855.038330 348.870789,854.765747 
	C346.300842,853.103333 343.859558,851.713501 341.418274,850.323608 
	C340.980377,850.135376 340.542450,849.947083 339.908417,849.207764 
	C337.079437,847.137756 334.446594,845.618835 331.813751,844.099915 
	C331.515076,844.023010 331.274475,843.860229 330.992065,843.414429 
	C331.436859,842.010254 331.981598,840.803345 333.070312,839.452026 
	C335.405151,840.548218 337.196014,841.788757 339.089355,843.336548 
	C339.906708,844.032654 340.621582,844.421448 341.588348,844.890076 
	C342.239960,845.304260 342.639679,845.638611 343.132507,846.291504 
	C343.969849,846.971313 344.714111,847.332703 345.760559,847.720520 
	C346.701599,848.205017 347.340424,848.663086 348.091736,849.427612 
	C349.164764,850.128540 350.125336,850.523071 351.040222,850.940186 
	C350.994598,850.962830 351.070404,850.894226 351.154846,851.224731 
	C351.996826,851.928772 352.754333,852.302246 353.767822,852.694092 
	C354.337799,852.843994 354.651733,852.975342 355.052826,853.442139 
	C356.271210,854.394958 357.402435,855.012329 358.819397,855.674683 
	C359.766205,856.094055 360.427277,856.468506 361.199432,857.187866 
	C362.373535,857.902893 363.436523,858.273071 364.791260,858.700806 
	C365.706390,859.220337 366.329742,859.682312 367.086334,860.444214 
	C368.003204,861.014282 368.786804,861.284363 369.903931,861.623657 
	C372.474670,862.857178 374.711975,864.021545 377.087341,865.474121 
	C377.976837,866.070679 378.728271,866.379028 379.827698,866.762878 
	C385.310120,869.129028 390.406952,871.509033 395.587372,873.690430 
	C404.274963,877.348755 413.015778,880.880676 421.395294,884.539612 
	C419.702942,885.377258 418.349457,886.141052 416.982178,887.176575 
	C416.978729,887.629333 416.989044,887.810425 416.999329,887.991577 
	C412.565125,886.331421 408.130920,884.671204 403.214752,882.528870 
	C401.930542,881.838989 401.128265,881.631348 400.325989,881.423706 
z"/>
<path fill="#554A32" opacity="1.000000" stroke="none" 
	d="
M338.986877,843.029358 
	C337.196014,841.788757 335.405151,840.548218 333.307739,839.206909 
	C326.871368,834.326050 320.686829,829.613953 314.633423,824.738953 
	C311.289551,822.045959 308.165588,819.079895 304.945160,815.523743 
	C304.968964,814.550659 304.990143,814.290771 305.407532,814.028015 
	C306.861084,814.029724 307.918457,814.034241 309.004761,814.367676 
	C309.682526,815.463135 310.331329,816.229675 310.987549,817.001343 
	C310.994934,817.006470 311.008728,817.018188 311.147247,817.203857 
	C311.489532,817.624573 311.721893,817.826782 312.151611,818.277222 
	C313.545105,819.392517 314.769836,820.226746 316.230774,821.294189 
	C322.474121,826.053772 328.406189,830.684692 334.513275,835.071899 
	C339.019196,838.308899 343.880646,841.057251 348.326263,844.368774 
	C350.225006,845.783203 351.468689,848.076904 352.995544,850.258423 
	C352.974426,850.737610 352.967834,850.929382 352.648132,851.063477 
	C351.913513,850.968567 351.491943,850.931396 351.070404,850.894165 
	C351.070404,850.894226 350.994598,850.962830 350.900116,850.651245 
	C349.863495,849.933472 348.921387,849.527344 347.979279,849.121155 
	C347.340424,848.663086 346.701599,848.205017 345.611267,847.442871 
	C344.453033,846.750183 343.746216,846.361572 343.039398,845.973022 
	C342.639679,845.638611 342.239960,845.304260 341.439301,844.618652 
	C340.354523,843.854797 339.670685,843.442078 338.986877,843.029358 
z"/>
<path fill="#4F3D2B" opacity="1.000000" stroke="none" 
	d="
M305.011292,814.030823 
	C304.990143,814.290771 304.968964,814.550659 304.921631,815.202576 
	C299.565735,811.033691 294.236053,806.472900 288.786835,801.602905 
	C288.667297,801.293762 288.880798,801.101501 289.378540,801.032288 
	C295.126190,800.151123 296.998199,805.509155 301.040955,807.357422 
	C302.396149,809.126526 303.678345,810.573547 304.967896,812.355469 
	C304.987274,813.137207 304.999268,813.583984 305.011292,814.030823 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M331.964355,844.408691 
	C334.446594,845.618835 337.079437,847.137756 339.765869,849.009521 
	C337.251312,847.814087 334.683136,846.265747 331.964355,844.408691 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M341.603271,850.617432 
	C343.859558,851.713501 346.300842,853.103333 348.802063,854.835754 
	C346.504120,853.755981 344.146179,852.333618 341.603271,850.617432 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M348.975159,855.409058 
	C350.860870,856.186279 352.873810,857.210388 354.958313,858.581543 
	C353.646698,858.291260 352.263489,857.654053 350.556519,856.947632 
	C349.855957,856.470947 349.479156,856.063477 348.975159,855.409058 
z"/>
<path fill="#3B3539" opacity="1.000000" stroke="none" 
	d="
M240.956665,736.666687 
	C236.104401,736.327026 236.590759,731.845215 235.068726,728.371460 
	C235.542740,727.662964 236.054688,727.249390 236.634109,726.982361 
	C238.095901,726.308777 239.588120,725.701172 241.068115,725.067078 
	C240.792130,726.931091 240.645813,728.829529 240.166321,730.639648 
	C240.009064,731.233276 238.987274,731.597900 238.285629,732.123657 
	C239.264542,733.678040 240.095261,734.997070 240.956665,736.666687 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M271.204163,626.217163 
	C271.858429,626.138916 272.634338,626.316223 273.654022,626.663147 
	C273.040497,626.712830 272.183167,626.592834 271.204163,626.217163 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M270.921631,625.202515 
	C271.042450,625.132446 271.033112,625.525818 271.036316,625.721802 
	C270.720734,625.768555 270.641174,625.553528 270.921631,625.202515 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M276.075684,625.778809 
	C275.953217,625.846863 275.976135,625.437622 275.982788,625.233032 
	C276.287994,625.206970 276.357574,625.434326 276.075684,625.778809 
z"/>
<path fill="#322C2E" opacity="1.000000" stroke="none" 
	d="
M216.509079,689.493286 
	C216.516891,689.514343 216.501266,689.472229 216.509079,689.493286 
z"/>
<path fill="#403D40" opacity="1.000000" stroke="none" 
	d="
M305.407532,814.028015 
	C304.999268,813.583984 304.987274,813.137207 305.368286,812.347656 
	C307.091339,811.205261 308.421356,810.405640 309.751373,809.606018 
	C308.232422,808.546082 306.836639,807.132629 305.152802,806.546143 
	C303.959473,806.130493 302.376831,806.832336 300.967987,807.035400 
	C296.998199,805.509155 295.126190,800.151123 289.484467,800.935181 
	C290.840057,793.983276 284.690491,791.926025 281.243042,788.216797 
	C280.359833,787.266357 279.322998,786.458679 278.177979,785.300049 
	C278.483368,781.548157 279.178009,778.093201 279.370667,774.610535 
	C279.481934,772.599854 279.109344,770.419250 278.386444,768.532776 
	C276.341003,763.194885 277.786224,760.244202 283.432556,760.111633 
	C288.054077,760.003052 289.076569,757.615356 289.009552,753.750977 
	C288.932312,749.293457 288.081360,744.401001 294.667145,743.447937 
	C295.282928,743.358765 295.535278,741.480896 296.161407,740.575317 
	C297.151215,739.143738 298.187134,736.805115 299.427155,736.651123 
	C304.280396,736.048523 304.025330,732.862549 304.028748,729.404419 
	C304.036499,721.543213 305.574158,720.054077 313.324646,720.000122 
	C319.954590,719.953979 320.043732,719.953003 319.969452,713.352783 
	C319.917877,708.770020 319.996460,704.612976 325.990143,703.614990 
	C326.879669,703.466858 327.848297,700.749451 327.903198,699.189819 
	C328.084991,694.028015 327.086975,688.428894 334.625153,687.529907 
	C335.075867,687.476135 335.720245,686.610291 335.778076,686.069336 
	C336.468842,679.606873 341.189392,679.665222 345.798126,680.058655 
	C350.571442,680.466248 352.480835,678.735168 352.063171,673.867981 
	C351.657440,669.139343 352.287140,664.776245 358.270020,663.601624 
	C359.017700,663.454895 359.750977,661.564209 359.900818,660.405273 
	C360.176239,658.274780 359.897339,656.078918 360.055756,653.925537 
	C360.390656,649.372681 362.469635,647.552490 366.949921,648.579651 
	C371.039581,649.517212 372.822235,647.958191 373.745392,644.236572 
	C375.049225,638.980225 376.556396,633.774353 378.245544,628.136475 
	C378.858215,627.711670 379.201935,627.697266 379.545685,627.682861 
	C382.512299,627.567383 385.478943,627.451904 389.118042,627.184265 
	C395.006195,629.130188 398.855225,627.033936 402.381531,622.909058 
	C403.118866,621.862305 403.556396,620.933594 403.967010,619.657776 
	C402.876984,617.836182 401.813873,616.361755 400.838501,614.565918 
	C401.444855,612.816284 401.963409,611.387878 402.481995,609.959473 
	C403.433533,609.982605 404.385101,610.005737 405.724915,610.448608 
	C405.763123,613.475586 404.990936,616.109253 405.151947,618.684631 
	C405.475464,623.859375 409.830566,627.617432 415.181885,627.896423 
	C420.020630,628.148743 422.916931,625.767517 423.819031,621.053284 
	C420.881622,621.341370 417.546478,622.574219 414.985596,621.653503 
	C412.633301,620.807861 410.391846,617.900818 409.497742,615.402344 
	C408.951935,613.877197 410.513000,610.712341 412.072662,609.521484 
	C414.583862,607.604065 416.865173,609.218872 418.806885,611.563538 
	C419.320374,612.183594 421.518280,611.408691 422.942749,611.274292 
	C422.802612,609.846008 423.137207,607.528320 422.434753,607.153259 
	C420.169556,605.944031 417.522400,605.450195 415.022491,604.680542 
	C411.158508,600.975037 408.318451,596.876099 408.195801,591.148865 
	C408.122955,587.746155 408.618927,584.929443 412.257233,584.296814 
	C415.593903,583.716675 416.172699,581.829773 416.013123,579.037476 
	C415.927979,577.547974 416.022949,576.048889 416.012543,574.554321 
	C415.982910,570.295715 418.003479,568.036316 422.345795,568.020081 
	C423.508301,568.015686 424.687134,567.877930 425.830353,568.022949 
	C429.731873,568.517944 432.185852,567.362122 432.601440,563.002869 
	C432.746613,561.480103 433.749603,560.039124 434.451202,558.342163 
	C436.484711,559.284790 438.052460,560.011536 439.248993,560.566223 
	C442.559387,559.675842 438.768219,553.312073 444.822815,555.358704 
	C448.037506,561.183594 450.486145,566.816223 453.767365,571.912842 
	C457.570282,577.819763 462.104889,583.255615 466.070618,589.149170 
	C466.265503,593.652527 466.630920,597.914246 467.236389,602.141541 
	C467.338409,602.853760 468.476654,603.417542 469.292328,604.457275 
	C469.457031,606.904114 469.465515,608.943604 469.115173,611.053040 
	C468.173187,613.856873 467.284546,616.566895 467.069885,619.329346 
	C466.611328,625.231384 465.808258,630.671997 458.977295,632.762756 
	C457.931763,633.082764 456.662598,634.534119 456.521423,635.598145 
	C456.097046,638.796448 453.409698,639.106323 451.575073,640.712219 
	C447.799988,644.016602 444.558197,647.963379 440.788055,651.422974 
	C438.687103,653.350952 438.876312,657.774658 438.025482,661.104797 
	C433.024139,661.988525 432.830994,663.007629 436.457642,669.763062 
	C435.973602,669.819763 435.489563,669.876404 435.005524,669.933105 
	C435.005524,671.954529 435.005524,673.975952 434.786072,676.296936 
	C432.683411,682.274780 427.907318,679.724854 424.362823,679.894531 
	C416.696228,680.261475 416.044220,680.649170 416.000122,688.169434 
	C415.954468,695.956360 415.954498,695.956482 408.246918,695.999878 
	C407.581421,696.003601 406.914185,696.036560 406.250763,696.000427 
	C401.803558,695.758057 399.851440,697.876282 399.977417,702.294861 
	C400.100616,706.616821 400.293610,710.977600 399.834961,715.254700 
	C399.656616,716.917847 397.884491,718.488647 396.648102,719.939392 
	C395.187378,721.653503 393.575439,723.250305 391.932556,724.795288 
	C388.175110,728.328674 384.662567,732.318298 380.844696,735.491699 
	C378.074310,737.794434 375.979645,739.066467 375.960205,742.834412 
	C375.913544,751.868591 375.826477,751.868286 367.086212,752.000977 
	C366.753510,752.006042 366.420074,752.019775 366.088074,752.004944 
	C361.934631,751.818726 359.910126,753.731812 359.989044,757.957825 
	C360.069794,762.281799 359.947876,766.609497 360.009552,770.934143 
	C360.050049,773.773193 359.221741,775.450867 356.070160,776.256226 
	C354.459229,776.667908 353.385162,779.050720 351.991394,780.466797 
	C348.188599,784.330444 344.689941,788.616150 340.391602,791.824829 
	C337.685638,793.844788 333.045868,801.177307 335.042725,804.071716 
	C338.084625,808.480896 337.017944,811.646057 333.728912,815.072510 
	C332.512299,816.339905 332.071777,818.319946 330.990967,819.760010 
	C330.113647,820.928894 328.808197,821.776489 327.692627,822.766541 
	C326.800110,821.554504 325.278870,820.413879 325.131409,819.117126 
	C324.664185,815.008301 322.618591,812.759033 318.603821,813.516724 
	C313.944855,814.395935 315.185303,817.947205 315.994568,821.061035 
	C314.769836,820.226746 313.545105,819.392517 312.203918,818.115356 
	C311.727844,817.454407 311.368286,817.236328 311.008728,817.018188 
	C311.008728,817.018188 310.994934,817.006470 310.981873,816.703003 
	C310.304504,815.612610 309.640137,814.825684 308.975769,814.038818 
	C307.918457,814.034241 306.861084,814.029724 305.407532,814.028015 
M443.058044,606.020630 
	C439.430664,606.002991 435.315491,606.751526 435.943054,600.775146 
	C436.020325,600.039246 434.532379,598.389282 434.115570,598.497253 
	C433.033936,598.777588 431.647186,599.607178 431.272461,600.565613 
	C430.132690,603.480652 429.413422,606.560059 428.860474,608.448364 
	C429.741669,611.960388 430.470245,614.634033 431.073059,617.335754 
	C431.606628,619.727051 431.312134,622.681030 432.647491,624.410278 
	C434.008514,626.172791 436.915863,627.594971 439.138458,627.583984 
	C441.124695,627.574219 443.100281,625.407410 445.080566,624.192444 
	C436.867706,622.596680 436.177063,621.650452 436.707001,614.436890 
	C437.055298,609.695923 441.453400,610.007141 444.578308,607.960571 
	C445.077911,607.687134 445.577484,607.413635 446.077087,607.140198 
	C445.361572,606.775513 444.646027,606.410767 443.058044,606.020630 
M331.608612,807.913757 
	C329.975311,807.724304 328.342010,807.534851 326.708710,807.345398 
	C327.085938,808.751892 327.463165,810.158325 327.840424,811.564819 
	C329.154755,810.589722 330.469086,809.614624 331.608612,807.913757 
z"/>
<path fill="#F4D035" opacity="1.000000" stroke="none" 
	d="
M461.485718,435.547546 
	C461.490601,428.713287 461.652161,422.315796 461.741547,415.455383 
	C461.854065,414.980499 462.038757,414.968475 462.889984,415.005707 
	C467.097900,415.703308 470.618134,416.702332 474.185638,416.917419 
	C479.476257,417.236328 484.800415,417.016296 490.110168,416.995239 
	C496.618317,416.969391 496.971466,416.655182 496.980804,410.326202 
	C497.005005,393.898956 496.987518,377.471619 497.013458,360.982361 
	C497.040253,360.920380 497.094543,360.796692 497.094543,360.796692 
	C497.708405,362.376984 498.848877,363.954712 498.856659,365.537994 
	C498.964294,387.448151 498.918396,409.359039 498.905182,431.269775 
	C498.901794,436.855560 498.881165,442.441376 498.936584,448.887756 
	C498.761536,452.532257 498.167572,455.339600 498.351593,458.094971 
	C498.686127,463.104279 499.482971,468.082764 500.015991,473.346313 
	C499.633026,474.079590 499.321014,474.540619 498.711639,474.783356 
	C497.319183,465.082428 490.560577,465.737000 483.616547,465.904083 
	C476.719116,466.070007 469.802856,465.455780 462.776428,465.152893 
	C462.657990,465.122162 462.413391,465.128326 462.352753,464.659790 
	C462.075623,459.445404 461.859131,454.699493 461.642639,449.953613 
	C461.642548,445.297180 461.642426,440.640747 461.485718,435.547546 
z"/>
<path fill="#3B3539" opacity="1.000000" stroke="none" 
	d="
M320.286774,605.180481 
	C319.310608,604.626099 318.321930,604.092529 317.361145,603.512695 
	C315.759308,602.545959 314.175293,601.549744 312.583801,600.565918 
	C314.318146,598.416626 315.673279,595.030884 317.861084,594.359863 
	C323.439880,592.648499 329.390472,592.148987 335.596130,591.082275 
	C339.238464,592.000183 342.421082,593.474243 345.731445,593.864075 
	C348.892914,594.236389 351.509277,594.320618 352.696533,598.006531 
	C353.190033,599.538696 354.882019,600.684814 356.206635,602.211182 
	C352.078766,602.969177 346.762817,597.949890 343.608887,604.979187 
	C340.284882,604.978027 338.418091,605.954285 338.104584,608.993530 
	C337.215576,609.033142 336.326569,609.072754 334.748840,609.022827 
	C333.613983,608.342651 333.167877,607.751953 332.900940,606.992065 
	C333.058655,606.429565 333.037170,606.036255 333.015717,605.642883 
	C332.622742,605.856750 332.229767,606.070679 331.433685,606.219971 
	C327.449310,605.830383 323.868042,605.505432 320.286774,605.180481 
M332.494171,601.910950 
	C332.103943,601.427063 331.713715,600.943237 331.323486,600.459351 
	C331.115875,600.656067 330.908295,600.852844 330.700684,601.049561 
	C331.180328,601.455688 331.659973,601.861877 332.494171,601.910950 
M337.673737,601.952087 
	C337.958344,602.422546 338.242920,602.893005 338.527527,603.363464 
	C338.813934,603.091492 339.100311,602.819580 339.386719,602.547607 
	C338.925262,602.245789 338.463837,601.943970 337.673737,601.952087 
z"/>
<path fill="#71612B" opacity="1.000000" stroke="none" 
	d="
M461.308838,450.226898 
	C461.859131,454.699493 462.075623,459.445404 462.260254,464.848511 
	C462.181244,465.809143 462.268829,466.066742 462.396790,466.609222 
	C462.715057,469.890503 463.127716,472.841095 463.278290,475.967407 
	C440.213776,502.005890 437.444733,543.545898 456.851074,572.039917 
	C460.842010,577.899719 465.633667,583.214233 469.992493,589.209229 
	C469.736847,590.769287 469.544952,591.900208 469.101746,592.900635 
	C468.007568,591.478760 467.164734,590.187378 466.321869,588.895935 
	C462.104889,583.255615 457.570282,577.819763 453.767365,571.912842 
	C450.486145,566.816223 448.037506,561.183594 445.170837,555.424500 
	C440.819092,538.644775 441.364227,522.129272 444.611359,505.681213 
	C446.663330,495.287231 452.070465,486.300903 458.263641,477.072113 
	C459.079285,475.460602 459.597015,474.414948 460.317810,473.118164 
	C460.659668,472.256866 460.798462,471.646729 460.908173,470.774231 
	C460.840637,470.340118 460.802185,470.168396 460.763733,469.996704 
	C460.834167,463.497864 460.904572,456.999023 461.308838,450.226898 
z"/>
<path fill="#0A0808" opacity="1.000000" stroke="none" 
	d="
M338.125824,609.461426 
	C338.418091,605.954285 340.284882,604.978027 343.441254,605.326355 
	C345.381439,605.500122 347.063873,605.980225 348.534576,605.612854 
	C354.743805,604.062012 357.836090,606.217957 357.972595,612.558533 
	C358.044006,615.875061 358.301208,619.236694 357.863556,622.495850 
	C357.619110,624.316223 356.123932,625.968628 355.192383,627.696716 
	C354.446869,627.500977 353.701385,627.305298 352.955872,627.109558 
	C352.955872,623.095947 352.857788,619.079346 352.982391,615.069580 
	C353.117279,610.728088 351.465668,608.382019 346.115356,609.030518 
	C344.995880,609.759644 344.587128,610.381348 344.178345,611.002991 
	C344.086792,614.963196 344.239868,618.952698 343.771637,622.867859 
	C343.606659,624.247253 341.921051,625.444824 340.929749,626.725403 
	C340.016541,625.421509 338.429688,624.173767 338.315338,622.803223 
	C337.959381,618.536926 338.161438,614.224121 338.125824,609.461426 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M320.052551,605.435547 
	C323.868042,605.505432 327.449310,605.830383 331.567078,606.379395 
	C332.290497,606.810608 332.496582,606.996521 332.721802,607.161194 
	C333.167877,607.751953 333.613983,608.342651 334.305511,609.061523 
	C336.300171,615.118530 336.408081,620.735596 331.177765,625.151855 
	C325.173553,630.221680 317.288300,628.113647 314.095917,620.342773 
	C313.447021,614.220032 314.548157,609.105774 320.052551,605.435547 
M318.545074,616.860901 
	C319.352417,618.471313 319.889709,620.310791 321.022064,621.645508 
	C323.558502,624.635498 329.337494,624.938477 329.875153,622.064026 
	C330.471100,618.877747 329.610046,614.950073 328.093536,612.010864 
	C327.161621,610.204712 323.812439,609.645752 321.548645,608.526794 
	C320.483582,611.052734 319.418549,613.578735 318.545074,616.860901 
z"/>
<path fill="#0D0B0E" opacity="1.000000" stroke="none" 
	d="
M414.685730,604.790405 
	C417.522400,605.450195 420.169556,605.944031 422.434753,607.153259 
	C423.137207,607.528320 422.802612,609.846008 422.942749,611.274292 
	C421.518280,611.408691 419.320374,612.183594 418.806885,611.563538 
	C416.865173,609.218872 414.583862,607.604065 412.072662,609.521484 
	C410.513000,610.712341 408.951935,613.877197 409.497742,615.402344 
	C410.391846,617.900818 412.633301,620.807861 414.985596,621.653503 
	C417.546478,622.574219 420.881622,621.341370 423.819031,621.053284 
	C422.916931,625.767517 420.020630,628.148743 415.181885,627.896423 
	C409.830566,627.617432 405.475464,623.859375 405.151947,618.684631 
	C404.990936,616.109253 405.763123,613.475586 406.200562,610.464233 
	C406.891907,609.273193 407.495911,608.486145 408.429565,607.258301 
	C409.497498,606.509399 410.235779,606.201355 410.974060,605.893311 
	C412.098999,605.562317 413.223969,605.231323 414.685730,604.790405 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M379.740906,627.361267 
	C379.201935,627.697266 378.858215,627.711670 378.253723,627.725342 
	C377.394073,624.863098 376.502930,622.025818 376.261322,619.134338 
	C375.845520,614.157593 378.311615,607.723694 368.395294,608.321289 
	C368.740997,607.622192 369.086700,606.923096 369.432404,606.223999 
	C372.275574,606.020447 375.460907,604.949951 377.851501,605.893311 
	C379.697388,606.621704 381.610657,609.868530 381.625275,612.008484 
	C381.659515,617.014771 380.570404,622.028809 379.740906,627.361267 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M402.081757,623.027161 
	C398.855225,627.033936 395.006195,629.130188 389.405426,626.955505 
	C383.951508,620.847351 383.419495,616.021484 387.039246,611.490540 
	C388.018860,613.855652 389.070892,616.395508 390.542114,619.351562 
	C391.650116,620.776245 392.338898,621.784790 393.424988,622.857910 
	C396.221863,622.349365 398.621338,621.776245 401.235046,621.368286 
	C401.660095,622.031372 401.870911,622.529297 402.081757,623.027161 
z"/>
<path fill="#0A0808" opacity="1.000000" stroke="none" 
	d="
M366.339783,607.041382 
	C366.605652,608.438904 366.871490,609.836365 366.898071,611.847656 
	C366.795868,613.654175 366.932953,614.846863 367.070068,616.039551 
	C367.058807,618.730225 367.047546,621.420898 366.700684,624.623413 
	C365.894287,625.453247 365.423492,625.771301 364.952698,626.089355 
	C363.991394,624.754822 362.305450,623.473206 362.203308,622.075806 
	C361.884491,617.713013 362.164398,613.306458 362.597534,608.373535 
	C364.101837,607.567261 365.220795,607.304321 366.339783,607.041382 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M402.314758,609.699707 
	C401.963409,611.387878 401.444855,612.816284 400.602783,614.784180 
	C399.185150,615.972534 398.090942,616.621277 396.669250,617.212524 
	C395.902954,617.093018 395.464142,617.030945 395.025330,616.968872 
	C398.383698,610.065369 397.311493,608.520020 389.195343,608.566162 
	C392.977173,603.448853 398.170654,603.799194 402.314758,609.699707 
z"/>
<path fill="#383335" opacity="1.000000" stroke="none" 
	d="
M309.190033,625.275757 
	C309.703156,624.372070 310.428223,623.768127 311.394012,623.026611 
	C312.969940,624.887329 314.305145,626.885559 315.739563,629.032349 
	C310.894318,631.224121 310.189789,628.339172 309.190033,625.275757 
z"/>
<path fill="#403D40" opacity="1.000000" stroke="none" 
	d="
M410.839966,605.573547 
	C410.235779,606.201355 409.497498,606.509399 408.495728,606.956055 
	C406.268616,606.479736 404.304962,605.864868 402.341309,605.249939 
	C402.639801,604.387390 402.938324,603.524841 403.236816,602.662292 
	C405.726501,603.526062 408.216217,604.389893 410.839966,605.573547 
z"/>
<path fill="#403B3D" opacity="1.000000" stroke="none" 
	d="
M460.114746,473.369293 
	C459.597015,474.414948 459.079285,475.460602 458.405762,476.749756 
	C454.432526,474.921783 454.842651,473.398346 460.438599,470.033813 
	C460.802185,470.168396 460.840637,470.340118 460.718567,471.022705 
	C460.410248,472.145477 460.262482,472.757385 460.114746,473.369293 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M394.841919,617.117432 
	C395.464142,617.030945 395.902954,617.093018 396.548462,617.501709 
	C395.512634,619.496643 394.270142,621.144958 393.027649,622.793274 
	C392.338898,621.784790 391.650116,620.776245 390.904419,619.445801 
	C392.117828,618.504517 393.388153,617.885315 394.841919,617.117432 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M366.421875,606.788208 
	C365.220795,607.304321 364.101837,607.567261 362.661499,607.909058 
	C363.091919,605.680359 364.075226,604.036377 366.421875,606.788208 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M367.220703,615.768188 
	C366.932953,614.846863 366.795868,613.654175 366.869324,612.206787 
	C367.177002,613.133606 367.274170,614.315247 367.220703,615.768188 
z"/>
<path fill="#383335" opacity="1.000000" stroke="none" 
	d="
M344.445282,610.905151 
	C344.587128,610.381348 344.995880,609.759644 345.702820,609.084839 
	C345.571381,609.623596 345.141785,610.215454 344.445282,610.905151 
z"/>
<path fill="#0B090A" opacity="1.000000" stroke="none" 
	d="
M365.167908,626.183289 
	C365.423492,625.771301 365.894287,625.453247 366.643311,625.069214 
	C366.408722,625.427856 365.895905,625.852539 365.167908,626.183289 
z"/>
<path fill="#322818" opacity="1.000000" stroke="none" 
	d="
M701.861633,873.281067 
	C700.689209,874.981262 699.516785,876.681519 698.133240,878.665894 
	C697.922119,878.950012 697.471313,878.951233 696.985901,878.958740 
	C696.327332,878.968140 696.154236,878.969971 695.981079,878.971863 
	C687.962830,882.409729 679.944580,885.847534 671.582336,889.432861 
	C671.854980,890.846191 672.172668,892.493164 672.789978,895.693542 
	C666.442383,889.667419 661.256226,894.132507 655.968506,895.268250 
	C650.663635,896.407593 645.089050,897.414490 640.367493,899.884277 
	C633.205750,903.630554 625.773682,903.781555 618.103394,904.407837 
	C611.931824,904.911743 605.948364,907.390686 599.789490,908.467957 
	C593.278198,909.606750 586.661194,910.150513 580.084656,910.904236 
	C574.907532,911.497498 569.730713,912.254456 564.535461,912.447754 
	C562.460632,912.524963 560.336792,911.283936 558.390198,910.130493 
	C558.967590,909.518127 559.389954,909.418335 560.145813,909.477417 
	C561.652466,909.418579 562.825745,909.200867 564.429810,909.001831 
	C570.516602,908.466858 576.172424,907.913208 582.141296,907.505066 
	C583.297729,907.423279 584.141113,907.195923 585.347900,906.938721 
	C586.424988,906.773132 587.138672,906.637451 588.254150,906.620117 
	C590.965759,906.395386 593.275635,906.052246 595.984985,905.648376 
	C598.541870,905.257935 600.699280,904.928284 603.182739,904.701050 
	C604.337463,904.526794 605.166138,904.250061 606.384460,903.946655 
	C607.785645,903.686096 608.797302,903.452271 610.079773,903.352173 
	C610.896851,903.297668 611.443115,903.109436 612.279175,902.909912 
	C613.057556,902.630615 613.546265,902.362488 614.431274,902.132751 
	C617.180359,901.695190 619.533020,901.219238 622.220886,900.847900 
	C623.372192,900.624634 624.188354,900.296753 625.358826,899.904907 
	C626.411743,899.678345 627.110291,899.515625 628.146729,899.465942 
	C629.994019,899.052551 631.503418,898.526062 633.400757,897.967285 
	C648.254272,893.194946 662.825562,888.749023 677.142822,883.596985 
	C684.869385,880.816589 692.161499,876.828552 699.875854,873.426880 
	C700.688660,873.406006 701.275146,873.343567 701.861633,873.281067 
z"/>
<path fill="#E0DA9E" opacity="1.000000" stroke="none" 
	d="
M559.812378,909.318481 
	C559.389954,909.418335 558.967590,909.518127 558.273560,909.797546 
	C557.548523,909.910828 557.095032,909.844604 555.998413,909.745483 
	C546.569702,909.821533 537.784058,909.930298 528.998474,910.039124 
	C528.293762,909.888062 527.589050,909.737061 526.408936,909.258545 
	C525.265808,908.670105 524.598022,908.409180 524.001465,907.799805 
	C524.386475,906.324097 524.700256,905.196960 525.476318,903.994995 
	C529.763306,903.955872 533.605774,904.248169 537.409546,903.983948 
	C556.614319,902.649963 575.809021,901.171143 595.409729,899.786194 
	C601.549377,898.915283 607.286499,898.001282 613.042847,897.452698 
	C613.386353,899.243530 613.710632,900.669006 614.034973,902.094360 
	C613.546265,902.362488 613.057556,902.630615 612.131592,902.746460 
	C611.065796,902.802307 610.437378,903.010376 609.808899,903.218445 
	C608.797302,903.452271 607.785645,903.686096 606.182373,903.759094 
	C604.679443,903.931702 603.768127,904.265198 602.856750,904.598633 
	C600.699280,904.928284 598.541870,905.257935 595.565186,905.492065 
	C592.448120,905.764954 590.150269,906.133362 587.852417,906.501709 
	C587.138672,906.637451 586.424988,906.773132 585.147095,906.762207 
	C583.664795,906.863647 582.746521,907.111633 581.828308,907.359619 
	C576.172424,907.913208 570.516602,908.466858 564.206787,908.801147 
	C562.306091,908.827393 561.059204,909.072937 559.812378,909.318481 
z"/>
<path fill="#322818" opacity="1.000000" stroke="none" 
	d="
M529.024170,910.428284 
	C537.784058,909.930298 546.569702,909.821533 555.675171,909.878662 
	C555.559326,913.127808 553.613770,914.048218 550.722717,913.998108 
	C545.094604,913.900513 539.447754,914.201416 533.843750,913.821045 
	C532.185486,913.708557 530.644531,911.866821 529.024170,910.428284 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M523.930237,908.148315 
	C524.598022,908.409180 525.265808,908.670105 526.054871,909.173645 
	C524.742065,909.588867 523.307922,909.761475 521.436646,909.974548 
	C520.999512,910.015015 520.954773,909.534302 520.949158,909.040161 
	C520.971069,908.378479 520.998535,908.210876 521.388000,908.088501 
	C522.476685,908.138611 523.203491,908.143494 523.930237,908.148315 
z"/>
<path fill="#1A1715" opacity="1.000000" stroke="none" 
	d="
M696.097412,879.071289 
	C696.154236,878.969971 696.327332,878.968140 696.760620,878.966431 
	C697.020752,878.966614 696.647217,879.215576 696.647217,879.215576 
	C696.647217,879.215576 696.213684,879.170715 696.097412,879.071289 
z"/>
<path fill="#DDD18C" opacity="1.000000" stroke="none" 
	d="
M524.001465,907.799805 
	C523.203491,908.143494 522.476685,908.138611 521.312195,907.836670 
	C520.251343,907.918457 519.628174,908.297424 519.005005,908.676392 
	C513.662781,908.356689 508.320618,908.036987 502.348602,907.329712 
	C498.468018,906.647278 495.217224,906.352356 491.966461,906.057434 
	C488.618561,905.650940 485.270691,905.244446 481.385010,904.476440 
	C479.570496,904.054260 478.293701,903.993713 477.016937,903.933105 
	C476.587463,903.885498 476.158020,903.837891 475.349670,903.433350 
	C474.311218,903.082336 473.651642,903.088318 472.992065,903.094299 
	C471.958893,902.992126 470.925720,902.889954 469.376953,902.388062 
	C468.120728,901.903809 467.380127,901.819275 466.639496,901.734741 
	C466.639496,901.734741 466.509003,901.686462 466.626648,901.151123 
	C466.837280,899.351501 466.930267,898.087219 467.355133,896.814941 
	C468.118256,896.900330 468.549530,896.993774 469.097687,897.383179 
	C470.474823,897.794373 471.735107,897.909607 473.331207,898.028320 
	C474.093475,898.161194 474.519928,898.290588 475.115601,898.708130 
	C478.200775,899.298279 481.116730,899.600220 484.363586,899.896057 
	C485.118378,900.004578 485.542328,900.119202 486.110260,900.527222 
	C487.844971,900.855652 489.435699,900.890686 491.398224,900.892822 
	C492.501465,900.990967 493.232880,901.122009 494.076965,901.522095 
	C495.152832,901.771790 496.116028,901.752563 497.519165,901.721436 
	C500.287628,901.951233 502.616150,902.192932 505.136932,902.722778 
	C507.873260,903.051697 510.417328,903.092407 513.383057,903.127502 
	C517.541138,903.437866 521.277527,903.753784 525.013977,904.069763 
	C524.700256,905.196960 524.386475,906.324097 524.001465,907.799805 
z"/>
<path fill="#CCBD7D" opacity="1.000000" stroke="none" 
	d="
M467.023315,896.822998 
	C466.930267,898.087219 466.837280,899.351501 466.636566,901.082275 
	C462.985199,900.945923 459.441528,900.343079 455.301788,899.341309 
	C453.372528,898.688660 452.039337,898.434998 450.706146,898.181335 
	C450.706146,898.181335 450.334991,898.229736 450.082520,898.027344 
	C449.526062,897.619507 449.247375,897.648315 448.993988,897.911316 
	C442.835114,896.147949 436.676208,894.384644 430.301941,892.106201 
	C431.066010,890.232544 432.045471,888.874023 433.361267,887.642151 
	C436.111084,888.311951 438.524597,888.855042 441.140137,889.581543 
	C441.874176,889.871155 442.406219,889.977356 443.187042,890.340088 
	C447.949615,891.870361 452.463379,893.144165 457.066406,894.628296 
	C457.778656,894.875122 458.401611,894.911621 459.406311,894.970337 
	C462.199829,895.602722 464.611572,896.212830 467.023315,896.822998 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M433.024963,887.515503 
	C432.045471,888.874023 431.066010,890.232544 429.995544,891.902771 
	C428.497559,891.849121 427.090576,891.483704 425.359497,890.693115 
	C424.990326,889.954895 424.932648,889.644165 424.899506,888.935059 
	C424.950012,887.461609 424.963318,886.388916 425.349487,885.302551 
	C428.156586,886.031067 430.590790,886.773254 433.024963,887.515503 
z"/>
<path fill="#CCBD7D" opacity="1.000000" stroke="none" 
	d="
M424.976624,885.316223 
	C424.963318,886.388916 424.950012,887.461609 424.465942,888.921387 
	C423.428162,889.428589 422.861115,889.548584 422.294098,889.668579 
	C421.705780,889.550476 421.117493,889.432434 420.160522,888.862244 
	C418.859863,887.908386 417.927917,887.406616 416.995972,886.904785 
	C418.349457,886.141052 419.702942,885.377258 421.724670,884.575745 
	C423.254181,884.797485 424.115417,885.056824 424.976624,885.316223 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M368.626862,866.606812 
	C371.894012,867.938843 375.371216,869.551453 378.941895,871.488647 
	C375.635895,870.171326 372.236420,868.529358 368.626862,866.606812 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M492.282776,906.270142 
	C495.217224,906.352356 498.468018,906.647278 501.906952,907.223755 
	C498.929779,907.164490 495.764435,906.823669 492.282776,906.270142 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M450.878601,898.506104 
	C452.039337,898.434998 453.372528,898.688660 454.900696,899.224609 
	C453.747467,899.281555 452.399231,899.056213 450.878601,898.506104 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M477.278839,904.181396 
	C478.293701,903.993713 479.570496,904.054260 480.974976,904.392700 
	C479.915405,904.590271 478.728058,904.510010 477.278839,904.181396 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M416.982208,887.176514 
	C417.927917,887.406616 418.859863,887.908386 419.890686,888.727783 
	C419.123810,888.878662 418.258148,888.712036 417.195892,888.268433 
	C416.989044,887.810425 416.978729,887.629333 416.982208,887.176514 
z"/>
<path fill="#E0DA9E" opacity="1.000000" stroke="none" 
	d="
M519.317993,908.813843 
	C519.628174,908.297424 520.251343,907.918457 520.950256,907.791382 
	C520.998535,908.210876 520.971069,908.378479 520.968384,908.799561 
	C520.539124,909.019165 520.085083,908.985229 519.317993,908.813843 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M422.488281,889.914917 
	C422.861115,889.548584 423.428162,889.428589 424.428741,889.322144 
	C424.932648,889.644165 424.990326,889.954895 425.043030,890.507141 
	C424.261292,890.551331 423.471863,890.356262 422.488281,889.914917 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M473.200562,903.308289 
	C473.651642,903.088318 474.311218,903.082336 475.051941,903.300415 
	C474.558411,903.523743 473.983734,903.523010 473.200562,903.308289 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M466.811432,901.965454 
	C467.380127,901.819275 468.120728,901.903809 469.008514,902.257935 
	C468.431580,902.417114 467.707489,902.306641 466.811432,901.965454 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M361.463593,862.793579 
	C362.092468,862.800171 362.926331,863.052856 363.887970,863.633911 
	C363.233368,863.654724 362.450958,863.347229 361.463593,862.793579 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M384.630493,874.551147 
	C385.232727,874.539856 385.997803,874.798401 386.910919,875.335022 
	C386.303772,875.349060 385.548584,875.085083 384.630493,874.551147 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M389.451477,876.795166 
	C390.080170,876.785156 390.909271,877.032288 391.874969,877.597656 
	C391.224976,877.627991 390.438416,877.340088 389.451477,876.795166 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M400.517822,881.679199 
	C401.128265,881.631348 401.930542,881.838989 402.895752,882.328430 
	C402.275696,882.384949 401.492706,882.159790 400.517822,881.679199 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M449.223450,898.049805 
	C449.247375,897.648315 449.526062,897.619507 449.906189,897.964600 
	C449.805847,898.132263 449.629395,898.160339 449.223450,898.049805 
z"/>
<path fill="#E4B30F" opacity="1.000000" stroke="none" 
	d="
M500.086914,473.074097 
	C499.482971,468.082764 498.686127,463.104279 498.351593,458.094971 
	C498.167572,455.339600 498.761536,452.532257 499.024414,449.283600 
	C499.429535,448.805420 499.814941,448.791962 500.853333,448.765564 
	C502.850433,447.843964 504.194641,446.935272 505.538849,446.026550 
	C505.538849,446.026581 505.737946,445.914856 505.851532,445.920349 
	C511.666962,444.887512 517.332642,443.508728 523.079895,442.898438 
	C530.004639,442.163116 536.998779,442.080505 544.432861,442.174164 
	C542.252991,445.895782 539.718445,449.261414 536.923279,452.394470 
	C533.655457,456.057220 530.160278,459.517090 526.343018,463.064514 
	C518.120422,462.938690 511.037201,464.984100 504.641907,470.128479 
	C502.924408,471.189392 501.505676,472.131744 500.086914,473.074097 
z"/>
<path fill="#F7D943" opacity="1.000000" stroke="none" 
	d="
M469.011414,360.627686 
	C470.664612,360.092010 472.310150,359.124451 473.972321,359.094971 
	C481.338104,358.964233 488.708710,359.102814 496.526276,359.271362 
	C497.028931,359.708130 497.059204,360.019745 497.080200,360.565186 
	C497.094543,360.796692 497.040253,360.920380 496.546509,360.964539 
	C487.039001,360.881714 478.025208,360.754700 469.011414,360.627686 
z"/>
<path fill="#F4D035" opacity="1.000000" stroke="none" 
	d="
M505.239868,446.093689 
	C504.194641,446.935272 502.850433,447.843964 501.142395,448.744446 
	C502.165955,447.877777 503.553436,447.019287 505.239868,446.093689 
z"/>
<path fill="#F1C624" opacity="1.000000" stroke="none" 
	d="
M626.828491,359.960876 
	C636.868774,359.924500 646.622620,359.980835 657.097412,360.241577 
	C659.087585,360.899628 660.356812,361.353271 662.249084,362.029602 
	C654.818604,370.091064 647.936462,377.557617 640.752502,385.105835 
	C639.984436,385.820984 639.518188,386.454437 638.962036,387.410156 
	C636.940247,389.835785 635.008423,391.939087 632.740723,394.102356 
	C631.631165,395.151306 630.857361,396.140320 629.934814,397.408356 
	C628.841553,398.784332 627.896912,399.881317 626.587646,400.985809 
	C623.817810,402.997894 621.412537,405.002472 618.648010,406.985352 
	C616.233032,407.515350 614.188049,408.112305 612.117859,408.602631 
	C610.786499,408.917908 609.392578,409.362213 608.071533,409.244659 
	C605.470825,409.013214 602.915222,408.194244 600.316101,408.042206 
	C594.662170,407.711456 593.947327,406.977966 594.581482,401.540863 
	C594.698364,400.538635 594.341980,399.481232 594.081482,397.532928 
	C592.229065,399.136597 591.076721,400.134155 590.076904,400.999756 
	C588.357727,400.632904 586.758057,400.291565 585.158386,399.950256 
	C587.212708,397.391266 589.267090,394.832306 591.783813,392.251160 
	C592.514282,391.812225 592.782349,391.395447 593.050415,390.978699 
	C594.167542,389.714783 595.284729,388.450836 596.996094,387.216003 
	C601.182068,387.395386 600.537537,385.144073 600.017700,382.963501 
	C606.778015,375.696045 613.538330,368.428589 621.035156,361.056641 
	C622.602295,360.872559 623.432983,360.792969 624.263611,360.713379 
	C625.023071,360.493469 625.782593,360.273529 626.828491,359.960876 
M611.552185,401.825867 
	C610.162781,401.106628 608.773315,400.387421 607.383911,399.668213 
	C607.160583,400.661743 606.937256,401.655304 606.713867,402.648834 
	C608.331604,402.603760 609.949341,402.558655 611.552185,401.825867 
M602.097412,397.483337 
	C602.337158,396.251770 602.576843,395.020172 602.816528,393.788605 
	C601.948425,393.887482 601.080261,393.986359 600.212158,394.085236 
	C600.633179,395.271545 601.054199,396.457825 602.097412,397.483337 
z"/>
<path fill="#F3CB2C" opacity="1.000000" stroke="none" 
	d="
M584.910400,400.121582 
	C586.758057,400.291565 588.357727,400.632904 590.076904,400.999756 
	C591.076721,400.134155 592.229065,399.136597 594.081482,397.532928 
	C594.341980,399.481232 594.698364,400.538635 594.581482,401.540863 
	C593.947327,406.977966 594.662170,407.711456 600.316101,408.042206 
	C602.915222,408.194244 605.470825,409.013214 608.071533,409.244659 
	C609.392578,409.362213 610.786499,408.917908 612.117859,408.602631 
	C614.188049,408.112305 616.233032,407.515350 618.649963,407.334473 
	C619.021851,408.171814 619.032532,408.638336 618.866333,409.340210 
	C618.166992,410.406189 617.644531,411.236816 616.749878,412.069885 
	C614.937439,413.737793 613.497253,415.403320 612.031128,417.046143 
	C612.005127,417.023468 612.054810,417.059296 611.688660,417.065918 
	C607.470520,418.769470 604.152405,422.619049 604.169922,425.442078 
	C603.718445,426.129517 603.408447,426.581512 602.754822,427.095367 
	C597.121460,431.386566 591.831665,435.615875 587.139465,439.367371 
	C585.573120,436.984039 584.530029,433.572479 582.945984,433.299469 
	C575.715332,432.053192 568.332825,431.687988 561.002441,431.003052 
	C561.000000,431.000000 560.995117,430.994843 561.133667,430.668457 
	C564.660400,428.737579 565.486023,425.942261 564.917053,422.498840 
	C564.875366,422.256104 564.961792,422.074249 565.739380,421.923279 
	C567.559875,420.220245 568.817444,418.547150 570.075073,416.874084 
	C571.928040,414.696014 573.781067,412.517944 576.185547,410.040894 
	C577.124512,409.299408 577.512085,408.856873 577.899597,408.414368 
	C577.899597,408.414368 577.817200,408.157166 578.201599,407.971985 
	C578.988831,407.150696 579.391724,406.514587 579.794617,405.878510 
	C579.993530,405.592438 580.192505,405.306335 580.952820,404.847229 
	C581.954590,403.973022 582.394958,403.271820 582.835327,402.570648 
	C582.835327,402.570648 582.898926,402.194489 583.165161,401.954468 
	C583.607849,401.567139 583.757507,401.396271 583.880249,401.201843 
	C584.140991,400.898865 584.401733,400.595886 584.910400,400.121582 
z"/>
<path fill="#F3CB2C" opacity="1.000000" stroke="none" 
	d="
M599.712524,383.097076 
	C600.537537,385.144073 601.182068,387.395386 597.315063,387.134308 
	C597.828918,385.759186 598.618164,384.494934 599.712524,383.097076 
z"/>
<path fill="#F4D136" opacity="1.000000" stroke="none" 
	d="
M569.749146,416.886536 
	C568.817444,418.547150 567.559875,420.220245 565.898438,421.905548 
	C566.334290,420.551941 567.174072,419.186127 568.079895,417.484375 
	C568.571716,417.065338 568.997498,416.982178 569.749146,416.886536 
z"/>
<path fill="#F3CB2C" opacity="1.000000" stroke="none" 
	d="
M592.797180,391.054626 
	C592.782349,391.395447 592.514282,391.812225 592.072632,392.084320 
	C592.113953,391.669983 592.328979,391.400299 592.797180,391.054626 
z"/>
<path fill="#CEC86E" opacity="1.000000" stroke="none" 
	d="
M623.996582,360.578857 
	C623.432983,360.792969 622.602295,360.872559 621.354858,360.947510 
	C621.868530,360.776733 622.799011,360.610565 623.996582,360.578857 
z"/>
<path fill="#F1C624" opacity="1.000000" stroke="none" 
	d="
M579.538086,406.000275 
	C579.391724,406.514587 578.988831,407.150696 578.296631,407.875977 
	C578.432007,407.350800 578.856812,406.736420 579.538086,406.000275 
z"/>
<path fill="#F1C624" opacity="1.000000" stroke="none" 
	d="
M582.567383,402.722900 
	C582.394958,403.271820 581.954590,403.973022 581.192383,404.733093 
	C581.346863,404.153046 581.823120,403.514130 582.567383,402.722900 
z"/>
<path fill="#F1C624" opacity="1.000000" stroke="none" 
	d="
M577.606812,408.503052 
	C577.512085,408.856873 577.124512,409.299408 576.457397,409.837433 
	C576.556580,409.485870 576.935303,409.038788 577.606812,408.503052 
z"/>
<path fill="#F1C624" opacity="1.000000" stroke="none" 
	d="
M583.694885,401.240326 
	C583.757507,401.396271 583.607849,401.567139 583.292725,401.812622 
	C583.146179,401.628967 583.264648,401.418304 583.694885,401.240326 
z"/>
<path fill="#F1C625" opacity="1.000000" stroke="none" 
	d="
M468.649170,360.561951 
	C478.025208,360.754700 487.039001,360.881714 496.519714,361.026550 
	C496.987518,377.471619 497.005005,393.898956 496.980804,410.326202 
	C496.971466,416.655182 496.618317,416.969391 490.110168,416.995239 
	C484.800415,417.016296 479.476257,417.236328 474.185638,416.917419 
	C470.618134,416.702332 467.097900,415.703308 463.160645,414.943970 
	C462.708221,398.112610 462.688232,381.392029 462.569916,364.672119 
	C462.539825,360.421814 465.336700,360.452820 468.649170,360.561951 
z"/>
<path fill="#373133" opacity="1.000000" stroke="none" 
	d="
M460.617981,414.571106 
	C459.690216,413.676208 458.291199,412.385834 458.110657,410.942993 
	C457.542999,406.406128 457.156342,401.803955 457.287659,397.243988 
	C457.337616,395.508636 458.984436,393.819275 460.233582,392.057556 
	C460.610565,399.372711 460.657410,406.739319 460.617981,414.571106 
z"/>
<path fill="#F4D136" opacity="1.000000" stroke="none" 
	d="
M564.604004,422.674774 
	C565.486023,425.942261 564.660400,428.737579 560.885132,430.739685 
	C560.209839,431.659668 559.921753,432.182068 559.633667,432.704468 
	C559.917908,432.815979 560.202209,432.927490 560.486450,433.039001 
	C560.659241,432.361389 560.832092,431.683746 561.004883,431.006104 
	C568.332825,431.687988 575.715332,432.053192 582.945984,433.299469 
	C584.530029,433.572479 585.573120,436.984039 587.139465,439.367371 
	C591.831665,435.615875 597.121460,431.386566 602.669617,427.431396 
	C598.046265,433.225403 593.195129,438.772766 588.265930,444.249908 
	C586.603882,446.096710 584.444458,447.669037 586.256836,450.570129 
	C584.135376,451.928528 581.655151,452.943512 579.962097,454.712006 
	C575.495056,459.377899 571.316406,464.325897 567.145569,469.265106 
	C566.112183,470.488922 565.544739,472.106140 564.415527,473.889893 
	C563.714478,474.196289 563.360840,474.155243 562.965698,473.734955 
	C561.513184,472.679688 559.637390,471.068909 558.775574,471.497345 
	C555.812378,472.970612 553.171875,475.113617 550.473694,477.090515 
	C549.104980,478.093323 547.875854,479.286743 546.583618,480.393982 
	C545.722229,478.754303 544.630798,477.190033 544.061890,475.454498 
	C543.449646,473.586914 543.466064,471.520081 542.935242,469.616302 
	C542.651550,468.599030 542.004150,467.387695 541.153320,466.880066 
	C538.695068,465.413452 531.563538,467.905060 529.563660,470.084930 
	C526.096069,473.864624 522.243286,477.290894 517.833435,481.561279 
	C518.112183,478.091553 518.277954,476.028290 518.477051,473.779175 
	C518.825378,473.388062 519.140381,473.182831 519.756226,472.771790 
	C520.365112,472.193024 520.673096,471.820038 520.938782,471.294128 
	C520.896484,471.141174 521.085938,470.886353 521.085938,470.886353 
	C521.085938,470.886353 521.402893,470.905823 521.824341,470.909271 
	C525.836792,470.701508 527.135864,468.644653 526.719604,464.996704 
	C526.685120,464.173401 526.724487,463.619202 526.763794,463.065002 
	C530.160278,459.517090 533.655457,456.057220 536.923279,452.394470 
	C539.718445,449.261414 542.252991,445.895782 544.886597,442.262970 
	C549.966797,436.975342 555.074036,432.068878 560.152710,427.132996 
	C561.575500,425.750183 562.913879,424.280548 564.604004,422.674774 
z"/>
<path fill="#FDF168" opacity="1.000000" stroke="none" 
	d="
M586.573181,450.669434 
	C584.444458,447.669037 586.603882,446.096710 588.265930,444.249908 
	C593.195129,438.772766 598.046265,433.225403 603.013184,427.369507 
	C603.408447,426.581512 603.718445,426.129517 604.495850,425.373138 
	C607.327087,422.398926 609.690979,419.729126 612.054810,417.059326 
	C612.054810,417.059296 612.005127,417.023468 612.345703,416.948242 
	C614.164917,415.271149 615.643494,413.669312 617.122070,412.067444 
	C617.644531,411.236816 618.166992,410.406189 619.186279,409.237061 
	C622.106140,406.258484 624.529236,403.618378 626.952271,400.978271 
	C627.896912,399.881317 628.841553,398.784332 630.250366,397.314697 
	C631.501953,395.975433 632.289246,395.008911 633.076538,394.042389 
	C635.008423,391.939087 636.940247,389.835785 639.250732,387.309448 
	C640.104370,386.265717 640.579346,385.644928 641.054321,385.024170 
	C647.936462,377.557617 654.818604,370.091064 662.249084,362.029602 
	C660.356812,361.353271 659.087585,360.899628 657.588379,360.251465 
	C660.295959,360.057007 663.233459,360.057007 667.284302,360.057007 
	C665.388428,362.983917 664.103943,364.967010 662.674561,367.254700 
	C654.868103,375.846283 647.111084,384.047546 639.569702,392.442444 
	C630.581543,402.447723 621.750793,412.595093 612.903015,422.725586 
	C604.936707,431.846954 597.039490,441.028656 588.980591,450.490417 
	C588.196106,450.787689 587.542786,450.778229 586.573181,450.669434 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M658.386780,600.385498 
	C662.718018,606.361084 666.750366,612.161682 670.744385,617.988525 
	C671.757690,619.466858 672.649048,621.028870 673.500366,623.070435 
	C673.404297,623.588562 673.078430,623.925476 672.845947,623.692261 
	C670.124573,620.600281 667.635559,617.741516 665.091431,614.882568 
	C665.036255,614.882446 665.145569,614.857117 665.066772,614.524963 
	C664.540588,613.506042 664.128540,612.792908 663.640625,612.136292 
	C654.997986,600.505066 646.346130,588.880798 637.822510,577.141846 
	C637.948730,577.028748 638.200806,576.802246 638.530518,576.909790 
	C639.537537,577.185669 640.214966,577.353943 640.892334,577.522156 
	C644.182434,582.217712 647.371582,586.988525 650.798523,591.581970 
	C653.045776,594.594055 655.636658,597.349792 658.386780,600.385498 
z"/>
<path fill="#4C494A" opacity="1.000000" stroke="none" 
	d="
M816.489868,330.404236 
	C812.195496,334.773987 807.286865,332.887299 802.673462,332.330780 
	C801.818787,332.227661 800.492249,329.902069 800.681702,328.832092 
	C800.917297,327.501434 802.392761,325.517273 803.523438,325.379456 
	C807.122559,324.940704 810.861816,324.896637 814.429260,325.453613 
	C815.388000,325.603302 815.928040,328.434784 816.489868,330.404236 
z"/>
<path fill="#464446" opacity="1.000000" stroke="none" 
	d="
M624.943420,555.657349 
	C623.926086,555.293335 622.935120,554.524780 621.754272,553.495789 
	C621.564453,550.767578 621.564453,548.299927 621.564453,546.030823 
	C625.620789,547.186096 626.041260,548.146057 624.943420,555.657349 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M630.890503,568.073608 
	C633.053650,569.855408 635.216736,571.637146 637.590088,574.038940 
	C637.933838,575.373352 638.067322,576.087769 638.200806,576.802246 
	C638.200806,576.802246 637.948730,577.028748 637.556274,576.964844 
	C636.206970,576.051208 635.250122,575.201477 634.322083,574.244507 
	C634.350830,574.137329 634.212219,573.964050 634.129028,573.628784 
	C632.994141,571.553467 631.942322,569.813538 630.890503,568.073608 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M630.744629,567.881897 
	C631.942322,569.813538 632.994141,571.553467 633.770752,573.571594 
	C629.952087,572.383728 628.145142,569.750427 628.389526,566.032471 
	C629.105164,566.408386 629.581848,566.650574 630.058533,566.892700 
	C630.226379,567.166809 630.406433,567.432678 630.744629,567.881897 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M630.059998,566.562500 
	C629.581848,566.650574 629.105164,566.408386 628.375183,566.033813 
	C628.121887,565.901367 628.100220,565.889893 628.024902,565.563599 
	C626.741394,563.377014 625.533203,561.516724 624.325012,559.656372 
	C624.325012,559.656372 624.656189,559.334045 625.105713,559.315430 
	C626.669006,560.359314 627.782715,561.421814 628.896423,562.484253 
	C629.284790,563.733643 629.673096,564.982971 630.059998,566.562500 
z"/>
<path fill="#464446" opacity="1.000000" stroke="none" 
	d="
M628.909546,562.062988 
	C627.782715,561.421814 626.669006,560.359314 625.271118,559.153992 
	C624.912781,558.280090 624.838745,557.549011 624.821899,556.455383 
	C628.147644,556.542725 629.053772,558.714294 628.909546,562.062988 
z"/>
<path fill="#464446" opacity="1.000000" stroke="none" 
	d="
M638.530457,576.909790 
	C638.067322,576.087769 637.933838,575.373352 637.790405,574.292236 
	C638.812744,574.851624 639.844910,575.777527 640.884766,577.112793 
	C640.214966,577.353943 639.537537,577.185669 638.530457,576.909790 
z"/>
<path fill="#FAF269" opacity="1.000000" stroke="none" 
	d="
M628.136230,565.900024 
	C628.145142,569.750427 629.952087,572.383728 633.853882,573.906860 
	C634.212219,573.964050 634.350830,574.137329 634.308472,574.636414 
	C634.854065,576.290710 635.304504,577.544739 636.051941,578.585449 
	C641.934570,586.775940 647.675720,595.080383 653.875793,603.025635 
	C657.210327,607.298767 661.362427,610.934021 665.145569,614.857117 
	C665.145569,614.857117 665.036255,614.882446 665.078369,615.255005 
	C667.986084,620.389343 670.851685,625.151184 674.185059,630.690430 
	C671.938660,630.578308 670.838989,630.478271 669.739014,630.475647 
	C661.278625,630.455505 652.763733,629.879028 644.379089,630.672485 
	C639.677368,631.117432 636.618774,629.925720 634.080444,626.493896 
	C628.789490,619.340515 623.677612,612.054443 618.368225,604.915100 
	C613.060242,597.777649 607.599121,590.754150 602.208923,583.677795 
	C599.003174,579.469238 595.789124,575.266846 592.607239,571.040283 
	C588.849365,566.048523 585.226929,560.951050 581.356079,556.049194 
	C576.176453,549.489929 570.644775,543.204163 565.575439,536.563416 
	C559.638672,528.786194 553.923340,520.831726 548.360657,512.782349 
	C546.771057,510.482208 546.044556,507.585632 544.962524,504.610291 
	C547.160156,501.838226 549.477844,499.540771 551.439270,496.971313 
	C554.785522,492.587433 561.517273,489.565247 557.650452,482.028656 
	C557.291748,481.329620 558.453613,479.656342 559.187683,478.635498 
	C560.335938,477.038574 561.721985,475.612610 563.007263,474.114197 
	C563.360840,474.155243 563.714478,474.196289 564.328491,474.267090 
	C564.746033,474.651733 564.903137,475.006561 564.817871,475.842468 
	C560.848450,481.275848 562.929504,486.568329 569.117371,487.869141 
	C569.125183,487.883392 569.135498,487.914154 569.118591,488.280701 
	C571.064636,491.098694 573.027527,493.550171 574.990479,496.001617 
	C574.990479,496.001617 575.002441,495.998779 574.998413,496.469574 
	C575.096130,504.124115 574.150269,511.483795 578.522644,517.950378 
	C578.633972,518.742493 578.745300,519.534546 578.660034,521.062866 
	C578.317322,522.778625 578.070740,523.755188 578.041199,524.738281 
	C577.781555,533.372559 579.108521,534.812134 587.566711,536.202576 
	C595.757080,537.549011 604.737122,536.856934 610.676331,544.547729 
	C610.909485,544.849731 611.613892,544.787842 612.098694,544.895508 
	C612.098694,544.895508 612.018921,544.938171 612.057739,545.292114 
	C613.117065,546.734924 614.137573,547.823730 615.158081,548.912598 
	C615.431274,549.047546 615.660095,549.236267 616.064331,549.889893 
	C616.466858,550.572876 616.695862,550.797668 616.971008,550.975464 
	C616.971008,550.975464 617.029175,550.982239 617.078247,551.289551 
	C617.475281,552.036133 617.823303,552.475464 618.171265,552.914734 
	C618.171265,552.914734 618.086487,552.872559 618.107971,553.243835 
	C620.260559,558.675354 622.761658,563.435059 628.100220,565.889893 
	C628.100220,565.889893 628.121887,565.901367 628.136230,565.900024 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M612.015747,544.564941 
	C611.613892,544.787842 610.909485,544.849731 610.676331,544.547729 
	C604.737122,536.856934 595.757080,537.549011 587.566711,536.202576 
	C579.108521,534.812134 577.781555,533.372559 578.041199,524.738281 
	C578.070740,523.755188 578.317322,522.778625 578.651611,521.463989 
	C579.488525,524.165833 579.941589,527.264587 580.872070,530.212585 
	C581.305786,531.586487 582.555359,533.606262 583.622070,533.730713 
	C590.640503,534.549438 597.709900,534.931030 605.107178,535.380859 
	C607.611938,538.282410 609.772339,541.258423 612.015747,544.564941 
z"/>
<path fill="#71612B" opacity="1.000000" stroke="none" 
	d="
M566.412720,477.012360 
	C572.864319,479.575989 576.272156,485.969971 574.821899,493.735870 
	C572.630920,491.871307 570.883179,489.892731 569.135498,487.914154 
	C569.135498,487.914154 569.125183,487.883392 569.045654,487.541016 
	C567.973328,485.909119 567.032959,484.574646 565.976196,483.339752 
	C564.021912,481.056122 564.108704,478.972748 566.412720,477.012360 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M578.626038,517.543823 
	C574.150269,511.483795 575.096130,504.124115 574.995605,496.475159 
	C575.564026,496.741455 576.535034,497.422180 576.634460,498.212494 
	C577.426086,504.509003 578.052368,510.826294 578.626038,517.543823 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M566.209412,476.827698 
	C564.108704,478.972748 564.021912,481.056122 565.976196,483.339752 
	C567.032959,484.574646 567.973328,485.909119 569.037842,487.526764 
	C562.929504,486.568329 560.848450,481.275848 564.875610,476.128662 
	C565.452637,476.170197 565.729370,476.406616 566.209412,476.827698 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M569.118591,488.280701 
	C570.883179,489.892731 572.630920,491.871307 574.630981,494.081787 
	C574.920288,494.687805 574.957153,495.061890 574.992249,495.718811 
	C573.027527,493.550171 571.064636,491.098694 569.118591,488.280701 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M628.024902,565.563599 
	C622.761658,563.435059 620.260559,558.675354 618.155151,553.239990 
	C620.052612,555.001526 621.924316,557.138123 624.060547,559.465576 
	C625.533203,561.516724 626.741394,563.377014 628.024902,565.563599 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M615.079956,548.598694 
	C614.137573,547.823730 613.117065,546.734924 612.101562,545.283142 
	C613.071655,546.041748 614.036682,547.163269 615.079956,548.598694 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M618.136047,552.626282 
	C617.823303,552.475464 617.475281,552.036133 617.069946,551.317627 
	C617.375366,551.471497 617.738098,551.904663 618.136047,552.626282 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M616.898560,550.733643 
	C616.695862,550.797668 616.466858,550.572876 616.173462,550.085510 
	C616.317322,550.077271 616.571655,550.284546 616.898560,550.733643 
z"/>
<path fill="#D3CD93" opacity="1.000000" stroke="none" 
	d="
M873.000183,701.007874 
	C872.630798,702.104126 872.261414,703.200439 871.912476,704.646851 
	C870.764465,706.840454 869.595947,708.683838 867.846436,710.858765 
	C866.566589,712.920959 865.867798,714.651550 865.169067,716.382080 
	C855.635742,731.488159 846.496948,746.863953 836.422424,761.599976 
	C830.278442,770.586731 822.693970,778.588684 815.291504,786.824524 
	C813.762756,785.448425 812.698853,784.280823 811.635010,783.113220 
	C817.000549,776.276917 822.726990,769.689941 827.656067,762.552246 
	C837.797668,747.866638 847.518372,732.890320 857.397644,718.023926 
	C858.771545,715.956421 860.062622,713.833923 861.853088,711.300720 
	C863.782227,708.793823 865.944641,706.909668 866.562561,704.610474 
	C867.600586,700.747559 869.517761,699.829407 873.000183,701.007874 
z"/>
<path fill="#E1E0C1" opacity="1.000000" stroke="none" 
	d="
M702.037476,873.055176 
	C701.275146,873.343567 700.688660,873.406006 699.484802,873.218445 
	C697.267517,872.159302 695.667603,871.350159 694.316833,870.272583 
	C697.146545,868.900391 699.727051,867.796631 702.735596,866.755615 
	C704.734985,865.779724 706.306335,864.741089 708.102478,863.459473 
	C713.684753,860.054382 719.042175,856.892151 724.399597,853.729980 
	C728.297424,851.503052 732.195190,849.276123 736.741943,846.969849 
	C738.292114,846.202393 739.193420,845.514282 740.094727,844.826172 
	C741.634399,843.682007 743.174194,842.537903 745.386108,841.227905 
	C749.405579,838.544067 752.752869,836.026001 756.100159,833.507996 
	C756.230469,833.397095 756.360718,833.286255 757.168579,833.098755 
	C759.676575,833.549255 761.506958,834.076538 763.337402,834.603760 
	C763.337402,834.603760 763.207153,834.946533 762.742188,834.994263 
	C761.816956,835.587158 761.356628,836.132385 760.896240,836.677612 
	C757.409790,839.278381 753.923340,841.879211 749.905884,844.747192 
	C748.778198,845.584351 748.181335,846.154358 747.584595,846.724365 
	C747.584595,846.724426 747.249695,846.797546 746.828857,846.907593 
	C745.850586,847.582092 745.293030,848.146545 744.735535,848.710938 
	C744.735535,848.710938 744.985352,848.997559 744.524170,848.825745 
	C743.251221,849.342590 742.439514,850.031311 741.627808,850.719971 
	C741.627808,850.719971 741.259399,850.773560 740.794434,850.887207 
	C739.097656,851.909607 737.865784,852.818298 736.633911,853.727051 
	C736.176697,853.994568 735.719421,854.262085 734.805115,854.761108 
	C733.725220,855.514832 733.102417,856.036987 732.479614,856.559143 
	C732.143738,856.670349 731.807861,856.781494 730.955200,857.034912 
	C729.856079,857.704834 729.273804,858.232544 728.691528,858.760315 
	C728.691528,858.760315 728.301514,858.821533 727.838867,858.912598 
	C726.780823,859.559692 726.185425,860.115784 725.589966,860.671875 
	C724.860046,860.986938 724.130188,861.302063 722.886475,861.795776 
	C721.712830,862.450317 721.053101,862.926453 720.393433,863.402588 
	C719.686218,863.756958 718.979065,864.111328 717.763062,864.729858 
	C712.985474,867.385132 708.716675,869.776123 704.447876,872.167175 
	C704.447876,872.167175 704.062622,872.085449 703.954529,872.262695 
	C703.846436,872.440002 703.805115,872.400818 703.805115,872.400757 
	C703.274475,872.543579 702.743896,872.686462 702.037476,873.055176 
z"/>
<path fill="#D5D2A8" opacity="1.000000" stroke="none" 
	d="
M777.040100,817.040039 
	C785.303223,809.136780 793.566406,801.233521 802.453125,793.129456 
	C804.329834,792.236755 805.583069,791.544922 806.920349,790.932373 
	C807.004395,791.011658 807.171936,791.170715 807.136719,791.564087 
	C807.057922,793.289246 807.014343,794.621033 806.970764,795.952881 
	C798.579285,804.003906 790.187744,812.054932 781.347534,819.872314 
	C779.704651,818.870483 778.510498,818.102417 777.247192,817.260803 
	C777.178101,817.187256 777.040100,817.040039 777.040100,817.040039 
z"/>
<path fill="#D5D2A8" opacity="1.000000" stroke="none" 
	d="
M771.108826,822.061768 
	C772.871033,820.456543 774.633179,818.851318 776.717712,817.143066 
	C777.040100,817.040039 777.178101,817.187256 777.195190,817.594971 
	C778.235168,819.040833 779.257996,820.078979 780.280823,821.117126 
	C777.135315,823.832825 773.989807,826.548523 770.439941,828.939087 
	C768.746887,827.766052 767.458252,826.918030 766.169556,826.070007 
	C767.582336,824.796814 768.995178,823.523621 770.735352,822.522888 
	C771.507629,823.649841 771.952637,824.504456 772.397644,825.359070 
	C772.817749,824.930542 773.237854,824.502014 773.657959,824.073486 
	C772.808289,823.402893 771.958557,822.732300 771.108826,822.061768 
z"/>
<path fill="#D5D2A8" opacity="1.000000" stroke="none" 
	d="
M811.312683,783.301025 
	C812.698853,784.280823 813.762756,785.448425 814.969482,786.929688 
	C814.502380,788.051575 813.892273,788.859863 812.647827,789.879028 
	C810.399658,790.450134 808.785767,790.810425 807.171936,791.170715 
	C807.171936,791.170715 807.004395,791.011658 806.969971,790.589478 
	C806.972351,789.470581 807.009155,788.773926 807.046021,788.077209 
	C807.046021,788.077209 807.027466,788.013428 807.285400,787.888428 
	C808.078674,787.109741 808.614075,786.455994 809.149536,785.802246 
	C809.763184,785.031067 810.376770,784.259949 811.312683,783.301025 
z"/>
<path fill="#322818" opacity="1.000000" stroke="none" 
	d="
M704.853455,872.321106 
	C708.716675,869.776123 712.985474,867.385132 717.616455,864.995605 
	C716.521057,869.398376 710.992371,872.648743 704.853455,872.321106 
z"/>
<path fill="#D5D2A8" opacity="1.000000" stroke="none" 
	d="
M763.674988,834.559143 
	C761.506958,834.076538 759.676575,833.549255 757.411011,833.014771 
	C758.799622,831.540527 760.623413,830.073547 762.819336,828.906006 
	C764.995239,829.787903 766.798950,830.370239 768.602661,830.952576 
	C767.072632,832.139893 765.542603,833.327148 763.674988,834.559143 
z"/>
<path fill="#E1E0C1" opacity="1.000000" stroke="none" 
	d="
M768.837769,830.801880 
	C766.798950,830.370239 764.995239,829.787903 763.142700,828.875732 
	C763.905396,827.787964 764.716919,827.029907 765.848999,826.170898 
	C767.458252,826.918030 768.746887,827.766052 770.089233,829.000488 
	C769.786194,829.808350 769.429504,830.229736 768.837769,830.801880 
z"/>
<path fill="#D3CD93" opacity="1.000000" stroke="none" 
	d="
M807.136719,791.564087 
	C808.785767,790.810425 810.399658,790.450134 812.359253,789.999451 
	C811.000366,791.852173 809.295715,793.795288 807.280884,795.845703 
	C807.014343,794.621033 807.057922,793.289246 807.136719,791.564087 
z"/>
<path fill="#E1E0C1" opacity="1.000000" stroke="none" 
	d="
M780.500122,821.075928 
	C779.257996,820.078979 778.235168,819.040833 777.264343,817.668457 
	C778.510498,818.102417 779.704651,818.870483 781.008423,819.959717 
	C781.140259,820.614136 781.007385,820.865479 780.500122,821.075928 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M865.468872,716.206238 
	C865.867798,714.651550 866.566589,712.920959 867.594910,711.077271 
	C867.205872,712.652954 866.487366,714.341675 865.468872,716.206238 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M736.998047,853.786133 
	C737.865784,852.818298 739.097656,851.909607 740.663574,851.019531 
	C739.785828,851.973938 738.573975,852.909607 736.998047,853.786133 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M741.985474,850.798157 
	C742.439514,850.031311 743.251221,849.342590 744.395874,848.684998 
	C743.933655,849.436157 743.138428,850.156250 741.985474,850.798157 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M725.915405,860.725586 
	C726.185425,860.115784 726.780823,859.559692 727.690063,859.042236 
	C727.416199,859.646973 726.828491,860.213074 725.915405,860.725586 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M729.012207,858.802368 
	C729.273804,858.232544 729.856079,857.704834 730.747925,857.203125 
	C730.482666,857.767578 729.907776,858.305969 729.012207,858.802368 
z"/>
<path fill="#191718" opacity="1.000000" stroke="none" 
	d="
M706.038452,876.647034 
	C706.190369,876.868835 706.197693,877.086243 706.204956,877.303650 
	C706.082397,877.299683 705.959900,877.295715 705.837341,877.291748 
	C705.856201,877.075378 705.875061,876.859009 706.038452,876.647034 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M720.723755,863.485840 
	C721.053101,862.926453 721.712830,862.450317 722.684875,862.019531 
	C722.349487,862.566284 721.701782,863.067688 720.723755,863.485840 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M745.055725,848.731689 
	C745.293030,848.146545 745.850586,847.582092 746.726990,847.045410 
	C746.489258,847.632935 745.932556,848.192627 745.055725,848.731689 
z"/>
<path fill="#D5D2A8" opacity="1.000000" stroke="none" 
	d="
M761.164307,836.602356 
	C761.356628,836.132385 761.816956,835.587158 762.559448,835.008423 
	C762.371887,835.492249 761.902100,836.009705 761.164307,836.602356 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M747.925903,846.777039 
	C748.181335,846.154358 748.778198,845.584351 749.698608,845.017212 
	C749.437256,845.623352 748.852234,846.226501 747.925903,846.777039 
z"/>
<path fill="#554738" opacity="1.000000" stroke="none" 
	d="
M732.813354,856.614319 
	C733.102417,856.036987 733.725220,855.514832 734.666138,854.992981 
	C734.371887,855.552002 733.759521,856.110718 732.813354,856.614319 
z"/>
<path fill="#322818" opacity="1.000000" stroke="none" 
	d="
M703.874756,872.510132 
	C703.805115,872.400818 703.846436,872.440002 703.842712,872.424805 
	C703.838989,872.409607 704.094666,872.647949 704.094666,872.647949 
	C704.094666,872.647949 703.944336,872.619385 703.874756,872.510132 
z"/>
<path fill="#5F5A4A" opacity="1.000000" stroke="none" 
	d="
M724.152710,853.448669 
	C719.042175,856.892151 713.684753,860.054382 707.745483,863.504089 
	C705.544983,864.758667 703.926270,865.725769 702.307556,866.692871 
	C699.727051,867.796631 697.146545,868.900391 693.978638,870.296204 
	C680.715149,875.515381 668.134216,880.708984 655.331787,885.282532 
	C645.673645,888.732910 635.732056,891.389893 625.752808,894.330200 
	C625.319458,894.291077 625.123169,894.427612 624.560913,894.660645 
	C620.422363,895.463257 616.723022,896.275330 613.023682,897.087341 
	C607.286499,898.001282 601.549377,898.915283 595.394531,899.593811 
	C594.976807,899.358337 594.936279,898.974426 594.936279,898.974426 
	C598.585083,898.210449 602.368835,897.822571 605.844421,896.575684 
	C609.256714,895.351685 612.195435,892.726685 615.635254,891.682251 
	C619.463074,890.519836 623.651611,890.602905 627.625549,889.842712 
	C629.997070,889.389099 632.257874,888.357056 634.781799,887.292480 
	C646.017639,886.224670 656.072571,882.074707 666.183960,878.096558 
	C674.774658,874.716675 683.433228,871.505981 691.980042,868.020081 
	C694.340027,867.057556 696.324402,865.212219 698.624817,864.058472 
	C706.419250,860.149109 714.269409,856.350220 722.137085,852.590271 
	C722.542236,852.396606 723.307983,852.957642 724.152710,853.448669 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M873.276001,700.832764 
	C869.517761,699.829407 867.600586,700.747559 866.562561,704.610474 
	C865.944641,706.909668 863.782227,708.793823 862.058105,711.007812 
	C862.952759,708.471313 864.138916,705.806213 865.246948,703.109009 
	C867.748901,697.018616 870.064331,690.846497 872.743530,684.835938 
	C874.504089,680.886230 876.766968,677.160461 879.065186,673.205444 
	C880.590637,670.652405 881.675171,668.107117 883.189880,665.850647 
	C884.080139,664.524353 885.654114,663.657104 886.992859,663.237183 
	C886.924988,664.914246 886.784790,665.937622 886.473999,667.185303 
	C886.094604,668.575562 885.885925,669.741516 885.677246,670.907410 
	C885.438660,671.735962 885.200073,672.564514 884.461426,673.800659 
	C883.094666,676.990784 882.228027,679.773193 881.361389,682.555664 
	C881.182861,683.158630 881.004333,683.761658 880.360596,684.768738 
	C879.376587,686.626892 878.857910,688.080994 878.339233,689.535095 
	C877.834900,690.806763 877.330505,692.078430 876.367981,693.785767 
	C875.694397,694.958496 875.479065,695.695618 875.263733,696.432739 
	C874.693176,697.841064 874.122498,699.249329 873.276001,700.832764 
z"/>
<path fill="#D5D2A8" opacity="1.000000" stroke="none" 
	d="
M755.772705,833.557495 
	C752.752869,836.026001 749.405579,838.544067 745.711914,841.076416 
	C748.725403,838.596191 752.085388,836.101624 755.772705,833.557495 
z"/>
<path fill="#D3CD93" opacity="1.000000" stroke="none" 
	d="
M806.761658,788.224609 
	C807.009155,788.773926 806.972351,789.470581 806.885925,790.510193 
	C805.583069,791.544922 804.329834,792.236755 802.790527,793.033386 
	C803.828735,791.549438 805.153015,789.960754 806.761658,788.224609 
z"/>
<path fill="#D5D2A8" opacity="1.000000" stroke="none" 
	d="
M739.804138,844.824768 
	C739.193420,845.514282 738.292114,846.202393 737.085144,846.892517 
	C737.690796,846.204102 738.602173,845.513733 739.804138,844.824768 
z"/>
<path fill="#D3CD93" opacity="1.000000" stroke="none" 
	d="
M808.896606,785.866821 
	C808.614075,786.455994 808.078674,787.109741 807.317993,787.894653 
	C807.609741,787.327698 808.126770,786.629578 808.896606,785.866821 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M889.742554,660.307373 
	C890.611755,656.735474 891.770264,652.958801 893.217285,649.017822 
	C892.347839,652.603271 891.189819,656.352905 889.742554,660.307373 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M881.629517,682.325745 
	C882.228027,679.773193 883.094666,676.990784 884.263916,674.067993 
	C883.676880,676.650452 882.787231,679.373108 881.629517,682.325745 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M878.611023,689.345459 
	C878.857910,688.080994 879.376587,686.626892 880.204529,685.043213 
	C879.970154,686.327576 879.426453,687.741638 878.611023,689.345459 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M885.935791,670.727844 
	C885.885925,669.741516 886.094604,668.575562 886.709717,667.229858 
	C887.116150,667.050110 887.592102,667.091614 887.592102,667.091614 
	C887.126221,668.243835 886.660339,669.396057 885.935791,670.727844 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M887.817932,666.775635 
	C887.592102,667.091614 887.116150,667.050110 886.880371,667.005493 
	C886.784790,665.937622 886.924988,664.914246 887.098389,663.507568 
	C887.605469,662.825500 888.079346,662.526733 888.814941,662.126160 
	C888.732361,663.502808 888.388062,664.981201 887.817932,666.775635 
z"/>
<path fill="#A98F66" opacity="1.000000" stroke="none" 
	d="
M875.502319,696.258423 
	C875.479065,695.695618 875.694397,694.958496 876.169739,694.067871 
	C876.200073,694.637695 875.970520,695.360962 875.502319,696.258423 
z"/>
<path fill="#645A41" opacity="1.000000" stroke="none" 
	d="
M594.557373,898.904358 
	C594.936279,898.974426 594.976807,899.358337 594.992065,899.550720 
	C575.809021,901.171143 556.614319,902.649963 537.409546,903.983948 
	C533.605774,904.248169 529.763306,903.955872 525.476318,903.994995 
	C521.277527,903.753784 517.541138,903.437866 513.067017,902.894592 
	C509.867798,902.589722 507.406250,902.512146 504.944702,902.434570 
	C502.616150,902.192932 500.287628,901.951233 497.265228,901.505005 
	C495.702362,901.284729 494.833313,901.268860 493.964294,901.253052 
	C493.232880,901.122009 492.501465,900.990967 491.074890,900.714111 
	C488.908600,900.456787 487.437408,900.345337 485.966248,900.233765 
	C485.542328,900.119202 485.118378,900.004578 484.050232,899.683655 
	C480.586151,899.124878 477.766296,898.772400 474.946411,898.419922 
	C474.519928,898.290588 474.093475,898.161194 473.071289,897.787476 
	C471.310638,897.391235 470.145752,897.239258 468.980835,897.087219 
	C468.549530,896.993774 468.118256,896.900330 467.355164,896.814941 
	C464.611572,896.212830 462.199829,895.602722 459.189941,894.797852 
	C458.053589,894.541443 457.515381,894.479736 456.977142,894.417969 
	C452.463379,893.144165 447.949615,891.870361 443.296478,890.045654 
	C446.765808,889.963440 450.374512,890.432190 454.392944,890.923706 
	C457.536835,891.623291 460.271027,892.300293 463.385925,893.058838 
	C465.829681,893.441284 467.892639,893.742065 470.202148,894.185364 
	C471.957397,893.895081 473.466095,893.462341 474.974792,893.029541 
	C475.411804,893.038574 475.848816,893.047546 476.740234,893.358276 
	C478.132629,894.106628 479.070587,894.553345 480.008545,895.000000 
	C504.919769,900.609985 530.216919,901.424194 555.600891,900.941223 
	C558.866882,900.879150 562.116760,899.966431 565.693298,899.230957 
	C573.613525,898.861694 581.214172,898.685364 588.815796,898.574219 
	C590.601685,898.548096 592.390808,898.741760 594.557373,898.904358 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M463.005188,892.977295 
	C460.271027,892.300293 457.536835,891.623291 454.503845,890.615479 
	C456.392395,887.553711 455.809662,885.728516 452.726929,883.992126 
	C449.050232,881.921143 448.896698,876.443420 452.577271,875.383118 
	C458.498291,873.677429 459.058807,869.685730 459.136078,864.652344 
	C459.165558,862.730652 460.239899,859.706421 461.636383,859.134766 
	C465.776886,857.439697 466.994537,854.647705 467.032288,850.582886 
	C467.100677,843.220398 467.187195,843.221191 474.743011,842.997009 
	C480.784271,842.817627 482.843170,840.829224 482.980957,834.862244 
	C483.065521,831.200989 483.158081,827.528015 482.963043,823.875488 
	C482.764587,820.159424 484.343048,818.985107 487.960785,818.936462 
	C498.149719,818.799377 498.669830,818.207642 498.956848,807.648926 
	C499.029053,804.993225 500.226318,803.973389 502.360626,802.420044 
	C506.779480,799.204041 510.424622,794.923401 514.368164,791.060303 
	C514.584595,790.848389 514.544861,790.383728 514.753296,790.153381 
	C515.781555,789.016602 516.656616,787.381287 517.946899,786.909363 
	C521.701538,785.536316 523.117981,782.895020 522.969910,779.206116 
	C522.812683,775.289490 522.470337,771.380249 522.216675,767.585144 
	C514.467957,766.675415 507.134979,766.297852 500.031189,764.819153 
	C494.864105,763.743591 489.999847,761.213318 485.375275,759.273010 
	C508.335693,765.649536 531.058105,766.972046 553.858459,760.550476 
	C582.518311,752.478821 607.127197,738.583069 621.964661,711.395813 
	C631.349609,694.199402 635.128784,675.719727 631.174744,656.452332 
	C629.267029,647.156128 624.980591,638.348145 622.025208,629.193115 
	C622.191101,628.373657 622.103027,627.680115 621.944153,626.630127 
	C620.717712,624.196106 621.385437,619.762939 616.383179,622.142944 
	C614.715515,620.372070 613.353271,618.718689 612.271606,616.948120 
	C612.444458,615.571411 612.833374,613.731201 612.148987,613.145752 
	C607.433655,609.112305 602.484558,605.352905 597.630432,601.480652 
	C594.847229,599.260376 592.101196,596.993591 589.338379,594.747742 
	C589.902588,595.026489 590.466736,595.305237 591.030945,595.583984 
	C592.334656,593.475403 593.494141,591.257202 594.987000,589.292297 
	C596.214294,587.676819 597.837341,586.361938 599.547058,584.998291 
	C600.238098,585.854675 600.667114,586.626648 601.077515,587.823730 
	C600.598511,589.808716 600.311890,591.447693 599.644226,592.913147 
	C597.804016,596.951904 598.557312,598.518066 603.569885,600.057861 
	C602.932678,597.295105 598.340942,593.344666 605.722595,593.008423 
	C615.299988,605.283997 624.508728,617.494202 633.891846,629.568970 
	C635.093079,631.114746 637.188843,632.941895 638.893188,632.965393 
	C652.207825,633.148438 665.528015,632.922119 679.116333,632.706787 
	C679.830261,631.941406 680.274231,631.291382 680.718201,630.641418 
	C680.293152,633.096130 679.868042,635.550903 679.158325,639.649597 
	C674.094421,632.729858 670.677429,638.450439 665.974365,638.995361 
	C663.482300,639.092590 661.779968,639.180969 659.535217,639.297485 
	C661.054749,640.968872 662.717834,641.940430 662.812927,643.046814 
	C663.304749,648.764587 664.976929,655.091003 663.162537,660.088928 
	C661.486450,664.706116 655.928406,667.917542 652.056824,671.724426 
	C651.852112,671.925781 651.332703,671.806763 651.127625,672.007996 
	C650.036499,673.078979 648.897400,674.126892 647.974121,675.335022 
	C647.194275,676.355469 646.919434,678.313416 646.020020,678.600586 
	C640.533325,680.352844 640.707947,684.595886 640.990234,688.992737 
	C632.016052,688.685608 631.280701,689.660522 633.216064,698.195251 
	C633.452515,699.237732 632.130737,700.761902 631.298462,701.891479 
	C629.633240,704.151245 627.646667,706.186401 626.106018,708.521057 
	C625.145813,709.976257 624.876709,711.868469 624.086182,713.459534 
	C622.970276,715.705078 621.504822,717.479065 624.572510,719.790955 
	C627.252625,721.810791 626.471619,727.048218 623.615051,727.632996 
	C617.643799,728.855530 618.760132,731.296326 621.853516,734.554932 
	C622.150330,734.867676 622.175598,735.438049 622.344482,735.941101 
	C618.562988,736.161987 614.840637,736.052551 611.252808,736.694824 
	C608.495056,737.188416 605.842163,738.491455 603.269897,739.704346 
	C601.953186,740.325073 601.057007,741.960693 599.726013,742.405884 
	C595.177185,743.927490 593.168213,746.419800 595.410217,751.191101 
	C595.510071,751.403748 594.907471,751.946533 594.428528,752.623047 
	C592.555603,751.280273 590.754578,749.989075 588.869629,748.637756 
	C587.359009,752.085938 586.649170,755.279785 584.786194,757.510742 
	C582.965759,759.690796 579.968628,760.888245 576.647156,763.069153 
	C576.404907,763.926331 575.780090,766.137085 575.010681,768.859375 
	C578.078613,774.406433 578.004883,774.266846 571.878906,777.502014 
	C570.554626,778.201477 569.555115,780.197632 569.153381,781.785522 
	C568.676880,783.669006 568.895020,785.763794 569.014648,787.754822 
	C569.249023,791.656555 567.426270,793.287964 563.632080,793.001099 
	C562.803772,792.938538 561.966431,792.993225 561.133240,792.998108 
	C553.755432,793.041443 552.972046,793.733643 553.026306,800.922363 
	C553.057312,805.037170 552.774109,807.665466 547.841614,810.022644 
	C544.244873,811.741455 541.556519,816.813660 537.705750,819.845093 
	C536.313965,820.940735 537.033325,824.885498 537.028381,827.530579 
	C537.022888,830.489197 535.742920,831.647949 533.096863,833.368103 
	C528.485962,836.365540 525.006653,841.104431 521.063904,845.124023 
	C520.968567,845.221191 521.061584,845.502991 520.966248,845.600464 
	C517.266113,849.385742 514.087830,854.085876 509.669037,856.631775 
	C506.342926,858.548157 504.863922,860.146240 504.985901,863.677368 
	C505.049133,865.507385 505.239868,867.401428 504.859863,869.155396 
	C504.582764,870.434326 503.588440,872.439941 502.704529,872.558228 
	C497.949738,873.194824 496.775848,876.025269 496.811005,880.259888 
	C496.818695,881.182800 495.479187,882.341858 494.495636,882.992126 
	C493.219788,883.835632 491.679169,884.278748 489.368774,885.275391 
	C491.163940,886.270630 492.065155,886.605042 492.756866,887.188049 
	C494.177917,888.385742 495.638611,889.604492 496.725769,891.078064 
	C496.970551,891.409790 495.753143,893.599121 495.204620,893.600830 
	C492.976990,893.607910 490.509064,893.580994 488.588165,892.635315 
	C486.778442,891.744324 485.271362,888.208252 484.109955,888.423157 
	C481.011871,888.996460 478.171356,890.961426 475.101624,892.701416 
	C473.466095,893.462341 471.957397,893.895081 470.335022,893.871094 
	C469.318451,892.294983 468.175781,890.120422 467.559021,890.260498 
	C465.919922,890.632874 464.507660,892.003601 463.005188,892.977295 
M620.556213,723.379761 
	C620.049805,722.884705 619.543396,722.389648 619.036987,721.894592 
	C617.460693,723.826233 615.884338,725.757874 614.307983,727.689453 
	C614.659912,728.026367 615.011780,728.363281 615.363708,728.700134 
	C617.002319,727.119568 618.640991,725.538940 620.556213,723.379761 
M543.931519,766.478088 
	C543.557861,766.631165 543.184204,766.784180 542.810547,766.937256 
	C542.986877,767.157959 543.163147,767.378723 543.339478,767.599426 
	C543.630127,767.334290 543.920715,767.069153 543.931519,766.478088 
M536.893799,780.642212 
	C536.874939,780.858826 536.856018,781.075439 536.837158,781.292053 
	C536.959839,781.296021 537.082520,781.299927 537.205261,781.303833 
	C537.197937,781.086182 537.190613,780.868591 536.893799,780.642212 
M563.323303,761.183960 
	C563.185486,761.015625 563.047729,760.847290 562.909912,760.678955 
	C562.819458,760.761719 562.729004,760.844421 562.638550,760.927124 
	C562.796570,761.077271 562.954590,761.227478 563.323303,761.183960 
M572.501465,756.474792 
	C572.501465,756.474792 572.516602,756.514832 572.501465,756.474792 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M641.250000,689.170654 
	C640.707947,684.595886 640.533325,680.352844 646.020020,678.600586 
	C646.919434,678.313416 647.194275,676.355469 647.974121,675.335022 
	C648.897400,674.126892 650.036499,673.078979 651.127625,672.007996 
	C651.332703,671.806763 651.852112,671.925781 652.056824,671.724426 
	C655.928406,667.917542 661.486450,664.706116 663.162537,660.088928 
	C664.976929,655.091003 663.304749,648.764587 662.812927,643.046814 
	C662.717834,641.940430 661.054749,640.968872 659.535217,639.297485 
	C661.779968,639.180969 663.482300,639.092590 665.592834,639.007019 
	C666.141113,642.312073 667.794006,643.154358 670.990112,642.693115 
	C673.224670,642.370728 675.726318,642.604187 677.880859,643.297485 
	C682.115662,644.660095 683.495544,643.681091 683.671753,639.182007 
	C683.729797,637.698730 684.904785,636.069702 685.995422,634.907349 
	C686.448547,634.424500 688.869507,634.804199 688.935608,635.108521 
	C689.259827,636.602478 689.513611,638.354126 689.017151,639.736389 
	C687.771606,643.204651 687.269836,647.368652 682.285461,648.063721 
	C681.298157,648.201355 680.559021,649.915894 679.647888,650.846863 
	C678.043640,652.486023 676.252991,653.963440 674.792480,655.718384 
	C670.885193,660.413391 667.021362,665.152161 663.366943,670.042908 
	C662.697205,670.939209 662.953064,672.640991 663.028442,673.956787 
	C663.188049,676.742004 662.306580,678.261658 659.518311,679.659790 
	C657.470703,680.686523 655.655090,683.679321 655.215759,686.068176 
	C654.504028,689.937439 654.856323,694.036987 655.040649,698.026245 
	C655.207764,701.644104 653.843689,703.531067 650.145203,702.952148 
	C647.431885,702.527344 644.521301,702.191406 642.163879,700.939636 
	C638.632263,699.064514 637.630615,696.250854 640.987427,692.890076 
	C641.664612,692.212036 641.361145,690.554443 641.250000,689.170654 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M607.619080,750.485107 
	C606.151428,751.277649 604.830688,751.726929 603.509949,752.176208 
	C603.423706,750.567810 602.746094,748.636292 603.403076,747.434021 
	C604.200378,745.974976 606.124207,745.131592 607.560608,744.021851 
	C607.957947,744.269226 608.355286,744.516602 608.752686,744.763916 
	C608.423767,746.556580 608.094910,748.349243 607.619080,750.485107 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M680.630249,630.365845 
	C680.274231,631.291382 679.830261,631.941406 678.995239,632.408203 
	C677.170532,630.002625 675.736816,627.780273 674.461426,625.432129 
	C674.619812,625.306274 674.937012,625.055298 674.937012,625.055298 
	C675.591614,625.138428 676.246277,625.221558 677.218689,625.329895 
	C678.538452,626.933472 679.540405,628.511841 680.630249,630.365845 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M479.892639,894.689575 
	C479.070587,894.553345 478.132629,894.106628 477.068054,893.357788 
	C477.886566,893.496704 478.831665,893.937927 479.892639,894.689575 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M672.506592,639.508423 
	C672.486389,639.515991 672.526855,639.500916 672.506592,639.508423 
z"/>
<path fill="#8C7652" opacity="1.000000" stroke="none" 
	d="
M673.565186,623.418152 
	C674.080688,623.667725 674.435425,624.087646 674.863525,624.781494 
	C674.937012,625.055298 674.619812,625.306274 674.262878,625.265381 
	C673.630066,624.791504 673.354248,624.358521 673.078430,623.925476 
	C673.078430,623.925476 673.404297,623.588562 673.565186,623.418152 
z"/>
<path fill="#5C503A" opacity="1.000000" stroke="none" 
	d="
M425.349487,885.302551 
	C424.115417,885.056824 423.254181,884.797485 422.063538,884.501953 
	C413.015778,880.880676 404.274963,877.348755 395.587372,873.690430 
	C390.406952,871.509033 385.310120,869.129028 379.659058,866.500793 
	C378.411377,865.837463 377.680298,865.511719 376.949249,865.185974 
	C374.711975,864.021545 372.474670,862.857178 369.705811,861.385498 
	C368.433807,860.766968 367.693451,860.455627 366.953094,860.144287 
	C366.329742,859.682312 365.706390,859.220337 364.573303,858.449585 
	C363.071869,857.708130 362.080078,857.275574 361.088318,856.842957 
	C360.427277,856.468506 359.766205,856.094055 358.643799,855.399780 
	C357.110199,854.422241 356.037933,853.764526 354.965698,853.106812 
	C354.651733,852.975342 354.337799,852.843994 353.667786,852.437378 
	C353.194885,851.815186 353.078064,851.468201 352.961212,851.121155 
	C352.967834,850.929382 352.974426,850.737610 353.351624,850.339111 
	C358.818817,852.429016 363.915436,854.725708 369.147247,857.204285 
	C369.479797,857.617676 369.706573,857.815552 370.326233,858.001160 
	C373.129242,858.701660 375.568817,859.380737 378.240906,860.290771 
	C383.244690,863.006531 388.011017,865.500854 392.791626,867.967590 
	C393.663422,868.417419 394.784760,868.546997 395.466461,869.176086 
	C401.182495,874.450684 408.382782,876.141663 415.650604,877.647217 
	C419.395264,878.422974 424.155487,876.813721 425.833038,882.231689 
	C425.925568,882.530579 426.692047,882.653137 427.163330,882.797302 
	C430.892090,883.937500 434.689056,884.897339 438.318909,886.292725 
	C439.426239,886.718445 440.077789,888.329773 440.938110,889.398132 
	C438.524597,888.855042 436.111084,888.311951 433.361267,887.642212 
	C430.590790,886.773254 428.156586,886.031067 425.349487,885.302551 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M355.052795,853.442200 
	C356.037933,853.764526 357.110199,854.422241 358.358093,855.354858 
	C357.402435,855.012329 356.271210,854.394958 355.052795,853.442200 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M361.199432,857.187866 
	C362.080078,857.275574 363.071869,857.708130 364.281555,858.391968 
	C363.436523,858.273071 362.373535,857.902893 361.199432,857.187866 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M348.091736,849.427612 
	C348.921387,849.527344 349.863495,849.933472 350.945740,850.628601 
	C350.125336,850.523071 349.164764,850.128540 348.091736,849.427612 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M367.086334,860.444214 
	C367.693451,860.455627 368.433807,860.766968 369.372314,861.316406 
	C368.786804,861.284363 368.003204,861.014282 367.086334,860.444214 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M377.087341,865.474121 
	C377.680298,865.511719 378.411377,865.837463 379.311066,866.425293 
	C378.728271,866.379028 377.976837,866.070679 377.087341,865.474121 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M352.648132,851.063477 
	C353.078064,851.468201 353.194885,851.815186 353.411774,852.418945 
	C352.754333,852.302246 351.996826,851.928772 351.154846,851.224731 
	C351.491943,850.931396 351.913513,850.968567 352.648132,851.063477 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M339.089355,843.336548 
	C339.670685,843.442078 340.354523,843.854797 341.187408,844.538940 
	C340.621582,844.421448 339.906708,844.032654 339.089355,843.336548 
z"/>
<path fill="#AE996B" opacity="1.000000" stroke="none" 
	d="
M343.132507,846.291504 
	C343.746216,846.361572 344.453033,846.750183 345.309082,847.416443 
	C344.714111,847.332703 343.969849,846.971313 343.132507,846.291504 
z"/>
<path fill="#444244" opacity="1.000000" stroke="none" 
	d="
M369.012024,857.022461 
	C363.915436,854.725708 358.818817,852.429016 353.366119,850.051636 
	C351.468689,848.076904 350.225006,845.783203 348.326263,844.368774 
	C343.880646,841.057251 339.019196,838.308899 334.513275,835.071899 
	C328.406189,830.684692 322.474121,826.053772 316.230774,821.294189 
	C315.185303,817.947205 313.944855,814.395935 318.603821,813.516724 
	C322.618591,812.759033 324.664185,815.008301 325.131409,819.117126 
	C325.278870,820.413879 326.800110,821.554504 327.692627,822.766541 
	C328.808197,821.776489 330.113647,820.928894 330.990967,819.760010 
	C332.071777,818.319946 332.512299,816.339905 333.728912,815.072510 
	C337.017944,811.646057 338.084625,808.480896 335.042725,804.071716 
	C333.045868,801.177307 337.685638,793.844788 340.391602,791.824829 
	C344.689941,788.616150 348.188599,784.330444 351.991394,780.466797 
	C353.385162,779.050720 354.459229,776.667908 356.070160,776.256226 
	C359.221741,775.450867 360.050049,773.773193 360.009552,770.934143 
	C359.947876,766.609497 360.069794,762.281799 359.989044,757.957825 
	C359.910126,753.731812 361.934631,751.818726 366.088074,752.004944 
	C366.420074,752.019775 366.753510,752.006042 367.086212,752.000977 
	C375.826477,751.868286 375.913544,751.868591 375.960205,742.834412 
	C375.979645,739.066467 378.074310,737.794434 380.844696,735.491699 
	C384.662567,732.318298 388.175110,728.328674 391.932556,724.795288 
	C393.575439,723.250305 395.187378,721.653503 396.648102,719.939392 
	C397.884491,718.488647 399.656616,716.917847 399.834961,715.254700 
	C400.293610,710.977600 400.100616,706.616821 399.977417,702.294861 
	C399.851440,697.876282 401.803558,695.758057 406.250763,696.000427 
	C406.914185,696.036560 407.581421,696.003601 408.246918,695.999878 
	C415.954498,695.956482 415.954468,695.956360 416.000122,688.169434 
	C416.044220,680.649170 416.696228,680.261475 424.362823,679.894531 
	C427.907318,679.724854 432.683411,682.274780 435.081238,676.518433 
	C435.902466,678.280701 436.477844,680.122742 436.466370,681.961060 
	C436.428741,687.980408 436.177612,693.998535 436.028931,700.354614 
	C436.353943,701.523438 436.658600,702.354858 436.967285,703.450562 
	C436.967224,704.066528 436.963074,704.418152 436.751526,705.111572 
	C436.025208,711.986633 435.506348,718.519958 435.013855,725.391418 
	C435.940613,726.835449 436.841034,727.941406 437.836792,729.051453 
	C437.932129,729.055542 437.957642,729.244690 437.953491,729.614929 
	C437.962524,730.978760 437.975769,731.972351 437.629211,733.015015 
	C434.103394,734.952454 433.013031,737.633606 434.039917,741.365295 
	C436.191589,737.720947 437.998230,733.964355 443.071167,734.901306 
	C443.038483,734.885071 443.110077,734.870544 443.159729,735.171753 
	C443.809235,735.914551 444.409088,736.356201 445.098389,736.791016 
	C445.187775,736.784180 445.212341,736.961792 445.153931,737.340454 
	C445.071808,738.487244 445.048096,739.255310 444.679810,740.025146 
	C443.425507,740.352356 442.515717,740.677856 441.224121,741.139954 
	C447.195862,745.429626 447.306213,745.436584 449.334290,741.982788 
	C450.234436,741.884277 450.747742,741.815247 451.190521,741.823608 
	C451.120026,741.901001 451.225861,741.720337 451.259003,742.059082 
	C451.998444,742.787292 452.704773,743.176819 453.429779,743.551697 
	C453.448486,743.537048 453.494415,743.549133 453.360657,743.906067 
	C453.166199,744.507629 453.105469,744.752258 452.801727,745.233276 
	C447.701263,753.107117 447.601837,753.041748 440.158813,748.602905 
	C438.718292,747.743774 436.573578,748.065430 434.753662,747.842407 
	C435.601593,749.517090 436.479614,751.177307 437.289246,752.870300 
	C438.147278,754.664429 439.740906,756.614929 439.483795,758.257751 
	C439.308777,759.375854 436.567993,760.661987 434.860565,760.852661 
	C431.245636,761.256409 427.556091,760.983948 423.897247,761.004333 
	C417.380951,761.040588 417.037659,761.359192 417.007477,767.691528 
	C416.978149,773.845154 417.214783,780.011414 416.868500,786.145813 
	C416.752350,788.203674 415.483398,791.711060 414.260742,791.935242 
	C408.291260,793.029968 408.939453,797.098206 408.959290,801.295593 
	C408.988495,807.475281 407.476349,808.967651 401.295593,808.976440 
	C392.380463,808.989136 392.899170,808.707825 393.014648,817.511353 
	C393.065948,821.423706 391.479401,823.309082 387.773590,825.341370 
	C383.860687,827.487122 382.361053,833.243347 377.301849,834.788391 
	C377.213531,834.815369 377.136566,835.100220 377.161652,835.246338 
	C378.576477,843.479431 373.585999,851.931885 378.008362,860.059814 
	C375.568817,859.380737 373.129242,858.701660 370.192535,857.818787 
	C369.496857,857.383728 369.269073,857.186218 369.012024,857.022461 
M365.499878,849.127197 
	C364.943787,850.094849 364.387665,851.062561 363.831573,852.030212 
	C364.763977,852.349304 365.696350,852.668335 366.628723,852.987427 
	C366.474426,851.723633 366.320129,850.459839 365.499878,849.127197 
z"/>
<path fill="#444244" opacity="1.000000" stroke="none" 
	d="
M309.004761,814.367676 
	C309.640137,814.825684 310.304504,815.612610 310.974487,816.697876 
	C310.331329,816.229675 309.682526,815.463135 309.004761,814.367676 
z"/>
<path fill="#444244" opacity="1.000000" stroke="none" 
	d="
M311.147247,817.203857 
	C311.368286,817.236328 311.727844,817.454407 312.035156,817.834351 
	C311.721893,817.826782 311.489532,817.624573 311.147247,817.203857 
z"/>
<path fill="#444244" opacity="1.000000" stroke="none" 
	d="
M301.040955,807.357422 
	C302.376831,806.832336 303.959473,806.130493 305.152802,806.546143 
	C306.836639,807.132629 308.232422,808.546082 309.751373,809.606018 
	C308.421356,810.405640 307.091339,811.205261 305.360962,812.012695 
	C303.678345,810.573547 302.396149,809.126526 301.040955,807.357422 
z"/>
<path fill="#494543" opacity="1.000000" stroke="none" 
	d="
M436.008545,700.017090 
	C436.177612,693.998535 436.428741,687.980408 436.466370,681.961060 
	C436.477844,680.122742 435.902466,678.280701 435.300690,676.218872 
	C435.005524,673.975952 435.005524,671.954529 435.005524,669.933105 
	C435.489563,669.876404 435.973602,669.819763 436.457642,669.763062 
	C432.830994,663.007629 433.024139,661.988525 438.025482,661.104797 
	C438.876312,657.774658 438.687103,653.350952 440.788055,651.422974 
	C444.558197,647.963379 447.799988,644.016602 451.575073,640.712219 
	C453.409698,639.106323 456.097046,638.796448 456.521423,635.598145 
	C456.662598,634.534119 457.931763,633.082764 458.977295,632.762756 
	C465.808258,630.671997 466.611328,625.231384 467.069885,619.329346 
	C467.284546,616.566895 468.173187,613.856873 469.179993,611.516113 
	C469.520325,616.584351 469.437042,621.259521 469.103943,626.103210 
	C469.027679,627.845215 469.201233,629.418640 469.591309,631.379028 
	C470.152435,632.100281 470.493683,632.723145 470.842194,632.727173 
	C481.828003,632.856018 492.815552,632.998291 503.798645,632.842712 
	C504.557526,632.831970 505.859589,630.563354 505.923279,629.295227 
	C506.165253,624.476013 506.002106,619.636475 506.373474,614.835999 
	C507.715118,615.075562 508.799713,615.062622 509.612793,615.526001 
	C517.913269,620.256775 526.225769,624.971008 534.424255,629.874878 
	C545.214905,636.329285 556.344666,642.341370 566.514832,649.672607 
	C576.388916,656.790405 583.779053,666.450500 585.720276,678.946472 
	C587.590942,690.989136 586.574646,702.742493 579.064026,712.694336 
	C580.370728,707.691345 581.694702,703.089050 583.005188,698.482910 
	C587.815491,681.575256 581.342041,667.663208 570.093506,656.049927 
	C559.277222,644.882935 545.131592,638.101135 531.382690,631.096497 
	C530.047668,630.416321 527.986816,631.160828 526.262878,631.243958 
	C526.321960,631.651733 526.381042,632.059448 526.440125,632.467224 
	C529.844727,633.943665 533.249329,635.420105 536.846375,636.979980 
	C533.322998,643.401917 527.279419,641.203857 522.916016,642.485168 
	C522.916016,645.900635 523.026184,648.866699 522.889709,651.821411 
	C522.691345,656.117126 520.377625,658.385559 516.048462,658.927612 
	C506.991608,660.061890 507.000916,660.136292 507.000000,669.283569 
	C506.999908,670.116577 507.002197,670.949646 506.999359,671.782654 
	C506.966766,681.345642 505.608063,682.804810 496.090790,682.962830 
	C493.384644,683.007812 491.106689,683.246460 490.345886,686.407593 
	C489.374207,690.445007 486.336609,690.115356 483.202698,689.783752 
	C480.315613,689.478333 477.402649,689.416992 473.286316,689.177429 
	C474.975800,692.857422 476.239838,695.158569 477.087585,697.604004 
	C477.736572,699.476013 478.669006,701.836670 478.060608,703.444641 
	C476.416260,707.790588 478.155121,711.250305 480.758026,713.891418 
	C485.777008,718.984192 490.862396,724.383728 496.915436,727.981873 
	C503.154755,731.690674 510.413483,734.132996 517.539001,735.754944 
	C522.874512,736.969421 528.709106,735.901611 534.315613,736.058899 
	C536.573120,736.122253 538.814880,736.743042 540.754456,737.340820 
	C516.782837,742.287476 496.784180,735.431030 480.612549,717.859680 
	C477.553741,714.536133 475.569672,709.687134 474.469635,705.203430 
	C471.783081,694.252930 469.975983,683.089172 467.650085,672.045959 
	C467.079285,669.335693 465.876648,666.758484 465.160217,663.879761 
	C465.401001,662.858521 465.446106,662.077637 465.491211,661.296692 
	C463.646149,661.957275 461.801086,662.617859 459.662659,663.390869 
	C458.593018,663.309753 457.816772,663.116272 456.988800,662.548828 
	C454.477203,658.817932 451.799713,659.245361 448.638367,661.801880 
	C446.863708,661.571777 445.455170,661.258362 444.157166,660.662964 
	C443.761292,659.683044 443.254852,658.985107 442.748413,658.287109 
	C442.216492,659.275513 441.684570,660.263977 441.086639,661.657837 
	C440.671112,665.827454 440.246307,669.586365 439.987152,673.356750 
	C439.378479,682.212097 438.848969,691.072815 438.002960,699.942993 
	C437.148529,699.975464 436.578552,699.996277 436.008545,700.017090 
M462.909851,658.521667 
	C462.859314,658.159363 462.808746,657.796997 462.758179,657.434692 
	C462.477844,657.511536 462.197479,657.588318 461.917145,657.665161 
	C462.110565,657.985229 462.303986,658.305359 462.909851,658.521667 
M471.929657,635.594910 
	C471.929657,635.594910 472.125885,635.459961 472.125885,635.459961 
	C472.125885,635.459961 471.875488,635.474060 471.929657,635.594910 
z"/>
<path fill="#FAF169" opacity="1.000000" stroke="none" 
	d="
M505.990387,614.804504 
	C506.002106,619.636475 506.165253,624.476013 505.923279,629.295227 
	C505.859589,630.563354 504.557526,632.831970 503.798645,632.842712 
	C492.815552,632.998291 481.828003,632.856018 470.842194,632.727173 
	C470.493683,632.723145 470.152435,632.100281 469.745819,630.977417 
	C469.573822,628.770813 469.463776,627.352722 469.353760,625.934631 
	C469.437042,621.259521 469.520325,616.584351 469.538788,611.446167 
	C469.465515,608.943604 469.457031,606.904114 469.469482,604.027588 
	C469.444580,599.803955 469.398773,596.417542 469.353027,593.031067 
	C469.544952,591.900208 469.736847,590.769287 470.313782,589.345703 
	C479.387726,595.303101 488.091705,601.532471 496.757568,607.814453 
	C499.882263,610.079529 502.915436,612.470825 505.990387,614.804504 
z"/>
<path fill="#0D0B0E" opacity="1.000000" stroke="none" 
	d="
M443.976685,607.987427 
	C441.453400,610.007141 437.055298,609.695923 436.707001,614.436890 
	C436.177063,621.650452 436.867706,622.596680 445.080566,624.192444 
	C443.100281,625.407410 441.124695,627.574219 439.138458,627.583984 
	C436.915863,627.594971 434.008514,626.172791 432.647491,624.410278 
	C431.312134,622.681030 431.606628,619.727051 431.073059,617.335754 
	C430.470245,614.634033 429.741669,611.960388 428.860474,608.448364 
	C429.413422,606.560059 430.132690,603.480652 431.272461,600.565613 
	C431.647186,599.607178 433.033936,598.777588 434.115570,598.497253 
	C434.532379,598.389282 436.020325,600.039246 435.943054,600.775146 
	C435.315491,606.751526 439.430664,606.002991 443.507111,606.356445 
	C443.963013,607.123962 443.969849,607.555725 443.976685,607.987427 
z"/>
<path fill="#3D373A" opacity="1.000000" stroke="none" 
	d="
M393.424988,622.857910 
	C394.270142,621.144958 395.512634,619.496643 396.875977,617.559204 
	C398.090942,616.621277 399.185150,615.972534 400.515045,615.105530 
	C401.813873,616.361755 402.876984,617.836182 403.633179,619.675415 
	C402.557831,620.427795 401.789337,620.815491 401.020844,621.203125 
	C398.621338,621.776245 396.221863,622.349365 393.424988,622.857910 
M401.500824,618.471863 
	C401.500824,618.471863 401.516113,618.512817 401.500824,618.471863 
z"/>
<path fill="#494543" opacity="1.000000" stroke="none" 
	d="
M469.101715,592.900635 
	C469.398773,596.417542 469.444580,599.803955 469.313232,603.620117 
	C468.476654,603.417542 467.338409,602.853760 467.236389,602.141541 
	C466.630920,597.914246 466.265503,593.652527 466.070618,589.149170 
	C467.164734,590.187378 468.007568,591.478760 469.101715,592.900635 
z"/>
<path fill="#444244" opacity="1.000000" stroke="none" 
	d="
M331.695984,808.276611 
	C330.469086,809.614624 329.154755,810.589722 327.840424,811.564819 
	C327.463165,810.158325 327.085938,808.751892 326.708710,807.345398 
	C328.342010,807.534851 329.975311,807.724304 331.695984,808.276611 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M401.235046,621.368286 
	C401.789337,620.815491 402.557831,620.427795 403.660095,620.022461 
	C403.556396,620.933594 403.118866,621.862305 402.381531,622.909058 
	C401.870911,622.529297 401.660095,622.031372 401.235046,621.368286 
z"/>
<path fill="#302E31" opacity="1.000000" stroke="none" 
	d="
M444.277496,607.973999 
	C443.969849,607.555725 443.963013,607.123962 443.943359,606.369141 
	C444.646027,606.410767 445.361572,606.775513 446.077087,607.140198 
	C445.577484,607.413635 445.077911,607.687134 444.277496,607.973999 
z"/>
<path fill="#F7D943" opacity="1.000000" stroke="none" 
	d="
M562.965698,473.734955 
	C561.721985,475.612610 560.335938,477.038574 559.187683,478.635498 
	C558.453613,479.656342 557.291748,481.329620 557.650452,482.028656 
	C561.517273,489.565247 554.785522,492.587433 551.439270,496.971313 
	C549.477844,499.540771 547.160156,501.838226 544.598999,504.641602 
	C539.789612,505.950989 536.056274,510.852570 530.868103,507.286316 
	C530.707886,507.176208 530.192017,507.392029 529.968079,507.595032 
	C527.174133,510.127991 524.302429,512.586365 521.647949,515.259705 
	C516.158386,520.788269 511.063171,526.739746 505.294220,531.950989 
	C498.921326,537.707886 498.671906,537.431763 501.588806,545.124390 
	C498.331146,545.459167 495.396332,546.656311 493.694946,542.634644 
	C488.669373,530.755188 483.502258,518.932678 478.195099,507.176147 
	C473.445068,496.653778 468.435455,486.248566 463.540375,475.791687 
	C463.127716,472.841095 462.715057,469.890503 462.520081,466.343903 
	C462.790131,465.559845 462.842499,465.371765 462.894867,465.183655 
	C469.802856,465.455780 476.719116,466.070007 483.616547,465.904083 
	C490.560577,465.737000 497.319183,465.082428 498.555084,475.168640 
	C498.478302,478.396942 497.936462,481.045746 498.129211,483.639893 
	C498.346405,486.562134 499.225342,489.435211 499.816467,492.329651 
	C501.910004,490.148895 504.003540,487.968109 506.407959,485.700073 
	C510.627136,481.730194 514.535400,477.847595 518.443726,473.965027 
	C518.277954,476.028290 518.112183,478.091553 517.833435,481.561279 
	C522.243286,477.290894 526.096069,473.864624 529.563660,470.084930 
	C531.563538,467.905060 538.695068,465.413452 541.153320,466.880066 
	C542.004150,467.387695 542.651550,468.599030 542.935242,469.616302 
	C543.466064,471.520081 543.449646,473.586914 544.061890,475.454498 
	C544.630798,477.190033 545.722229,478.754303 546.583618,480.393982 
	C547.875854,479.286743 549.104980,478.093323 550.473694,477.090515 
	C553.171875,475.113617 555.812378,472.970612 558.775574,471.497345 
	C559.637390,471.068909 561.513184,472.679688 562.965698,473.734955 
z"/>
<path fill="#F5EB69" opacity="1.000000" stroke="none" 
	d="
M506.097046,485.787323 
	C504.003540,487.968109 501.910004,490.148895 499.816467,492.329651 
	C499.225342,489.435211 498.346405,486.562134 498.129211,483.639893 
	C497.936462,481.045746 498.478302,478.396942 498.852448,475.386963 
	C499.321014,474.540619 499.633026,474.079590 500.015991,473.346313 
	C501.505676,472.131744 502.924408,471.189392 504.759216,470.387085 
	C505.495392,470.692810 505.815521,470.858459 506.112762,471.420837 
	C506.159180,472.877991 506.228455,473.938446 506.142700,475.431366 
	C506.024139,479.171631 506.060608,482.479492 506.097046,485.787323 
z"/>
<path fill="#F1C625" opacity="1.000000" stroke="none" 
	d="
M462.776428,465.152893 
	C462.842499,465.371765 462.790131,465.559845 462.614502,466.013214 
	C462.268829,466.066742 462.181244,465.809143 462.320923,465.317017 
	C462.413391,465.128326 462.657990,465.122162 462.776428,465.152893 
z"/>
<path fill="#383335" opacity="1.000000" stroke="none" 
	d="
M332.316895,602.089478 
	C331.659973,601.861877 331.180328,601.455688 330.700684,601.049561 
	C330.908295,600.852844 331.115875,600.656067 331.323486,600.459351 
	C331.713715,600.943237 332.103943,601.427063 332.316895,602.089478 
z"/>
<path fill="#3D373A" opacity="1.000000" stroke="none" 
	d="
M337.838074,601.797119 
	C338.463837,601.943970 338.925262,602.245789 339.386719,602.547607 
	C339.100311,602.819580 338.813934,603.091492 338.527527,603.363464 
	C338.242920,602.893005 337.958344,602.422546 337.838074,601.797119 
z"/>
<path fill="#383335" opacity="1.000000" stroke="none" 
	d="
M332.900940,606.992065 
	C332.496582,606.996521 332.290497,606.810608 331.970154,606.444031 
	C332.229767,606.070679 332.622742,605.856750 333.015717,605.642883 
	C333.037170,606.036255 333.058655,606.429565 332.900940,606.992065 
z"/>
<path fill="#E5B30C" opacity="1.000000" stroke="none" 
	d="
M463.278290,475.967407 
	C468.435455,486.248566 473.445068,496.653778 478.195099,507.176147 
	C483.502258,518.932678 488.669373,530.755188 493.694946,542.634644 
	C495.396332,546.656311 498.331146,545.459167 501.813904,545.332886 
	C503.558502,546.328735 504.702728,547.125000 506.070679,548.185730 
	C514.387634,554.156799 522.127808,560.467590 530.646912,565.445374 
	C548.810364,576.058472 567.453735,585.846985 585.733154,596.266785 
	C591.130310,599.343323 595.967346,603.402405 601.096680,607.298340 
	C601.739136,607.938416 602.346069,608.291382 603.179443,608.932983 
	C604.846680,610.526855 606.287415,611.832031 607.646973,613.163696 
	C607.565674,613.190186 607.715881,613.159973 608.002258,613.430786 
	C608.520813,613.824524 608.752991,613.947449 609.036255,614.386475 
	C610.034058,615.471313 610.980835,616.240173 611.932861,617.034790 
	C611.938110,617.060669 611.990967,617.065247 611.990967,617.065247 
	C613.353271,618.718689 614.715515,620.372070 616.446838,622.364441 
	C616.815857,622.703369 617.146729,623.022461 617.159912,623.331787 
	C618.060425,624.480408 618.947754,625.319702 619.850586,626.107788 
	C619.866089,626.056519 619.786255,626.127930 619.782837,626.459045 
	C620.444519,627.603027 621.109741,628.415955 621.767578,629.250977 
	C621.760254,629.273132 621.771240,629.319092 621.771240,629.319092 
	C624.980591,638.348145 629.267029,647.156128 631.174744,656.452332 
	C635.128784,675.719727 631.349609,694.199402 621.964661,711.395813 
	C607.127197,738.583069 582.518311,752.478821 553.858459,760.550476 
	C531.058105,766.972046 508.335693,765.649536 485.089783,759.085938 
	C483.985199,758.890198 483.540863,758.822876 482.857758,758.501709 
	C476.802124,755.757935 470.985229,753.267944 465.125885,750.777039 
	C465.083435,750.776123 465.158295,750.815918 465.017395,750.507935 
	C463.264343,749.410217 461.652161,748.620361 459.956879,747.721924 
	C459.873749,747.613342 459.624268,747.725586 459.478394,747.400085 
	C457.386475,745.899475 455.440430,744.724304 453.494415,743.549133 
	C453.494415,743.549133 453.448486,743.537048 453.331604,743.264221 
	C452.551788,742.567688 451.888824,742.144043 451.225861,741.720337 
	C451.225861,741.720337 451.120026,741.901001 451.090576,741.511475 
	C449.111542,739.735229 447.161926,738.348511 445.212341,736.961792 
	C445.212341,736.961792 445.187775,736.784180 445.039978,736.511841 
	C444.298126,735.783142 443.704102,735.326843 443.110077,734.870544 
	C443.110077,734.870544 443.038483,734.885071 443.045593,734.610474 
	C442.505646,733.829895 441.958618,733.323914 441.313599,732.503784 
	C440.129669,731.207947 439.043671,730.226318 437.957642,729.244690 
	C437.957642,729.244690 437.932129,729.055542 437.852417,728.691040 
	C437.486084,726.908325 437.199463,725.489990 437.051819,723.644958 
	C437.422058,717.123962 437.653381,711.029663 438.069336,704.737915 
	C438.184753,704.032471 438.115570,703.524536 438.223083,702.656250 
	C438.362335,701.507751 438.324860,700.719604 438.287384,699.931396 
	C438.848969,691.072815 439.378479,682.212097 439.987152,673.356750 
	C440.246307,669.586365 440.671112,665.827454 441.451477,661.701233 
	C442.603790,661.207764 443.325226,661.076355 444.046661,660.944946 
	C445.455170,661.258362 446.863708,661.571777 448.934143,661.973999 
	C452.077545,662.349548 454.559052,662.636230 457.040558,662.922852 
	C457.816772,663.116272 458.593018,663.309753 460.039703,663.603149 
	C462.128296,663.842102 463.546387,663.981140 464.964508,664.120117 
	C465.876648,666.758484 467.079285,669.335693 467.650085,672.045959 
	C469.975983,683.089172 471.783081,694.252930 474.469635,705.203430 
	C475.569672,709.687134 477.553741,714.536133 480.612549,717.859680 
	C496.784180,735.431030 516.782837,742.287476 541.138062,737.401611 
	C542.583984,737.071655 543.336792,736.911621 544.458191,736.782959 
	C554.449646,733.889587 563.911926,730.659607 571.337280,722.944458 
	C572.090149,722.273621 572.555359,721.671265 573.354614,721.002869 
	C574.831665,719.280029 575.974670,717.623169 577.196411,715.889282 
	C577.275208,715.812317 577.129150,715.647461 577.466064,715.531433 
	C578.234802,714.623230 578.666626,713.831055 579.066833,713.040405 
	C579.035156,713.041931 579.075134,713.093201 579.075134,713.093201 
	C586.574646,702.742493 587.590942,690.989136 585.720276,678.946472 
	C583.779053,666.450500 576.388916,656.790405 566.514832,649.672607 
	C556.344666,642.341370 545.214905,636.329285 534.424255,629.874878 
	C526.225769,624.971008 517.913269,620.256775 509.612793,615.526001 
	C508.799713,615.062622 507.715118,615.075562 506.373474,614.835999 
	C502.915436,612.470825 499.882263,610.079529 496.757568,607.814453 
	C488.091705,601.532471 479.387726,595.303101 470.377533,588.916504 
	C465.633667,583.214233 460.842010,577.899719 456.851074,572.039917 
	C437.444733,543.545898 440.213776,502.005890 463.278290,475.967407 
z"/>
<path fill="#3D373A" opacity="1.000000" stroke="none" 
	d="
M460.317780,473.118164 
	C460.262482,472.757385 460.410248,472.145477 460.747620,471.285065 
	C460.798462,471.646729 460.659668,472.256866 460.317780,473.118164 
z"/>
<path fill="#373336" opacity="1.000000" stroke="none" 
	d="
M318.449280,616.482788 
	C319.418549,613.578735 320.483582,611.052734 321.548645,608.526794 
	C323.812439,609.645752 327.161621,610.204712 328.093536,612.010864 
	C329.610046,614.950073 330.471100,618.877747 329.875153,622.064026 
	C329.337494,624.938477 323.558502,624.635498 321.022064,621.645508 
	C319.889709,620.310791 319.352417,618.471313 318.449280,616.482788 
z"/>
<path fill="#DAD8AF" opacity="1.000000" stroke="none" 
	d="
M625.916626,894.400024 
	C635.732056,891.389893 645.673645,888.732910 655.331787,885.282532 
	C668.134216,880.708984 680.715149,875.515381 693.729492,870.564636 
	C695.667603,871.350159 697.267517,872.159302 699.258545,873.176880 
	C692.161499,876.828552 684.869385,880.816589 677.142822,883.596985 
	C662.825562,888.749023 648.254272,893.194946 633.204468,897.771362 
	C632.079773,897.498596 631.539429,897.389404 630.841492,896.889648 
	C629.094849,895.799438 627.505737,895.099731 625.916626,894.400024 
z"/>
<path fill="#DAD8AF" opacity="1.000000" stroke="none" 
	d="
M613.042847,897.452759 
	C616.723022,896.275330 620.422363,895.463257 624.562256,895.104248 
	C624.994934,896.734497 624.987000,897.911804 624.671021,899.156982 
	C623.537170,899.731018 622.711426,900.237122 621.885742,900.743286 
	C619.533020,901.219238 617.180359,901.695190 614.431274,902.132812 
	C613.710632,900.669006 613.386353,899.243530 613.042847,897.452759 
z"/>
<path fill="#E0DA9E" opacity="1.000000" stroke="none" 
	d="
M624.979065,899.089050 
	C624.987000,897.911804 624.994934,896.734497 625.001526,895.113647 
	C625.123169,894.427612 625.319458,894.291077 625.752808,894.330200 
	C627.505737,895.099731 629.094849,895.799438 630.586792,896.998901 
	C629.596008,898.116760 628.702454,898.734863 627.808899,899.352905 
	C627.110291,899.515625 626.411743,899.678345 625.397949,899.683655 
	C625.082825,899.526306 624.979065,899.089050 624.979065,899.089050 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M588.254150,906.620117 
	C590.150269,906.133362 592.448120,905.764954 595.165771,905.552856 
	C593.275635,906.052246 590.965759,906.395386 588.254150,906.620117 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M628.146729,899.465942 
	C628.702454,898.734863 629.596008,898.116760 630.744324,897.389404 
	C631.539429,897.389404 632.079773,897.498596 632.816528,897.803711 
	C631.503418,898.526062 629.994019,899.052551 628.146729,899.465942 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M560.145813,909.477417 
	C561.059204,909.072937 562.306091,908.827393 563.775940,908.782532 
	C562.825745,909.200867 561.652466,909.418579 560.145813,909.477417 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M624.671021,899.156982 
	C624.979065,899.089050 625.082825,899.526306 625.043701,899.747559 
	C624.188354,900.296753 623.372192,900.624634 622.220825,900.847900 
	C622.711426,900.237122 623.537170,899.731018 624.671021,899.156982 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M582.141296,907.505127 
	C582.746521,907.111633 583.664795,906.863647 584.783813,906.792114 
	C584.141113,907.195923 583.297729,907.423279 582.141296,907.505127 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M603.182739,904.701111 
	C603.768127,904.265198 604.679443,903.931702 605.792847,903.785767 
	C605.166138,904.250061 604.337463,904.526794 603.182739,904.701111 
z"/>
<path fill="#A9A276" opacity="1.000000" stroke="none" 
	d="
M610.079773,903.352173 
	C610.437378,903.010376 611.065796,902.802307 611.841797,902.757751 
	C611.443115,903.109436 610.896851,903.297668 610.079773,903.352173 
z"/>
<path fill="#CCBD7D" opacity="1.000000" stroke="none" 
	d="
M475.115601,898.708130 
	C477.766296,898.772400 480.586151,899.124878 483.719360,899.689758 
	C481.116730,899.600220 478.200775,899.298279 475.115601,898.708130 
z"/>
<path fill="#CCBD7D" opacity="1.000000" stroke="none" 
	d="
M505.136932,902.722778 
	C507.406250,902.512146 509.867798,902.589722 512.645386,902.900208 
	C510.417328,903.092407 507.873260,903.051697 505.136932,902.722778 
z"/>
<path fill="#CCBD7D" opacity="1.000000" stroke="none" 
	d="
M486.110260,900.527222 
	C487.437408,900.345337 488.908600,900.456787 490.703064,900.746948 
	C489.435699,900.890686 487.844971,900.855652 486.110260,900.527222 
z"/>
<path fill="#CCBD7D" opacity="1.000000" stroke="none" 
	d="
M469.097687,897.383179 
	C470.145752,897.239258 471.310638,897.391235 472.735474,897.784058 
	C471.735107,897.909607 470.474823,897.794373 469.097687,897.383179 
z"/>
<path fill="#CCBD7D" opacity="1.000000" stroke="none" 
	d="
M494.076965,901.522034 
	C494.833313,901.268860 495.702362,901.284729 496.825317,901.516907 
	C496.116028,901.752563 495.152832,901.771790 494.076965,901.522034 
z"/>
<path fill="#4B494B" opacity="1.000000" stroke="none" 
	d="
M483.096497,758.755493 
	C483.540863,758.822876 483.985199,758.890198 484.715088,759.144531 
	C489.999847,761.213318 494.864105,763.743591 500.031189,764.819153 
	C507.134979,766.297852 514.467957,766.675415 522.216675,767.585144 
	C522.470337,771.380249 522.812683,775.289490 522.969910,779.206116 
	C523.117981,782.895020 521.701538,785.536316 517.946899,786.909363 
	C516.656616,787.381287 515.781555,789.016602 514.753296,790.153381 
	C514.544861,790.383728 514.584595,790.848389 514.368164,791.060303 
	C510.424622,794.923401 506.779480,799.204041 502.360626,802.420044 
	C500.226318,803.973389 499.029053,804.993225 498.956848,807.648926 
	C498.669830,818.207642 498.149719,818.799377 487.960785,818.936462 
	C484.343048,818.985107 482.764587,820.159424 482.963043,823.875488 
	C483.158081,827.528015 483.065521,831.200989 482.980957,834.862244 
	C482.843170,840.829224 480.784271,842.817627 474.743011,842.997009 
	C467.187195,843.221191 467.100677,843.220398 467.032288,850.582886 
	C466.994537,854.647705 465.776886,857.439697 461.636383,859.134766 
	C460.239899,859.706421 459.165558,862.730652 459.136078,864.652344 
	C459.058807,869.685730 458.498291,873.677429 452.577271,875.383118 
	C448.896698,876.443420 449.050232,881.921143 452.726929,883.992126 
	C455.809662,885.728516 456.392395,887.553711 454.094116,890.592773 
	C450.374512,890.432190 446.765808,889.963440 443.047668,889.789124 
	C442.406219,889.977356 441.874176,889.871155 441.140137,889.581543 
	C440.077789,888.329773 439.426239,886.718445 438.318909,886.292725 
	C434.689056,884.897339 430.892090,883.937500 427.163330,882.797302 
	C426.692047,882.653137 425.925568,882.530579 425.833038,882.231689 
	C424.155487,876.813721 419.395264,878.422974 415.650604,877.647217 
	C408.382782,876.141663 401.182495,874.450684 395.466461,869.176086 
	C394.784760,868.546997 393.663422,868.417419 392.791626,867.967590 
	C388.011017,865.500854 383.244690,863.006531 378.240906,860.290771 
	C373.585999,851.931885 378.576477,843.479431 377.161652,835.246338 
	C377.136566,835.100220 377.213531,834.815369 377.301849,834.788391 
	C382.361053,833.243347 383.860687,827.487122 387.773590,825.341370 
	C391.479401,823.309082 393.065948,821.423706 393.014648,817.511353 
	C392.899170,808.707825 392.380463,808.989136 401.295593,808.976440 
	C407.476349,808.967651 408.988495,807.475281 408.959290,801.295593 
	C408.939453,797.098206 408.291260,793.029968 414.260742,791.935242 
	C415.483398,791.711060 416.752350,788.203674 416.868500,786.145813 
	C417.214783,780.011414 416.978149,773.845154 417.007477,767.691528 
	C417.037659,761.359192 417.380951,761.040588 423.897247,761.004333 
	C427.556091,760.983948 431.245636,761.256409 434.860565,760.852661 
	C436.567993,760.661987 439.308777,759.375854 439.483795,758.257751 
	C439.740906,756.614929 438.147278,754.664429 437.289246,752.870300 
	C436.479614,751.177307 435.601593,749.517090 434.753662,747.842407 
	C436.573578,748.065430 438.718292,747.743774 440.158813,748.602905 
	C447.601837,753.041748 447.701263,753.107117 453.095398,745.418579 
	C455.629517,746.153503 457.626892,746.939575 459.624268,747.725586 
	C459.624268,747.725586 459.873749,747.613342 460.044128,748.071167 
	C461.862457,749.291260 463.510376,750.053589 465.158295,750.815918 
	C465.158295,750.815918 465.083435,750.776123 465.178894,751.128723 
	C468.744690,753.542786 472.078522,755.908020 475.736633,757.551575 
	C477.927887,758.536072 480.626129,758.392212 483.096497,758.755493 
z"/>
<path fill="#C3B07B" opacity="1.000000" stroke="none" 
	d="
M457.066406,894.628296 
	C457.515381,894.479736 458.053589,894.541443 458.808197,894.775635 
	C458.401611,894.911621 457.778656,894.875122 457.066406,894.628296 
z"/>
<path fill="#71612B" opacity="1.000000" stroke="none" 
	d="
M506.407959,485.700073 
	C506.060608,482.479492 506.024139,479.171631 506.431335,475.573730 
	C507.507416,476.711304 508.139862,478.138977 509.177246,480.480743 
	C513.574707,475.511230 517.468994,471.110321 521.363281,466.709412 
	C520.956360,466.108398 520.549500,465.507416 520.142578,464.906403 
	C515.473572,466.945648 510.804596,468.984863 506.135620,471.024109 
	C505.815521,470.858459 505.495392,470.692810 505.057983,470.268555 
	C511.037201,464.984100 518.120422,462.938690 526.343018,463.064514 
	C526.724487,463.619202 526.685120,464.173401 526.357544,465.074799 
	C524.513855,467.249939 522.958374,469.077881 521.402893,470.905823 
	C521.402893,470.905823 521.085938,470.886353 521.085938,470.886353 
	C521.085938,470.886353 520.896484,471.141174 520.594727,471.448242 
	C520.013794,472.162750 519.734558,472.570160 519.455322,472.977600 
	C519.140381,473.182831 518.825378,473.388062 518.477051,473.779175 
	C514.535400,477.847595 510.627136,481.730194 506.407959,485.700073 
z"/>
<path fill="#F3CB2C" opacity="1.000000" stroke="none" 
	d="
M611.559631,402.169739 
	C609.949341,402.558655 608.331604,402.603760 606.713867,402.648834 
	C606.937256,401.655304 607.160583,400.661743 607.383911,399.668213 
	C608.773315,400.387421 610.162781,401.106628 611.559631,402.169739 
z"/>
<path fill="#F4D136" opacity="1.000000" stroke="none" 
	d="
M626.587646,400.985779 
	C624.529236,403.618378 622.106140,406.258484 619.363159,409.001709 
	C619.032532,408.638336 619.021851,408.171814 619.009277,407.356201 
	C621.412537,405.002472 623.817810,402.997894 626.587646,400.985779 
z"/>
<path fill="#F3CB2C" opacity="1.000000" stroke="none" 
	d="
M601.786316,397.563721 
	C601.054199,396.457825 600.633179,395.271545 600.212158,394.085236 
	C601.080261,393.986359 601.948425,393.887482 602.816528,393.788605 
	C602.576843,395.020172 602.337158,396.251770 601.786316,397.563721 
z"/>
<path fill="#F4D136" opacity="1.000000" stroke="none" 
	d="
M632.740723,394.102356 
	C632.289246,395.008911 631.501953,395.975433 630.399109,397.035645 
	C630.857361,396.140320 631.631165,395.151306 632.740723,394.102356 
z"/>
<path fill="#F4D136" opacity="1.000000" stroke="none" 
	d="
M640.752502,385.105835 
	C640.579346,385.644928 640.104370,386.265717 639.340637,386.987183 
	C639.518188,386.454437 639.984436,385.820984 640.752502,385.105835 
z"/>
<path fill="#F4D136" opacity="1.000000" stroke="none" 
	d="
M611.688721,417.065918 
	C609.690979,419.729126 607.327087,422.398926 604.637329,425.137695 
	C604.152405,422.619049 607.470520,418.769470 611.688721,417.065918 
z"/>
<path fill="#F4D136" opacity="1.000000" stroke="none" 
	d="
M616.749878,412.069885 
	C615.643494,413.669312 614.164917,415.271149 612.371704,416.970917 
	C613.497253,415.403320 614.937439,413.737793 616.749878,412.069885 
z"/>
<path fill="#F7D943" opacity="1.000000" stroke="none" 
	d="
M521.824341,470.909302 
	C522.958374,469.077881 524.513855,467.249939 526.431396,465.343903 
	C527.135864,468.644653 525.836792,470.701508 521.824341,470.909302 
z"/>
<path fill="#F3CB2C" opacity="1.000000" stroke="none" 
	d="
M561.002441,431.003052 
	C560.832092,431.683746 560.659241,432.361389 560.486450,433.039001 
	C560.202209,432.927490 559.917908,432.815979 559.633667,432.704468 
	C559.921753,432.182068 560.209839,431.659668 560.746582,431.066040 
	C560.995117,430.994843 561.000000,431.000000 561.002441,431.003052 
z"/>
<path fill="#F7D943" opacity="1.000000" stroke="none" 
	d="
M519.756226,472.771790 
	C519.734558,472.570160 520.013794,472.162750 520.637024,471.601196 
	C520.673096,471.820038 520.365112,472.193024 519.756226,472.771790 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M505.846924,547.921326 
	C504.702728,547.125000 503.558502,546.328735 502.189240,545.323975 
	C498.671906,537.431763 498.921326,537.707886 505.294220,531.950989 
	C511.063171,526.739746 516.158386,520.788269 521.647949,515.259705 
	C524.302429,512.586365 527.174133,510.127991 529.968079,507.595032 
	C530.192017,507.392029 530.707886,507.176208 530.868103,507.286316 
	C536.056274,510.852570 539.789612,505.950989 544.558716,504.993866 
	C546.044556,507.585632 546.771057,510.482208 548.360657,512.782349 
	C553.923340,520.831726 559.638672,528.786194 565.575439,536.563416 
	C570.644775,543.204163 576.176453,549.489929 581.356079,556.049194 
	C585.226929,560.951050 588.849365,566.048523 592.607239,571.040283 
	C595.789124,575.266846 599.003174,579.469238 602.208923,583.677795 
	C607.599121,590.754150 613.060242,597.777649 618.368225,604.915100 
	C623.677612,612.054443 628.789490,619.340515 634.080444,626.493896 
	C636.618774,629.925720 639.677368,631.117432 644.379089,630.672485 
	C652.763733,629.879028 661.278625,630.455505 669.739014,630.475647 
	C670.838989,630.478271 671.938660,630.578308 674.185059,630.690430 
	C670.851685,625.151184 667.986084,620.389343 665.133545,615.255127 
	C667.635559,617.741516 670.124573,620.600281 672.845947,623.692261 
	C673.354248,624.358521 673.630066,624.791504 674.104492,625.391235 
	C675.736816,627.780273 677.170532,630.002625 678.725342,632.523621 
	C665.528015,632.922119 652.207825,633.148438 638.893188,632.965393 
	C637.188843,632.941895 635.093079,631.114746 633.891846,629.568970 
	C624.508728,617.494202 615.299988,605.283997 605.614990,592.674805 
	C603.827148,590.622192 602.461609,589.010376 601.096130,587.398560 
	C600.667114,586.626648 600.238098,585.854675 599.447998,584.664978 
	C579.968445,558.923218 560.849976,533.599243 541.406860,507.845093 
	C536.222778,511.537994 531.636475,514.181213 527.824646,517.670532 
	C522.449402,522.590881 517.863098,528.368530 512.533142,533.344360 
	C508.184967,537.403687 505.044769,541.664734 505.846924,547.921326 
z"/>
<path fill="#EFE567" opacity="1.000000" stroke="none" 
	d="
M665.066772,614.524963 
	C661.362427,610.934021 657.210327,607.298767 653.875793,603.025635 
	C647.675720,595.080383 641.934570,586.775940 636.051941,578.585449 
	C635.304504,577.544739 634.854065,576.290710 634.279663,574.743591 
	C635.250122,575.201477 636.206970,576.051208 637.430054,577.078003 
	C646.346130,588.880798 654.997986,600.505066 663.640625,612.136292 
	C664.128540,612.792908 664.540588,613.506042 665.066772,614.524963 
z"/>
<path fill="#DAD8AF" opacity="1.000000" stroke="none" 
	d="
M702.735596,866.755615 
	C703.926270,865.725769 705.544983,864.758667 707.520630,863.747070 
	C706.306335,864.741089 704.734985,865.779724 702.735596,866.755615 
z"/>
<path fill="#E1E0C1" opacity="1.000000" stroke="none" 
	d="
M771.071045,822.047607 
	C771.958557,822.732300 772.808289,823.402893 773.657959,824.073486 
	C773.237854,824.502014 772.817749,824.930542 772.397644,825.359070 
	C771.952637,824.504456 771.507629,823.649841 771.062012,822.452148 
	C771.061462,822.109070 771.033203,822.033386 771.071045,822.047607 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M463.385925,893.058838 
	C464.507660,892.003601 465.919922,890.632874 467.559021,890.260498 
	C468.175781,890.120422 469.318451,892.294983 470.088501,893.728638 
	C467.892639,893.742065 465.829681,893.441284 463.385925,893.058838 
z"/>
<path fill="#494642" opacity="1.000000" stroke="none" 
	d="
M506.070679,548.185730 
	C505.044769,541.664734 508.184967,537.403687 512.533142,533.344360 
	C517.863098,528.368530 522.449402,522.590881 527.824646,517.670532 
	C531.636475,514.181213 536.222778,511.537994 541.406860,507.845093 
	C560.849976,533.599243 579.968445,558.923218 599.185913,584.580444 
	C597.837341,586.361938 596.214294,587.676819 594.987000,589.292297 
	C593.494141,591.257202 592.334656,593.475403 591.030945,595.583984 
	C590.466736,595.305237 589.902588,595.026489 589.338379,594.747742 
	C592.101196,596.993591 594.847229,599.260376 597.630432,601.480652 
	C602.484558,605.352905 607.433655,609.112305 612.148987,613.145752 
	C612.833374,613.731201 612.444458,615.571411 612.271606,616.948120 
	C611.990967,617.065247 611.938110,617.060669 611.882507,616.709595 
	C610.879700,615.595886 609.932434,614.833130 608.985229,614.070435 
	C608.752991,613.947449 608.520813,613.824524 607.999451,613.443054 
	C607.710266,613.184509 607.728210,613.137268 607.728210,613.137268 
	C606.287415,611.832031 604.846680,610.526855 603.094421,608.680908 
	C602.208984,607.763855 601.635071,607.387512 601.061096,607.011169 
	C595.967346,603.402405 591.130310,599.343323 585.733154,596.266785 
	C567.453735,585.846985 548.810364,576.058472 530.646912,565.445374 
	C522.127808,560.467590 514.387634,554.156799 506.070679,548.185730 
z"/>
<path fill="#494642" opacity="1.000000" stroke="none" 
	d="
M601.077515,587.823730 
	C602.461609,589.010376 603.827148,590.622192 605.300171,592.567627 
	C598.340942,593.344666 602.932678,597.295105 603.569885,600.057861 
	C598.557312,598.518066 597.804016,596.951904 599.644226,592.913147 
	C600.311890,591.447693 600.598511,589.808716 601.077515,587.823730 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M620.417908,723.669067 
	C618.640991,725.538940 617.002319,727.119568 615.363708,728.700134 
	C615.011780,728.363281 614.659912,728.026367 614.307983,727.689453 
	C615.884338,725.757874 617.460693,723.826233 619.036987,721.894592 
	C619.543396,722.389648 620.049805,722.884705 620.417908,723.669067 
z"/>
<path fill="#494642" opacity="1.000000" stroke="none" 
	d="
M617.146729,623.022461 
	C617.146729,623.022461 616.815857,622.703369 616.752197,622.481873 
	C621.385437,619.762939 620.717712,624.196106 621.936157,626.636963 
	C621.999084,627.000183 621.983765,627.010864 621.717896,626.880859 
	C620.896729,626.543213 620.341492,626.335571 619.786255,626.127930 
	C619.786255,626.127930 619.866089,626.056519 619.837036,625.773315 
	C618.920837,624.667603 618.033813,623.845032 617.146729,623.022461 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M544.071411,766.641052 
	C543.920715,767.069153 543.630127,767.334290 543.339478,767.599426 
	C543.163147,767.378723 542.986877,767.157959 542.810547,766.937256 
	C543.184204,766.784180 543.557861,766.631165 544.071411,766.641052 
z"/>
<path fill="#4B494B" opacity="1.000000" stroke="none" 
	d="
M537.038574,780.646606 
	C537.190613,780.868591 537.197937,781.086182 537.205200,781.303833 
	C537.082520,781.299927 536.959839,781.296021 536.837158,781.292053 
	C536.856018,781.075439 536.874939,780.858826 537.038574,780.646606 
z"/>
<path fill="#535154" opacity="1.000000" stroke="none" 
	d="
M563.217957,761.280762 
	C562.954590,761.227478 562.796570,761.077271 562.638550,760.927124 
	C562.729004,760.844421 562.819458,760.761719 562.909912,760.678955 
	C563.047729,760.847290 563.185486,761.015625 563.217957,761.280762 
z"/>
<path fill="#494642" opacity="1.000000" stroke="none" 
	d="
M622.007080,626.993408 
	C622.103027,627.680115 622.191101,628.373657 622.025208,629.193115 
	C621.771240,629.319092 621.760254,629.273132 621.830688,628.882935 
	C621.928650,627.998840 621.956177,627.504822 621.983765,627.010864 
	C621.983765,627.010864 621.999084,627.000183 622.007080,626.993408 
z"/>
<path fill="#4B494B" opacity="1.000000" stroke="none" 
	d="
M572.509033,756.494812 
	C572.516602,756.514832 572.501465,756.474792 572.509033,756.494812 
z"/>
<path fill="#4B494B" opacity="1.000000" stroke="none" 
	d="
M369.147247,857.204285 
	C369.269073,857.186218 369.496857,857.383728 369.829102,857.797302 
	C369.706573,857.815552 369.479797,857.617676 369.147247,857.204285 
z"/>
<path fill="#4F3D2B" opacity="1.000000" stroke="none" 
	d="
M437.884705,704.935425 
	C437.653381,711.029663 437.422058,717.123962 436.809021,723.760010 
	C435.947327,724.552307 435.467407,724.802856 434.987488,725.053345 
	C435.506348,718.519958 436.025208,711.986633 436.981750,705.161987 
	C437.419464,704.870789 437.884674,704.935425 437.884705,704.935425 
z"/>
<path fill="#4B494B" opacity="1.000000" stroke="none" 
	d="
M441.411560,732.817932 
	C441.958618,733.323914 442.505646,733.829895 443.078308,734.626709 
	C437.998230,733.964355 436.191589,737.720947 434.039917,741.365295 
	C433.013031,737.633606 434.103394,734.952454 438.057037,733.022705 
	C439.700317,732.926941 440.555939,732.872437 441.411560,732.817932 
z"/>
<path fill="#4B494B" opacity="1.000000" stroke="none" 
	d="
M448.947449,742.012268 
	C447.306213,745.436584 447.195862,745.429626 441.224121,741.139954 
	C442.515717,740.677856 443.425507,740.352356 444.920288,740.224854 
	C446.652679,740.952698 447.800079,741.482483 448.947449,742.012268 
z"/>
<path fill="#4B494B" opacity="1.000000" stroke="none" 
	d="
M365.832825,849.161621 
	C366.320129,850.459839 366.474426,851.723633 366.628723,852.987427 
	C365.696350,852.668335 364.763977,852.349304 363.831543,852.030273 
	C364.387665,851.062561 364.943787,850.094849 365.832825,849.161621 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M449.334290,741.982788 
	C447.800079,741.482483 446.652679,740.952698 445.264832,740.223145 
	C445.048096,739.255310 445.071808,738.487244 445.153931,737.340454 
	C447.161926,738.348511 449.111542,739.735229 451.161072,741.434082 
	C450.747742,741.815247 450.234436,741.884277 449.334290,741.982788 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M459.478394,747.400146 
	C457.626892,746.939575 455.629517,746.153503 453.338440,745.182129 
	C453.105469,744.752258 453.166199,744.507629 453.360657,743.906067 
	C455.440430,744.724304 457.386475,745.899475 459.478394,747.400146 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M435.013855,725.391418 
	C435.467407,724.802856 435.947327,724.552307 436.670044,724.186768 
	C437.199463,725.489990 437.486084,726.908325 437.757080,728.687012 
	C436.841034,727.941406 435.940613,726.835449 435.013855,725.391418 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M441.313599,732.503784 
	C440.555939,732.872437 439.700317,732.926941 438.416870,732.973694 
	C437.975769,731.972351 437.962524,730.978760 437.953491,729.614929 
	C439.043671,730.226318 440.129669,731.207947 441.313599,732.503784 
z"/>
<path fill="#4F3D2B" opacity="1.000000" stroke="none" 
	d="
M438.002960,699.942993 
	C438.324860,700.719604 438.362335,701.507751 438.043121,702.679565 
	C437.442383,703.086609 437.201324,703.127625 436.963257,703.186218 
	C436.658600,702.354858 436.353943,701.523438 436.028931,700.354553 
	C436.578552,699.996277 437.148529,699.975464 438.002960,699.942993 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M451.259003,742.059082 
	C451.888824,742.144043 452.551788,742.567688 453.312927,743.278870 
	C452.704773,743.176819 451.998444,742.787292 451.259003,742.059082 
z"/>
<path fill="#494543" opacity="1.000000" stroke="none" 
	d="
M436.967285,703.450562 
	C437.201324,703.127625 437.442383,703.086609 437.866394,703.039795 
	C438.115570,703.524536 438.184753,704.032471 438.069305,704.737915 
	C437.884674,704.935425 437.419464,704.870789 437.189209,704.820312 
	C436.963074,704.418152 436.967224,704.066528 436.967285,703.450562 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M443.159729,735.171753 
	C443.704102,735.326843 444.298126,735.783142 444.950562,736.518677 
	C444.409088,736.356201 443.809235,735.914551 443.159729,735.171753 
z"/>
<path fill="#4C494A" opacity="1.000000" stroke="none" 
	d="
M544.089661,736.751526 
	C543.336792,736.911621 542.583984,737.071655 541.447510,737.170898 
	C538.814880,736.743042 536.573120,736.122253 534.315613,736.058899 
	C528.709106,735.901611 522.874512,736.969421 517.539001,735.754944 
	C510.413483,734.132996 503.154755,731.690674 496.915436,727.981873 
	C490.862396,724.383728 485.777008,718.984192 480.758026,713.891418 
	C478.155121,711.250305 476.416260,707.790588 478.060608,703.444641 
	C478.669006,701.836670 477.736572,699.476013 477.087585,697.604004 
	C476.239838,695.158569 474.975800,692.857422 473.286316,689.177429 
	C477.402649,689.416992 480.315613,689.478333 483.202698,689.783752 
	C486.336609,690.115356 489.374207,690.445007 490.345886,686.407593 
	C491.106689,683.246460 493.384644,683.007812 496.090790,682.962830 
	C505.608063,682.804810 506.966766,681.345642 506.999359,671.782654 
	C507.002197,670.949646 506.999908,670.116577 507.000000,669.283569 
	C507.000916,660.136292 506.991608,660.061890 516.048462,658.927612 
	C520.377625,658.385559 522.691345,656.117126 522.889709,651.821411 
	C523.026184,648.866699 522.916016,645.900635 522.916016,642.485168 
	C527.279419,641.203857 533.322998,643.401917 536.846375,636.979980 
	C533.249329,635.420105 529.844727,633.943665 526.440125,632.467224 
	C526.381042,632.059448 526.321960,631.651733 526.262878,631.243958 
	C527.986816,631.160828 530.047668,630.416321 531.382690,631.096497 
	C545.131592,638.101135 559.277222,644.882935 570.093506,656.049927 
	C581.342041,667.663208 587.815491,681.575256 583.005188,698.482910 
	C581.694702,703.089050 580.370728,707.691345 579.064026,712.694336 
	C579.075134,713.093201 579.035156,713.041931 578.731689,713.159363 
	C577.995239,714.067017 577.562195,714.857239 577.129150,715.647461 
	C577.129150,715.647461 577.275208,715.812317 576.843506,715.971497 
	C575.281372,717.776733 574.151001,719.422791 573.020630,721.068848 
	C572.555359,721.671265 572.090149,722.273621 570.963013,722.964539 
	C568.668091,723.755981 567.024597,724.435791 565.403931,725.166016 
	C559.527222,727.813721 553.606873,730.373718 547.821838,733.209290 
	C546.352173,733.929688 545.321838,735.546570 544.089661,736.751526 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M465.160217,663.879822 
	C463.546387,663.981140 462.128296,663.842102 460.333130,663.490845 
	C461.801086,662.617859 463.646149,661.957275 465.491211,661.296692 
	C465.446106,662.077637 465.401001,662.858521 465.160217,663.879822 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M456.988800,662.548889 
	C454.559052,662.636230 452.077545,662.349548 449.300293,661.890747 
	C451.799713,659.245361 454.477203,658.817932 456.988800,662.548889 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M444.157166,660.662964 
	C443.325226,661.076355 442.603790,661.207764 441.517487,661.295776 
	C441.684570,660.263977 442.216492,659.275513 442.748413,658.287109 
	C443.254852,658.985107 443.761292,659.683044 444.157166,660.662964 
z"/>
<path fill="#71612B" opacity="1.000000" stroke="none" 
	d="
M469.103943,626.103210 
	C469.463776,627.352722 469.573822,628.770813 469.529297,630.590454 
	C469.201233,629.418640 469.027679,627.845215 469.103943,626.103210 
z"/>
<path fill="#4C4532" opacity="1.000000" stroke="none" 
	d="
M462.703613,658.573547 
	C462.303986,658.305359 462.110565,657.985229 461.917145,657.665161 
	C462.197479,657.588318 462.477844,657.511536 462.758179,657.434692 
	C462.808746,657.796997 462.859314,658.159363 462.703613,658.573547 
z"/>
<path fill="#403D40" opacity="1.000000" stroke="none" 
	d="
M471.902588,635.534485 
	C471.875488,635.474060 472.125885,635.459961 472.125885,635.459961 
	C472.125885,635.459961 471.929657,635.594910 471.902588,635.534485 
z"/>
<path fill="#403D40" opacity="1.000000" stroke="none" 
	d="
M401.508484,618.492310 
	C401.516113,618.512817 401.500824,618.471863 401.508484,618.492310 
z"/>
<path fill="#403B3D" opacity="1.000000" stroke="none" 
	d="
M506.112762,471.420807 
	C510.804596,468.984863 515.473572,466.945648 520.142578,464.906403 
	C520.549500,465.507416 520.956360,466.108398 521.363281,466.709412 
	C517.468994,471.110321 513.574707,475.511230 509.177246,480.480743 
	C508.139862,478.138977 507.507416,476.711304 506.586365,475.141296 
	C506.228455,473.938446 506.159180,472.877991 506.112762,471.420807 
z"/>
<path fill="#494543" opacity="1.000000" stroke="none" 
	d="
M544.458191,736.782959 
	C545.321838,735.546570 546.352173,733.929688 547.821838,733.209290 
	C553.606873,730.373718 559.527222,727.813721 565.403931,725.166016 
	C567.024597,724.435791 568.668091,723.755981 570.675354,723.032959 
	C563.911926,730.659607 554.449646,733.889587 544.458191,736.782959 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M482.857758,758.501709 
	C480.626129,758.392212 477.927887,758.536072 475.736633,757.551575 
	C472.078522,755.908020 468.744690,753.542786 465.221344,751.129639 
	C470.985229,753.267944 476.802124,755.757935 482.857758,758.501709 
z"/>
<path fill="#494543" opacity="1.000000" stroke="none" 
	d="
M573.354614,721.002869 
	C574.151001,719.422791 575.281372,717.776733 576.764709,716.048462 
	C575.974670,717.623169 574.831665,719.280029 573.354614,721.002869 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M465.017395,750.507935 
	C463.510376,750.053589 461.862457,749.291260 460.127258,748.179749 
	C461.652161,748.620361 463.264343,749.410217 465.017395,750.507935 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M609.036255,614.386475 
	C609.932434,614.833130 610.879700,615.595886 611.877258,616.683777 
	C610.980835,616.240173 610.034058,615.471313 609.036255,614.386475 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M617.159912,623.331787 
	C618.033813,623.845032 618.920837,624.667603 619.821472,625.824585 
	C618.947754,625.319702 618.060425,624.480408 617.159912,623.331787 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M621.717896,626.880859 
	C621.956177,627.504822 621.928650,627.998840 621.838013,628.860840 
	C621.109741,628.415955 620.444519,627.603027 619.782837,626.459045 
	C620.341492,626.335571 620.896729,626.543213 621.717896,626.880859 
z"/>
<path fill="#494543" opacity="1.000000" stroke="none" 
	d="
M577.466064,715.531433 
	C577.562195,714.857239 577.995239,714.067017 578.763428,713.157837 
	C578.666626,713.831055 578.234802,714.623230 577.466064,715.531433 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M601.096680,607.298340 
	C601.635071,607.387512 602.208984,607.763855 602.867981,608.392273 
	C602.346069,608.291382 601.739136,607.938416 601.096680,607.298340 
z"/>
<path fill="#54504B" opacity="1.000000" stroke="none" 
	d="
M607.646973,613.163696 
	C607.728210,613.137268 607.710266,613.184509 607.713074,613.172241 
	C607.715881,613.159973 607.565674,613.190186 607.646973,613.163696 
z"/>


         
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}